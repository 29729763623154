import React, { Component } from "react"
import PropTypes from "prop-types"
import classnames from "classnames"
import { connect } from "react-redux"
import { push } from "connected-react-router"

import Button from "components/Button"

import TrackingSegment from "managers/segmentManager"

import { setSEOCTAFooterHeight } from "actions/ui"

import styles from "./index.css"
import consts from "consts"
import i18n from "consts/i18n"
import routesHelper from "helpers/route"
import UrlHelper from "helpers/url"
import routeHelpers from "helpers/route"
import FormatterHelper from "helpers/formatter"

import selectorNavigator from "selectors/navigator"
import selectAuthentication from "selectors/authentication"
import selectChannel from "selectors/channels"

@connect(state => {
  return {
    navigator: selectorNavigator(state),
    authentication: selectAuthentication(state),
    channelOffer: selectChannel(state)?.direct?.offer,
    pathname: state.router.location.pathname,
  }
})
export default class SEOCTAFooter extends Component {
  static propTypes = {
    background: PropTypes.string.isRequired,
    buttonText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    bodyText: PropTypes.node,
    customStyle: PropTypes.object,
    buttonsStyle: PropTypes.string,
    contentStyle: PropTypes.string,
    textStyle: PropTypes.string,
    dispatch: PropTypes.func.isRequired,
    visible: PropTypes.bool.isRequired,
    downloadCampaign: PropTypes.string,
    downloadAdGroup: PropTypes.string,
    trackDownloadIdentifier: PropTypes.string,
    navigator: PropTypes.object.isRequired,
    program: PropTypes.object.isRequired,
    channel: PropTypes.object.isRequired,
    channelsPage: PropTypes.bool.isRequired,
    replayPage: PropTypes.bool.isRequired,
    authentication: PropTypes.object.isRequired,
    channelOffer: PropTypes.object,
    redirectToApp: PropTypes.bool,
    pathname: PropTypes.string,
  }

  static defaultProps = {
    buttonText: i18n.deepLinkPage.downloadApp,
    customStyle: {},
    deeplink: false,
    visible: false,
    redirectToApp: false,
    pathname: "",
  }

  onClickCTA = () => {
    const { navigator } = this.props

    if (navigator.isMobile) {
      this.onMobileClick()
    } else {
      this.onDesktopClick()
    }
  }

  onMobileClick = () => {
    let { program, authentication } = this.props
    const programOffer = program?.data?.offer

    if (!program?.pending && program?.getBroadcastDate() === i18n.not_broadcasted) {
      this.redirectToDownloadPage()
    } else {
      if (this.props.redirectToApp) {
        UrlHelper.openInBrowser("/home", true)
      }

      if (program.pending && !this.props.channelsPage && !this.props.replayPage) {
        sessionStorage.setItem(
          consts.sessionStorageKey.programOfferToSubscribe,
          JSON.stringify({
            url: routesHelper.getDownloadURL(this.props.downloadCampaign, this.props.downloadAdGroup),
            programOffer: { freeTier: true, isMobile: true },
          })
        )
        this.props.dispatch(push(FormatterHelper.basic(`${consts.routes.offers}?signup=true`)))
      }

      if (!authentication?.isAuthenticated) {
        // if the program goes under a paid subscription
        // redirect to a /subscribe page (there is an authorisation wall already, no need in any additional steps)
        if (programOffer?.product_equivalence_code) {
          this.redirectToProgramOffer(program, programOffer, true)
        } else {
          // product is from a free tier
          this.redirectToProgramOffer(program, undefined, true)
        }
      } else {
        if (programOffer?.product_equivalence_code) {
          // there is an offer === user is not subscribed to a product
          this.redirectToProgramOffer(program, programOffer, true)
        } else {
          // no offer === program is free || user already subscribed
          this.redirectToDownloadPage()
        }
      }
    }
  }

  onDesktopClick = () => {
    let { program, authentication } = this.props
    const programOffer = program?.data?.offer
    const programMeta = program?.data?.metadata
    const baseUrl = this.props.pathname.includes("movies") ? "/category/1" : "/home"

    if (!program?.pending && program?.getBroadcastDate() === i18n.not_broadcasted) {
      UrlHelper.openInBrowser("", true)
    } else {
      // CTA is clicked from a general /programme-tv route

      if (this.props.redirectToApp) {
        UrlHelper.openInBrowser("/home", true)
      }

      if (program.pending && !this.props.channelsPage && !this.props.replayPage) {
        if (!authentication?.isAuthenticated) {
          sessionStorage.setItem(consts.sessionStorageKey.programOfferToSubscribe, JSON.stringify({ url: baseUrl, programOffer: { freeTier: true } }))
          this.props.dispatch(push(FormatterHelper.basic(`${consts.routes.offers}?signup=true`)))
        } else {
          UrlHelper.openInBrowser(baseUrl, true)
        }
      }

      if (!authentication?.isAuthenticated) {
        // if the program goes under a paid subscription
        // redirect to a /subscribe page (there is an authorisation wall already, no need in any additional steps)
        if (programOffer?.product_equivalence_code) {
          this.redirectToProgramOffer(program, programOffer)
        } else {
          // product is from a free tier
          this.redirectToProgramOffer(program)
        }
      } else {
        if (programOffer?.product_equivalence_code) {
          // there is an offer === user is not subscribed to a product
          this.redirectToProgramOffer(program, programOffer)
        } else {
          // no offer === program is free || user already subscribed
          this.openInBrowser(program, programMeta)
        }
      }
    }
  }

  redirectToProgramOffer = (program, programOffer, isMobile = false) => {
    // we want to redirect a user to the app immediately after payment
    // thus we have to store redirection url && subscription info in a session storage for further redirection
    const programMeta = program?.data?.metadata

    const getUrl = () => {
      if (isMobile) {
        return routesHelper.getDownloadURL(this.props.downloadCampaign, this.props.downloadAdGroup)
      } else {
        // @dev here program.pending === no program was chosen, it is redirection from programme-tv page or movies page
        if (!program.pending) {
          if (this.props.pathname.includes("movies")) {
            return "/category/1"
          } else {
            return program?.isLive()
              ? `/channels/${programMeta?.channel_id}`
              : `/channel/${programMeta?.channel_id}/program/${programMeta?.program_id}`
          }
        } else {
          if (this.props.pathname.includes("movies")) {
            return "/category/1"
          } else {
            return "/home"
          }
        }
      }
    }

    const url = getUrl()

    const paidChannelOffer = this.props.channelOffer
    const offer = programOffer || paidChannelOffer

    const storageData = {
      url,
      programOffer: offer ? { ...offer, isMobile } : { freeTier: true, isMobile },
    }

    sessionStorage.setItem(consts.sessionStorageKey.programOfferToSubscribe, JSON.stringify(storageData))

    if (storageData.programOffer.freeTier) {
      this.props.dispatch(push(FormatterHelper.basic(`${consts.routes.offers}?signup=true`)))
    } else {
      this.props.dispatch(
        push(FormatterHelper.basic(`${consts.routes.subscribePage}&signup=true`, { equivalenceCode: offer?.product_equivalence_code }))
      )
    }
  }

  openInBrowser = (program, programMeta) => {
    const baseUrl = program?.isLive()
      ? `/channels/${programMeta?.channel_id}`
      : `/channel/${programMeta?.channel_id}/program/${programMeta?.program_id}`

    const query = {
      ...(program.isLive() && { live_program_id: programMeta.program_id }),
      origin: "programtv.seo",
      utm_medium: "seo",
      utm_campaign: `page_seo_${programMeta.channel_name}`,
    }

    const parsedQuery = routeHelpers.objectToQuery(query)

    const url = `${baseUrl}?${parsedQuery}`

    UrlHelper.openInBrowser(url, true)
  }

  trackDownloadClick = (identifier, cb) => {
    TrackingSegment.trackEvent(identifier, null, cb)
  }

  redirectToDownloadPage = () => {
    window.location = routesHelper.getDownloadURL(this.props.downloadCampaign, this.props.downloadAdGroup)
  }

  generateButtonText = () => {
    const { program, buttonText } = this.props

    const programUnavailable = !program?.pending && !program?.error && program?.getBroadcastDate() === i18n.not_broadcasted

    return programUnavailable ? i18n.deepLinkPage.programUnavailableBtn : buttonText
  }

  componentDidMount() {
    this.props.dispatch(setSEOCTAFooterHeight(this.footer.getBoundingClientRect().height))
  }

  componentDidUpdate() {
    this.props.dispatch(setSEOCTAFooterHeight(this.footer.getBoundingClientRect().height))
  }

  render() {
    const { background, customStyle, buttonsStyle, contentStyle, textStyle, bodyText, visible } = this.props

    const darkBackground = background !== "transparent"

    return (
      <div
        className={classnames(styles.root, { [styles.visible]: visible, [styles.background]: darkBackground })}
        ref={footer => (this.footer = footer)}
        style={customStyle}
      >
        <div className={classnames(styles.content, contentStyle)}>
          {bodyText && <div className={classnames(styles.text, textStyle)}>{bodyText}</div>}
          <div className={classnames(styles.buttons, buttonsStyle)}>
            <span className={classnames(styles.link, consts.deeplinkDownloadClass)} onClick={this.onClickCTA}>
              <Button color={Button.colors.yellowFull} customStyle={styles.button} value={this.generateButtonText()} />
            </span>
          </div>
        </div>
      </div>
    )
  }
}
