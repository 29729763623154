// *** external ***
import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import classNames from "classnames"
// *** actions ***
import { deleteNotification } from "actions/notification"
import { redirect } from "actions/routing"
// *** components ***
import NotificationView from "./notification"
// *** styles ***
import styleClasses from "./index.css"

@connect(state => ({
  notifications: state.newNotifications.list,
}))
export default class Notifications extends React.Component {
  static propTypes = {
    // own props
    rootClass: PropTypes.string,

    // connect props
    notifications: PropTypes.array,
    dispatch: PropTypes.func.isRequired,
  }

  onButtonClick = (notificationId, buttonIndex) => {
    this.props.dispatch(deleteNotification(notificationId))
    const notification = this.props.notifications.find(notification => notification._id === notificationId)
    if (notification) {
      const button = notification.buttons[buttonIndex]
      if (button) {
        this.props.dispatch(button.onClickAction)
      }
    }
  }

  onNotificationClick = notificationId => {
    const notification = this.props.notifications.find(notification => notification._id === notificationId)
    if (notification) {
      this.props.dispatch(redirect(notification.redirection.type, notification.redirection.id))
    }
  }

  onNotificationClose = notificationId => this.props.dispatch(deleteNotification(notificationId))

  render() {
    const finalClass = classNames({
      [this.props.rootClass]: this.props.rootClass,
    })

    return (
      <div className={finalClass}>
        {this.props.notifications &&
          this.props.notifications.map((notification, index) => {
            return (
              <NotificationView
                key={index}
                id={notification._id}
                rootClass={styleClasses.notification}
                color={notification.color}
                iconType={notification.iconType}
                title={notification.title}
                text={notification.text}
                animation={true}
                autoCloseTimer={notification.autoCloseTimer}
                onClose={this.onNotificationClose}
                onClick={notification.redirection && this.onNotificationClick}
                buttons={notification.buttons}
                onButtonClick={this.onButtonClick}
                style={notification.style}
                closeBtn={notification.closeBtn}
              />
            )
          })}
      </div>
    )
  }
}
