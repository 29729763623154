import { GET_GENRE_PENDING, GET_GENRE_SUCCESS, GET_GENRE_FAILED } from "consts/actions"

import stateHelper from "helpers/state"

const initialState = {}

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_GENRE_PENDING:
      return stateHelper.pending()

    case GET_GENRE_SUCCESS:
      return action.payload

    case GET_GENRE_FAILED:
      return { error: action.payload }

    default:
      return state
  }
}
