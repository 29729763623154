import {
  ALLOW_CLIENT_RENDER,
  APP_ERROR,
  CLEAR_BACKGROUND_IMAGE,
  CLOSE_SEO_SUB_MENU,
  DARK_SEO_CTA_FOOTER,
  DISPLAY_SEO_CTA_FOOTER,
  FETCH_PROMO_CODE_PAGE_SUCCESS,
  GET_GIFT_CLAIM_PENDING,
  GET_GIFT_CLAIM_SUCCESS,
  GET_GIFT_LIST_SUCCESS,
  GET_GIFT_OPTIONS_SUCCESS,
  HIDE_HEADER_MENU,
  HIDE_NAV,
  HIDE_SEO_CTA_FOOTER,
  HIDE_SEO_NAV,
  SCROLL,
  SET_WINDOW_HEIGHT,
  SET_WINDOW_WIDTH,
  SET_DOCUMENT_HEIGHT,
  SET_LOGO_OFFSET_TOP,
  SET_SEO_CTA_FOOTER_HEIGHT,
  SET_FOOTER_HEIGHT,
  SET_SEO_FOOTER_HEIGHT,
  SET_404_ERROR,
  SHOW_SEO_NAV,
  TOGGLE_HEADER_MENU,
  TOGGLE_SEO_MENU,
  TRANSPARENT_SEO_CTA_FOOTER,
  TOGGLE_MOBILE_NAV,
  LOGOUT,
} from "consts/actions"
import { clone, get } from "lodash"

const initialState = {
  app404Error: false,
  backgroundImage: "",
  clientRenderAllowed: __IS_CLIENT__,
  documentHeight: null,
  footerHeight: 0,
  headerMenuVisible: false,
  maxScrollTop: 0,
  navVisible: true,
  scrollTop: 0,
  seoCTAFooterBackground: "dark",
  seoCTAFooterHeight: 0,
  seoCTAFooterUrl: false,
  seoCTAFooterVisible: false,
  seoFooterHeight: 0,
  seoMenuVisible: {},
  seoNavVisible: false,
  showMobileNav: false,
  windowHeight: null,
  windowWidth: null,
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_HEADER_MENU:
      return {
        ...state,
        headerMenuVisible: !state.headerMenuVisible,
      }

    case HIDE_HEADER_MENU:
      return {
        ...state,
        headerMenuVisible: false,
      }

    case GET_GIFT_CLAIM_PENDING:
    case HIDE_NAV:
      return {
        ...state,
        navVisible: false,
      }

    case SCROLL:
      const maxScrollTop = action.payload > state.maxScrollTop ? action.payload : state.maxScrollTop

      return {
        ...state,
        maxScrollTop,
        scrollTop: action.payload,
      }

    case "@@router/LOCATION_CHANGE":
      return {
        ...state,
        maxScrollTop: 0,
        appError: false,
        app404Error: false,
        showMobileNav: false,
      }

    case SHOW_SEO_NAV:
      return {
        ...state,
        seoNavVisible: true,
      }

    case HIDE_SEO_NAV:
      return {
        ...state,
        seoNavVisible: false,
        seoMenuVisible: {},
      }

    case TOGGLE_SEO_MENU: {
      const seoMenuVisible = clone(state.seoMenuVisible)

      Object.keys(seoMenuVisible)
        .filter(m => m !== action.id)
        .forEach(menu => {
          let open = false

          if (action.subMenu && !seoMenuVisible[menu].subMenu && seoMenuVisible[menu].open) {
            open = true
          }

          seoMenuVisible[menu] = {
            ...seoMenuVisible[menu],
            open,
          }
        })

      seoMenuVisible[action.id] = {
        subMenu: action.subMenu,
        open: seoMenuVisible[action.id] && seoMenuVisible[action.id].open ? false : true,
      }

      return {
        ...state,
        seoMenuVisible,
      }
    }

    case CLOSE_SEO_SUB_MENU: {
      const seoMenuVisible = clone(state.seoMenuVisible)
      seoMenuVisible[action.payload].open = false

      return {
        ...state,
        seoMenuVisible,
      }
    }

    case SET_WINDOW_HEIGHT:
      return {
        ...state,
        windowHeight: action.payload,
      }

    case SET_WINDOW_WIDTH:
      return {
        ...state,
        windowWidth: action.payload,
      }

    case SET_DOCUMENT_HEIGHT:
      return {
        ...state,
        documentHeight: action.payload,
      }

    case SET_LOGO_OFFSET_TOP:
      return {
        ...state,
        logoOffsetTop: action.payload,
      }

    case DISPLAY_SEO_CTA_FOOTER:
      return {
        ...state,
        seoCTAFooterVisible: true,
        seoCTAFooterUrl: action.payload,
      }

    case HIDE_SEO_CTA_FOOTER:
      return {
        ...state,
        seoCTAFooterVisible: false,
      }

    case ALLOW_CLIENT_RENDER:
      return {
        ...state,
        clientRenderAllowed: true,
      }

    case TRANSPARENT_SEO_CTA_FOOTER:
      return {
        ...state,
        seoCTAFooterBackground: "transparent",
      }

    case DARK_SEO_CTA_FOOTER:
      return {
        ...state,
        seoCTAFooterBackground: "dark",
      }

    case SET_SEO_CTA_FOOTER_HEIGHT:
      return {
        ...state,
        seoCTAFooterHeight: action.height,
      }

    case SET_FOOTER_HEIGHT:
      return {
        ...state,
        footerHeight: action.height,
      }

    case SET_SEO_FOOTER_HEIGHT:
      return {
        ...state,
        seoFooterHeight: action.height,
      }

    case APP_ERROR:
      return {
        ...state,
        appError: true,
      }

    case SET_404_ERROR:
      return {
        ...state,
        app404Error: true,
      }

    case FETCH_PROMO_CODE_PAGE_SUCCESS:
    case GET_GIFT_CLAIM_SUCCESS:
    case GET_GIFT_OPTIONS_SUCCESS:
    case GET_GIFT_LIST_SUCCESS:
      return {
        ...state,
        backgroundImage: get(action.payload, "data.image_bundle.background.large.url"),
      }

    case CLEAR_BACKGROUND_IMAGE:
      return {
        ...state,
        backgroundImage: "",
      }

    case TOGGLE_MOBILE_NAV:
      return {
        ...state,
        showMobileNav: !state.showMobileNav,
      }

    case LOGOUT:
      return {
        ...state,
        showMobileNav: false,
      }

    default:
      return state
  }
}
