import React, { Component } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import classNames from "classnames"

import SEOMenu from "containers/SEOMenu"
import Icon, { IconTypes } from "components/Icon"

import { showSEONav, hideSEONav } from "actions/ui"

import styles from "./index.css"
import consts from "consts"

@connect(state => ({
  seoNavVisible: state.ui.seoNavVisible,
}))
export default class SEOHeader extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    seoNavVisible: PropTypes.bool.isRequired,
  }

  toggleNav = (e = null) => {
    e && e.stopPropagation()

    if (this.props.seoNavVisible) {
      this.props.dispatch(hideSEONav())
    } else {
      this.props.dispatch(showSEONav())
    }
  }

  renderMobileMenuIcon() {
    let type = IconTypes.burger
    let rootStyle = styles.burger

    if (this.props.seoNavVisible) {
      type = IconTypes.close
      rootStyle = styles.close
    }

    return <Icon type={type} rootStyle={rootStyle} iconStyle={styles.icon} onClick={this.toggleNav} />
  }

  render() {
    const { location, seoNavVisible } = this.props

    return (
      <header className={classNames(styles.root, { [styles.rootMenuVisible]: seoNavVisible })}>
        <div className={styles.content}>
          <Link className={styles.logo} to={consts.routes.default} aria-label="home page">
            <Icon type={IconTypes.logoWithFubo} iconStyle={styles.logoStyle} />
          </Link>
          <SEOMenu rootStyle={classNames(styles.menu, { [styles.mobileMenuVisible]: seoNavVisible })} location={location} />
          <div className={styles.mobileHeader}>{this.renderMobileMenuIcon()}</div>
        </div>
      </header>
    )
  }
}
