import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { replace } from "connected-react-router"
import classNames from "classnames"

import Loader from "components/Loader"
import ContentHeader from "components/ContentHeader"
import Product from "containers/Product"
import BorderBox from "components/BorderBox"
import ResizeWatcher from "components/ResizeWatcher"
import Button from "components/Button"
import ProductOpenEurope from "containers/Product/ProductOpenEurope"

import routeHelper from "helpers/route"
import styles from "./index.css"

import i18n from "consts/i18n"
import consts from "consts"

import { getAssetURL } from "helpers/static"
import { updateMeta, restoreDefaultMeta } from "helpers/meta"

import { getList as getProducts, getFreeProduct } from "actions/products"
import { get as getSubscriptions } from "actions/subscriptions"
import { getRecords as getBookmarksRecord, getScheduled as getBookmarksScheduled } from "actions/bookmarks"

import selectAuthentication from "selectors/authentication"
import selectProducts from "selectors/products"
import selectProductFree from "selectors/productFree"
import { selectQuery } from "selectors/location"

@connect((state, ownProps) => ({
  pathname: ownProps.location.pathname,
  appSettings: state.appSettings,
  navVisible: state.ui.navVisible,
  productFree: selectProductFree(state),
  isAuthenticated: selectAuthentication(state).isAuthenticated,
  products: selectProducts(state),
  query: selectQuery(ownProps),
  router: state.router.location,
}))
export default class PageProducts extends Component {
  static propTypes = {
    pathname: PropTypes.string,
    appSettings: PropTypes.object,
    navVisible: PropTypes.bool,
    productFree: PropTypes.object,
    products: PropTypes.object,
    isAuthenticated: PropTypes.bool,
    dispatch: PropTypes.func,
    location: PropTypes.object,
    query: PropTypes.object,
    serverCookies: PropTypes.string,
    router: PropTypes.object,
  }

  static defaultProps = {
    products: {},
    productFree: {},
    isAuthenticated: false,
  }

  state = {
    forceMobileDisplay: false,
  }

  static getMeta() {
    return {
      og: {
        title: i18n.pageTitle.public,
        type: "website",
        image: getAssetURL("image.pageHome.ogImage"),
        imageAlt: "Logo Molotov.tv",
        url: `${consts.externalLink.landing}${consts.routes.offers}`,
        imageHeight: 400,
        imageWidth: 588,
        description: "Les offres disponibles",
      },
    }
  }

  static fetchData(dispatch, state) {
    const {
      appSettings: { platform },
      isAuthenticated,
      router,
      query,
    } = state

    const originPlatform = (router?.location?.query || query)?.originPlatform

    const requests = [dispatch(getFreeProduct()), dispatch(getProducts(platform, !isAuthenticated, originPlatform))]

    if (__IS_CLIENT__) {
      requests.unshift(dispatch(getSubscriptions()))
    }

    return requests
  }

  trackPageView = () => {
    if (!this.trackDone) {
      this.trackDone = true
    }
  }

  needsHashTrigger = (props, hash) => {
    return !this.hashApplied && props.products && !props.products.pending && hash && hash.match(/#offer_[a-zA-Z0-9]+/)
  }

  applyHash = (location, hash) => {
    const currentHash = hash.replace("#", "")
    location.hash = ""
    // Ugly timeout to make sure that browser has finished rendering.
    // Without that timeout the position of the hash in page might not be correct (even though
    // the DOM has been updated, the layout / compositing / painting might not be finished).
    setTimeout(() => (location.hash = currentHash), 50)
    this.hashApplied = true
  }

  needSubscribeTrigger = (query, hash, product) => {
    // console.log('needSubscribeTrigger', product.code, {
    //     query: /\bsubscribe=1\b/.test(query || ""),
    //     hashApplied: this.hashApplied,
    //     hash: ["#offer_" + product.equivalence_code, hash, new RegExp("#offer_" + product.equivalence_code).test(hash)],
    //     isSubscribe: product.isSubscribe,
    //     isSubscribeWithApple: product.isSubscribeWithApple,
    // })
    return (
      /\bsubscribe=1\b/.test(query || "") &&
      this.hashApplied &&
      new RegExp("#offer_" + product.equivalence_code).test(hash) &&
      !(product.isSubscribe || product.isSubscribeWithApple)
    )
  }

  shouldRedirectToPrivateOfferPage() {
    const { isAuthenticated, pathname } = this.props

    // If user is logged we want him to go to the "private" offers page,
    // not the public one.
    return isAuthenticated && routeHelper.isPublicOffers(pathname)
  }

  handleResize = () => {
    // if on account space, compare width to the breakpoint to is supposed to trigger mobile display
    if (routeHelper.isAccountPage(this.props.pathname)) {
      if (this.container.offsetWidth < consts.smallViewportWidth) {
        this.setState({
          forceMobileDisplay: true,
        })
      } else {
        this.setState({
          forceMobileDisplay: false,
        })
      }
    }
  }

  redirectToPrivateOfferPage() {
    this.props.dispatch(replace(location.pathname.replace(consts.routes.products, consts.routes.offers) + location.search + location.hash))
  }

  componentDidMount() {
    if (this.shouldRedirectToPrivateOfferPage()) {
      this.redirectToPrivateOfferPage()
    }

    if (!global.serverRendered) {
      this.constructor.fetchData(this.props.dispatch, this.props)
    }

    if (this.props.isAuthenticated) {
      this.props.dispatch(getBookmarksScheduled())
      this.props.dispatch(getBookmarksRecord())
    }

    const campaignParameters = ["acq_source", "acq_campaign", "acq_adset", "acq_crea"].reduce((acc, val) => {
      if (typeof this.props.query[val] === "string") {
        acc[val] = this.props.query[val]
      }
      return acc
    }, {})

    if (this.props.isAuthenticated || Object.keys(campaignParameters).length) {
      this.trackPageView(campaignParameters)
    }

    this.handleResize()
    updateMeta(PageProducts.getMeta())
  }

  componentDidUpdate(prevProps) {
    if (this.needsHashTrigger(this.props, document.location.hash)) {
      this.applyHash(document.location, document.location.hash)
    }

    if (this.shouldRedirectToPrivateOfferPage()) {
      this.redirectToPrivateOfferPage()
    }

    if (!prevProps.isAuthenticated && this.props.isAuthenticated) {
      this.props.dispatch(getBookmarksScheduled())
      this.props.dispatch(getBookmarksRecord())
    }

    if (this.props.isAuthenticated) {
      this.trackPageView()
    }
  }

  componentWillUnmount() {
    restoreDefaultMeta()
  }

  render() {
    const {
      productFree,
      products,
      pathname,
      location,
      navVisible,
      appSettings: { platform },
    } = this.props

    const { forceMobileDisplay } = this.state

    const isAccountPage = routeHelper.isAccountPage(pathname)
    const finalRootStyle = classNames(styles.content, ContentHeader.above)
    const cookies = __IS_CLIENT__ ? document.cookie : this.props.serverCookies
    const isOpenEuropeUser = cookies !== undefined && cookies.split(";").some(item => item.includes("openEuropePopinSeen=ok"))

    return (
      <ResizeWatcher onResize={this.handleResize}>
        <BorderBox navVisible={navVisible && isAccountPage} invisibleMode={!isAccountPage}>
          <ContentHeader
            hasBackground={!isAccountPage}
            title={<span>{i18n.productsPage.title}</span>}
            subTitle={
              <span>
                {i18n.productsPage.subtitle1}
                <a style={{ color: "rgba(0, 0, 0, 0.7)" }} href={consts.externalLink.extra}>
                  {i18n.publicMenu.molotovExtra}
                </a>
                {" ou "}
                <a style={{ color: "rgba(0, 0, 0, 0.7)" }} href={consts.externalLink.extended}>
                  {i18n.publicMenu.molotovExtended}
                </a>
                {i18n.productsPage.subtitle2}
              </span>
            }
          />
          <div
            ref={container => (this.container = container)}
            className={classNames(finalRootStyle, {
              [styles.offerInAccount]: isAccountPage,
            })}
          >
            {productFree.pending && <Loader centerInParent={true} />}

            {productFree.data && (
              <div>
                {!isAccountPage &&
                  (isOpenEuropeUser ? (
                    <ProductOpenEurope anchor={`#offer_${consts.productEquivalenceCode.option100H}`} />
                  ) : (
                    <Product product={productFree.data} forceMobileDisplay={forceMobileDisplay} />
                  ))}

                {products.data.length === 0 && products.pending && <Loader centerInParent={true} />}

                {products.available &&
                  products.available.map(product => {
                    return (
                      <Product
                        product={product}
                        key={product.equivalence_code}
                        forceMobileDisplay={forceMobileDisplay}
                        disabled={product.disabled}
                        triggerSubscribe={this.needSubscribeTrigger(location.search, location.hash, product)}
                      />
                    )
                  })}

                {products.disabled && products.disabled.length > 0 && (
                  <div>
                    <h2 className={styles.title2}>{i18n.productsPage.soon_available}</h2>
                    {products.disabled.map(product => {
                      return <Product product={product} key={product.equivalence_code} forceMobileDisplay={forceMobileDisplay} disabled={true} />
                    })}
                  </div>
                )}
              </div>
            )}

            {consts.platform.apple === platform && (
              <div className={styles.restoreButtonContainer}>
                <a type="button" href="inapp-restore://" className={classNames(Button.colors.greyFull, styles.restoreButton)}>
                  {i18n.productsPage.restorePayments}
                </a>
              </div>
            )}

            {consts.platform.apple === platform && (
              <div className={styles.cguApple}>
                <p>{i18n.productsPage.cguApple0}</p>
                <p>{i18n.productsPage.cguApple1}</p>
                <p>{i18n.productsPage.cguApple2}</p>
                <p>{i18n.productsPage.cguApple3}</p>
              </div>
            )}
          </div>
        </BorderBox>
      </ResizeWatcher>
    )
  }
}
