import { SET_LOCALE } from "consts/actions"

export function setLocale(locale) {
  return (dispatch, getState) => {
    const currentLocale = getState().locale.locale

    if (currentLocale !== locale) {
      dispatch({
        type: SET_LOCALE,
        locale,
      })
    }
  }
}
