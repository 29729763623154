import { GET_CATEGORY_PENDING, GET_CATEGORY_SUCCESS, GET_CATEGORY_FAILED } from "consts/actions"

import stateHelper from "helpers/state"

const initialState = {}

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_CATEGORY_PENDING:
      return stateHelper.pending()

    case GET_CATEGORY_SUCCESS:
      return action.payload

    case GET_CATEGORY_FAILED:
      return { error: action.payload }

    default:
      return state
  }
}
