import { SET_NAVIGATOR } from "consts/actions"

const initialState = {
  appVersion: "",
  userAgent: "",
  platform: "",
  vendor: "",
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_NAVIGATOR:
      return action.payload

    default:
      return state
  }
}
