import { DELETE_NOTIFICATION, ACTION_FEEDBACK } from "consts/actions"

export function deleteNotification(notificationId) {
  return {
    type: DELETE_NOTIFICATION,
    payload: {
      notificationId,
    },
  }
}

export function addActionFeedback(action) {
  return {
    type: ACTION_FEEDBACK,
    payload: action,
  }
}
