import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import styles from "./../index.css"

const IconMolotovLogo = ({ customStyle = null }) => (
  <svg viewBox="0 0 24 24" className={classNames(customStyle, styles.fill)}>
    <g fill="none" fillRule="evenodd">
      <g fill="#000100">
        <g>
          <g>
            <g>
              <g>
                <path
                  d="M4.436.125c.356-.164 1.107-.133 1.51-.094.311.03.784.16.934.402.08.13-.005.52-.023.652l-.068.444c-.166 1.054-.506 3.037-.81 4.805l-.554 3.223c-.075.464-.445 2.85-.53 3.376l.104-.335c.723-2.293 2-5.74 3.341-7.563.507-.688 1.243-1.517 2.099-1.95.453-.229.806-.319 1.334-.161l.09.028.007.002c.106.042.209.087.31.128.385.16.928.585 1.07.876.101.213-.038.589-.057.72l-.023.138c-.226 1.312-1.184 5.433-1.461 6.88l.144-.404c.772-2.135 1.931-4.894 3.358-6.683l.156-.193c.513-.62 1.188-1.322 1.967-1.689.453-.212.91-.307 1.495-.101l.124.046c.477.206.938.529 1.088.711.298.361.213.957.178 1.128l-.564 2.727c-.224 1.094-.45 2.22-.606 3.088l-.033.182c-.499 2.824-.875 6.361-.957 7.966-.086 1.67.15 3.292 1.03 4.735.27.441-1.086.533-1.458.427-1.891-.539-2.846-2.204-2.82-4.297.027-2.255.884-6.992 1.488-10.109l.514-2.61.154-.813c-2.764 2.69-5.033 8.62-5.657 11.164-.133.54-1.575 1.074-1.954 1.157-.029.006-.054.01-.077.012h-.032c-.859.04-.593-2.38-.42-3.868l.053-.46c.13-1.068.776-5.31 1.212-7.626C7.046 9.292 4.887 16.87 4.228 19.683c-.127.544-1.552 1.126-1.927 1.224-.025.006-.048.01-.068.013l-.03.001c-.974.112-.796-1.892-.655-2.926l.097-.66C1.901 15.53 3.077 6.496 3.513 3.65 2.61 4.654 1.541 6.186.825 7.316.27 8.194-.192 6.775.081 6.25l.228-.431c.305-.57.637-1.148 1.23-2.158l.154-.262C2.11 2.689 3.502.703 4.342.177l.03-.019.04-.022.024-.01zm8.955 19.959c.055.51.309 1.527.52 1.937l-.434.028c-2.975.2-6.095.498-10.291 1.187-.88.169-1.15-.39-1.152-.55-.003-.16.486-.264.486-.264 3.887-.84 7.225-1.92 10.871-2.338zm6.133-.57c1.191.008 2.218.132 3.09.382 1.73.497 1.353 2.31 1.028 3.048-.848-.556-1.993-1.06-3.847-1.282.009-.34.005-.71-.034-.978-.034-.233-.09-.52-.158-.827l-.079-.344z"
                  transform="translate(-428 -245) translate(352 169) translate(40 48) translate(24 16) translate(12 12)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

IconMolotovLogo.propTypes = {
  customStyle: PropTypes.string,
}

export default IconMolotovLogo
