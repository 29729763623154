import { DSP_PAYMENT_PENDING, DSP_PAYMENT_SUCCESS, DSP_PAYMENT_ERROR, SET_DSP_PASSWORD_ERROR, SET_DSP_PAYMENT_FAILED } from "consts/actions"

import { updateProduct } from "helpers/payment"

const initialState = {}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case DSP_PAYMENT_PENDING:
      return updateProduct(state, action.equivalenceCode, {
        paymentPending: true,
      })

    case DSP_PAYMENT_SUCCESS:
      return updateProduct(state, action.equivalenceCode, {
        paymentPending: false,
        subscriptionSuccess: true,
      })

    case DSP_PAYMENT_ERROR:
      return updateProduct(state, action.equivalenceCode, {
        paymentPending: false,
      })

    case SET_DSP_PASSWORD_ERROR:
    case SET_DSP_PAYMENT_FAILED:
      return updateProduct(state, action.equivalenceCode, {
        passwordError: action.payload,
      })

    default:
      return state
  }
}
