import consts from "consts"
import { GET_OFFERS_SUCCESS, GET_OFFERS_FAILED, GET_MULTIPLE_OFFERS } from "consts/actions"

import { executeApiActions } from "actions/api"

import LogManager from "managers/logManager"

import selectAuthentication from "selectors/authentication"

export function getOffers(platformPayment = consts.platformPayment.molotovpay, apiPath = false, ratePlan) {
  return (dispatch, getState) => {
    const authentication = selectAuthentication(getState())
    const originPlatform = getState().router?.location?.query?.originPlatform

    const productRequest = {
      name: "products",
      url: authentication.isAuthenticated ? consts.endpoints.productCatalog : consts.endpoints.productCatalogPublic,
      params: {
        platform: platformPayment,
        originPlatform,
      },
    }

    if (ratePlan) {
      productRequest.params = {
        ...productRequest.params,
        "rate-plan": ratePlan,
        trkOp: "product_catalog",
      }
    }

    if (apiPath) {
      productRequest.url = apiPath.replace(/__DOT__/g, ".")
      productRequest.base = ""
      delete productRequest.params
    }

    const request = authentication.isAuthenticated
      ? [
          productRequest,
          {
            name: "subscriptions",
            url: consts.endpoints.subscriptions,
          },
        ]
      : [productRequest]

    dispatch({
      accessTokenRequired: !!authentication.isAuthenticated,
      request: request,
      onSuccess: payload => {
        return {
          type: GET_OFFERS_SUCCESS,
          payload,
        }
      },
      onError: payload => {
        LogManager.ERROR("Failed to load product catalog / subscriptions", {
          request: request,
        })

        return {
          type: GET_OFFERS_FAILED,
          payload,
        }
      },
    })
  }
}

export function getMultipleOffers() {
  return dispatch => {
    dispatch({
      accessTokenRequired: true,
      request: {
        url: consts.endpoints.newFunnel,
        method: "GET",
      },
      onSuccess: payload => {
        if (payload.execute_actions) {
          dispatch(executeApiActions(payload.execute_actions))
        } else {
          dispatch({
            type: GET_MULTIPLE_OFFERS,
            payload,
          })
        }
      },
      onError: payload => {
        if (payload.execute_actions) {
          dispatch(executeApiActions(payload.execute_actions))
        }
        LogManager.ERROR("Failed to load product catalog / subscriptions", {
          request: {
            url: consts.endpoints.newFunnel,
            method: "GET",
          },
        })
      },
    })
  }
}
