import React, { Component } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import styles from "./index.css"
import consts from "consts"

export default class Input extends Component {
  static propTypes = {
    type: PropTypes.string,
    rootStyle: PropTypes.string,
    customStyle: PropTypes.string,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    name: PropTypes.string,
    inputRef: PropTypes.object,
    placeholder: PropTypes.string,
    autoFocus: PropTypes.bool,
    autoCorrect: PropTypes.string,
    autoCapitalize: PropTypes.string,
    autoComplete: PropTypes.string,
    value: PropTypes.string,
    success: PropTypes.bool,
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
    disabled: PropTypes.bool,
    stateless: PropTypes.bool,
  }

  static defaultProps = {
    type: "text",
    rootStyle: null,
    customStyle: null,
    onChange: consts.NoOp,
    onFocus: consts.NoOp,
    onBlur: consts.NoOp,
    name: null,
    inputRef: null,
    placeholder: null,
    autoFocus: false,
    autoCorrect: "off",
    autoCapitalize: "off",
    autoComplete: "off",
    value: "",
    success: false,
    error: false,
    errorMessage: null,
    disabled: false,
  }

  state = { value: this.props.value }

  onChange = event => {
    const value = event.target.value

    if (!this.stateless) {
      this.setState({ value: event.target.value })
    }

    this.props.onChange(value, event.target.name)
  }

  render() {
    const {
      rootStyle,
      customStyle,
      type,
      inputRef,
      name,
      onBlur,
      onFocus,
      autoFocus,
      autoCorrect,
      autoComplete,
      autoCapitalize,
      placeholder,
      success,
      error,
      errorMessage,
      disabled,
      stateless,
    } = this.props

    const value = stateless ? this.props.value : this.state.value

    return (
      <div
        className={classNames(styles.root, rootStyle, {
          [styles.success]: success,
          [styles.error]: error,
        })}
      >
        <input
          type={type}
          name={name}
          ref={inputRef}
          placeholder={placeholder}
          autoCorrect={autoCorrect}
          autoComplete={autoComplete}
          autoCapitalize={autoCapitalize}
          autoFocus={autoFocus}
          onChange={this.onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          value={value}
          className={classNames(styles.input, customStyle, {
            [styles.safariCredentialsIcon]: value.length,
          })}
          disabled={disabled}
        />
        {errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}
      </div>
    )
  }
}
