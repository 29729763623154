import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import classNames from "classnames"
import { push } from "connected-react-router"

import Input from "components/Input"
import InputSubmit from "components/InputSubmit"
import Button from "components/Button"
import Alert from "components/Alert"

import { change as changePassword } from "actions/password"

import selectAuthentication from "selectors/authentication"

import i18n from "consts/i18n"
import consts from "consts"
import routes from "consts/routes"

import styles from "./index.css"

// valid characters abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890._-@+* àâäéèêëïîôöùûüÿçÀÂÄÉÈÊËÏÎÔÖÙÛÜŸÇ$€£&"'()§!?#%~^*=`|\/,{}[]:;<>
const PWD_REGEX = /[^\w\d\s\.\_\-\@\+\*\à\â\ä\é\è\ê\ë\ï\î\ô\ö\ù\û\ü\ÿ\ç\À\Â\Ä\É\È\Ê\Ë\Ï\Î\Ô\Ö\Ù\Û\Ü\Ÿ\Ç\$\€\£\&\"\'\(\)\§\!\?\#\%\~\^\*\=\`\|\\\/\,\{\}\[\]\:\;\<\>]/

@connect(state => ({
  passwordForm: state.forms.password,
  isAuthenticated: selectAuthentication(state).isAuthenticated,
}))
export default class PageChangePassword extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    isAuthenticated: PropTypes.bool,
    match: PropTypes.object.isRequired,
    passwordForm: PropTypes.object.isRequired,
  }

  state = {
    password: {
      value: "",
    },
    confirmation: {
      value: "",
    },
  }

  onSubmit = event => {
    event.preventDefault()

    const {
      match: { params },
    } = this.props

    const { password, confirmation } = this.state

    if (password.valid && confirmation.valid) {
      this.props.dispatch(changePassword(password.value, params.token))
    }
  }

  resetError(state, fieldName, value) {
    state[fieldName] = { error: null, value }
  }

  setError(state, fieldName, value, error) {
    state[fieldName] = { ...this.state[fieldName], error, valid: false, value }
  }

  setValid(state, fieldName, value) {
    state[fieldName] = { ...this.state[fieldName], error: null, valid: true, value }
  }

  updateState = (passwordValue = null, confirmationValue = null) => {
    const newState = {}
    const password = passwordValue !== null ? passwordValue : this.state.password.value
    const confirmation = confirmationValue !== null ? confirmationValue : this.state.confirmation.value

    if (password.length) {
      if (password.length < consts.minPasswordLength) {
        this.setError(newState, "password", password, i18n.changePasswordPage.minimumCharsCount)
        this.resetError(newState, "confirmation", confirmation)
      } else {
        if (password.length > consts.maxPasswordLength) {
          this.setError(newState, "password", password, i18n.changePasswordPage.maximumCharsCount)
          this.resetError(newState, "confirmation", confirmation)
        } else {
          this.setValid(newState, "password", password)
        }
      }
    } else {
      this.resetError(newState, "password", password)
      this.resetError(newState, "confirmation", confirmation)
    }

    if (PWD_REGEX.test(password)) {
      this.setError(newState, "password", password, i18n.changePasswordPage.invalidPassword)
      this.resetError(newState, "confirmation", confirmation)
    }

    if (newState.password.valid === true && confirmation.length) {
      if (confirmation !== password) {
        this.setError(newState, "confirmation", confirmation, i18n.changePasswordPage.nonIdenticalPasswords)
      } else if (confirmation.length >= consts.minPasswordLength) {
        this.setValid(newState, "password", password)
        this.setValid(newState, "confirmation", confirmation)
      }
    } else {
      this.resetError(newState, "confirmation", confirmation)
    }

    if (Object.keys(newState).length) {
      this.setState(newState)
    }
  }

  submitDisabled = () => {
    const { passwordForm } = this.props

    const { password, confirmation } = this.state

    return !password.valid || !confirmation.valid || passwordForm.pending
  }

  goToLogin = () => {
    this.props.dispatch(push(routes.profile))
  }

  goToHome = () => {
    this.props.dispatch(push(routes.default))
  }

  render() {
    const { isAuthenticated, passwordForm } = this.props

    const { password, confirmation } = this.state

    const passwordFormSuccess = passwordForm.success

    return (
      <div className={styles.root}>
        <div className={styles.content}>
          <h3 className={styles.title}>{i18n.changePasswordPage.title}</h3>
          {passwordForm.error && (
            <Alert customStyle={styles.alert}>
              <span>{passwordForm.error.message}</span>
            </Alert>
          )}
          {!passwordFormSuccess && (
            <form onSubmit={this.onSubmit}>
              <Input
                type="password"
                placeholder={i18n.changePasswordPage.newPassword}
                success={password.valid}
                rootStyle={styles.inputRoot}
                customStyle={styles.input}
                error={password.valid === false}
                onChange={value => {
                  this.updateState(value, null)
                }}
              />
              <span className={styles.inputErrorMessage}>{this.state.password.error}</span>
              <Input
                type="password"
                disabled={!password.valid}
                placeholder={i18n.changePasswordPage.confirmPassword}
                success={confirmation.valid}
                error={confirmation.valid === false}
                rootStyle={styles.inputRoot}
                customStyle={styles.input}
                onChange={value => {
                  this.updateState(null, value)
                }}
              />
              <span className={styles.inputErrorMessage}>{this.state.confirmation.error}</span>
              <InputSubmit
                type={InputSubmit.types.yellowFull}
                customStyle={classNames(styles.button, {
                  [styles.disabled]: this.submitDisabled(),
                })}
                onClick={this.onSubmit}
                value={i18n.validate}
              />
            </form>
          )}
          {passwordFormSuccess && (
            <div>
              <p className={styles.successText}>
                {isAuthenticated ? i18n.changePasswordPage.passwordSentLogged : i18n.changePasswordPage.passwordSent}
              </p>
              {isAuthenticated ? (
                <Button color={Button.colors.yellowFull} customStyle={styles.button} onClick={this.goToHome} value={i18n.backToHome} />
              ) : (
                <Button color={Button.colors.yellowFull} customStyle={styles.button} onClick={this.goToLogin} value={i18n.login} />
              )}
            </div>
          )}
        </div>
      </div>
    )
  }
}
