import { createStore, applyMiddleware, compose } from "redux"

// Root reducer
import getRootReducer from "reducers"

export default (history, middlewares) => {
  return createStore(
    getRootReducer(history),
    typeof global.__INITIAL_STATE__ === "string" ? JSON.parse(global.__INITIAL_STATE__) : {},
    compose(applyMiddleware(...middlewares), global.__REDUX_DEVTOOLS_EXTENSION__ && !__PROD__ ? global.__REDUX_DEVTOOLS_EXTENSION__() : f => f)
  )
}
