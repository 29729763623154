import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import styles from "./../index.css"

const Csa12 = ({ customStyle = null }) => (
  <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className={classNames(customStyle, styles.csa12)}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.05 13H5.5v-2H2.05C2.55 5.947 6.814 2 12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10c-5.185 0-9.449-3.947-9.95-9zm5.45 5H10V6H6.5v2h1v10zm8-2c1.5-3 3-4.502 3-7s-1.593-3.25-3.25-3.25S12 6.501 12 9v1h2.25v-.985c.035-.843.351-1.265.95-1.265.898 0 1.05.748 1.05 1.25s-.2 1.5-1 3l-3.5 6h6.75v-2h-3z"
      fill="#fff"
    />
  </svg>
)

Csa12.propTypes = {
  customStyle: PropTypes.string,
}

export default Csa12
