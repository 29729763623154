import React, { Component } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import styles from "./index.css"

export default class CheckboxButton extends Component {
  static propTypes = {
    name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    valueDisplayed: PropTypes.string,
    customStyle: PropTypes.string,
    checked: PropTypes.bool,
    onChange: PropTypes.func,
    offerId: PropTypes.string,
  }

  static defaultProps = {
    onChange: () => {},
    buttonStyle: "",
    valueDisplayed: null,
    checked: false,
    customStyle: "",
  }

  state = { checked: this.props.checked }

  onChange = value => {
    this.setState({
      checked: !this.state.checked,
    })
    this.props.onChange(value, !this.state.checked)
  }

  componentDidUpdate() {
    if (this.props.checked !== this.state.checked) {
      this.setState({
        checked: this.props.checked,
      })
    }
  }

  render() {
    const { name, value, valueDisplayed, customStyle, offerId } = this.props

    const id = `${name}_${value}_${offerId}`

    return (
      <>
        <input
          id={id}
          type="checkbox"
          className={styles.checkbox}
          checked={this.state.checked}
          name={name}
          value={value}
          onChange={event => this.onChange(event.target.value)}
        />
        <label className={classNames(styles.label, customStyle)} htmlFor={id}>
          {valueDisplayed}
        </label>
      </>
    )
  }
}
