import { combineReducers } from "redux"

import invoices from "./invoices"
import cards from "./cards"
import cardForm from "./cardForm"
import payment from "./payment"
import periodicity from "./periodicity"

export default combineReducers({
  cardForm,
  cards,
  invoices,
  payment,
  periodicity,
})
