import { OPEN_FLASH, CLOSE_FLASH } from "consts/actions"

export function open(message, messageDeveloper, status) {
  return {
    type: OPEN_FLASH,
    message,
    status,
    messageDeveloper,
  }
}

export function close() {
  return {
    type: CLOSE_FLASH,
  }
}
