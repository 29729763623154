import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import classnames from "classnames"
import { Route } from "react-router-dom"

import Icon, { IconTypes } from "components/Icon"
import MenuItem from "components/MenuItem"

import { toggleSEOMenu, closeSubMenu } from "actions/ui"

import styles from "./index.css"
import consts from "consts"

@connect(state => ({
  menus: state.ui.seoMenuVisible,
}))
export default class Menu extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    label: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
    children: PropTypes.func.isRequired,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    isLabelActive: PropTypes.func,
    menus: PropTypes.object,
    path: PropTypes.string,
    rootStyle: PropTypes.string,
    subMenu: PropTypes.bool,
  }

  static defaultProps = {
    id: false,
    isLabelActive: consts.NoOp,
    rootStyle: "",
    subMenu: false,
    menus: {},
  }

  state = {
    open: false,
  }

  toggleMenu = (e = null) => {
    if (!this.props.id) {
      return
    }

    e && e.stopPropagation()

    this.props.dispatch(toggleSEOMenu(this.props.id, this.props.subMenu))
  }

  closeSubMenu = (e = null) => {
    e && e.stopPropagation()

    this.props.dispatch(closeSubMenu(this.props.id))
  }

  render() {
    const { children, id, isLabelActive, label, menus, path, rootStyle, subMenu } = this.props
    const open = id && menus[id] && menus[id].open

    return (
      <Route
        path={path}
        children={({ match }) => {
          const isActive = (path && match) || open

          return (
            <div className={classnames(styles.root, rootStyle)} data-test={`seo-menu-${id}`}>
              <span
                className={classnames(styles.label, { [styles.subMenuLabel]: subMenu, [styles.labelActive]: isActive || isLabelActive(match) })}
                onClick={this.toggleMenu}
              >
                {typeof label === "function" ? label(isActive, match) : label}
                {subMenu && (
                  <Icon
                    type={IconTypes.arrowLeft}
                    rootStyle={styles.menuArrow}
                    iconStyle={classnames(styles.arrowIcon, { [styles.arrowIconActive]: isActive })}
                  />
                )}
              </span>
              {children && id && (
                <ul className={classnames(styles.menu, { [styles.menuOpen]: open, [styles.subMenu]: subMenu })}>
                  <MenuItem rootStyle={classnames(styles.label, styles.subMenuLabel, styles.backLink)} onClick={this.closeSubMenu}>
                    <div>
                      <Icon type={IconTypes.arrowLeft} rootStyle={classnames(styles.menuArrow, styles.backLinkArrow)} iconStyle={styles.arrowIcon} />{" "}
                      Retour
                    </div>
                  </MenuItem>
                  {children(open)}
                </ul>
              )}
            </div>
          )
        }}
      />
    )
  }
}
