import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Link } from "react-router-dom"

import styles from "./index.css"
import consts from "consts"

@connect()
export default class ModalOpenEurope extends Component {
  static propTypes = {
    close: PropTypes.func.isRequired,
    dispatch: PropTypes.func,
  }

  handleClose = accept => {
    const date = new Date()
    date.setTime(date.getTime() + 24 * 60 * 60 * 1000) // 24h
    const expires = "; expires=" + date.toGMTString()
    document.cookie = `openEuropePopinSeen=${accept}; expires=${expires}`
    this.props.close()
  }

  render() {
    return (
      <div className={styles.root}>
        <p>Nous avons remarqué que vous n’êtes pas en France actuellement.</p>
        <p>
          <b>Molotov</b> est accessible depuis l’Europe aux résidents français en souscrivant à l’option <b>Molotov Plus</b>.
        </p>
        <Link to={consts.routes.travel} className={styles.continue} onClick={() => this.handleClose("ok")}>
          Continuer
        </Link>
        <button className={styles.close} onClick={() => this.handleClose("ko")}>
          Je suis en France
        </button>
      </div>
    )
  }
}

const ModalOpenEuropeCustomStyle = styles.modal
const ModalOpenEuropeCustomRootStyle = styles.overlay

export { ModalOpenEuropeCustomStyle, ModalOpenEuropeCustomRootStyle }
