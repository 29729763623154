import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import styles from "./index.css"

import helperFormatter from "helpers/formatter"

const ProductPanel = ({ customStyle, footer, forceMobileDisplay, product: { isSubscribe, reinsurance }, wave }) => {
  return (
    <div
      className={classNames(styles.root, customStyle, {
        [styles.panelNotSubscribe]: !isSubscribe,
        [styles.mobileDisplay]: forceMobileDisplay,
      })}
    >
      {reinsurance && reinsurance.label ? <div className={styles.head}>{helperFormatter.styledFormatter(reinsurance.label, "label")}</div> : null}

      {reinsurance && reinsurance.features ? (
        <div className={styles.features}>{helperFormatter.styledFormatter(reinsurance.features, "label")}</div>
      ) : null}

      <div className={styles.priceContainer}>
        {reinsurance && reinsurance.price ? (
          <div className={styles.pricingBig}>{helperFormatter.styledFormatter(reinsurance.price, "price")}</div>
        ) : null}

        {reinsurance && reinsurance.price_subtitle ? (
          <div className={styles.pricingSmall}>{helperFormatter.styledFormatter(reinsurance.price_subtitle, "price_subtitle")}</div>
        ) : null}
      </div>

      {/* Only displayed in NON-mobile mode */}
      {footer}

      {/* Only displayed in NON-mobile mode */}
      {wave}
    </div>
  )
}

ProductPanel.propTypes = {
  customStyle: PropTypes.string,
  footer: PropTypes.object,
  forceMobileDisplay: PropTypes.bool,
  product: PropTypes.object,
  wave: PropTypes.object,
}

export default ProductPanel
