import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

const IconPaypal = ({ customStyle = null }) => (
  <svg width="38" height="24" viewBox="0 0 38 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={classNames(customStyle)}>
    <rect width="38" height="24" rx="2" fill="white" />
    <rect x="0.5" y="0.5" width="37" height="23" rx="1.5" stroke="black" strokeOpacity="0.1" />
    <path
      d="M15.6982 21L16.0099 18.9924L15.3156 18.9761H12L14.3042 4.16196C14.3183 4.06841 14.398 3.9995 14.4913 4H20.0818C21.9378 4 23.2186 4.39161 23.8873 5.16456C24.2008 5.52716 24.4005 5.90608 24.497 6.32307C24.5984 6.76061 24.6001 7.28337 24.5012 7.92094L24.4941 7.96747V8.37601L24.8076 8.5561C25.0469 8.67904 25.262 8.84536 25.4423 9.04682C25.7105 9.35684 25.8839 9.75087 25.9572 10.218C26.0329 10.6985 26.0079 11.2702 25.8839 11.9174C25.7409 12.662 25.5096 13.3104 25.1973 13.841C24.9218 14.3164 24.5513 14.7281 24.1096 15.0497C23.6948 15.3482 23.2019 15.5749 22.6446 15.7199C22.1047 15.8625 21.489 15.9344 20.8137 15.9344H20.3786C20.0675 15.9344 19.7653 16.0481 19.5281 16.2517C19.2913 16.4576 19.1341 16.742 19.0847 17.0543L19.0519 17.235L18.5012 20.7734L18.4762 20.9033C18.4696 20.9444 18.4583 20.9649 18.4416 20.9788C18.4255 20.9923 18.4053 20.9997 18.3844 21H15.6982Z"
      fill="#253B80"
    />
    <path
      d="M25.4351 7C25.4178 7.11133 25.398 7.22514 25.3757 7.34207C24.6101 11.292 21.9907 12.6566 18.6453 12.6566H16.942C16.5329 12.6566 16.1881 12.9551 16.1244 13.3606L15.2523 18.9183L15.0054 20.4937C14.9854 20.6202 15.0216 20.7491 15.1044 20.8465C15.1871 20.9438 15.3081 20.9999 15.4355 21H18.4566C18.8143 21 19.1182 20.7388 19.1745 20.3843L19.2042 20.23L19.773 16.6028L19.8096 16.4038C19.8653 16.0481 20.1698 15.7868 20.5275 15.7868H20.9794C23.9063 15.7868 26.1976 14.5927 26.8673 11.1372C27.1471 9.69365 27.0023 8.48832 26.262 7.6406C26.0274 7.37837 25.7472 7.16129 25.4351 7V7Z"
      fill="#179BD7"
    />
    <path
      d="M25.2258 7.27804C24.9895 7.20654 24.7491 7.15075 24.5061 7.11097C24.0259 7.03379 23.5405 6.99675 23.0546 7.00022H18.6561C18.3102 6.99994 18.0158 7.26348 17.9626 7.62092L17.0269 13.8192L17 14C17.0602 13.5923 17.3959 13.2915 17.7903 13.2917H19.4368C22.6705 13.2917 25.2025 11.9183 25.9426 7.94504C25.9647 7.82741 25.9832 7.7129 26 7.6009C25.8047 7.4938 25.6012 7.40401 25.3915 7.33247C25.3366 7.3134 25.2813 7.29525 25.2258 7.27804V7.27804Z"
      fill="#222D65"
    />
    <path
      d="M17.8055 7.87823C17.8587 7.5161 18.1562 7.24918 18.5054 7.25032H22.945C23.471 7.25032 23.9619 7.28643 24.41 7.36247C24.7132 7.41247 25.0122 7.48725 25.3043 7.58613C25.5247 7.6628 25.7294 7.75341 25.9184 7.85796C26.1407 6.37085 25.9166 5.35833 25.1503 4.44148C24.3055 3.43213 22.7807 3 20.8297 3H15.1654C14.7669 3 14.4269 3.30414 14.3653 3.71789L12.006 19.4095C11.9837 19.5569 12.0241 19.7072 12.1164 19.8207C12.2087 19.9343 12.3438 19.9998 12.4861 20H15.983L16.8611 14.1549L17.8055 7.87823Z"
      fill="#253B80"
    />
  </svg>
)

IconPaypal.propTypes = {
  customStyle: PropTypes.string,
}

export default IconPaypal
