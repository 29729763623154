import {
  CARD_FORM_REQUESTED,
  INFOS_SCREEN_REQUESTED,
  INVALID_CARD_INFORMATIONS,
  LOGOUT,
  MTVPAY_PAYMENT_ERROR,
  PRODUCT_PAYMENT_INSTANCIATED,
  RECURLY_3DS_TOKEN,
  RESET_CARD_ERRORS,
  SAVE_MTVPAY_CARD_PENDING,
  SAVE_MTVPAY_CARD_SUCCESS,
  SAVE_MTVPAY_CARD_ERROR,
  SET_PAYMENT_DATA,
} from "consts/actions"

import i18n from "consts/i18n"

const initialState = {
  error: null,
  savePending: false,
  cardnumber: {
    error: false,
    value: "",
    formattedValue: "",
  },
  expiry: {
    error: false,
    value: "",
    formattedValue: "",
  },
  cvv: {
    error: false,
    value: "",
    formattedValue: "",
  },
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case INVALID_CARD_INFORMATIONS:
      return {
        ...state,
        error: {
          message: i18n.payment.invalidCardInformations,
        },
        savePending: false,
      }

    case RESET_CARD_ERRORS:
      return {
        ...state,
        error: null,
      }

    case SAVE_MTVPAY_CARD_PENDING:
      return {
        ...state,
        error: null,
        savePending: true,
      }

    case RECURLY_3DS_TOKEN:
      return {
        ...state,
        recurly_3d_token: action.payload.token_3ds_id,
      }

    case MTVPAY_PAYMENT_ERROR:
      return {
        ...state,
        recurly_3d_token: undefined,
        error: action.payload.error,
      }

    case SAVE_MTVPAY_CARD_ERROR:
      return {
        ...state,
        error: action.payload,
        savePending: false,
      }

    case SET_PAYMENT_DATA:
      return {
        ...state,
        [action.field]: {
          ...state[action.field],
          ...action.payload,
        },
      }

      return state

    case LOGOUT:
    case PRODUCT_PAYMENT_INSTANCIATED:
    case INFOS_SCREEN_REQUESTED:
    case CARD_FORM_REQUESTED:
    case SAVE_MTVPAY_CARD_SUCCESS:
      return initialState

    default:
      return state
  }
}
