import React, { Component } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import styles from "./index.css"

export default class Icon extends Component {
  static propTypes = {
    type: PropTypes.object,
    rootStyle: PropTypes.string,
    iconStyle: PropTypes.string,
    iconProps: PropTypes.object,
    onClick: PropTypes.func,
  }

  static defaultProps = {
    rootStyle: null,
    iconStyle: null,
    iconProps: {},
    onClick: null,
  }

  render() {
    const { type, rootStyle, iconStyle, iconProps, onClick } = this.props

    return (
      <div className={classNames(rootStyle, { [styles.action]: onClick })} onClick={onClick}>
        {React.createElement(type ? type.default : "div", { customStyle: iconStyle, ...iconProps })}
      </div>
    )
  }
}

import * as IconTypes from "./consts"
export { IconTypes }
