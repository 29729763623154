import consts from "consts"

const sessionKey = "session"
const cacheKey = "data_cache"

export default {
  setSession(session) {
    this.writeInLocalStorage(sessionKey, JSON.stringify(session))
    document.cookie = `${sessionKey}=${JSON.stringify({
      account: { id: session.account.id, displayName: session.account.display_name, profiles: session.account.profiles },
      auth: session.auth,
    })};path=/;domain=molotov.tv;SameSite=Lax;`
  },

  getSession() {
    if (__IS_CLIENT__) {
      try {
        let localStorage = JSON.parse(window.localStorage.getItem(sessionKey))
        if (!localStorage) {
          const cookieArray = document.cookie.split(";")
          for (let i = 0; i < cookieArray.length; i++) {
            const trimedParam = cookieArray[i].trim()
            if (trimedParam.startsWith("session=")) {
              localStorage = JSON.parse(trimedParam.slice(8))
            }
          }
        }
        return localStorage
      } catch (e) {
        /* eslint-disable no-console */
        console.log("Error encountered when parsing localStorage session infos.")
        /*eslint-enable no-console */
      }
    }

    return null
  },

  setCachedData(key, data) {
    let cache

    try {
      cache = JSON.parse(this.getFromLocalStorage(cacheKey)) || {}
    } catch (e) {
      cache = {}
    }

    cache[key] = data

    this.writeInLocalStorage(cacheKey, JSON.stringify(cache))
  },

  getCachedData(key) {
    try {
      const cache = JSON.parse(this.getFromLocalStorage(cacheKey))
      return cache && cache[key]
    } catch (e) {
      /* eslint-disable no-console */
      console.log("Error encountered when reading / parsing localStorage key '" + key + "'.")
      /*eslint-enable no-console */
    }

    return null
  },

  clearCache() {
    try {
      window.localStorage && window.localStorage.clear()
    } catch (e) {}
  },

  writeInLocalStorage(key, value) {
    try {
      window.localStorage && localStorage.setItem(key, value)
    } catch (e) {}
  },

  getFromLocalStorage(key) {
    try {
      return window.localStorage && window.localStorage.getItem(key)
    } catch (e) {}
  },

  clear() {
    // keys to keep
    const backup = [consts.localStorageKey.lastEmailLogged, consts.localStorageKey.cookieSettings].map(key => ({
      key,
      value: this.getCachedData(key),
    }))

    localStorage.clear()
    document.cookie = `${sessionKey}=;path=/;domain=molotov.tv;SameSite=Lax;Max-Age=-99999999;`

    backup.forEach(b => {
      this.setCachedData(b.key, b.value)
    })
  },
}
