import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import classNames from "classnames"

import SectionTitle from "components/SectionTitle"
import FormGroup from "components/FormGroup"
import FormLabel from "components/FormGroup/FormLabel"
import Input from "components/Input"
import Separator from "components/Separator"
import Button from "components/Button"
import InputSubmit from "components/InputSubmit"
import Panel from "components/Panel"

import { update as updatePassword } from "actions/password"
import { get as getAccount } from "actions/account"

import selectAccount from "selectors/account"

import i18n from "consts/i18n"
import consts from "consts"

import styles from "./index.css"

@connect(state => ({
  passwordForm: state.forms.password,
  account: selectAccount(state),
}))
export default class ModalSetPassword extends Component {
  static propTypes = {
    passwordForm: PropTypes.object,
    account: PropTypes.object,
    close: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
  }

  static defaultProps = {
    passwordForm: {},
    account: {},
  }

  state = {
    currentPassword: "",
    password: {
      value: "",
      focused: false,
      valid: false,
    },
    passwordConfirm: {
      value: "",
      valid: false,
    },
  }

  onSubmit = ev => {
    const { password } = this.state

    ev.preventDefault()

    if (!this.submitDisabled()) {
      this.props.dispatch(
        updatePassword(null, password.value, () => {
          this.props.dispatch(getAccount(this.props.close))
        })
      )
    }
  }

  isPasswordConfirmValid = (passwordConfirm, password) => {
    return this.isPasswordValid(passwordConfirm) && password === passwordConfirm
  }

  isPasswordValid = password => {
    return password.length >= consts.minPasswordLength
  }

  submitDisabled = () => {
    const { passwordForm } = this.props

    const { password, passwordConfirm } = this.state

    return !password.valid || !passwordConfirm.valid || passwordForm.pending
  }

  onPasswordChange = value => {
    this.setState({
      password: {
        ...this.state.password,
        value,
        valid: this.isPasswordValid(value),
      },
      passwordConfirm: {
        ...this.state.passwordConfirm,
        valid: this.isPasswordConfirmValid(this.state.passwordConfirm.value, value),
      },
    })
  }

  onPasswordConfirmChange = value => {
    this.setState({
      passwordConfirm: {
        ...this.state.passwordConfirm,
        value,
        valid: this.isPasswordConfirmValid(value, this.state.password.value),
      },
    })
  }

  onPasswordFocus = () => {
    this.setState({
      password: {
        ...this.state.password,
        focused: true,
      },
    })
  }

  onPasswordBlur = () => {
    this.setState({
      password: {
        ...this.state.password,
        focused: false,
      },
    })
  }

  componentDidMount() {
    if (this.props.account.hasPassword) {
      this.props.close()
    }
  }

  render() {
    const { close, passwordForm } = this.props

    const { password, passwordConfirm } = this.state

    return (
      <div>
        <SectionTitle title={i18n.modalSetPassword.title} customStyle={styles.title} />

        <div className={styles.intro}>{i18n.modalSetPassword.intro}</div>

        {passwordForm.error && (
          <Panel customStyle={styles.error}>
            <span>{i18n.modalSetPassword.failed}</span>
          </Panel>
        )}

        <form onSubmit={this.onSubmit}>
          <FormGroup customStyle={styles.group}>
            <FormLabel title={i18n.modalSetPassword.password} />
            <Input
              type="password"
              success={password.valid}
              error={!this.state.password.focused && password.value.length > 0 && !password.valid}
              onFocus={this.onPasswordFocus}
              onBlur={this.onPasswordBlur}
              placeholder={i18n.modalSetPassword.inputPlaceholder}
              customStyle={styles.password}
              onChange={this.onPasswordChange}
            />
          </FormGroup>

          <FormGroup customStyle={styles.group}>
            <FormLabel title={i18n.modalSetPassword.passwordConfirm} />
            <Input
              type="password"
              success={passwordConfirm.valid}
              placeholder={i18n.modalSetPassword.inputPlaceholder}
              error={passwordConfirm.value.length > 0 && !passwordConfirm.valid}
              customStyle={styles.password}
              onChange={this.onPasswordConfirmChange}
            />
          </FormGroup>

          <Separator customStyle={styles.separator} />

          <Button color={Button.colors.greyFull} customStyle={styles.button} onClick={close} value={i18n.cancel} />
          <InputSubmit
            type="submit"
            customStyle={classNames(styles.rightButton, InputSubmit.types.yellowFull, {
              [styles.disabled]: this.submitDisabled(),
            })}
            value={i18n.save}
          />
        </form>
      </div>
    )
  }
}
