/* eslint-disable react/sort-comp */
import React from "react"
import ImageSliderPropTypes from "./ImageSliderPropTypes"
import { assignObjects } from "./ImageSliderUtil"
import data from "./ImageSliderData"
import styles from "./ImageSliderStyle"

import assets from "consts/assets"

import { getAssetURL } from "helpers/static"

class ImageSlider extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      idx: 0,
      sliding: false,
      currentSlideStyle: styles.getImageSlide(this.getImageUrl(0), this.getImageUrlx2(0), this.props.slideDuration, 0),
      nextSlideStyle: styles.getImageSlide(this.getImageUrl(1), this.getImageUrlx2(1), this.props.slideDuration, this.props.width),
    }
  }

  getImageUrl = idx => getAssetURL(`image.dsp.${this.props.dsp}.sliderImages[${idx}].url`)
  getImageUrlx2 = idx => getAssetURL(`image.dsp.${this.props.dsp}.sliderImages[${idx}].urlx2`)

  isCanSlide = idx => idx !== this.state.idx && !this.state.sliding

  callPropsFunc = (name, ...args) => {
    if (this.props[name]) {
      this.props[name](...args)
    }
  }

  onClickBullets = idx => {
    if (!this.isCanSlide(idx)) {
      return
    }

    this.callPropsFunc("onClickBullets", idx)
    this.slide(idx)

    if (this.props.autoplay) {
      clearInterval(this.interval)
      this.startAutoPlay()
    }
  }

  slide = idx => {
    const toNext = idx > this.state.idx
    const currentUrl = this.getImageUrl(this.state.idx)
    const currentUrlx2 = this.getImageUrlx2(this.state.idx)
    const nextUrl = this.getImageUrl(idx)
    const nextUrlx2 = this.getImageUrlx2(idx)
    const nextReadyX = toNext ? this.props.width : -this.props.width
    const currentOffetX = toNext ? -this.props.width : this.props.width

    // ready to animation slides
    this.setState(
      {
        idx,
        sliding: true,
        currentSlideStyle: styles.getImageSlide(currentUrl, currentUrlx2, 0, 0, this.props.useGPURender),
        nextSlideStyle: styles.getImageSlide(nextUrl, nextUrlx2, 0, nextReadyX, this.props.useGPURender),
      },
      () => {
        // animation slides
        setTimeout(() => {
          this.setState({
            currentSlideStyle: styles.getImageSlide(currentUrl, currentUrlx2, this.props.slideDuration, currentOffetX, this.props.useGPURender),
            nextSlideStyle: styles.getImageSlide(nextUrl, nextUrlx2, this.props.slideDuration, 0, this.props.useGPURender),
          })
        }, 50)
      }
    )

    this.callPropsFunc("onStartSlide", idx + 1, assets.image.dsp[this.props.dsp].sliderImages.length)
  }

  onSlideEnd = () => {
    // console.log(`[ImageSlider] onSlideEnd idx:${this.state.idx}`)
    const currentUrl = this.getImageUrl(this.state.idx)
    const currentUrlx2 = this.getImageUrlx2(this.state.idx)
    const nextUrl = this.getImageUrl(this.state.idx + 1)
    const nextUrlx2 = this.getImageUrlx2(this.state.idx + 1)

    this.setState({
      currentSlideStyle: styles.getImageSlide(currentUrl, currentUrlx2, 0, 0, this.props.useGPURender),
      nextSlideStyle: styles.getImageSlide(nextUrl, nextUrlx2, 0, this.props.width, this.props.useGPURender),
      sliding: false,
    })

    this.callPropsFunc("onCompleteSlide", this.state.idx + 1, assets.image.dsp[this.props.dsp].sliderImages.length)
  }

  renderBullets = (length, idx) => {
    if (length > 1) {
      const bulletList = Array.from({ length }).map((e, i) => (
        <button
          type="button"
          className={data.ClassNameBullets}
          style={i === idx ? styles.BulletActive : styles.BulletNormal}
          key={`bullet-${i + 1}`}
          onClick={this.onClickBullets.bind(this, i)}
        />
      ))
      return <div style={styles.BulletContainer(length)}>{bulletList}</div>
    }
    return null
  }

  renderSlide = () => {
    return (
      <div style={styles.ImageSlider}>
        <div style={this.state.currentSlideStyle} onTransitionEnd={this.onSlideEnd} />
        <div style={this.state.nextSlideStyle} />
      </div>
    )
  }

  startAutoPlay = () => {
    this.interval = setInterval(() => {
      const nextSlideIndex = this.state.idx + 1 < assets.image.dsp[this.props.dsp].sliderImages.length ? this.state.idx + 1 : 0
      this.slide(nextSlideIndex)
    }, 5000)
  }

  componentDidMount() {
    if (this.props.autoplay) {
      this.startAutoPlay()
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  render() {
    const rootStyle = styles.getRootContainer(this.props.width, this.props.height, this.props.bgColor)
    const imageLength = assets.image.dsp[this.props.dsp].sliderImages.length
    const bullets = this.props.showBullets ? this.renderBullets(imageLength, this.state.idx) : null

    return (
      <div className={data.ClassNameRoot} style={assignObjects(rootStyle, this.props.style)}>
        <div style={styles.getSubContainer(this.props.width, this.props.height)}>{this.renderSlide()}</div>
        {bullets}
      </div>
    )
  }
}

ImageSlider.propTypes = ImageSliderPropTypes.propTypes
ImageSlider.defaultProps = ImageSliderPropTypes.defaultTypes

export default ImageSlider
