import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import styles from "./../index.css"

const IconRadioOutline = ({ customStyle = null }) => (
  <svg height="24" className={classNames(customStyle, styles.radioOutline)} viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m12 2c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"
      fillRule="evenodd"
    />
  </svg>
)

IconRadioOutline.propTypes = {
  customStyle: PropTypes.string,
}

export default IconRadioOutline
