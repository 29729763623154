import { GET_SEO_CONFIG_PENDING, GET_SEO_CONFIG_SUCCESS, GET_SEO_CONFIG_FAILED } from "consts/actions"

import stateHelper from "helpers/state"

import consts from "consts"

export function getSEOConfig() {
  return (dispatch, getState) => {
    const seo = getState().seo

    if (Object.keys(seo).length && !seo.pending && !seo.error) {
      return
    }

    return dispatch({
      accessTokenRequired: false,
      request: {
        url: consts.endpoints.seoConfig,
        method: "GET",
      },
      cache: 15 * 60 * 1000,
      onStart: () => {
        return {
          type: GET_SEO_CONFIG_PENDING,
        }
      },
      onSuccess: (payload, meta, dispatch) => {
        return dispatch({
          type: GET_SEO_CONFIG_SUCCESS,
          payload,
        })
      },
      onError: (payload, meta, dispatch) => {
        return dispatch({
          type: GET_SEO_CONFIG_FAILED,
          payload: stateHelper.errorPayload(payload.user_message, meta.httpCode),
        })
      },
    })
  }
}
