import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import styles from "./../index.css"

const IconUser = ({ customStyle = null }) => (
  <svg viewBox="0 -3 24 30" className={classNames(customStyle, styles.fill)}>
    <path
      fillRule="evenodd"
      d="M12 24C5.373 24 0 18.627 0 12S5.373 0 12 0s12 5.373 12 12-5.373 12-12 12zm6.647-3.43c-.09-.13-.202-.295-.293-.423-.16-.225-.317-.45-.46-.673-.005-.01-.788-1.06-3.608-1.188-.98 0-1.695-.736-1.696-1.752L12.57 16c0-.077-.008.085 0 0v-.57l.573-.573c.418-.194.948-.52 1.368-.836l.08-.053c.33-.21.795-.654.84-.824l.02-.218c.212-1.1.55-3.098.55-3.782 0-2.692-1.31-4-4-4-2.73 0-4 1.27-4 4 0 .703.338 2.692.55 3.78l.02.202c.036.178.457.602.828.835l.056.035c.45.315.99.666 1.403.862l.572.572V16c.007.08 0-.083 0 0l-.02.534c0 1.014-.712 1.75-1.648 1.75-2.833.115-3.655 1.187-3.656 1.187-.144.228-.3.452-.46.678-.09.128-.204.29-.294.423C7.19 22 9.492 22.858 12 22.858c2.507 0 4.81-.858 6.647-2.286zM12 1.144C6.004 1.143 1.143 6.003 1.143 12c0 3.078 1.286 5.85 3.343 7.825.218-.323.45-.645.657-.968 0 0 .954-1.567 4.57-1.714.37 0 .554-.24.554-.61V16c0-.074-.012-.14-.016-.213-.412-.196-.892-.465-1.45-.854 0 0-1.37-.843-1.37-1.79 0 0-.572-2.924-.572-4C6.858 6.49 7.993 4 12 4c3.955 0 5.143 2.49 5.143 5.143 0 1.05-.57 4-.57 4 0 .92-1.372 1.79-1.372 1.79-.545.412-1.03.687-1.453.884-.002.062-.013.12-.013.183v.534c0 .368.183.61.552.61 3.67.163 4.57 1.713 4.57 1.713.207.323.44.645.658.968 2.057-1.975 3.343-4.747 3.343-7.825 0-5.996-4.86-10.857-10.857-10.857z"
    />
  </svg>
)

IconUser.propTypes = {
  customStyle: PropTypes.string,
}

export default IconUser
