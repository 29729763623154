import {
  DELETE_BOOKMARKS_RECORD_PENDING,
  DELETE_BOOKMARKS_RECORD_SUCCESS,
  DELETE_BOOKMARKS_RECORD_FAILED,
  DELETE_BOOKMARKS_SCHEDULED_PENDING,
  DELETE_BOOKMARKS_SCHEDULED_SUCCESS,
  DELETE_BOOKMARKS_SCHEDULED_FAILED,
} from "consts/actions"

import stateHelper from "helpers/state"

const initialState = {}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case DELETE_BOOKMARKS_RECORD_PENDING:
    case DELETE_BOOKMARKS_SCHEDULED_PENDING:
      return stateHelper.pending()

    case DELETE_BOOKMARKS_RECORD_SUCCESS:
    case DELETE_BOOKMARKS_RECORD_FAILED:
    case DELETE_BOOKMARKS_SCHEDULED_SUCCESS:
    case DELETE_BOOKMARKS_SCHEDULED_FAILED:
      return initialState

    default:
      return state
  }
}
