import {
  LOGOUT,
  SET_SESSION,
  OAUTH_AUTHORIZE_SUCCESS,
  OAUTH_AUTHORIZE_PENDING,
  OAUTH_AUTHORIZE_FAILURE,
  OAUTH_AUTHORIZE_REQUESTED,
} from "consts/actions"

import stateHelper from "helpers/state"

const initialState = {
  ...stateHelper.pending(false),
  data: null,
  choseProfile: false,
  redirectionURL: null,
  application: null,
  error: null,
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_SESSION:
      return {
        ...initialState,
        ...stateHelper.pending(state.pending),
      }

    case OAUTH_AUTHORIZE_SUCCESS:
      return {
        ...state,
        ...stateHelper.pending(false),
        redirectionURL: action.payload.redirect_uri || null,
      }

    case OAUTH_AUTHORIZE_REQUESTED:
      return {
        ...state,
        ...stateHelper.pending(false),
        application: action.payload,
      }

    case OAUTH_AUTHORIZE_PENDING:
      return {
        ...state,
        ...stateHelper.pending(),
        error: null,
      }

    case OAUTH_AUTHORIZE_FAILURE:
      return {
        ...state,
        ...stateHelper.pending(false),
        error: action.payload.message,
        redirectionURL: action.payload.redirect_uri || null,
      }

    case LOGOUT:
      return initialState

    default:
      return state
  }
}
