import { ADD_FEEDBACK, DELETE_FEEDBACK } from "consts/actions"

export function addFeedback(action) {
  return {
    type: ADD_FEEDBACK,
    payload: {
      label: action.label,
      isError: action.isError,
    },
  }
}

export function deleteFeedback(feedbackId) {
  return {
    type: DELETE_FEEDBACK,
    payload: {
      feedbackId,
    },
  }
}
