import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import Alert from "components/Alert"

import { close as closeFlash } from "actions/flash"

@connect(
  state => ({
    flash: state.flash,
  }),
  dispatch => ({
    closeFlash: () => {
      dispatch(closeFlash())
    },
  })
)
export default class Flash extends Component {
  static propTypes = {
    flash: PropTypes.object,
    closeFlash: PropTypes.func,
    status: PropTypes.string,
    customStyle: PropTypes.string,
  }

  static defaultProps = {
    flash: {},
    closeFlash: () => {},
    customStyle: "",
  }

  flashTimeout = null

  componentDidUpdate() {
    const { closeFlash } = this.props

    if (this.props.flash.open) {
      if (this.flashTimeout) {
        clearTimeout(this.flashTimeout)
      }

      this.flashTimeout = setTimeout(() => {
        closeFlash()
      }, 5000)
    }
  }

  componentWillUnmount() {
    clearTimeout(this.flashTimeout)
    this.props.closeFlash()
  }

  render() {
    const { customStyle, flash, status } = this.props

    return (
      <div>
        {flash.open && (
          <div className={customStyle}>
            <Alert type={status}>
              <div>{flash.message}</div>
              {!__PROD__ && <div>{flash.messageDeveloper}</div>}
            </Alert>
          </div>
        )}
      </div>
    )
  }
}
