import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import classNames from "classnames"

import apiAction from "helpers/apiAction"

import consts from "consts"
import styles from "./index.css"

@connect()
export default class FormatterText extends Component {
  static propTypes = {
    children: PropTypes.node,
    dispatch: PropTypes.func.isRequired,
    part: PropTypes.object.isRequired,
    style: PropTypes.object.isRequired,
  }

  onClick = e => {
    const { dispatch, part } = this.props

    if (part) {
      const hasAction = apiAction.getAction(part, consts.apiActionTypes.onClick)

      if (hasAction) {
        e.preventDefault()
        dispatch(apiAction.handleAction(part, consts.apiActionTypes.onClick))
      }
    }
  }

  render() {
    const { children, part, style } = this.props

    return (
      <span
        className={classNames({
          [styles.withAction]: part.on_click,
          [styles.iconContainer]: part.type === "icon",
        })}
        style={style}
        onClick={this.onClick}
      >
        {children}
      </span>
    )
  }
}
