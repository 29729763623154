import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import CookieSettings from "components/CookieSettings"
import Icon, { IconTypes } from "components/Icon"

import { applyPrivacySettings } from "actions/privacy"

import styles from "./index.css"

@connect()
export default class ModalCookie extends Component {
  static propTypes = {
    close: PropTypes.func.isRequired,
    dispatch: PropTypes.func,
  }

  onValidate = () => {
    this.props.dispatch(applyPrivacySettings())
    this.props.close()
  }

  render() {
    return (
      <div className={styles.root} onClick={this.onClick}>
        <Icon type={IconTypes.close} rootStyle={styles.close} iconStyle={styles.closeIcon} onClick={this.props.close} />
        <CookieSettings render={true} onValidate={this.onValidate} />
      </div>
    )
  }
}

const ModalCookieModalCustomStyle = styles.modal

export { ModalCookieModalCustomStyle }
