import {
  CARD_FORM_REQUESTED,
  DELETE_MTVPAY_CARD_SUCCESS,
  GET_MTVPAY_PAYMENT_METHOD_PENDING,
  GET_MTVPAY_PAYMENT_METHOD_SUCCESS,
  GET_MTVPAY_PAYMENT_METHOD_FAILED,
  INFOS_SCREEN_REQUESTED,
  LOGOUT,
  SAVE_MTVPAY_CARD_SUCCESS,
} from "consts/actions"

import stateHelper from "helpers/state"
import routes from "consts/routes"

const initialState = {
  deleteError: null,
  formVisible: false,
  wording: null,
  details: null,
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case CARD_FORM_REQUESTED:
      return {
        ...state,
        formVisible: true,
      }

    case DELETE_MTVPAY_CARD_SUCCESS:
      // Remove the matching card from the list
      if (Array.isArray(state.cards)) {
        return {
          ...state,
          cards: state.cards.filter(card => card.recurring_reference !== action.payload.id),
        }
      }

      return state

    case GET_MTVPAY_PAYMENT_METHOD_PENDING:
      return {
        ...state,
        ...stateHelper.pending(),
      }

    case GET_MTVPAY_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        ...action.payload,
      }

    case GET_MTVPAY_PAYMENT_METHOD_FAILED:
      return {
        ...state,
        error: action.payload,
      }

    case SAVE_MTVPAY_CARD_SUCCESS:
    case INFOS_SCREEN_REQUESTED:
      return {
        ...state,
        ...initialState,
        details: {
          ...state.details,
          cards: action.payload || state?.details?.cards,
        },
      }

    case LOGOUT:
    case "@@router/LOCATION_CHANGE":
      if (action?.payload?.location?.pathname !== routes.payment) {
        return {
          ...state,
          ...initialState,
        }
      }
      if (state.formVisible) {
        return {
          ...state,
          deleteError: null,
          formVisible: false,
          wording: null,
        }
      }

      return state

    default:
      return state
  }
}
