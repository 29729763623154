import React, { useState } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { useDispatch, useSelector } from "react-redux"

import formatterHelper from "helpers/formatter"

import ProductPanelDesktop from "../ProductPanel/desktop"
import ProductDesktopHeader from "../productDesktopHeader"
import Icon, { IconTypes } from "components/Icon"
import Button from "components/Button"

import apiAction from "helpers/apiAction"

import { SELECTED_PERIODICITY } from "consts/actions"

import styles from "../index.css"
import periodicityStyles from "./index.css"

const ProductPeriodicity = props => {
  const { frameless, productIframeMode, periodicity } = props
  const frameType = useSelector(state => state.appSettings.frameType)
  const dispatch = useDispatch()
  const [indexActive, setActive] = useState(periodicity.offers.findIndex(offer => offer.selected))

  const onClickOffer = index => {
    setActive(index)
  }

  const button = periodicity?.offers[indexActive]?.buy_button

  const handleInteractionButton = () => {
    const actionName = button?.["on_click"]
    const action = button?.actions?.[actionName]
    if (action) {
      dispatch({
        type: SELECTED_PERIODICITY,
      })
      dispatch(apiAction.executeAction(action))
    }
  }

  return (
    <div
      className={classNames(styles.rootDesktop, {
        [styles.productForFrameless]: frameless,
      })}
    >
      <div className={styles.productContent}>
        {productIframeMode && <ProductDesktopHeader periodicity={periodicity} />}

        <div className={styles.productContainer}>
          <div className={periodicityStyles.offersWrapper}>
            {periodicity &&
              (periodicity?.offers || []).map((offer, index) => {
                const isDisabled = !periodicity.offers[index].clickable
                const isActive = !isDisabled && indexActive === index
                return (
                  <div
                    className={classNames(periodicityStyles.offer, {
                      [periodicityStyles.active]: isActive,
                      [periodicityStyles.disabled]: isDisabled,
                    })}
                    onClick={isDisabled ? null : () => onClickOffer(index)}
                    key={index}
                  >
                    <div className={classNames(periodicityStyles.flexWrapper)}>
                      <div className={periodicityStyles.iconWrapper}>
                        <div
                          className={classNames(periodicityStyles.icon, {
                            [periodicityStyles.iconActive]: isActive,
                          })}
                        >
                          {isActive && <Icon type={IconTypes.checkmark} iconStyle={periodicityStyles.iconRootStyle} />}
                        </div>
                      </div>
                      <div className={periodicityStyles.descWrapper}>
                        {offer.header_formatter && (
                          <div className={periodicityStyles.offerHeader}>
                            {formatterHelper.styled(offer.header_formatter.format, offer.header_formatter.parts)}
                          </div>
                        )}
                        {offer.title_formatter && (
                          <div className={periodicityStyles.offerTitle}>
                            {formatterHelper.styled(offer.title_formatter.format, offer.title_formatter.parts)}
                          </div>
                        )}
                        {offer.description_formatter && (
                          <div className={periodicityStyles.offerDescription}>
                            {formatterHelper.styled(offer.description_formatter.format, offer.description_formatter.parts)}
                          </div>
                        )}
                      </div>
                      <div className={periodicityStyles.priceWrapper}>
                        {offer.subtitle_formatter && !isDisabled && (
                          <div className={periodicityStyles.offerPrice}>
                            {formatterHelper.styled(offer.subtitle_formatter.format, offer.subtitle_formatter.parts)}
                          </div>
                        )}
                        {offer.subtitle_formatter && isDisabled && (
                          <div className={periodicityStyles.disabledText}>
                            {formatterHelper.styled(offer.subtitle_formatter.format, offer.subtitle_formatter.parts)}
                          </div>
                        )}
                        {offer.subtitle_note_formatter && !isDisabled && (
                          <div className={periodicityStyles.offerPriceSubtitle}>
                            {formatterHelper.styled(offer.subtitle_note_formatter.format, offer.subtitle_note_formatter.parts)}
                          </div>
                        )}
                      </div>
                    </div>
                    {offer.banner_formatter && !isDisabled && (
                      <div className={periodicityStyles.offerBanner}>
                        {formatterHelper.styled(offer.banner_formatter.format, offer.banner_formatter.parts)}
                      </div>
                    )}
                  </div>
                )
              })}
            {button && (
              <Button
                color={Button.colors[button.styles[0]]}
                onClick={handleInteractionButton}
                value={formatterHelper.styled(button.title_formatter.format, button.title_formatter.parts)}
                customStyle={periodicityStyles.button}
              />
            )}
            <div className={periodicityStyles.buttonSubtitle}>Sans engagement</div>
          </div>
          <ProductPanelDesktop
            customStyle={classNames(styles.panelDesktop, periodicityStyles.panelDesktop)}
            forceMobileDisplay={false}
            frameType={frameType}
            product={periodicity.product}
            dispatch={dispatch}
            currentOfferReinsurance={periodicity?.offers[indexActive]?.reinsurance}
          />
        </div>
      </div>
    </div>
  )
}

ProductPeriodicity.propTypes = {
  frameless: PropTypes.bool,
  productIframeMode: PropTypes.bool,
  periodicity: PropTypes.object,
}

export default ProductPeriodicity
