import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import styles from "./../index.css"

const IconLike = ({ customStyle = null }) => (
  <svg viewBox="0 0 20 18" className={classNames(customStyle, styles.fill)}>
    <g fill="#FFF" fillRule="evenodd" opacity=".5">
      <path d="M18.03 10.807c.538.106.934.42 1.322.756.539-1.041 1.269-3.59-4.232-3.905 0 0 .187-1.293.934-2.403.748-1.111 1.134-3.02-.377-4.083-1.511-1.063-1.745-.534-1.887.014-.082.311-.586 2.435-.586 2.435S9.775 7.09 9.19 8.107c0 0-.423.599-1.17.599H5.356s-2.195 2.642-.293 6.308c0 0 .033.167.423.167h2.99s.828.904 2.777 1.983c0 0 1.251.556 2.356.238 0 0 .227.191.748.191.5 0 2.127.303 2.435-1.376-.233-.182-.481-.322-.784-.377-.247-.044-.142-.412.104-.367.378.07.69.257.98.492.404-.116 1.13-.468 1.177-1.481-.013-.009-.027-.014-.04-.026-.282-.27-.592-.484-.986-.554-.247-.043-.142-.41.104-.367.447.079.818.331 1.138.635.404-.203 1.196-.793.599-2.165 0 0 .029-.04.074-.108-.363-.32-.729-.626-1.233-.724-.246-.049-.142-.415.104-.368z" />
      <path d="M4.623 8.325H1.21c-2.193 3.809 0 7.237 0 7.237h3.51c-2.706-3.619-.098-7.237-.098-7.237z" />
    </g>
  </svg>
)

IconLike.propTypes = {
  customStyle: PropTypes.string,
}

export default IconLike
