import React from "react"
import PropTypes from "prop-types"

const MolotovPlus = ({ customStyle = null }) => (
  <svg width="1378" height="135" viewBox="0 0 1378 135" fill="none" xmlns="http://www.w3.org/2000/svg" className={customStyle}>
    <path
      d="M106.85 2.8498H149.9V132H108.9V71.5248L81.225 132H68.925L41.25 71.5248V132H0.25V2.8498H43.3L75.075 70.4998L106.85 2.8498Z"
      fill="white"
    />
    <path
      d="M256.516 67.4248C256.516 63.5981 255.764 60.0448 254.261 56.7648C252.894 53.3481 250.981 50.4098 248.521 47.9498C246.061 45.3531 243.191 43.3715 239.911 42.0048C236.768 40.5015 233.419 39.7498 229.866 39.7498C226.313 39.7498 222.896 40.5015 219.616 42.0048C216.473 43.3715 213.671 45.3531 211.211 47.9498C208.751 50.4098 206.769 53.3481 205.266 56.7648C203.899 60.0448 203.216 63.5981 203.216 67.4248C203.216 71.2515 203.899 74.8731 205.266 78.2898C206.769 81.5698 208.751 84.5081 211.211 87.1048C213.671 89.5648 216.473 91.5465 219.616 93.0498C222.896 94.4165 226.313 95.0998 229.866 95.0998C233.419 95.0998 236.768 94.4165 239.911 93.0498C243.191 91.5465 246.061 89.5648 248.521 87.1048C250.981 84.5081 252.894 81.5698 254.261 78.2898C255.764 74.8731 256.516 71.2515 256.516 67.4248ZM297.516 67.4248C297.516 76.7181 295.739 85.3965 292.186 93.4598C288.769 101.523 283.986 108.561 277.836 114.575C271.823 120.588 264.648 125.371 256.311 128.925C248.111 132.341 239.296 134.05 229.866 134.05C220.436 134.05 211.553 132.341 203.216 128.925C195.016 125.371 187.841 120.588 181.691 114.575C175.678 108.561 170.894 101.523 167.341 93.4598C163.924 85.3965 162.216 76.7181 162.216 67.4248C162.216 58.2681 163.924 49.6581 167.341 41.5948C170.894 33.3948 175.678 26.2881 181.691 20.2748C187.841 14.2615 195.016 9.54647 203.216 6.12981C211.553 2.57647 220.436 0.799805 229.866 0.799805C239.296 0.799805 248.111 2.57647 256.311 6.12981C264.648 9.54647 271.823 14.2615 277.836 20.2748C283.986 26.2881 288.769 33.3948 292.186 41.5948C295.739 49.6581 297.516 58.2681 297.516 67.4248Z"
      fill="white"
    />
    <path d="M309.76 2.8498H350.76V93.0498H406.11V132H309.76V2.8498Z" fill="white" />
    <path
      d="M500.562 67.4248C500.562 63.5981 499.81 60.0448 498.307 56.7648C496.94 53.3481 495.027 50.4098 492.567 47.9498C490.107 45.3531 487.237 43.3715 483.957 42.0048C480.814 40.5015 477.465 39.7498 473.912 39.7498C470.359 39.7498 466.942 40.5015 463.662 42.0048C460.519 43.3715 457.717 45.3531 455.257 47.9498C452.797 50.4098 450.815 53.3481 449.312 56.7648C447.945 60.0448 447.262 63.5981 447.262 67.4248C447.262 71.2515 447.945 74.8731 449.312 78.2898C450.815 81.5698 452.797 84.5081 455.257 87.1048C457.717 89.5648 460.519 91.5465 463.662 93.0498C466.942 94.4165 470.359 95.0998 473.912 95.0998C477.465 95.0998 480.814 94.4165 483.957 93.0498C487.237 91.5465 490.107 89.5648 492.567 87.1048C495.027 84.5081 496.94 81.5698 498.307 78.2898C499.81 74.8731 500.562 71.2515 500.562 67.4248ZM541.562 67.4248C541.562 76.7181 539.785 85.3965 536.232 93.4598C532.815 101.523 528.032 108.561 521.882 114.575C515.869 120.588 508.694 125.371 500.357 128.925C492.157 132.341 483.342 134.05 473.912 134.05C464.482 134.05 455.599 132.341 447.262 128.925C439.062 125.371 431.887 120.588 425.737 114.575C419.724 108.561 414.94 101.523 411.387 93.4598C407.97 85.3965 406.262 76.7181 406.262 67.4248C406.262 58.2681 407.97 49.6581 411.387 41.5948C414.94 33.3948 419.724 26.2881 425.737 20.2748C431.887 14.2615 439.062 9.54647 447.262 6.12981C455.599 2.57647 464.482 0.799805 473.912 0.799805C483.342 0.799805 492.157 2.57647 500.357 6.12981C508.694 9.54647 515.869 14.2615 521.882 20.2748C528.032 26.2881 532.815 33.3948 536.232 41.5948C539.785 49.6581 541.562 58.2681 541.562 67.4248Z"
      fill="white"
    />
    <path d="M617.452 132H576.452V41.7998H542.627V2.8498H651.277V41.7998H617.452V132Z" fill="white" />
    <path
      d="M746.61 67.4248C746.61 63.5981 745.858 60.0448 744.355 56.7648C742.988 53.3481 741.075 50.4098 738.615 47.9498C736.155 45.3531 733.285 43.3715 730.005 42.0048C726.862 40.5015 723.513 39.7498 719.96 39.7498C716.407 39.7498 712.99 40.5015 709.71 42.0048C706.567 43.3715 703.765 45.3531 701.305 47.9498C698.845 50.4098 696.864 53.3481 695.36 56.7648C693.994 60.0448 693.31 63.5981 693.31 67.4248C693.31 71.2515 693.994 74.8731 695.36 78.2898C696.864 81.5698 698.845 84.5081 701.305 87.1048C703.765 89.5648 706.567 91.5465 709.71 93.0498C712.99 94.4165 716.407 95.0998 719.96 95.0998C723.513 95.0998 726.862 94.4165 730.005 93.0498C733.285 91.5465 736.155 89.5648 738.615 87.1048C741.075 84.5081 742.988 81.5698 744.355 78.2898C745.858 74.8731 746.61 71.2515 746.61 67.4248ZM787.61 67.4248C787.61 76.7181 785.833 85.3965 782.28 93.4598C778.863 101.523 774.08 108.561 767.93 114.575C761.917 120.588 754.742 125.371 746.405 128.925C738.205 132.341 729.39 134.05 719.96 134.05C710.53 134.05 701.647 132.341 693.31 128.925C685.11 125.371 677.935 120.588 671.785 114.575C665.772 108.561 660.989 101.523 657.435 93.4598C654.019 85.3965 652.31 76.7181 652.31 67.4248C652.31 58.2681 654.019 49.6581 657.435 41.5948C660.989 33.3948 665.772 26.2881 671.785 20.2748C677.935 14.2615 685.11 9.54647 693.31 6.12981C701.647 2.57647 710.53 0.799805 719.96 0.799805C729.39 0.799805 738.205 2.57647 746.405 6.12981C754.742 9.54647 761.917 14.2615 767.93 20.2748C774.08 26.2881 778.863 33.3948 782.28 41.5948C785.833 49.6581 787.61 58.2681 787.61 67.4248Z"
      fill="white"
    />
    <path d="M914.75 2.8498L874.775 132H825.575L785.6 2.8498H829.675L850.175 80.7498L870.675 2.8498H914.75Z" fill="white" />
    <path
      d="M982.65 2.8498C990.303 2.8498 997.205 4.01147 1003.36 6.3348C1009.64 8.65814 1014.97 11.9381 1019.35 16.1748C1023.72 20.2748 1027.07 25.1948 1029.39 30.9348C1031.71 36.6748 1032.88 43.0298 1032.88 49.9998C1032.88 56.9698 1031.71 63.3248 1029.39 69.0648C1027.07 74.8048 1023.72 79.7931 1019.35 84.0298C1014.97 88.1298 1009.64 91.3415 1003.36 93.6648C997.205 95.9881 990.303 97.1498 982.65 97.1498H966.25V132H925.25V2.8498H982.65ZM978.55 60.2498C982.103 60.2498 984.837 59.3615 986.75 57.5848C988.8 55.6715 989.825 53.1431 989.825 49.9998C989.825 46.8565 988.8 44.3965 986.75 42.6198C984.837 40.7065 982.103 39.7498 978.55 39.7498H966.25V60.2498H978.55Z"
      fill="#FFC107"
    />
    <path d="M1043.07 2.8498H1084.07V93.0498H1139.42V132H1043.07V2.8498Z" fill="#FFC107" />
    <path
      d="M1183.65 2.8498V76.6498C1183.65 82.3898 1185.29 86.8998 1188.57 90.1798C1191.85 93.4598 1196.36 95.0998 1202.1 95.0998C1207.84 95.0998 1212.35 93.4598 1215.63 90.1798C1218.91 86.8998 1220.55 82.3898 1220.55 76.6498V2.8498H1261.55V76.6498C1261.55 85.3965 1260.18 93.3231 1257.45 100.43C1254.71 107.4 1250.75 113.413 1245.56 118.47C1240.5 123.39 1234.28 127.216 1226.9 129.95C1219.52 132.683 1211.25 134.05 1202.1 134.05C1192.8 134.05 1184.47 132.683 1177.09 129.95C1169.84 127.216 1163.62 123.39 1158.43 118.47C1153.37 113.413 1149.48 107.4 1146.75 100.43C1144.01 93.3231 1142.65 85.3965 1142.65 76.6498V2.8498H1183.65Z"
      fill="#FFC107"
    />
    <path
      d="M1272.81 39.7498C1272.81 34.6931 1273.9 29.8415 1276.09 25.1948C1278.41 20.4115 1281.69 16.2431 1285.93 12.6898C1290.16 8.99981 1295.29 6.12981 1301.3 4.07981C1307.45 1.89314 1314.35 0.799805 1322.01 0.799805C1330.48 0.799805 1338 1.96147 1344.56 4.28481C1351.25 6.47147 1356.86 9.54647 1361.37 13.5098C1365.88 17.3365 1369.29 21.9148 1371.62 27.2448C1374.08 32.4381 1375.31 37.9731 1375.31 43.8498L1335.33 47.9498C1335.33 44.8065 1334.44 42.3465 1332.67 40.5698C1330.89 38.6565 1328.7 37.6998 1326.11 37.6998C1324.06 37.6998 1322.35 38.1781 1320.98 39.1348C1319.61 40.0915 1318.93 41.3215 1318.93 42.8248C1318.93 44.8748 1320.37 46.5148 1323.24 47.7448C1326.11 48.8381 1329.73 49.9998 1334.1 51.2298C1338.47 52.4598 1343.12 53.9631 1348.04 55.7398C1353.1 57.3798 1357.81 59.7031 1362.19 62.7098C1366.56 65.7165 1370.18 69.6798 1373.05 74.5998C1375.92 79.3831 1377.36 85.5331 1377.36 93.0498C1377.36 98.3798 1376.13 103.573 1373.67 108.63C1371.34 113.55 1367.99 117.923 1363.62 121.75C1359.25 125.44 1353.85 128.446 1347.43 130.77C1341.14 132.956 1334.03 134.05 1326.11 134.05C1317.36 134.05 1309.57 132.888 1302.74 130.565C1295.9 128.105 1290.09 124.825 1285.31 120.725C1280.66 116.625 1277.04 111.841 1274.45 106.375C1271.99 100.908 1270.76 95.0998 1270.76 88.9498L1310.73 84.8498C1310.73 88.4031 1311.82 91.3415 1314.01 93.6648C1316.2 95.9881 1318.86 97.1498 1322.01 97.1498C1325.15 97.1498 1327.47 96.4665 1328.98 95.0998C1330.48 93.7331 1331.23 92.0248 1331.23 89.9748C1331.23 87.9248 1329.8 86.3531 1326.93 85.2598C1324.06 84.0298 1320.43 82.7998 1316.06 81.5698C1311.69 80.3398 1306.97 78.9048 1301.92 77.2648C1297 75.4882 1292.35 73.0965 1287.98 70.0898C1283.6 67.0831 1279.98 63.1881 1277.11 58.4048C1274.24 53.4848 1272.81 47.2665 1272.81 39.7498Z"
      fill="#FFC107"
    />
  </svg>
)

MolotovPlus.propTypes = {
  customStyle: PropTypes.string,
}

export default MolotovPlus
