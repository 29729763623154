import { get } from "lodash"

import logmatic from "logmatic-js"
import consts from "consts"

const baseUrl = typeof window !== "undefined" ? window.location.href.replace(/(https?:\/\/[^/]+)\/.*/, "$1") : ""
const internalScriptRegExp = new RegExp(baseUrl)
// extractNewForms: https://stackoverflow.com/questions/43399818/securityerror-from-facebook-and-cross-domain-messaging
const ignoreableRegExp = new RegExp(/extractNewForms/)

// the log manager uses the logmatic-js sdk
// but force the endpoint to send the logs
// to datadog
const LogManager = (function() {
  logmatic.init(consts.datadog.apiKey)
  logmatic.forceEndpoint(`${consts.datadog.endpoint}${consts.datadog.apiKey}`)
  logmatic.setIPTracking("client.IP")
  logmatic.setMetas({
    service: consts.datadog.name,
    appname: consts.datadog.name,
    mltv_info: {
      env: process.env.NODE_ENV,
    },
  })

  const LOG_SESSION_UID = Math.floor(Math.random() * 10000) + "-" + new Date().getTime()

  const log = function(type, message, params) {
    // only client side
    // for server-side the logs are forwarded
    // from syslog to datadog
    if (typeof window === "undefined") return

    logmatic.log(
      `${message} (#${LOG_SESSION_UID})`,
      {
        severity: type.toLowerCase(),
        mltv_details: params,
      },
      type
    )
  }

  return {
    INFO: (message, params) => {
      log("INFO", message, params)
    },
    WARNING: (message, params) => {
      log("WARNING", message, params)
    },
    ERROR: (message, params) => {
      log("ERROR", message, params)
    },
  }
})()

if (typeof window !== "undefined") {
  window.onerror = (messageOrEvent, source, lineno, colno, error) => {
    const stack = error ? JSON.stringify(error.stack) : "No stack found."
    const errorObj = {
      message: typeof messageOrEvent === "string" ? messageOrEvent : JSON.stringify(messageOrEvent),
      source: source,
      lineno: lineno,
      colno: colno,
      stack: stack,
    }

    let message = "An uncatched error occured on " + window.location.pathname

    if (source && !internalScriptRegExp.test(source)) {
      message = "[EXTERNAL] " + message
    }
    if (stack && ignoreableRegExp.test(stack)) {
      message = "[IGNOREABLE] " + message
    }

    errorObj.userAgent = get(window, "navigator.userAgent", "No user-agent found")

    console.error(message, errorObj) // eslint-disable-line

    LogManager.ERROR(message, errorObj)
  }
}

export default LogManager
