import defaultProductProps from "consts/product"
import FormatterHelper from "helpers/formatter"

export default {
  isDisabled(product) {
    const currentTimeInSeconds = Math.round(new Date().getTime() / 1000)
    const beginAt = product.begin_at
    const endAt = product.end_at
    // remove equivalenceCode check when PageProducts use begin_at / end_at
    if (beginAt <= currentTimeInSeconds && currentTimeInSeconds <= endAt) {
      return false
    }
    return true
  },

  // Replace undefined props of products by their equivalent default values from defaultProductProps.
  //      If no-property needs to be patched, returns currentProductProps as is (unmodified)
  //      If at least one property needs to be patched, returns a new object with the props patched (does not mutate currentProductProps)
  fixUndefinedProps(currentProductProps) {
    const fixes = {}

    if (currentProductProps) {
      for (var propName in defaultProductProps) {
        if (typeof currentProductProps[propName] === "undefined") {
          fixes[propName] = defaultProductProps[propName]
        }
      }
    }

    return Object.keys(fixes).length ? Object.assign({}, currentProductProps, fixes) : currentProductProps
  },

  getTextPrice(product) {
    if (!product || !product.reinsurance || !product.reinsurance.price) {
      return ""
    }
    return `${FormatterHelper.toTextOnly(product.reinsurance.price.format, product.reinsurance.price.parts)} ${
      product.reinsurance.price_subtitle
        ? FormatterHelper.toTextOnly(product.reinsurance.price_subtitle.format, product.reinsurance.price_subtitle.parts)
        : ""
    }`
  },
}
