import React, { Component } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { get } from "lodash"

import styles from "./index.css"

export default class Feedback extends Component {
  static propTypes = {
    feedback: PropTypes.object,
    dispatch: PropTypes.func,
    isError: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
  }

  state = {
    isOpen: false,
  }

  close = () => {
    this.setState({ isOpen: false })

    this.autoClose = setTimeout(() => {
      this.props.onClose()
    }, 250)
  }

  componentDidMount() {
    this.open = setTimeout(() => {
      this.setState({ isOpen: true })
    })

    this.autoCloseAnimation = setTimeout(this.close, 3000)
  }

  componentWillUnmount() {
    clearTimeout(this.open)
    clearTimeout(this.autoCloseAnimation)
    clearTimeout(this.autoClose)
  }

  render() {
    return (
      <div
        className={classNames(styles.feedback, { [styles.open]: this.state.isOpen, [styles.error]: get(this.props, "feedback.isError") })}
        onClick={this.props.onClose}
      >
        {get(this.props, "feedback.label")}
      </div>
    )
  }
}
