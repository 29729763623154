import {
  GET_BOOKMARKS_RECORD_PENDING,
  GET_BOOKMARKS_RECORD_SUCCESS,
  GET_BOOKMARKS_RECORD_FAILED,
  GET_BOOKMARKS_SCHEDULED_PENDING,
  GET_BOOKMARKS_SCHEDULED_SUCCESS,
  GET_BOOKMARKS_SCHEDULED_FAILED,
  DELETE_BOOKMARKS_RECORD_PENDING,
  DELETE_BOOKMARKS_RECORD_SUCCESS,
  DELETE_BOOKMARKS_RECORD_FAILED,
  DELETE_BOOKMARKS_SCHEDULED_PENDING,
  DELETE_BOOKMARKS_SCHEDULED_SUCCESS,
  DELETE_BOOKMARKS_SCHEDULED_FAILED,
  DELETE_ALL_BOOKMARKS_RECORD_PENDING,
  DELETE_ALL_BOOKMARKS_RECORD_FAILED,
  DELETE_ALL_BOOKMARKS_SCHEDULED_PENDING,
  DELETE_ALL_BOOKMARKS_SCHEDULED_FAILED,
  DELETE_FUTURE_BOOKMARKS_SCHEDULED_PENDING,
  DELETE_FUTURE_BOOKMARKS_SCHEDULED_FAILED,
  SET_BOOKMARKS_RECORD,
  SET_BOOKMARKS_SCHEDULED,
  TOGGLE_BOOKMARK_RECORD,
  TOGGLE_BOOKMARK_SCHEDULED,
  TOGGLE_BOOKMARKS,
} from "consts/actions"

import stateHelper from "helpers/state"
import { get as getRecordMetrics } from "actions/recordMetrics"
import { get as getScheduledMetrics } from "actions/scheduledMetrics"

import consts from "consts"

// remove childs with no parent
const filterBookmarksToKeep = bookmarks => {
  const cleanBookmarks = []

  bookmarks.forEach(bookmark => {
    if (bookmark.parent) {
      const mustKeep = bookmarks.filter(b => b.id === bookmark.parent).length

      if (!mustKeep) {
        return
      }
    }

    cleanBookmarks.push(bookmark)
  })

  return cleanBookmarks
}

export function getRecords() {
  return {
    accessTokenRequired: true,
    request: {
      url: consts.endpoints.bookmarksRecord,
      method: "GET",
    },
    onStart: () => {
      return {
        type: GET_BOOKMARKS_RECORD_PENDING,
      }
    },
    onSuccess: payload => {
      return {
        type: GET_BOOKMARKS_RECORD_SUCCESS,
        payload,
      }
    },
    onError: payload => {
      return {
        type: GET_BOOKMARKS_RECORD_FAILED,
        payload: stateHelper.errorPayload(payload.user_message),
      }
    },
  }
}

export function getScheduled() {
  return {
    accessTokenRequired: true,
    request: {
      url: consts.endpoints.bookmarksScheduled,
      method: "GET",
    },
    onStart: () => {
      return {
        type: GET_BOOKMARKS_SCHEDULED_PENDING,
      }
    },
    onSuccess: payload => {
      return {
        type: GET_BOOKMARKS_SCHEDULED_SUCCESS,
        payload,
      }
    },
    onError: payload => {
      return {
        type: GET_BOOKMARKS_SCHEDULED_FAILED,
        payload: stateHelper.errorPayload(payload.user_message),
      }
    },
  }
}

export function setBookmarksRecord(bookmarksRecord = []) {
  return {
    type: SET_BOOKMARKS_RECORD,
    payload: bookmarksRecord,
  }
}

export function toggleBookmarksRecord(value, checked) {
  return {
    type: TOGGLE_BOOKMARK_RECORD,
    parentChildCoupled: true,
    value,
    checked,
  }
}

export function setBookmarksScheduled(bookmarksScheduled = []) {
  return {
    type: SET_BOOKMARKS_SCHEDULED,
    payload: bookmarksScheduled,
  }
}

export function toggleBookmarksScheduled(value, checked) {
  return {
    type: TOGGLE_BOOKMARK_SCHEDULED,
    parentChildCoupled: false,
    value,
    checked,
  }
}

export function toggleBookmarks(value, checked) {
  return {
    type: TOGGLE_BOOKMARKS,
    parentChildCoupled: true,
    value,
    checked,
  }
}

export function removeRecords(ids = []) {
  return {
    accessTokenRequired: true,
    request: {
      url: consts.endpoints.bookmarksRecord,
      method: "DELETE",
      body: ids,
    },
    onStart: () => {
      return {
        type: DELETE_BOOKMARKS_RECORD_PENDING,
      }
    },
    onSuccess: (payload, meta, dispatch, getState) => {
      const bookmarksRecord = getState().bookmarksRecord
      const bookmarksRecordRefresh = []

      if (bookmarksRecord) {
        bookmarksRecord.data.map(bookmark => {
          if (ids.indexOf(bookmark.id) === -1) {
            bookmarksRecordRefresh.push(bookmark)
          }
        })

        const cleanBookmarksRecordRefresh = filterBookmarksToKeep(bookmarksRecordRefresh)

        dispatch({
          type: DELETE_BOOKMARKS_RECORD_SUCCESS,
        })
        dispatch(getRecordMetrics())
        dispatch(setBookmarksRecord(cleanBookmarksRecordRefresh))
      }
    },
    onError: payload => {
      return {
        type: DELETE_BOOKMARKS_RECORD_FAILED,
        payload: stateHelper.errorPayload(payload.user_message),
      }
    },
  }
}

export function deleteAllBookmarksRecord() {
  return {
    accessTokenRequired: true,
    request: {
      url: consts.endpoints.deleteAllbookmarksRecord,
      method: "DELETE",
    },
    onStart: () => {
      return {
        type: DELETE_ALL_BOOKMARKS_RECORD_PENDING,
      }
    },
    onSuccess: () => {
      return dispatch => {
        dispatch(getRecordMetrics())
        dispatch(setBookmarksRecord())
      }
    },
    onError: payload => {
      return {
        type: DELETE_ALL_BOOKMARKS_RECORD_FAILED,
        payload: stateHelper.errorPayload(payload.user_message),
      }
    },
  }
}

export function removeScheduled(ids = []) {
  return {
    accessTokenRequired: true,
    request: {
      url: consts.endpoints.bookmarksScheduled,
      method: "DELETE",
      body: ids,
    },
    onStart: () => {
      return {
        type: DELETE_BOOKMARKS_SCHEDULED_PENDING,
      }
    },
    onSuccess: (payload, meta, dispatch, getState) => {
      const bookmarksScheduled = getState().bookmarksScheduled
      const bookmarksScheduledRefresh = []

      if (bookmarksScheduled) {
        bookmarksScheduled.data.map(bookmark => {
          if (ids.indexOf(bookmark.id) === -1) {
            bookmarksScheduledRefresh.push(bookmark)
          }
        })

        const cleanBookmarksScheduledRefresh = filterBookmarksToKeep(bookmarksScheduledRefresh)

        dispatch({
          type: DELETE_BOOKMARKS_SCHEDULED_SUCCESS,
        })
        dispatch(getScheduledMetrics())
        dispatch(setBookmarksScheduled(cleanBookmarksScheduledRefresh))
      }
    },
    onError: payload => {
      return {
        type: DELETE_BOOKMARKS_SCHEDULED_FAILED,
        payload: stateHelper.errorPayload(payload.user_message),
      }
    },
  }
}

export function deleteAllBookmarksScheduled() {
  return {
    accessTokenRequired: true,
    request: {
      url: consts.endpoints.deleteAllbookmarksScheduled,
      method: "DELETE",
    },
    onStart: () => {
      return {
        type: DELETE_ALL_BOOKMARKS_SCHEDULED_PENDING,
      }
    },
    onSuccess: () => {
      return dispatch => {
        dispatch(getScheduledMetrics())
        dispatch(setBookmarksScheduled())
      }
    },
    onError: payload => {
      return {
        type: DELETE_ALL_BOOKMARKS_SCHEDULED_FAILED,
        payload: stateHelper.errorPayload(payload.user_message),
      }
    },
  }
}

export function deactivateRecurringBookmarks(programId, channelId) {
  return {
    accessTokenRequired: true,
    request: {
      url: consts.endpoints.deleteFutureBookmarksScheduled,
      method: "DELETE",
      body: {
        program_id: programId,
        channel_id: channelId,
      },
    },
    onStart() {
      return {
        type: DELETE_FUTURE_BOOKMARKS_SCHEDULED_PENDING,
      }
    },
    onSuccess(payload, meta, dispatch, getState) {
      const bookmarksRecord = getState().bookmarksRecord
      const bookmarksRecordRefresh = []

      bookmarksRecord.data.map(bookmark => {
        if (bookmark.id === `${programId}-${channelId}`) {
          bookmark.is_recurring = false
        }

        bookmarksRecordRefresh.push(bookmark)
      })

      const bookmarksScheduled = getState().bookmarksScheduled
      const bookmarksScheduledRefresh = []

      bookmarksScheduled.data.forEach(bookmark => {
        let bookmarkProgramId

        if (bookmark.parent) {
          bookmarkProgramId = bookmark.parent.split("-")[0]
        } else {
          bookmarkProgramId = bookmark.id.split("-")[0]
        }

        if (programId !== bookmarkProgramId) {
          bookmarksScheduledRefresh.push(bookmark)
        }
      })

      dispatch(getScheduledMetrics())
      dispatch(setBookmarksRecord(bookmarksRecordRefresh))
      dispatch(setBookmarksScheduled(bookmarksScheduledRefresh))
    },
    onError(payload) {
      return {
        type: DELETE_FUTURE_BOOKMARKS_SCHEDULED_FAILED,
        payload: stateHelper.errorPayload(payload.user_message),
      }
    },
  }
}
