import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import styles from "./index.css"

const Loader = ({ centerInParent = true, customStyle = null, dotsStyle = null }) => (
  <div
    className={classNames(
      styles.root,
      {
        [styles.centerInPage]: !centerInParent && !customStyle,
        [styles.centerInParent]: centerInParent && !customStyle,
      },
      customStyle
    )}
  >
    <div className={classNames(styles.dots, dotsStyle)} />
    <div className={classNames(styles.dots, dotsStyle)} />
    <div className={classNames(styles.dots, dotsStyle)} />
  </div>
)

Loader.propTypes = {
  centerInParent: PropTypes.bool,
  customStyle: PropTypes.string,
  dotsStyle: PropTypes.string,
}

export default Loader
