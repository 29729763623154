import { SET_SERVER_ERROR } from "consts/actions"

const initialState = false

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_SERVER_ERROR:
      return action.payload

    default:
      return state
  }
}
