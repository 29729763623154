import {
  GET_SUBSCRIPTIONS_PENDING,
  GET_SUBSCRIPTIONS_SUCCESS,
  GET_SUBSCRIPTIONS_FAILED,
  POST_SUBSCRIPTIONS_PENDING,
  POST_SUBSCRIPTIONS_SUCCESS,
  POST_SUBSCRIPTIONS_FAILED,
  DELETE_SUBSCRIPTIONS_PENDING,
  DELETE_SUBSCRIPTIONS_SUCCESS,
  DELETE_SUBSCRIPTIONS_FAILED,
  RESET_SUBSCRIPTIONS_ERROR,
  POST_CONVERSION,
} from "consts/actions"

import consts from "consts"

import { get as _get } from "lodash"

import formatterHelper from "helpers/formatter"

import stateHelper from "helpers/state"

function _post(endpoint) {
  return function(productId, ratePlanId, onSuccess = consts.NoOp) {
    let body
    let method = "GET"
    if (typeof productId !== "undefined" && typeof ratePlanId !== "undefined") {
      body = {
        product_id: productId,
        rate_plan_id: ratePlanId,
      }
      method = "POST"
    }

    return {
      accessTokenRequired: true,
      request: {
        url: endpoint,
        method,
        body,
      },
      onStart: () => {
        return {
          type: POST_SUBSCRIPTIONS_PENDING,
        }
      },
      onSuccess: (payload, meta, dispatch, getState) => {
        let result = payload

        // On "subscribe", API returns an array of subscriptions BUT
        // on "restore", API returns the subscription directly (in this case
        // we must manually build the subscriptions array)
        if (!Array.isArray(result)) {
          const productId = _get(payload, "product.id")

          result = [...(getState().subscriptions.data || []).filter(sub => sub.product.id !== productId), result]
        }

        // Temporarily ignore subscriptions (see reducer subscriptions) and restore response payload to fix an issue
        // where re-inssurance are missing in those response and disappears from UI in already
        // subscribed molotov Pay offers boxes.
        dispatch({
          type: POST_SUBSCRIPTIONS_SUCCESS,
          payload: result,
        })

        onSuccess()
      },
      onError: payload => {
        const errorPayload = stateHelper.errorPayload(payload.error.user_message, payload.error.error_code)
        errorPayload.id = `${productId}`

        return {
          type: POST_SUBSCRIPTIONS_FAILED,
          payload: errorPayload,
        }
      },
    }
  }
}

export const post = _post(consts.endpoints.subscriptionsLegacy)

export function restore(subscriptionId, onSuccess) {
  return _post(
    formatterHelper.basic(consts.endpoints.restoreSubscriptionsLegacy, {
      subscriptionId,
    })
  )(undefined, undefined, onSuccess)
}

export function remove(subscriptionId, onSuccess = consts.NoOp) {
  return {
    accessTokenRequired: true,
    request: {
      url: `${consts.endpoints.subscriptionsV3}/${subscriptionId}`,
      params: {
        legacy: 1,
      },
      method: "DELETE",
    },
    onStart: () => {
      return {
        type: DELETE_SUBSCRIPTIONS_PENDING,
      }
    },
    onSuccess: payload => {
      return dispatch => {
        dispatch({
          type: DELETE_SUBSCRIPTIONS_SUCCESS,
          payload,
        })
        onSuccess()
      }
    },
    onError: payload => {
      return {
        type: DELETE_SUBSCRIPTIONS_FAILED,
        subscriptionId: subscriptionId,
        payload: stateHelper.errorPayload(payload.error.user_message, payload.error.error_code),
      }
    },
  }
}

export function get() {
  return {
    accessTokenRequired: true,
    request: {
      url: consts.endpoints.subscriptions,
      method: "GET",
    },
    onStart: (payload, meta, dispatch) => {
      dispatch({
        type: GET_SUBSCRIPTIONS_PENDING,
      })
    },
    onSuccess: (payload, meta, dispatch) => {
      dispatch({
        type: GET_SUBSCRIPTIONS_SUCCESS,
        payload: { data: payload },
      })
    },
    onError: (payload, meta, dispatch) => {
      dispatch({
        type: GET_SUBSCRIPTIONS_FAILED,
        payload: stateHelper.errorPayload(payload.user_message),
      })
    },
  }
}

export function resetSubscriptionsError(id) {
  return {
    type: RESET_SUBSCRIPTIONS_ERROR,
    id,
  }
}

export function postConversion(equivalenceCode) {
  return {
    type: POST_CONVERSION,
    equivalenceCode,
  }
}
