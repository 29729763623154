export default class MenuItems {
  constructor(data) {
    this.items = data.items
  }

  _findMenuByTitle(title) {
    const menu = this.items.find(item => item.title === title)
    return menu ? menu.sub_items : []
  }

  getCategories() {
    return this._findMenuByTitle("Catégories")
  }

  _findCategoryByID(id) {
    const category = this.getCategories().find(category => category.ID === id)
    return category ? category.sub_items : []
  }

  getMovies() {
    const MoviesID = 1
    return this._findCategoryByID(MoviesID)
  }

  getSeries() {
    const SeriesID = 2
    return this._findCategoryByID(SeriesID)
  }

  getSport() {
    const SportID = 3
    return this._findCategoryByID(SportID)
  }

  getNews() {
    const NewsID = 4
    return this._findCategoryByID(NewsID)
  }

  getEntertainment() {
    const EntertainmentID = 5
    return this._findCategoryByID(EntertainmentID)
  }

  getKids() {
    const KidsID = 6
    return this._findCategoryByID(KidsID)
  }

  getDocumentaries() {
    const DocumentariesID = 8
    return this._findCategoryByID(DocumentariesID)
  }

  getCulture() {
    const CultureID = 9
    return this._findCategoryByID(CultureID)
  }

  getSchool() {
    const SchoolID = 10
    return this._findCategoryByID(SchoolID)
  }

  getGenre(categoryId, genreId) {
    const genre = this._findCategoryByID(parseInt(categoryId)).find(genre => genre.ID === parseInt(genreId))
    return genre
  }
}
