import React, { useState } from "react"
import PropTypes from "prop-types"
import classnames from "classnames"

import { useSelector, useDispatch } from "react-redux"

import "paypal-braintree-web-client" // import of window.paypal object

import { getPaypalTransactionToken, postPaypalSuccess } from "actions/paypal"
import { setPaymentInterstitial } from "actions/mtvPay"

import Loader from "components/Loader"

import style from "./index.css"

const PaypalButton = props => {
  const { account } = useSelector(state => state.account)
  const dispatch = useDispatch()

  const [loaded, setLoaded] = useState(false)
  const [displayLoader, setDisplayLoader] = useState(true)

  const { paypalButtonID, amount, buttonStyle, currency, customBtnStyle } = props

  const flow = props.isTBCC ? "vault" : "checkout"
  const intent = props.isTBCC ? "tokenize" : "capture"

  const errorInterstitial = {
    type: "interstitial",
    subtype: "error",
    blocking: false,
    interaction: {
      buttons: [{ title: "J'ai compris", styles: ["primary"], on_click: ["close"], metadata: null }],
      actions: { close: { type: "close", section: props.equivalenceCode } },
    },
    is_restorable: false,
    id: "",
    template: "image_middle",
    title_formatter: { format: "%s", parts: [{ title: "Paiement échoué", color: "#1b1c20" }] },
    message_formatter: { format: "%s", parts: [{ title: "La transaction n'a pu aboutir", color: "#1b1c20" }] },
    metadata: null,
  }

  const userID = account?.id || JSON.parse(localStorage.getItem("session")).account.id

  const createFunction = paypalCheckoutInstance => {
    setDisplayLoader(true)
    return paypalCheckoutInstance.createPayment({
      flow,
      amount,
      currency,
      intent,
      locale: "fr_FR",
      requestBillingAgreement: true,
      billingAgreementDetails: {
        description: "Description de l'accord de facturation",
      },
    })
  }

  const getCreateFunction = (isTBCC, paypalCheckoutInstance) => {
    return isTBCC
      ? {
          createBillingAgreement: () => createFunction(paypalCheckoutInstance),
        }
      : {
          createOrder: () => createFunction(paypalCheckoutInstance),
        }
  }

  if (!loaded) {
    setLoaded(true)

    dispatch(
      getPaypalTransactionToken(userID, ({ token }) => {
        let braintreeWeb

        import(/* webpackChunkName: "braintree" */ "braintree-web")
          .then(instance => {
            braintreeWeb = instance

            return braintreeWeb.client.create({
              authorization: token.token,
            })
          })
          .then(clientInstance => {
            return braintreeWeb.paypalCheckout.create({
              client: clientInstance,
            })
          })
          .then(paypalCheckoutInstance => {
            return paypalCheckoutInstance.loadPayPalSDK({
              currency,
              intent,
              vault: true,
            })
          })
          .then(paypalCheckoutInstance => {
            setDisplayLoader(false)
            return window.paypal
              .Buttons({
                fundingSource: window.paypal.FUNDING.PAYPAL,
                style: {
                  color: "blue",
                  shape: "rect",
                  label: "paypal",
                  ...customBtnStyle,
                },

                ...getCreateFunction(props.isTBCC, paypalCheckoutInstance),

                onApprove: function(data) {
                  return paypalCheckoutInstance.tokenizePayment(data).then(({ nonce }) => {
                    dispatch(
                      postPaypalSuccess(
                        { nonce, rateplan: props.ratePlanId },
                        () => {
                          dispatch(setPaymentInterstitial(errorInterstitial, props.equivalenceCode))
                        },
                        props.onSuccess
                      )
                    )
                  })
                },

                onCancel: function(data) {
                  console.log("PayPal payment cancelled", JSON.stringify(data, 0, 2)) // eslint-disable-line no-console
                  dispatch(setPaymentInterstitial(errorInterstitial, props.equivalenceCode))
                },

                onError: function(err) {
                  console.error("PayPal error", err) // eslint-disable-line no-console
                  dispatch(setPaymentInterstitial(errorInterstitial, props.equivalenceCode))
                },
              })
              .render("#" + paypalButtonID)
          })
      })
    )
  }

  return (
    <>
      {displayLoader && <Loader customStyle={style.loader} centerInParent={true} />}
      <div
        className={classnames(style.paypalButtonContainer, {
          [style.hidden]: displayLoader,
        })}
      >
        <div className={classnames(style.paypalButton, buttonStyle)} id={paypalButtonID} />
        <br />
      </div>
    </>
  )
}

PaypalButton.propTypes = {
  amount: PropTypes.number.isRequired,
  buttonStyle: PropTypes.string,
  customBtnStyle: PropTypes.object,
  currency: PropTypes.string,
  paypalButtonID: PropTypes.string,
  equivalenceCode: PropTypes.string,
  ratePlanId: PropTypes.string,
  isTBCC: PropTypes.bool,
  onSuccess: PropTypes.func,
}

PaypalButton.defaultProps = {
  currency: "EUR",
  paypalButtonID: "paypal-button",
}

export default PaypalButton
