import { get } from "lodash"

import pictureHelper from "helpers/picture"

import consts from "consts"

export function getAssetURL(asset) {
  return pictureHelper.replaceProtocol(`${consts.config.staticUrl}${get(consts.assets, asset)}`)
}

export function getSharingImageProps(sharing, fallbackUrl) {
  let sharingImageProps = get(sharing, "image")

  if (!sharingImageProps) {
    sharingImageProps = {}
  }

  // Old config, to be deleted when back has shutdown old image service (see with Eric)
  if (!sharingImageProps.url) {
    sharingImageProps.url = fallbackUrl
    sharingImageProps.width = consts.socialImagesSize.width
    sharingImageProps.height = consts.socialImagesSize.height
  }

  if (!sharingImageProps.width) {
    sharingImageProps.width = consts.newSocialImagesSize.width
  }

  if (!sharingImageProps.height) {
    sharingImageProps.height = consts.newSocialImagesSize.height
  }

  sharingImageProps.url = pictureHelper.replaceProtocol(sharingImageProps.url)

  return sharingImageProps
}
