import React, { Component } from "react"
import PropTypes from "prop-types"
import { throttle } from "lodash"
import windowEventsManager from "managers/windowEventsManager"

export default class ResizeWatcher extends Component {
  static propTypes = {
    frequency: PropTypes.number,
    onResize: PropTypes.func.isRequired,
    children: PropTypes.element,
  }

  onResize = null

  componentDidMount() {
    this.onResize = throttle(this.props.onResize, this.props.frequency || 200)
    windowEventsManager.onResize(this.onResize)
  }

  componentWillUnmount() {
    windowEventsManager.removeResizeListener(this.onResize)
  }

  render() {
    return React.Children.only(this.props.children)
  }
}
