import React from "react"
import { useDispatch } from "react-redux"
import PropTypes from "prop-types"
import classnames from "classnames"

import { logout } from "actions/session"

import Separator from "components/Separator"
import IconLink from "components/Icon/IconLink"

import i18n from "consts/i18n"
import styles from "./index.css"

const getTitle = (title, mobileTitle, customTitleStyle) => {
  if (mobileTitle) {
    return (
      <>
        <h3 className={classnames(styles.root, styles.desktopTitle)}>{title}</h3>
        <h3 className={classnames(styles.root, styles.mobileTitle)}>{mobileTitle}</h3>
      </>
    )
  }
  return <h3 className={classnames(styles.root, customTitleStyle)}>{title}</h3>
}

const getContent = (isLogout, title, mobileTitle, customTitleStyle) => {
  const dispatch = useDispatch()
  if (isLogout) {
    return (
      <div className={styles.flex}>
        {getTitle(title, mobileTitle, customTitleStyle)}
        <div className={styles.logout} onClick={() => dispatch(logout())}>
          {" "}
          <IconLink customStyle={styles.iconLink} /> {i18n.logout}
        </div>
      </div>
    )
  }
  return getTitle(title, mobileTitle, customTitleStyle)
}

const SectionTitle = ({ title, customStyle, isLogout = false, mobileTitle = "", displaySeparator = true, customTitleStyle }) => (
  <div className={customStyle}>
    {getContent(isLogout, title, mobileTitle, customTitleStyle)}
    {displaySeparator && <Separator />}
  </div>
)

SectionTitle.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  customStyle: PropTypes.string,
  mobileTitle: PropTypes.string,
  isLogout: PropTypes.bool,
  displaySeparator: PropTypes.bool,
  customTitleStyle: PropTypes.string,
}

export default SectionTitle
