import { createSelector } from "reselect"
import { get } from "lodash"

const selectSubscriptions = state => state.subscriptions

export default createSelector(
  [selectSubscriptions],
  subscriptions => {
    const formatedSubscriptions = []
    const hasValidSubscriptionsData = Array.isArray(subscriptions.data)

    if (hasValidSubscriptionsData) {
      subscriptions.data.map(subscription => {
        formatedSubscriptions.push({
          name: subscription.product.name,
          subscriptionId: subscription.id,
          productId: subscription.product.id,
          isApple: subscription.platform === "apple",
        })
      })
    }

    return {
      pending: subscriptions.pending,
      data: formatedSubscriptions,
      error: subscriptions.error
        ? {
            productId: hasValidSubscriptionsData
              ? get(subscriptions.data.find(({ id }) => id === subscriptions.error.subscriptionId), "product.id")
              : null,
            ...subscriptions.error,
          }
        : subscriptions.error,
    }
  }
)
