import consts from "consts"

const segmentHelpers = {
  getDeviceTypeFromAgent(molotovAgent) {
    return molotovAgent ? molotovAgent.type : null
  },

  getDeviceTypeFromNavigator(navigator) {
    if (navigator.isIphone || navigator.isAndroid) {
      return consts.devicesType.phone
    }
    if (navigator.isIpad) {
      return consts.devicesType.tablet
    }
    if (navigator.isMac || navigator.isWindows || navigator.isWindows64 || navigator.isLinux) {
      return consts.devicesType.desktop
    }
    // TODO: detect tv ?

    return "unknown"
  },

  getOsFromNavigator(navigator) {
    if (navigator.isMac) {
      return "macos"
    }
    if (navigator.isWindows || navigator.isWindows64) {
      return "windows"
    }
    if (navigator.isAndroid) {
      return "android"
    }
    if (navigator.isIphone || navigator.isIpad) {
      return "ios"
    }
    if (navigator.isLinux) {
      return "linux"
    }

    return "unknown os"
  },

  getOsFromAgent(molotovAgent) {
    return molotovAgent ? molotovAgent.os : null
  },

  getAppFromAppSettings(appSettings) {
    // We consider that we're in an app if a molotov-agent param was provided in URL (frameless is a fallback
    // that can be removed once IOS-1143 and REACT-1736 are done)
    return appSettings.molotovAgent || appSettings.frameless ? "molotov" : consts.molotovAgent.appId
  },
}

export default segmentHelpers
