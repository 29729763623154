import {
  GET_CHANNEL_PENDING,
  GET_CHANNEL_SUCCESS,
  GET_CHANNEL_FAILED,
  GET_CHANNEL_REPLAY_PENDING,
  GET_CHANNEL_REPLAY_SUCCESS,
  GET_CHANNEL_REPLAY_FAILED,
} from "consts/actions"

import stateHelper from "helpers/state"

const initialState = {
  direct: {},
  replay: {},
}

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_CHANNEL_PENDING:
      return {
        ...state,
        direct: stateHelper.pending(),
      }

    case GET_CHANNEL_SUCCESS:
      return {
        ...state,
        direct: action.payload,
      }

    case GET_CHANNEL_FAILED:
      return {
        ...state,
        direct: { error: action.payload },
      }

    case GET_CHANNEL_REPLAY_PENDING:
      return {
        ...state,
        replay: stateHelper.pending(),
      }

    case GET_CHANNEL_REPLAY_SUCCESS:
      return {
        ...state,
        replay: action.payload,
      }

    case GET_CHANNEL_REPLAY_FAILED:
      return {
        ...state,
        replay: { error: action.payload },
      }

    default:
      return state
  }
}
