export function getAPIEnv(apiPath) {
  let env = "prod"

  if (apiPath.includes("fapi")) {
    env = "prod"
  }

  if (apiPath.includes("staging")) {
    env = "staging"
  }

  if (apiPath.includes("dev")) {
    env = "dev"
  }

  if (apiPath.includes("127.0.0.1:3000")) {
    env = "local"
  }

  return env
}
