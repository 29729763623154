import { getAPIEnv } from "helpers/api"

import consts from "consts"

const featureFlipHelpers = {
  routesToFlip: {
    // route qui ne sera jamais visible
    ["/exemple/de/route/1"]: {},

    // route qui ne sera visible qu'à partir de la date définie
    ["/exemple/de/route/2"]: {
      releaseDate: new Date("04/15/2021 04:00:00"),
    },

    // Xbox
    [consts.routes.deviceXbox]: {
      releaseDate: new Date("02/08/2021 00:00:00"),
    },
  },

  isRouteFlipped(route) {
    const env = getAPIEnv(consts.config.apiPath)
    if (env === "prod" && route) {
      const routeToFlip = this.routesToFlip[route]
      return routeToFlip ? routeToFlip.releaseDate && routeToFlip.releaseDate < Date.now() : true
    }
    return true
  },
}

export default featureFlipHelpers
