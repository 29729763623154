import React, { Component } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import Icon, { IconTypes } from "components/Icon"
import Button from "components/Button"

import { getAPIEnv } from "helpers/api"

import styles from "./index.css"
import consts from "consts"

export default class ButtonAppleLogin extends Component {
  static propTypes = {
    rootStyle: PropTypes.string,
    onAppleResponse: PropTypes.func.isRequired,
  }

  onClick = () => {
    window.AppleID.auth.signIn().then(data => {
      this.props.onAppleResponse(data)
    })
  }

  getRedirectUri = () => {
    const env = getAPIEnv(consts.config.apiPath)
    return consts.apple[env].redirectURI
  }

  componentDidMount() {
    if (consts.config.appleAppId && window.AppleID) {
      window.AppleID.auth.init({
        clientId: consts.config.appleAppId,
        scope: "email name",
        redirectURI: this.getRedirectUri(),
        usePopup: true,
        responseType: "code id_token",
        responseMode: "query",
      })
    }
  }

  render() {
    const { rootStyle } = this.props

    return (
      <Button
        color={Button.colors.apple}
        customStyle={classNames(styles.root, rootStyle)}
        value={
          <div className={styles.btnContainer}>
            <Icon type={IconTypes.apple} rootStyle={styles.icon} />
            Apple
          </div>
        }
        onClick={this.onClick}
      />
    )
  }
}
