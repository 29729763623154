import React from "react"

const IconFilter = () => (
  <svg viewBox="0 0 15 14">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-973.000000, -626.000000)" fill="#000000">
        <g transform="translate(972.000000, 625.000000)">
          <g transform="translate(1.000000, 1.000000)">
            <path d="M3,2.2538488 L3,0.617647059 C3,0.333382844 2.76015255,0.102941176 2.46428571,0.102941176 C2.16841888,0.102941176 1.92857143,0.333382844 1.92857143,0.617647059 L1.92857143,2.30029298 M1.92857143,5.79828239 L1.92857143,13.3823529 L1.92857143,13.3823529 C1.92857143,13.6666172 2.16841888,13.8970588 2.46428571,13.8970588 C2.76015255,13.8970588 3,13.6666172 3,13.3823529 L3,5.78420842" />
            <path d="M4.91883118,4.00827206 C4.91883118,2.73252379 3.84158094,1.69852941 2.51298701,1.69852941 C1.18439308,1.69852941 0.107142857,2.73252379 0.107142857,4.00827206 C0.107142857,5.28402033 1.18439308,6.31801471 2.51298701,6.31801471 C3.84158094,6.31801471 4.91883118,5.28402033 4.91883118,4.00827206 Z M1.17857143,4.00827206 C1.17857143,3.30127747 1.77589254,2.72794118 2.51298701,2.72794118 C3.2500815,2.72794118 3.84740261,3.30127747 3.84740261,4.00827206 C3.84740261,4.71526664 3.2500815,5.28860294 2.51298701,5.28860294 C1.77589254,5.28860294 1.17857143,4.71526664 1.17857143,4.00827206 Z" />
            <path d="M13.0714286,2.2538488 L13.0714286,0.617647059 C13.0714286,0.333382844 12.8315811,0.102941176 12.5357143,0.102941176 C12.2398475,0.102941176 12,0.333382844 12,0.617647059 L12,2.30029298 M12,5.79828239 L12,13.3823529 L12,13.3823529 C12,13.6666172 12.2398475,13.8970588 12.5357143,13.8970588 C12.8315811,13.8970588 13.0714286,13.6666172 13.0714286,13.3823529 L13.0714286,5.78420842" />
            <path d="M14.9902597,4.00827206 C14.9902597,2.73252379 13.9130095,1.69852941 12.5844156,1.69852941 C11.2558216,1.69852941 10.1785714,2.73252379 10.1785714,4.00827206 C10.1785714,5.28402033 11.2558216,6.31801471 12.5844156,6.31801471 C13.9130095,6.31801471 14.9902597,5.28402033 14.9902597,4.00827206 Z M11.25,4.00827206 C11.25,3.30127747 11.8473211,2.72794118 12.5844156,2.72794118 C13.3215101,2.72794118 13.9188312,3.30127747 13.9188312,4.00827206 C13.9188312,4.71526664 13.3215101,5.28860294 12.5844156,5.28860294 C11.8473211,5.28860294 11.25,4.71526664 11.25,4.00827206 Z" />
            <path
              d="M8.14285714,2.2538488 L8.14285714,0.617647059 C8.14285714,0.333382844 7.90300969,0.102941176 7.60714286,0.102941176 C7.31127602,0.102941176 7.07142857,0.333382844 7.07142857,0.617647059 L7.07142857,2.30029298 M7.07142857,5.79828239 L7.07142857,13.3823529 L7.07142857,13.3823529 C7.07142857,13.6666172 7.31127602,13.8970588 7.60714286,13.8970588 C7.90300969,13.8970588 8.14285714,13.6666172 8.14285714,13.3823529 L8.14285714,5.78420842"
              transform="translate(7.607143, 7.000000) rotate(180.000000) translate(-7.607143, -7.000000) "
            />
            <path
              d="M9.96428561,9.99172794 C9.96428561,8.71597967 8.88703539,7.68198529 7.55844146,7.68198529 C6.22984753,7.68198529 5.15259729,8.71597967 5.15259729,9.99172794 C5.15259729,11.2674762 6.22984753,12.3014706 7.55844146,12.3014706 C8.88703539,12.3014706 9.96428561,11.2674762 9.96428561,9.99172794 Z M6.22402586,9.99172794 C6.22402586,9.28473336 6.82134696,8.71139706 7.55844146,8.71139706 C8.29553593,8.71139706 8.89285704,9.28473336 8.89285704,9.99172794 C8.89285704,10.6987225 8.29553593,11.2720588 7.55844146,11.2720588 C6.82134696,11.2720588 6.22402586,10.6987225 6.22402586,9.99172794 Z"
              transform="translate(7.558441, 9.991728) rotate(180.000000) translate(-7.558441, -9.991728) "
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default IconFilter
