import {
  UPDATE_EMAIL_PENDING,
  UPDATE_EMAIL_SUCCESS,
  UPDATE_EMAIL_FAILED,
  UPDATE_EMAIL_RESET,
  RESET_EMAIL_PENDING,
  RESET_EMAIL_SUCCESS,
  RESET_EMAIL_FAILED,
  RESET_EMAIL_FORM,
  CLEAR_EMAIL_FORM,
} from "consts/actions"

import { setSession } from "actions/session"

import stateHelper from "helpers/state"

import consts from "consts"

export function update(email, password, token, onSuccess = () => {}) {
  return {
    accessTokenRequired: false,
    request: {
      url: `${consts.endpoints.resetEmail}/${token}`,
      method: "POST",
      body: {
        email,
        password,
      },
    },
    onStart: () => {
      return {
        type: UPDATE_EMAIL_PENDING,
      }
    },
    onSuccess: (payload, meta, dispatch, getState) => {
      const session = getState().session.data

      if (session) {
        const updatedAccount = { account: { ...session.account, ...payload } }
        dispatch(setSession({ ...session, ...updatedAccount }))
      }
      onSuccess()
      dispatch(updateSuccess())
    },
    onError: payload => {
      return {
        type: UPDATE_EMAIL_FAILED,
        payload: stateHelper.errorPayload(payload.error.user_message),
      }
    },
  }
}

function updateSuccess() {
  return {
    type: UPDATE_EMAIL_SUCCESS,
  }
}

export function reset() {
  return {
    type: UPDATE_EMAIL_RESET,
  }
}

export function reinitializeEmail(email) {
  return {
    accessTokenRequired: false,
    request: {
      url: consts.endpoints.resetEmail,
      method: "POST",
      body: {
        email,
      },
    },
    onStart: () => {
      return {
        type: RESET_EMAIL_PENDING,
      }
    },
    onSuccess: () => {
      return {
        type: RESET_EMAIL_SUCCESS,
      }
    },
    onError: payload => {
      return {
        type: RESET_EMAIL_FAILED,
        payload: stateHelper.errorPayload(payload.error.user_message),
      }
    },
  }
}

export function resetEmailForm() {
  return {
    type: RESET_EMAIL_FORM,
  }
}

export function clearEmailForm() {
  return {
    type: CLEAR_EMAIL_FORM,
  }
}
