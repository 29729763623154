import { GET_PAYPAL_AB_TESTING_PENDING, GET_PAYPAL_AB_TESTING_SUCCESS, GET_PAYPAL_AB_TESTING_FAILED } from "consts/actions"

import consts from "consts"
import stateHelper from "helpers/state"

export const getPaypalAbTesting = () => {
  return {
    accessTokenRequired: true,
    request: {
      url: consts.endpoints.abTesting,
      method: "GET",
    },
    onStart: (payload, meta, dispatch) => {
      dispatch({
        type: GET_PAYPAL_AB_TESTING_PENDING,
      })
    },
    onSuccess: (payload, meta, dispatch) => {
      dispatch({
        type: GET_PAYPAL_AB_TESTING_SUCCESS,
        payload: { data: payload },
      })
    },
    onError: (payload, meta, dispatch) => {
      dispatch({
        type: GET_PAYPAL_AB_TESTING_FAILED,
        payload: stateHelper.errorPayload(payload.user_message),
      })
    },
  }
}
