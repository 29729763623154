import { GET_PRODUCT_CONSTRAINTS_PENDING, GET_PRODUCT_CONSTRAINTS_SUCCESS, GET_PRODUCT_CONSTRAINTS_FAILED } from "consts/actions"

import stateHelper from "helpers/state"

const initialState = {}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_PRODUCT_CONSTRAINTS_PENDING:
      return {
        ...state,
        [action.productId]: stateHelper.pending(),
      }

    case GET_PRODUCT_CONSTRAINTS_SUCCESS:
      return {
        ...state,
        [action.productId]: action.payload,
      }

    case GET_PRODUCT_CONSTRAINTS_FAILED:
      return {
        ...state,
        [action.productId]: action.payload,
      }

    default:
      return state
  }
}
