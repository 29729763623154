import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import classNames from "classnames"

import Button from "components/Button"

import apiAction from "helpers/apiAction"
import formatterHelper from "helpers/formatter"
import TrackingSegment from "managers/segmentManager"

import consts from "consts"
import styles from "./index.css"

@connect()
export default class InteractionButton extends Component {
  static propTypes = {
    button: PropTypes.object.isRequired,
    customClickHandler: PropTypes.func,
    customStyle: PropTypes.string,
    clickParams: PropTypes.object,
    dispatch: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    onBeforeClick: PropTypes.func,
    onClick: PropTypes.func,
    trackingEventName: PropTypes.string,
  }

  static defaultProps = {
    onClick() {},
  }

  handleClick = () => {
    let params = { ...this.props.clickParams }
    if (this.props.customClickHandler) {
      this.props.customClickHandler(this.props.button)
    } else {
      if (this.props.trackingEventName) {
        TrackingSegment.trackEvent(this.props.trackingEventName, this.props.button.metadata)
      }
      if (this.props.onBeforeClick) {
        params = this.props.onBeforeClick(this.props.button)
      }
      this.props.dispatch(apiAction.handleAction(this.props.button, consts.apiActionTypes.onClick, undefined, params))
      this.props.onClick()
    }
  }

  render() {
    const { button, customStyle, disabled } = this.props

    return (
      <Button
        type="button"
        color={Button.colors[button.color]}
        onClick={this.handleClick}
        customStyle={classNames(customStyle, {
          [styles.disabled]: this.props.disabled,
        })}
        value={button.title_formatter ? formatterHelper.styledFormatter(button.title_formatter) : button.title}
        outerTitle={button.outerTitle}
        disabled={disabled}
      />
    )
  }
}
