import {
  UPDATE_PASSWORD_PENDING,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_FAILED,
  RESET_PASSWORD_PENDING,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILED,
  CHECK_PASSWORD_PENDING,
  CHECK_PASSWORD_SUCCESS,
  CHECK_PASSWORD_FAILED,
  RESET_PASSWORD_FORM,
} from "consts/actions"

import stateHelper from "helpers/state"

const initialState = stateHelper.pending(false)

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case RESET_PASSWORD_PENDING:
    case UPDATE_PASSWORD_PENDING:
    case CHECK_PASSWORD_PENDING:
      return stateHelper.pending()

    case RESET_PASSWORD_SUCCESS:
    case UPDATE_PASSWORD_SUCCESS:
    case CHECK_PASSWORD_SUCCESS:
      return { success: true }

    case RESET_PASSWORD_FAILED:
    case UPDATE_PASSWORD_FAILED:
    case CHECK_PASSWORD_FAILED:
      return { error: action.payload }

    case RESET_PASSWORD_FORM:
      return initialState

    default:
      return state
  }
}
