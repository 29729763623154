import { GET_STRAPI_LANDING_CONFIG_PENDING, GET_STRAPI_LANDING_CONFIG_SUCCESS, GET_STRAPI_LANDING_CONFIG_FAILED } from "consts/actions"

import stateHelper from "helpers/state"

const initialState = {}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_STRAPI_LANDING_CONFIG_PENDING:
      return stateHelper.pending()

    case GET_STRAPI_LANDING_CONFIG_SUCCESS:
      return action.payload

    case GET_STRAPI_LANDING_CONFIG_FAILED:
      return { error: action.payload }

    default:
      return state
  }
}
