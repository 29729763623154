import { Component } from "react"

import { selectReferencesModel } from "selectors/references"

import FormatterHelper from "helpers/formatter"

import consts from "consts"

export default class PageSubCategory extends Component {
  static getMeta(state, renderProps) {
    const categoryId = renderProps.match.params.categoryId.split("-")[0]
    const references = selectReferencesModel(state)
    const category = references.getCategory(categoryId)

    return {
      canonical: FormatterHelper.basic(consts.routes.category, {
        locale: state.locale.locale,
        categoryId,
        categorySlug: category.slug_seo,
      }),
    }
  }

  render() {
    return null
  }
}
