import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import classNames from "classnames"

import apiAction from "helpers/apiAction"

import TrackingSegment from "managers/segmentManager"

import MolotovPay from "./MolotovPay"
import Dsp from "./Dsp"

import { gotBuyActionError, productPaymentInstanciated } from "actions/mtvPay"

import i18n from "consts/i18n"

import styles from "./index.css"

@connect(state => ({
  buyActionError: state.mtvPay.payment.buyActionError,
  isDesktop: state.appSettings.device === "desktop",
}))
export default class ProductPayment extends Component {
  static propTypes = {
    buyActionError: PropTypes.string,
    callPostSubscriptionActions: PropTypes.func,
    customStyle: PropTypes.string,
    dispatch: PropTypes.func.isRequired,
    footer: PropTypes.object,
    isFramelessMode: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    paymentTracker: PropTypes.func.isRequired,
    product: PropTypes.object.isRequired,
    isReSubscribing: PropTypes.bool,
    isDesktop: PropTypes.bool,
    location: PropTypes.object,
    equivalenceCode: PropTypes.string,
    fromOptionPage: PropTypes.bool,
  }

  onCancel = () => {
    this.props.onCancel()
  }

  executeBuyActions = () => {
    this.props.product.buy_action &&
      this.props.product.buy_action.forEach(action => {
        this.props.dispatch(
          apiAction.executeAction(action, gotBuyActionError, {
            errorMessage: i18n.payment.errorBuyActionDefault,
            isDsp: this.props.product.is_dsp,
          })
        )
      })
  }

  componentDidMount() {
    this.props.dispatch(productPaymentInstanciated(this.props.product.equivalence_code))
    if (window.location.href.indexOf("3dsecure=") === -1) {
      this.executeBuyActions()
      TrackingSegment.trackPage("checkout", {
        equivalence_code: this.props.equivalenceCode,
        origin_page: location.search.includes("from=options") ? "funnel_plans" : undefined,
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.product.id !== this.props.product.id) {
      this.executeBuyActions()
    }
  }

  render() {
    const {
      buyActionError,
      callPostSubscriptionActions,
      customStyle,
      footer,
      isFramelessMode,
      onCancel,
      paymentTracker,
      product,
      isReSubscribing,
      isDesktop,
      equivalenceCode,
      fromOptionPage,
    } = this.props

    return (
      <div className={classNames(customStyle, { [styles.dspProductMain]: product.is_dsp, [styles.mainFrameless]: isFramelessMode })}>
        {product.is_dsp ? (
          <Dsp
            equivalenceCode={product.equivalence_code}
            footer={footer}
            isFramelessMode={isFramelessMode}
            onCancel={onCancel}
            onSuccess={callPostSubscriptionActions}
            paymentTracker={paymentTracker}
            dsp={product.platform}
          />
        ) : buyActionError ? (
          <div>{buyActionError}</div>
        ) : (
          <MolotovPay
            equivalenceCode={equivalenceCode}
            footer={footer}
            isFramelessMode={isFramelessMode}
            onCancel={onCancel}
            onSuccess={callPostSubscriptionActions}
            paymentTracker={paymentTracker}
            currentProduct={product}
            isReSubscribing={isReSubscribing}
            isDesktop={isDesktop}
            fromOptionPage={fromOptionPage}
          />
        )}
      </div>
    )
  }
}
