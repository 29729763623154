import { GET_OFFER_PERIODICITY_SUCCESS, SELECTED_PERIODICITY } from "consts/actions"

const initialState = {}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_OFFER_PERIODICITY_SUCCESS:
      return {
        ...state,
        ...action.payload,
      }

    case SELECTED_PERIODICITY:
      return {
        ...state,
        selected: true,
      }

    default:
      return state
  }
}
