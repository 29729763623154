import React from "react"
import PropTypes from "prop-types"

const IconTwitter = ({ customStyle = null }) => (
  <svg viewBox="0 0 16 14" className={customStyle}>
    <path
      d="M16 1.578c-.588.268-1.22.45-1.885.53.678-.416 1.198-1.076 1.444-1.86-.636.384-1.338.664-2.087.814C12.876.408 12.023 0 11.077 0c-1.812 0-3.28 1.507-3.28 3.366 0 .264.027.52.083.766-2.728-.14-5.146-1.48-6.766-3.518C.83 1.114.67 1.692.67 2.308c0 1.168.58 2.198 1.46 2.802-.537-.016-1.044-.17-1.488-.42v.04c0 1.633 1.132 2.994 2.635 3.302-.276.08-.566.118-.865.118-.212 0-.418-.02-.618-.06.418 1.337 1.63 2.31 3.066 2.337-1.123.903-2.54 1.442-4.077 1.442-.265 0-.527-.017-.783-.048 1.453.954 3.18 1.51 5.032 1.51 6.038 0 9.34-5.127 9.34-9.575 0-.147-.003-.293-.01-.436.643-.474 1.2-1.066 1.638-1.742"
      fillRule="evenodd"
    />
  </svg>
)

IconTwitter.propTypes = {
  customStyle: PropTypes.string,
}

export default IconTwitter
