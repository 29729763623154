import {
  FETCH_PROMO_CODE_PAGE_SUCCESS,
  FETCH_PROMO_CODE_PAGE_ERROR,
  VALIDATE_PROMO_CODE_SUCCESS,
  RESET_PROMO_CODE,
  SET_PRODUCT,
  GET_MTVPAY_PAYMENT_FORM_SUCCESS,
  GET_MTVPAY_PASSWORD_FORM_SUCCESS,
} from "consts/actions"

const initialState = {
  page: null,
  product: null,
  validation: null,
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_PROMO_CODE_PAGE_SUCCESS:
      return {
        ...initialState,
        page: action.payload.data,
      }

    case FETCH_PROMO_CODE_PAGE_ERROR:
      return {
        ...state,
        page: {
          error: true,
        },
      }

    case VALIDATE_PROMO_CODE_SUCCESS:
      return {
        ...state,
        product: null,
        validation: action.payload,
      }

    case SET_PRODUCT:
      return {
        ...state,
        product: action.payload,
      }

    case GET_MTVPAY_PAYMENT_FORM_SUCCESS:
    case GET_MTVPAY_PASSWORD_FORM_SUCCESS:
      return {
        ...state,
        validation: {
          ...state.validation,
          page: action.payload.page || {},
        },
      }

    case RESET_PROMO_CODE:
      return initialState

    default:
      return state
  }
}
