import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import styles from "./../index.css"

const IconArrowRight = ({ customStyle = null }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={classNames(customStyle, styles.arrow)}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.293 17.293a1 1 0 1 0 1.414 1.414l6-6a1 1 0 0 0 0-1.414l-6-6a1 1 0 0 0-1.414 1.414L14.586 12l-5.293 5.293z"
      fill="#2E2F33"
    />
  </svg>
)

IconArrowRight.propTypes = {
  customStyle: PropTypes.string,
}

export default IconArrowRight
