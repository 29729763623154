import i18n from "consts/i18n"

export function updateMeta(meta = {}) {
  if (meta.title) {
    document.title = meta.title
  }

  if (meta.description) {
    document.querySelector("meta[name=description]").content = meta.description
  }

  if (meta.og) {
    // Very ugly... but no other idea to be secure
    if (document.querySelector("meta[property='og:type']")) {
      document.querySelector("meta[property='og:type']").content = meta.og.type
    }
    if (document.querySelector("meta[property='og:url']")) {
      document.querySelector("meta[property='og:url']").content = meta.og.url
    }
    if (document.querySelector("meta[property='og:image']")) {
      document.querySelector("meta[property='og:image']").content = meta.og.image
    }
    if (document.querySelector("meta[property='og:title']")) {
      document.querySelector("meta[property='og:title']").content = meta.og.title
    }
    if (document.querySelector("meta[property='og:image:alt']")) {
      document.querySelector("meta[property='og:image:alt']").content = meta.og.imageAlt
    }
    if (document.querySelector("meta[property='og:description']")) {
      document.querySelector("meta[property='og:description']").content = meta.og.description || meta.description
    }
    if (document.querySelector("meta[property='og:image:width']")) {
      document.querySelector("meta[property='og:image:width']").content = meta.og.imageWidth
    }
    if (document.querySelector("meta[property='og:image:height']")) {
      document.querySelector("meta[property='og:image:height']").content = meta.og.imageHeight
    }
  }

  if (meta.robots && document.querySelector("meta[name='robots']")) {
    document.querySelector("meta[name='robots']").content = meta.robots
  }
}

export function restoreDefaultMeta() {
  document.title = i18n.pageTitle.public
  document.querySelector("meta[name=description]").content = i18n.pageTitle.description

  if (document.querySelector("meta[name='robots']")) {
    document.querySelector("meta[name='robots']").content = ""
  }
}
