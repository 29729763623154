import { GET_EPISODE_PENDING, GET_EPISODE_SUCCESS, GET_EPISODE_FAILED } from "consts/actions"

import stateHelper from "helpers/state"

const initialState = {}

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_EPISODE_PENDING:
      return stateHelper.pending()

    case GET_EPISODE_SUCCESS:
      return action.payload

    case GET_EPISODE_FAILED:
      return { error: action.payload }

    default:
      return state
  }
}
