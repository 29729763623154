import {
  GET_REFERENCES_PENDING,
  GET_REFERENCES_SUCCESS,
  GET_REFERENCES_FAILED,
  GET_APP_REFERENCES_PENDING,
  GET_APP_REFERENCES_SUCCESS,
  GET_APP_REFERENCES_FAILED,
} from "consts/actions"

import stateHelper from "helpers/state"
import referenceCleaner from "helpers/referenceCleaner"

import consts from "consts"

export function getReferences() {
  return (dispatch, getState) => {
    const references = getState().references

    if (Object.keys(references).length && !references.pending && !references.error) {
      return
    }

    return dispatch({
      accessTokenRequired: false,
      request: {
        url: consts.endpoints.references,
        method: "GET",
      },
      cache: 15 * 60 * 1000,
      processResponse(response) {
        return referenceCleaner(response, getState())
      },
      onStart: (payload, meta, dispatch) => {
        dispatch({
          type: GET_REFERENCES_PENDING,
        })
      },
      onSuccess: (payload, meta, dispatch) => {
        if (payload.legal) {
          Object.keys(payload.legal).forEach(key => {
            payload.legal[key].html_text = payload.legal[key].html_text.replace(/<u data-href=/g, "<a href=").replace(/<\/u>/g, "</a>")
          })
        }

        return dispatch({
          type: GET_REFERENCES_SUCCESS,
          payload,
        })
      },
      onError: (payload, meta, dispatch) => {
        return dispatch({
          type: GET_REFERENCES_FAILED,
          payload: stateHelper.errorPayload(payload.user_message),
        })
      },
    })
  }
}

export function getAppReferences() {
  return (dispatch, getState) => {
    const appReferences = getState().appReferences

    if (Object.keys(appReferences).length && !appReferences.pending && !appReferences.error) {
      return
    }

    return dispatch({
      accessTokenRequired: false,
      request: {
        url: consts.endpoints.config,
        method: "GET",
      },
      cache: 15 * 60 * 1000,
      onStart: (payload, meta, dispatch) => {
        dispatch({
          type: GET_APP_REFERENCES_PENDING,
        })
      },
      onSuccess: (payload, meta, dispatch) => {
        return dispatch({
          type: GET_APP_REFERENCES_SUCCESS,
          payload,
        })
      },
      onError: (payload, meta, dispatch) => {
        return dispatch({
          type: GET_APP_REFERENCES_FAILED,
          payload: stateHelper.errorPayload(payload.user_message),
        })
      },
    })
  }
}
