import React from "react"
import PropTypes from "prop-types"

const SecurityCode = ({ rootStyle }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="160" height="106" fill="none" viewBox="0 0 160 106" className={rootStyle}>
    <g filter="url(#filter0_d)">
      <rect width="144" height="90" x="8" fill="url(#paint0_linear)" rx="8" />
      <path fill="#fff" d="M20 39H140V63H20z" />
      <path
        fill="#000"
        d="M113.348 47h-1.59l-1.98 1.254v1.5l1.832-1.148h.047V55h1.691v-8zm1.902 8h5.703v-1.383h-3.36v-.054l1.168-1.145c1.645-1.5 2.086-2.25 2.086-3.16 0-1.387-1.133-2.367-2.847-2.367-1.68 0-2.833 1.004-2.829 2.574h1.606c-.004-.766.48-1.235 1.211-1.235.703 0 1.226.438 1.226 1.141 0 .637-.39 1.074-1.117 1.773l-2.847 2.637V55zm9.885.11c1.797 0 3.082-.989 3.078-2.356.004-.996-.629-1.707-1.804-1.856v-.062c.894-.16 1.511-.793 1.508-1.691.003-1.262-1.102-2.254-2.758-2.254-1.641 0-2.875.96-2.899 2.343h1.621c.02-.609.578-1.004 1.27-1.004.683 0 1.141.415 1.137 1.016.004.629-.532 1.055-1.301 1.055h-.746v1.242h.746c.906 0 1.472.453 1.469 1.098.003.636-.543 1.074-1.317 1.074-.746 0-1.305-.39-1.336-.977H122.1c.027 1.399 1.277 2.371 3.035 2.371z"
      />
      <path fill="#000" fillOpacity=".5" d="M8 12H152V28H8z" />
      <rect width="30" height="18" x="104" y="42" stroke="#F93B25" strokeWidth="2" rx="7" />
    </g>
    <defs>
      <linearGradient id="paint0_linear" x1="182" x2="89.848" y1="90" y2="-43.931" gradientUnits="userSpaceOnUse">
        <stop stopColor="#536C82" />
        <stop offset="1" stopColor="#41608D" />
      </linearGradient>
      <filter id="filter0_d" width="160" height="106" x="0" y="0" colorInterpolationFilters="sRGB" filterUnits="userSpaceOnUse">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset dy="8" />
        <feGaussianBlur stdDeviation="4" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
    </defs>
  </svg>
)

SecurityCode.propTypes = {
  rootStyle: PropTypes.string,
}

export default SecurityCode
