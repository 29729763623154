import { SET_APP_SETTINGS } from "consts/actions"
import consts from "consts"

const initialState = {
  device: "",
  platform: consts.platform.web,
  frameless: false,
  frameType: consts.frameType.basic,
  molotovAgent: null,
  set: false,
}

export default function reducer(state = initialState, { type, settings }) {
  switch (type) {
    case SET_APP_SETTINGS:
      if (settings && settings.molotovAgent) {
        try {
          settings.molotovAgent = JSON.parse(settings.molotovAgent)
        } catch (e) {
          settings.molotovAgent = null
        }
      }
      return {
        ...state,
        ...settings,
        set: true,
      }

    default:
      return state
  }
}
