import { v4 as uuidv4 } from "uuid"

import cookiesHelper from "helpers/cookies"
import urlHelper from "helpers/url"
import consts from "consts"

const handleRegisterTracking = {
  check: queryParamsObject => {
    if (!handleRegisterTracking.checkExistingRegisterTrackingCookie()) {
      handleRegisterTracking.handleRegisterTrackingCookie(queryParamsObject)
    }
  },

  checkExistingRegisterTrackingCookie: () => {
    const existingCookie = cookiesHelper.getCookieValue(consts.cookieNames.userTracking)
    if (existingCookie) {
      if (JSON.parse(existingCookie).uuid !== sessionStorage.getItem(consts.sessionStorageKey.registerFollowUpId)) {
        // Cookie from previous session
        cookiesHelper.deleteCookie({ name: consts.cookieNames.userTracking, domain: urlHelper.getCurrentDomainWithExtension() }) // Delete cookie
        return false
      }
      // Cookie already here, from same session
      return true
    }
    // No cookie
    return false
  },

  handleRegisterTrackingCookie: queryParamsObject => {
    let trackingInfos = {}

    if (document.referrer !== "") {
      trackingInfos.trkRef = urlHelper.getDomainOnly(document.referrer)
    }
    if (Object.keys(queryParamsObject).length) {
      if (queryParamsObject?.utm_campaign) {
        trackingInfos.trkCn = queryParamsObject.utm_campaign
      }
      if (queryParamsObject?.utm_medium) {
        trackingInfos.trkMdm = queryParamsObject.utm_medium
      }
    }

    if (Object.keys(trackingInfos).length) {
      const uuid = uuidv4()
      cookiesHelper.setCookie({
        name: consts.cookieNames.userTracking,
        value: JSON.stringify({ ...trackingInfos, uuid }),
        domain: urlHelper.getCurrentDomainWithExtension(),
      })
      sessionStorage.setItem(consts.sessionStorageKey.registerFollowUpId, uuid)
    }
  },
}

export default handleRegisterTracking
