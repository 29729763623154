import { get } from "lodash"
import { ADD_FEEDBACK, API_ACTION_ERROR, DELETE_FEEDBACK } from "consts/actions"

const initialState = {
  idGenerator: 0,
  list: [],
}

function addFeedback(state, properties) {
  if (!properties.label) {
    /* eslint-disable */
    console.error("[FEEDBACK] Can't add a feedback without label")
    /* eslint-enable */
    return state
  }

  const newFeedback = {
    autoCloseTimer: null,
    ...properties,
    _id: state.idGenerator,
  }

  return {
    idGenerator: state.idGenerator + 1,
    list: [...state.list, newFeedback],
  }
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ADD_FEEDBACK:
      return addFeedback(state, {
        label: action.payload.label,
        isError: action.payload.isError,
      })

    case DELETE_FEEDBACK:
      return {
        ...state,
        list: state.list.filter(feedback => feedback._id !== action.payload.feedbackId),
      }

    case API_ACTION_ERROR:
      if (get(action, "payload.params.__context") === "parental_control_settings") {
        const errorMessage = get(action, "payload.response.error.user_message")
        if (errorMessage) {
          return addFeedback(state, {
            label: errorMessage,
            isError: true,
          })
        }
      }
      if (get(action, "payload.params.__context") === "winback") {
        return addFeedback(state, {
          label: "Un problème est survenu, Merci de réessayer",
          isError: true,
        })
      }
      return state

    default:
      return state
  }
}
