import React, { Component } from "react"
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { get } from "lodash"

import styles from "./index.css"

import consts from "consts"
import routes from "consts/routes"

import Button from "components/Button"
import ContentHeader from "components/ContentHeader"
import ContentBox from "components/ContentBox"

import { set404Error } from "actions/ui"

import { getAssetURL } from "helpers/static"

const notFoundImage = getAssetURL("image.pageErrors.notFound")

@connect(state => {
  return {
    appSettings: state.appSettings,
  }
})
export default class PageNotFound extends Component {
  static propTypes = {
    appSettings: PropTypes.object.isRequired,
    dispatch: PropTypes.func,
    location: PropTypes.object,
  }

  static getHttpStatus = () => ({
    code: 404,
  })

  componentDidMount() {
    this.props.dispatch(set404Error())
  }

  render() {
    const frameless = !!get(this.props.location, "query.frameless", false) || this.props.appSettings.frameless

    return (
      <div className={styles.root}>
        <ContentHeader title={<span>Oups!</span>} image={{ src: notFoundImage }} />
        <div className={ContentHeader.above}>
          <ContentBox>
            <h3 className={ContentBox.hasTextCentered}>
              <span className={styles.header}>
                La page que vous recherchez semble introuvable. <br />
              </span>
              {// test if not inFrame
              !frameless && <span className={styles.header}>Rendez-vous sur la page d&#39;accueil ou consultez notre Aide en ligne.</span>}
            </h3>
            {// test if not inFrame
            !frameless && (
              <section className={styles.buttonsContainer}>
                <Link to={routes.default}>
                  <Button customStyle={styles.button} color={Button.colors.black} value="Retour à l'accueil" />
                </Link>
                <a href={consts.externalLink.support}>
                  <Button customStyle={styles.button} color={Button.colors.black} value="Aide en ligne" />
                </a>
              </section>
            )}
          </ContentBox>
        </div>
      </div>
    )
  }
}
