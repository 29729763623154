import consts from "consts"
import formatterHelper from "helpers/formatter"
import { executeApiActions } from "actions/api"

export function getPaypalTransactionToken(userID, onSuccess) {
  return dispatch => {
    return dispatch({
      accessTokenRequired: true,
      request: {
        base: consts.apiPath,
        url: consts.endpoints.paypal.getToken,
        method: "GET",
      },
      onSuccess,
    })
  }
}

export function postPaypalSuccess({ nonce, rateplan }, onErrorFallback, onSuccess) {
  return dispatch => {
    return dispatch({
      accessTokenRequired: true,
      request: {
        base: consts.apiPath,
        url: formatterHelper.basic(consts.endpoints.paypal.postSuccess, { rateplan }),
        method: "POST",
        body: {
          payment_method_nonce: nonce,
        },
      },
      onSuccess: payload => {
        dispatch(executeApiActions(payload.execute_actions))
        onSuccess && onSuccess()
      },
      onError: payload => {
        if (payload?.error?.execute_actions) {
          dispatch(executeApiActions(payload.error.execute_actions))
        } else {
          onErrorFallback()
        }
      },
    })
  }
}
