import {
  UPDATE_EMAIL_PENDING,
  UPDATE_EMAIL_SUCCESS,
  UPDATE_EMAIL_FAILED,
  UPDATE_EMAIL_RESET,
  RESET_EMAIL_PENDING,
  RESET_EMAIL_SUCCESS,
  RESET_EMAIL_ERROR,
  CLEAR_EMAIL_FORM,
} from "consts/actions"

import stateHelper from "helpers/state"

const initialState = {}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_EMAIL_PENDING:
      return stateHelper.pending()

    case UPDATE_EMAIL_SUCCESS:
    case UPDATE_EMAIL_RESET:
      return { success: true }

    case UPDATE_EMAIL_FAILED:
      return { error: action.payload }

    case RESET_EMAIL_PENDING:
      return stateHelper.pending()

    case RESET_EMAIL_SUCCESS:
      return { success: true }

    case RESET_EMAIL_ERROR:
      return { error: action.payload }

    case CLEAR_EMAIL_FORM:
      return {}

    default:
      return state
  }
}
