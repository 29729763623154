import { get } from "lodash"

import { SET_SESSION, OCS_LOGIN_SUCCESS, OCS_LOGIN_PENDING, OCS_LOGIN_FAILURE } from "consts/actions"

import stateHelper from "helpers/state"

import consts from "consts"

const initialState = {
  ...stateHelper.pending(false),
  data: null,
  choseProfile: false,
  redirectionURL: null,
  error: null,
  noOCSSubscription: false,
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_SESSION:
      return {
        ...initialState,
        ...stateHelper.pending(state.pending),
        choseProfile: true,
      }

    case OCS_LOGIN_SUCCESS:
      return {
        ...state,
        ...stateHelper.pending(false),
        redirectionURL: action.payload.redirect_uri || null,
      }

    case OCS_LOGIN_PENDING:
      return {
        ...state,
        ...stateHelper.pending(),
        error: null,
        choseProfile: true,
      }

    case OCS_LOGIN_FAILURE:
      let noOCSSubscription = false
      if (get(action, "payload.code") === consts.api.errorCodes.OCS_NO_SUB) {
        noOCSSubscription = true
      }

      return {
        ...state,
        ...stateHelper.pending(false),
        noOCSSubscription,
        error: action.payload,
      }

    default:
      return state
  }
}
