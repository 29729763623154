import { createSelector } from "reselect"

const selectNavigatorKey = state => state.navigator

const selectNavigator = createSelector([selectNavigatorKey], ({ userAgent, vendor, platform, maxTouchPoints }) => {
  const matchers = {
    isMac: userAgent.indexOf("Windows") === -1 && platform.indexOf("MacIntel") > -1,
    isWindows: userAgent.indexOf("Windows") > -1,
    isChrome: userAgent.indexOf("Chrome") > -1 && vendor.indexOf("Google") > -1,
    isSafari: userAgent.indexOf("Safari") > -1 && vendor.indexOf("Apple") > -1,
    isWindows64: userAgent.indexOf("WOW64") > -1 || userAgent.indexOf("Win64") > -1,
    isAndroid: userAgent.indexOf("Android") > -1,
    isIpad: userAgent.indexOf("iPad") > -1 || (userAgent.match(/Mac/) && maxTouchPoints && maxTouchPoints > 2),
    isIphone: userAgent.indexOf("iPhone") > -1,
    isLinux: userAgent.indexOf("Linux") > -1 && platform.indexOf("Linux") > -1,
  }

  matchers.isIos = matchers.isIpad || matchers.isIphone
  matchers.isMobile = matchers.isAndroid || matchers.isIpad || matchers.isIphone

  // 10_9
  const osxVersionMatches = userAgent.match(/Mac OS X (10[_\.]\d{1,2})([_\.]\d{1,2})?/)

  if (osxVersionMatches && osxVersionMatches[1]) {
    matchers.osxVersion = osxVersionMatches[1].replace(".", "_")
  }

  return matchers
})

export default selectNavigator

export const selectIsMobile = createSelector([selectNavigator], ({ isMobile }) => isMobile)
