import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import formatterHelper from "helpers/formatter"

import Icon, { IconTypes } from "components/Icon"

import styles from "./index.css"
import consts from "consts"

const ProductDesktopHeader = ({ periodicity, isConfirmationStep }) => {
  const getText = type => {
    if (periodicity) {
      return type === "title"
        ? formatterHelper.styled(periodicity.page_title_formatter.format, periodicity.page_title_formatter.parts)
        : formatterHelper.styled(periodicity.page_subtitle_formatter.format, periodicity.page_subtitle_formatter.parts)
    }
    if (isConfirmationStep) {
      return type === "title" ? consts.i18n.productDesktopHeader.step2.title : consts.i18n.productDesktopHeader.step2.subtitle
    }
    return type === "title" ? consts.i18n.productDesktopHeader.step1.title : consts.i18n.productDesktopHeader.step1.subtitle
  }

  return (
    <div
      className={classNames(styles.iframeHeader, {
        [styles.iframeSuccessHeader]: isConfirmationStep,
      })}
    >
      <Icon
        type={isConfirmationStep ? IconTypes.checkmark : IconTypes.molotovLogo}
        rootStyle={styles.iframeHeaderLogo}
        iconStyle={isConfirmationStep ? styles.iframeHeaderCheckLogo : ""}
      />
      <div
        className={classNames(styles.iframeHeaderText, {
          [styles.confirmHeader]: isConfirmationStep,
        })}
      >
        <div>{getText("title")}</div>
        <div>{getText("subtitle")}</div>
      </div>
    </div>
  )
}

ProductDesktopHeader.propTypes = {
  isConfirmationStep: PropTypes.bool,
  periodicity: PropTypes.object,
}

export default ProductDesktopHeader
