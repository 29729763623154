import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import styles from "./../index.css"

const Csa10 = ({ customStyle = null }) => (
  <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className={classNames(customStyle, styles.csa10)}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.05 13H5.5v-2H2.05C2.55 5.947 6.814 2 12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10c-5.185 0-9.449-3.947-9.95-9zm5.45 5H10V6H6.5v2h1v10zm4.25-6c0 4.16 1.121 6.25 3.25 6.25 2.13 0 3.25-2.09 3.25-6.25 0-4.176-1.103-6.25-3.25-6.25-2.146 0-3.25 2.074-3.25 6.25zM15 7.5c.951 0 1 1.455 1 4.557 0 3.044-.049 4.443-1 4.443-.954 0-1-1.436-1-4.5 0-3.102.046-4.5 1-4.5z"
      fill="#fff"
    />
  </svg>
)

Csa10.propTypes = {
  customStyle: PropTypes.string,
}

export default Csa10
