import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

const ExternalLink = ({ customStyle = null }) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" className={classNames(customStyle)}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 1C9 1.55228 8.55229 2 8 2H2V16H16V10C16 9.44771 16.4477 9 17 9C17.5523 9 18 9.44771 18 10V16C18 17.1046 17.1046 18 16 18H2C0.89543 18 0 17.1046 0 16V2C0 0.89543 0.89543 0 2 0H8C8.55229 0 9 0.447715 9 1ZM17 0C17.5107 0 17.9352 0.38801 17.9932 0.883801L18 1V6C18 6.55 17.55 7 17 7C16.4893 7 16.0648 6.61199 16.0068 6.1162L16 6V3.41L6.87 12.54C6.48 12.93 5.85 12.93 5.46 12.54C5.1 12.18 5.07231 11.6155 5.37692 11.224L5.46 11.13L14.59 2H12C11.45 2 11 1.55 11 1C11 0.489286 11.388 0.0647959 11.8838 0.0067602L12 0H17Z"
      fill="black"
    />
  </svg>
)

ExternalLink.propTypes = {
  customStyle: PropTypes.string,
}

export default ExternalLink
