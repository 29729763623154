import routes from "consts/routes"
import i18n from "consts/i18n"

const pageTitle = {
  [routes.profile]: i18n.nav.profile,
  [routes.offers]: i18n.nav.products,
  [routes.payment]: i18n.nav.payment,
  [routes.parentalControl]: i18n.nav.parentalControl,
  [routes.storage]: i18n.nav.storage,
  [routes.devices]: i18n.nav.devices,
  [routes.tv]: i18n.nav.tv,
}

const iframeMessageMiddleware = () => next => action => {
  if (global.parent && global.parent.postMessage && action.type === "@@router/LOCATION_CHANGE" && pageTitle[action.payload.pathname]) {
    parent.postMessage(
      {
        mtvWebviewTitle: pageTitle[action.payload.pathname],
      },
      "*"
    )
  }

  return next(action)
}

export default iframeMessageMiddleware
