import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import Icon from "components/Icon"

import styleClasses from "./index.css"

const NotificationColors = {
  green: "green",
  red: "red",
  purple: "purple",
}

class NotificationView extends React.Component {
  static propTypes = {
    animation: PropTypes.bool,
    autoCloseTimer: PropTypes.number,
    color: PropTypes.oneOf(Object.values(NotificationColors)),
    iconType: PropTypes.oneOf([PropTypes.string, PropTypes.object]),
    id: PropTypes.number,
    onClick: PropTypes.func,
    onClose: PropTypes.func,
    rootClass: PropTypes.string,
    text: PropTypes.string,
    title: PropTypes.string.isRequired,
    style: PropTypes.string,
    closeBtn: PropTypes.bool,
  }

  static defaultProps = {
    color: NotificationColors.green,
    closeBtn: true,
  }

  state = {
    animation_enter: this.props.animation && true,
    animation_exit: this.props.animation && false,
  }

  onCloseButton = e => {
    e.stopPropagation()
    this.handleClose()
  }

  checkAnimation() {
    if (this.props.animation) {
      this.setState({
        animation_enter: false,
      })
    }
  }

  notifRef = React.createRef()

  handleClose = () => {
    if (this.props.animation) {
      setTimeout(() => this.props.onClose(this.props.id), 300) // 300ms is the transition duration of the .root element
      this.setState({ animation_exit: true })
    } else {
      this.props.onClose(this.props.id)
    }
  }

  startAutoclose() {
    if (this.props.onClose && this.props.autoCloseTimer) {
      this.autoCloseTimeout = setTimeout(() => {
        this.handleClose()
      }, this.props.autoCloseTimer * 1000)
    }
  }

  stopAutoclose() {
    this.autoCloseTimeout && clearTimeout(this.autoCloseTimeout)
  }

  mouseEnter = () => {
    this.stopAutoclose()
  }

  mouseLeave = () => {
    this.startAutoclose()
  }

  componentDidMount() {
    // Wait first paiting
    this.animationTimeout = setTimeout(() => {
      this.animationRequestId = requestAnimationFrame(() => {
        this.checkAnimation()
      })
    })

    this.startAutoclose()
  }

  componentWillUnmount() {
    clearTimeout(this.animationTimeout)
    cancelAnimationFrame(this.animationRequestId)
  }

  render() {
    const finalRootStyle = classNames(styleClasses.root, {
      [this.props.rootClass]: this.props.rootClass,
      [styleClasses.green]: this.props.color === NotificationColors.green,
      [styleClasses.red]: this.props.color === NotificationColors.red,
      [styleClasses.purple]: this.props.color === NotificationColors.purple,
      [styleClasses.clickable]: this.props.onClick,
      [styleClasses.animation_initial]: this.state.animation_enter,
      [styleClasses.animation_end]: this.state.animation_exit,
      [styleClasses.newNotif]: this.props.style === "newNotif",
    })

    return (
      <div
        data-test="notification"
        className={finalRootStyle}
        onClick={this.props.onClick}
        onMouseEnter={this.mouseEnter}
        onMouseLeave={this.mouseLeave}
        ref={this.notifRef}
      >
        <div className={styleClasses.content}>
          {this.props.iconType && <Icon iconStyle={styleClasses.icon} type={this.props.iconType} />}
          <div>
            <div className={styleClasses.title}>{this.props.title}</div>
            {this.props.text && <div className={styleClasses.subtitle}>{this.props.text}</div>}
          </div>
        </div>
        {this.props.onClose && this.props.closeBtn && <Icon type="close" onClick={this.onCloseButton} rootStyle={styleClasses.close} />}
      </div>
    )
  }
}

export default NotificationView
export { NotificationColors }
