import React, { Component } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import Icon, { IconTypes } from "components/Icon"
import Button from "components/Button"

import styles from "./index.css"
import consts from "consts"

export default class ButtonGoogleLogin extends Component {
  static propTypes = {
    rootStyle: PropTypes.string,
    onGoogleResponse: PropTypes.func.isRequired,
  }

  onClick = () => {
    window.auth2.grantOfflineAccess().then(data => {
      this.props.onGoogleResponse({
        ...data,
        googleId: consts.config.googleId,
      })
    })
  }

  componentDidMount() {
    if (consts.config.googleId && window.gapi) {
      window.gapi.load("auth2", function() {
        window.auth2 = window.gapi.auth2.init({
          client_id: consts.config.googleId,
          scope: "email",
        })
      })
    }
  }

  render() {
    const { rootStyle } = this.props

    return (
      <Button
        color={Button.colors.white}
        customStyle={classNames(styles.root, rootStyle)}
        value={
          <div>
            <Icon type={IconTypes.google} rootStyle={styles.icon} />
            avec Google
          </div>
        }
        onClick={this.onClick}
      />
    )
  }
}
