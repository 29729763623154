import { get } from "lodash"
import { GET_PRODUCT_CONSTRAINTS_PENDING, GET_PRODUCT_CONSTRAINTS_SUCCESS, GET_PRODUCT_CONSTRAINTS_FAILED } from "consts/actions"

import stateHelper from "helpers/state"

import consts from "consts"

export function getBookmarkConstraintsForProduct(productId) {
  return {
    accessTokenRequired: true,
    request: {
      url: consts.endpoints.loseBookmarksOnUnsub.replace(/:productId/, productId),
      method: "GET",
    },
    onStart: (payload, meta, dispatch) => {
      dispatch({
        type: GET_PRODUCT_CONSTRAINTS_PENDING,
        productId,
      })
    },
    onSuccess: (payload, meta, dispatch) => {
      return dispatch({
        type: GET_PRODUCT_CONSTRAINTS_SUCCESS,
        payload: { data: payload },
        productId,
      })
    },
    onError: (payload, meta, dispatch) => {
      return dispatch({
        type: GET_PRODUCT_CONSTRAINTS_FAILED,
        payload: stateHelper.errorPayload(get(payload, "error", "Unknown error")),
        productId,
      })
    },
  }
}
