import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { push } from "connected-react-router"

import PageLogin from "containers/PageLogin"
import PageSignup from "containers/PageSignup"
import SignupInformations from "containers/SignupInformations"

import routes from "consts/routes"

import { selectErrorMessages } from "selectors/errorMessages"
import selectAuthentication from "selectors/authentication"
import { selectQuery } from "selectors/location"

import { getReferences } from "actions/references"
import { set as setSignup } from "actions/signup"

import routeHelper from "helpers/route"

@connect((state, ownProps) => ({
  errorMessages: selectErrorMessages(state),
  pendingClientSession: state.session.pendingClientSession,
  authentication: selectAuthentication(state),
  query: selectQuery(ownProps),
  signup: state.signup,
}))
export default class Connection extends Component {
  static propTypes = {
    authentication: PropTypes.object,
    children: PropTypes.node,
    defaultForm: PropTypes.string,
    deviceTargetAgent: PropTypes.object,
    dispatch: PropTypes.func,
    errorMessages: PropTypes.object,
    location: PropTypes.object,
    onLoginDisplayed: PropTypes.func,
    onSignupDisplayed: PropTypes.func,
    originPage: PropTypes.string,
    pendingClientSession: PropTypes.bool,
    showGoogleConnect: PropTypes.bool,
    signup: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    title: PropTypes.node,
    onSignUp: PropTypes.func,
    fromOAuthPage: PropTypes.bool,
    query: PropTypes.object,
    fromEchoShow: PropTypes.bool,
    showOpenEurope: PropTypes.bool,
    isChoseOptionPage: PropTypes.bool,
  }

  onClickConnect = () => {
    this.props.dispatch(setSignup(""))
  }

  onClickRegister = () => {
    window.dataLayer &&
      window.dataLayer.push({
        event: "gtm.custom_event",
        event_name: "sign_up_link",
        event_category: "login",
        event_action: "start_create_account",
      })
    this.props.dispatch(setSignup("signup"))
  }

  onSignUp = () => {
    if (this.props.onSignUp) {
      this.props.onSignUp()
    } else {
      // redirect to download page unless the user comes from a subscribe link or oauth page
      if (
        !this.props.query.subscribe &&
        !this.props.location.hash.length &&
        !routeHelper.isLinkPage(this.props.location.pathname) &&
        !routeHelper.isOAuthPage(this.props.location.pathname) &&
        !routeHelper.isPromoCodePage(this.props.location.pathname) &&
        !routeHelper.isGiftPage(this.props.location.pathname) &&
        !routeHelper.isAccountPage(this.props.location.pathname)
      ) {
        this.props.dispatch(push(routes.download + "?newAccount=true"))
      }
    }
  }

  onFormMounted = props => {
    const onMount = props.signup === "signup" ? props.onSignupDisplayed : props.onLoginDisplayed

    if (onMount && props.pendingClientSession === false && !props.authentication.isAuthenticated) {
      onMount()
    }
  }

  renderConnection() {
    const { deviceTargetAgent, signup, errorMessages, originPage, location, fromEchoShow, showOpenEurope, isChoseOptionPage } = this.props

    //fromEchoShow
    const forceEmailSignup = true

    if (signup === "signup") {
      return (
        <PageSignup
          signUpOrigin={originPage}
          deviceTargetAgent={deviceTargetAgent}
          onClickConnect={this.onClickConnect}
          onSignUp={this.onSignUp}
          errorMessages={errorMessages}
          displayEmailSignup={forceEmailSignup}
          location={location}
          fromEchoShow={fromEchoShow}
          showOpenEurope={showOpenEurope}
          isChoseOptionPage={isChoseOptionPage}
        />
      )
    }

    if (signup === "signupInformations") {
      return <SignupInformations errorMessages={errorMessages} />
    }

    return (
      <PageLogin
        loginOrigin={originPage}
        deviceTargetAgent={deviceTargetAgent}
        onClickRegister={this.onClickRegister}
        errorMessages={errorMessages}
        fromOAuthPage={this.props.fromOAuthPage}
        location={location}
        fromEchoShow={fromEchoShow}
        showOpenEurope={showOpenEurope}
      />
    )
  }

  componentDidMount() {
    this.onFormMounted(this.props)

    const { query } = this.props

    let signupState = ""

    if (query.signup === "true" || this.props.defaultForm === "signup") {
      signupState = "signup"
    }

    if (query.signupInformations === "true") {
      signupState = "signupInformations"
    }

    this.props.dispatch(getReferences())
    this.props.dispatch(setSignup(signupState))
  }

  componentDidUpdate(prevProps) {
    if (prevProps.signup !== this.props.signup || prevProps.pendingClientSession !== this.props.pendingClientSession) {
      this.onFormMounted(this.props)
    }
  }

  render() {
    const { children } = this.props

    return (
      <div>
        {this.renderConnection()}
        {children}
      </div>
    )
  }
}
