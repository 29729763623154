import consts from "consts"

export default {
  getTopbarInformations() {
    return {
      logo: "molotovExtraLogo",
      routeLink: consts.externalLink.extra,
      className: "headerLogoIcon",
    }
  },
}
