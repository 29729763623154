import { get } from "lodash"

import { GET_OFFERS_SUCCESS, GET_MULTIPLE_OFFERS, GET_OFFERS_FAILED, UPDATE_PRODUCT_OPTINS } from "consts/actions"

const initialState = {}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_OFFERS_SUCCESS:
      return { data: action.payload }

    case GET_OFFERS_FAILED:
      return { error: get(action, "payload.products.error") }

    case UPDATE_PRODUCT_OPTINS:
      let newState = null
      if (state.data) {
        // state.data.products is an array on /account/group-offers/:equivalenceCode (used only via Android webview through the offers carousel)
        // The "equivalenceCode" param is later used in selector offerFilter to reduce the array to just one product
        if (Array.isArray(state.data.products)) {
          const updatedProducts = state.data.products.map(product => {
            if (product.id === action.payload.productId) {
              return {
                ...product,
                optins: action.payload.optins,
              }
            } else {
              return product
            }
          })

          newState = {
            ...state,
            data: {
              ...state.data,
              products: updatedProducts,
            },
          }
        }
        // state.data.products is an object on /offers/url (used only via Desktop webview through the offers carousel)
        else if (state.data.products) {
          if (state.data.products.id === action.payload.productId) {
            newState = {
              ...state,
              data: {
                ...state.data,
                products: {
                  ...state.data.products,
                  optins: action.payload.optins,
                },
              },
            }
          } else {
            newState = state
          }
        }
      }

      return newState || state

    case GET_MULTIPLE_OFFERS:
      return {
        ...state,
        multipleOffers: action.payload,
      }

    default:
      return state
  }
}
