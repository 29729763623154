import { SET_SIGNUP_STATE } from "consts/actions"

const initialState = ""

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_SIGNUP_STATE:
      return action.payload

    default:
      return state
  }
}
