import { GET_APP_REFERENCES_PENDING, GET_APP_REFERENCES_SUCCESS, GET_APP_REFERENCES_FAILED } from "consts/actions"

import stateHelper from "helpers/state"

const initialState = {}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_APP_REFERENCES_PENDING:
      return stateHelper.pending()

    case GET_APP_REFERENCES_SUCCESS:
      return {
        ...action.payload,
      }

    case GET_APP_REFERENCES_FAILED:
      return { error: action.payload }

    default:
      return state
  }
}
