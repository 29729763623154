import { createSelector } from "reselect"

import productHelper from "helpers/product"

import consts from "consts"

const selectProducts = state => state.products
const selectSubscriptions = state => state.subscriptions

export const selectCheckoutProduct = state => state.products && state.products.currentProductCheckout

// check if productId is in the subscriptions list
export default createSelector([selectProducts, selectSubscriptions], (products, subscriptions) => {
  let subscriptionsProducts = []
  let catalogProductsAvailable = []
  let catalogProductsDisabled = []

  if (subscriptions.data) {
    subscriptions.data.forEach(({ id, platform, product, end_at }) => {
      // eslint-disable-line camelcase
      // The subscription API is not the same as the product-catalog API and does not have
      // all the property we need. To prevent issue from this, we merge the product we
      // already have with the one returned after the subscription
      const completeProduct = products.data ? products.data.find(p => p.equivalence_code === product.equivalence_code) : {}

      if (product.price > 0) {
        subscriptionsProducts.push(
          productHelper.fixUndefinedProps({
            isSubscribe: true,
            subscriptionEndAt: end_at,
            isSubscribeWithAmazon: platform === consts.platformPayment.amazon,
            isSubscribeWithApple: platform === consts.platform.apple,
            isSubscribeWithGooglePlay: platform === consts.platformPayment.googlePlay,
            isSubscribeWithMolotovpay: platform === consts.platformPayment.molotovpay,
            subscriptionId: id,
            ...product,
            ...completeProduct,
          })
        )
      }
    })
  }

  if (products.data) {
    products.data.map(product => {
      const finalProduct = productHelper.fixUndefinedProps(product)

      if (productHelper.isDisabled(finalProduct)) {
        catalogProductsDisabled.push(finalProduct)
      } else {
        catalogProductsAvailable.push(finalProduct)
      }
    })
  }

  // Starting with already subscribed products ...
  const productsSorted = subscriptionsProducts
    // ... add available products that are not yet in subscriptions
    .concat(catalogProductsAvailable.filter(product => !subscriptionsProducts.find(p => p.equivalence_code === product.equivalence_code)))

  return {
    ...products,
    ...subscriptions,
    data: productsSorted,
    available: productsSorted,
    disabled: catalogProductsDisabled,
  }
})
