import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import styles from "./../index.css"

const IconCaret = ({ customStyle = null }) => (
  <svg viewBox="0 0 10 5" className={classNames(customStyle, styles.fill)}>
    <path fillRule="evenodd" d="M0 0h10L5 6" />
  </svg>
)

IconCaret.propTypes = {
  customStyle: PropTypes.string,
}

export default IconCaret
