const AltNavArrowLeft = "slide to left"
const AltNavArrowRight = "slide to right"

const ClassNameRoot = "image-slider"
const ClassNameNavs = `${ClassNameRoot}-navs`
const ClassNameBullets = `${ClassNameRoot}-bullets`

export default {
  ClassNameRoot,
  ClassNameNavs,
  ClassNameBullets,
  AltNavArrowLeft,
  AltNavArrowRight,
}
