import selectAuthentication from "selectors/authentication"
import selectAccount from "selectors/account"
import selectNavigator from "selectors/navigator"

import segmentHelpers from "helpers/segment"

import consts from "consts"

export default class TrackingSegment {
  static queue = []

  static pushToQueue(...args) {
    this.queue.push(args)
  }

  static runQueue() {
    this.queue.length && this.warning(`Running ${this.queue.length} events from queue`)

    this.queue.forEach(event => {
      const [method, ...args] = event

      this[method].call(this, ...args)
    })

    this.queue = []
  }

  static init(getState) {
    if (!consts.config.segment) {
      return false
    }

    this.getState = getState

    // Create a queue, but don't obliterate an existing one!
    const analytics = (window.analytics = window.analytics || [])

    // A list of the methods in Analytics.js to stub.
    const availableAnalyticsMethods = [
      "trackSubmit",
      "trackClick",
      "trackLink",
      "trackForm",
      "pageview",
      "identify",
      "reset",
      "group",
      "track",
      "ready",
      "alias",
      "debug",
      "page",
      "once",
      "off",
      "on",
    ]

    // Define a factory to create stubs. These are placeholders
    // for methods in Analytics.js so that you never have to wait
    // for it to load to actually record data. The `method` is
    // stored as the first argument, so we can replay the data.
    analytics.factory = function(method) {
      return function() {
        const args = Array.prototype.slice.call(arguments)
        args.unshift(method)
        analytics.push(args)
        return analytics
      }
    }

    // For each of our methods, generate a queueing stub.
    availableAnalyticsMethods.map(method => {
      analytics[method] = analytics.factory(method)
    })

    // Create an async script element based on your key.
    const script = document.createElement("script")
    script.type = "text/javascript"
    script.async = true
    script.src = `${consts.config.segment.analyticsJSDir}${consts.config.segment.apiKey}/analytics.min.js`

    // Insert our script next to the first script element.
    const first = document.getElementsByTagName("script")[0]
    first.parentNode.insertBefore(script, first)

    // Add a version to keep track of what's in the wild.
    analytics.SNIPPET_VERSION = "3.1.0"

    this.init = true

    // TODO : doc or improve this "test"
    // Re-identify
    const currentSession = this.getState().session
    if (currentSession && currentSession.data && currentSession.data.account) {
      this.identify(currentSession.data.account)
    }

    // Store the current userId to prevent multiple identify call on Segment
    this.currentUser = undefined

    // replay all events in queue
    this.runQueue()
  }

  static warning(errorMessage) {
    // We only display warnings in DEV
    if (!__PROD__) {
      /*eslint-disable no-console */
      console.warn(`TrackerWarning : ${errorMessage}`)
      /*eslint-enable no-console */
    }
  }

  static getTraits() {
    const state = this.getState()
    let traits = {}

    if (selectAuthentication(state).isAuthenticated) {
      const account = selectAccount(state)
      traits = {
        hashed_email: account.hashedEmail,
        user_id: account.id,
      }
    }

    return traits
  }

  static identify(user, afterSubscription = false) {
    if (this.init !== true) {
      this.warning("trying to identify user but Tracker isn't initialized, pushing to queue")
      this.pushToQueue("identify", user, afterSubscription)
      return
    }

    if (!user || user === this.currentUser) {
      return
    }

    this.currentUser = user

    const traits = this.getTraits()
    if (afterSubscription === true) {
      traits.createdAt = new Date()
    }

    const state = this.getState()
    traits.cookie_optin_essential = true
    traits.cookie_optin_measurable = state.privacy[consts.privacy.acceptTracking]
    traits.cookie_optin_ad = state.privacy[consts.privacy.acceptAds]

    window.analytics && window.analytics.identify(user, traits)
  }

  static trackPage(pageName, props) {
    if (this.init !== true) {
      this.warning(`trying to track page ${pageName} but Tracker isn't initialized, pushing to queue`)
      this.pushToQueue("trackPage", pageName, props)
      return
    }

    const currentUserId = selectAccount(this.getState()).id
    currentUserId && window.analytics.user && window.analytics.user().id(currentUserId)
    const state = this.getState()
    const navigator = selectNavigator(state)
    const propsToSend = {
      action_device_type: (
        segmentHelpers.getDeviceTypeFromAgent(state.appSettings.molotovAgent) || segmentHelpers.getDeviceTypeFromNavigator(navigator)
      ).toLowerCase(),
      action_os: segmentHelpers.getOsFromAgent(state.appSettings.molotovAgent)
        ? segmentHelpers.getOsFromAgent(state.appSettings.molotovAgent).toLowerCase()
        : segmentHelpers.getOsFromNavigator(navigator).toLowerCase(),
      action_app: segmentHelpers.getAppFromAppSettings(state.appSettings).toLowerCase(),
      logged_in: selectAuthentication(state).isAuthenticated,
      ...props,
    }
    window.analytics &&
      window.analytics.page("", pageName, propsToSend, {
        context: {
          traits: this.getTraits(),
        },
      })
  }

  static trackEvent(eventSlug, props, cb) {
    if (this.init !== true) {
      this.warning(`trying to track event ${eventSlug} but Tracker isn't initialized, pushing to queue`)
      this.pushToQueue("trackEvent", eventSlug, props, cb)
      return
    }

    const state = this.getState()
    const navigator = selectNavigator(state)
    const propsToSend = {
      action_device_type: (
        segmentHelpers.getDeviceTypeFromAgent(state.appSettings.molotovAgent) || segmentHelpers.getDeviceTypeFromNavigator(navigator)
      ).toLowerCase(),
      action_os: (segmentHelpers.getOsFromAgent(state.appSettings.molotovAgent) || segmentHelpers.getOsFromNavigator(navigator)).toLowerCase(),
      action_app: segmentHelpers.getAppFromAppSettings(state.appSettings).toLowerCase(),
      logged_in: selectAuthentication(state).isAuthenticated,
      ...props,
    }

    window.analytics &&
      window.analytics.track(
        eventSlug,
        propsToSend,
        {
          context: {
            traits: this.getTraits(),
          },
        },
        function() {
          cb && cb()
        }
      )
  }

  static logout() {
    this.trackEvent("logged_out", {})
    window.analytics && window.analytics.reset()
  }
}
