import { createSelector } from "reselect"

import productHelper from "helpers/product"

const selectProductFree = state => state.productFree

export default createSelector(
  [selectProductFree],
  productFree => {
    let formatedProductFree = {
      pending: productFree.pending,
    }

    if (productFree.data) {
      formatedProductFree = {
        data: productHelper.fixUndefinedProps(productFree.data),
        pending: productFree.pending || false,
      }
    }

    return formatedProductFree
  }
)
