import {
  GET_FREE_PRODUCT_PENDING,
  GET_FREE_PRODUCT_SUCCESS,
  GET_FREE_PRODUCT_FAILED,
  GET_PRODUCT_LIST_PENDING,
  GET_PRODUCT_LIST_SUCCESS,
  GET_PRODUCT_LIST_FAILED,
  SET_CURRENT_PRODUCT_CHECKOUT,
  UPDATE_PRODUCT_OPTINS,
} from "consts/actions"

import stateHelper from "helpers/state"

import consts from "consts"

export function checkout(productCode) {
  return {
    type: SET_CURRENT_PRODUCT_CHECKOUT,
    payload: productCode,
  }
}

export function getList(platform = consts.platform.web, isPublic = true, originPlatform = undefined) {
  const payment = platform === consts.platform.apple ? consts.platformPayment.apple : consts.platformPayment.molotovpay
  const endpoint = isPublic ? consts.endpoints.productCatalogPublic : consts.endpoints.productCatalog

  return {
    accessTokenRequired: !isPublic,
    request: {
      url: `${endpoint}`,
      method: "GET",
      params: {
        platform: payment,
        originPlatform
      }
    },
    onStart: (payload, meta, dispatch) => {
      dispatch({
        type: GET_PRODUCT_LIST_PENDING,
      })
    },
    onSuccess: (payload, meta, dispatch) => {
      dispatch({
        type: GET_PRODUCT_LIST_SUCCESS,
        payload: { data: payload, originPlatform },
      })
    },
    onError: (payload, meta, dispatch) => {
      dispatch({
        type: GET_PRODUCT_LIST_FAILED,
        payload: stateHelper.errorPayload(payload.user_message, meta.httpCode),
      })
    },
  }
}

export function getFreeProduct() {
  return {
    accessTokenRequired: false,
    request: {
      url: `${consts.endpoints.productCatalogPublic}/${consts.productCode.free}`,
      method: "GET",
    },
    onStart: (payload, meta, dispatch) => {
      dispatch({
        type: GET_FREE_PRODUCT_PENDING,
      })
    },
    onSuccess: (payload, meta, dispatch) => {
      return dispatch({
        type: GET_FREE_PRODUCT_SUCCESS,
        payload: { data: payload },
      })
    },
    onError: (payload, meta, dispatch) => {
      return dispatch({
        type: GET_FREE_PRODUCT_FAILED,
        payload: stateHelper.errorPayload(payload.user_message, meta.httpCode),
      })
    },
  }
}

export function updateProductOptins(productId, optins) {
  return {
    type: UPDATE_PRODUCT_OPTINS,
    payload: {
      productId,
      optins,
    },
  }
}
