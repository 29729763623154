import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import styles from "./index.css"

const FormGroup = ({ children = null, customStyle, inputStyle }) => {
  return (
    <div className={classNames(styles.root, customStyle)}>
      {children.length > 1 && (
        <div>
          {children.map((child, index) => {
            if (index === 0) {
              return (
                <div key={index} className={styles.label}>
                  {child}
                </div>
              )
            } else {
              return (
                <span key={index} className={classNames(styles.input, inputStyle)}>
                  {child}
                </span>
              )
            }
          })}
        </div>
      )}
    </div>
  )
}

FormGroup.propTypes = {
  children: PropTypes.array.isRequired,
  customStyle: PropTypes.string,
  inputStyle: PropTypes.string,
}

export default FormGroup
