import React, { Component } from "react"
import PropTypes from "prop-types"
import FacebookLogin from "@molotovtv/react-facebook-login"

import Icon, { IconTypes } from "components/Icon"

import consts from "consts"

import styles from "./index.css"

export default class ButtonFacebookLogin extends Component {
  static propTypes = {
    callback: PropTypes.func.isRequired,
    textButton: PropTypes.string,
    onClick: PropTypes.func,
  }

  render() {
    return (
      <FacebookLogin
        appId={consts.config.facebookAppId}
        onClick={this.props.onClick}
        callback={this.props.callback}
        autoLoad={false}
        fields="email"
        scope="email,user_birthday,public_profile,user_friends"
        authType="rerequest"
        textButton={this.props.textButton}
        cssClass={styles.facebookButton}
        icon={<Icon type={IconTypes.facebook} rootStyle={styles.facebookIconRootStyle} iconStyle={styles.facebookIcon} />}
        redirectUri={typeof window !== "undefined" ? window.location.href : null}
      />
    )
  }
}
