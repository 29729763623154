import { GET_REFERENCES_PENDING, GET_REFERENCES_SUCCESS, GET_REFERENCES_FAILED } from "consts/actions"

import stateHelper from "helpers/state"

const initialState = {}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_REFERENCES_PENDING:
      return stateHelper.pending()

    case GET_REFERENCES_SUCCESS:
      return {
        ...action.payload,
        categories: action.payload.categories.map(category => ({
          ...category,
          slug_seo: category.slug,
        })),
      }

    case GET_REFERENCES_FAILED:
      return { error: action.payload }

    default:
      return state
  }
}
