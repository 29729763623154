import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import classNames from "classnames"
import { push } from "connected-react-router"

import Input from "components/Input"
import InputSubmit from "components/InputSubmit"
import Button from "components/Button"
import Alert from "components/Alert"

import { update as updateEmail, reset as resetEmail } from "actions/email"

import selectAccount from "selectors/account"
import selectAuthentication from "selectors/authentication"

import i18n from "consts/i18n"
import routes from "consts/routes"

import styles from "./index.css"

@connect(state => ({
  account: selectAccount(state),
  emailForm: state.forms.email,
  isAuthenticated: selectAuthentication(state).isAuthenticated,
}))
export default class PageChangeEmail extends Component {
  static propTypes = {
    account: PropTypes.object,
    dispatch: PropTypes.func,
    emailForm: PropTypes.object,
    isAuthenticated: PropTypes.bool,
    match: PropTypes.object.isRequired,
  }

  static defaultProps = {
    account: {},
    emailForm: {},
    updateEmail: () => {},
    resetEmail: () => {},
  }

  state = {
    email: {
      value: "",
      valid: false,
    },
    confirmation: {
      value: "",
      valid: false,
    },
    password: "",
  }

  onSubmit = event => {
    event.preventDefault()

    const { dispatch, match } = this.props

    const { email, password } = this.state

    if (password.length > 0) {
      dispatch(updateEmail(email.value, password, match.params.token))
    }
  }

  isEmailValid = (email, isConfirmation) => {
    if (isConfirmation) {
      return this.state.email.value === email
    }
    if (!isConfirmation && this.state.confirmation.value.length > 0) {
      return this.state.confirmation.value === email
    }
    return false
  }

  onEmailChange(value) {
    const valid = this.isEmailValid(value)
    this.setState({
      email: {
        value,
        valid,
      },
      confirmation: {
        ...this.state.confirmation,
        valid,
      },
    })
  }

  onConfirmationChange(value) {
    const valid = this.isEmailValid(value, true)
    this.setState({
      confirmation: {
        value,
        valid,
      },
      email: {
        ...this.state.email,
        valid,
      },
    })
  }

  submitDisabled = () => {
    const { emailForm } = this.props

    const { confirmation, password } = this.state

    return !confirmation.valid || emailForm.pending || password.length === 0
  }

  goToConnexionPage = () => {
    this.props.dispatch(push(routes.signup))
  }

  componentWillUnmount() {
    this.props.dispatch(resetEmail())
  }

  render() {
    const { emailForm } = this.props

    const { confirmation, email, password } = this.state

    const emailFormSuccess = emailForm.success

    return (
      <div className={styles.root}>
        <div className={styles.content}>
          <h3 className={styles.title}>{i18n.pageChangeEmail.title}</h3>
          {!emailFormSuccess && emailForm.error && (
            <Alert customStyle={styles.alert}>
              <span>{emailForm.error.message}</span>
            </Alert>
          )}
          {!emailFormSuccess && (
            <form onSubmit={this.onSubmit}>
              <Input
                type="email"
                value={email.value}
                placeholder={i18n.pageChangeEmail.newEmail}
                success={email.valid}
                rootStyle={styles.inputRoot}
                customStyle={styles.input}
                onChange={value => this.onEmailChange(value)}
              />
              <Input
                type="email"
                placeholder={i18n.pageChangeEmail.confirmEmail}
                value={confirmation.value}
                success={confirmation.valid}
                error={confirmation.value.length > 0 && !confirmation.valid}
                rootStyle={styles.inputRoot}
                customStyle={styles.input}
                onChange={value => this.onConfirmationChange(value)}
              />
              <Input
                type="password"
                value={password}
                placeholder={i18n.pageChangeEmail.password}
                rootStyle={styles.inputRoot}
                customStyle={styles.input}
                onChange={value => {
                  this.setState({ password: value })
                }}
              />

              <InputSubmit
                type={InputSubmit.types.yellowFull}
                customStyle={classNames(styles.button, {
                  [styles.disabled]: this.submitDisabled(),
                })}
                onClick={this.onSubmit}
                value={i18n.validate}
              />
            </form>
          )}
          {emailFormSuccess && (
            <div>
              <p className={styles.successText}>{i18n.pageChangeEmail.success}</p>
              <Button
                color={Button.colors.yellowFull}
                customStyle={styles.button}
                onClick={this.goToConnexionPage}
                value={i18n.pageChangeEmail.connect}
              />
            </div>
          )}
        </div>
      </div>
    )
  }
}
