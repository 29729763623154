import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import styles from "./../index.css"

const IconGoogle = ({ customStyle = null }) => (
  <svg className={classNames(customStyle, styles.fill)} xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 19 19">
    <defs>
      <path
        id="google-a"
        d="M18.78 7.773H9.72v3.67h5.215c-.486 2.332-2.519 3.67-5.214 3.67-3.181 0-5.744-2.504-5.744-5.613 0-3.11 2.563-5.614 5.744-5.614A5.7 5.7 0 0 1 13.3 5.14l2.828-2.764C14.405.907 12.195 0 9.72 0 4.33 0 0 4.232 0 9.5S4.33 19 9.72 19C14.582 19 19 15.545 19 9.5c0-.561-.088-1.166-.22-1.727z"
      />
      <path
        id="google-c"
        d="M18.78 7.773H9.72v3.67h5.215c-.486 2.332-2.519 3.67-5.214 3.67-3.181 0-5.744-2.504-5.744-5.613 0-3.11 2.563-5.614 5.744-5.614A5.7 5.7 0 0 1 13.3 5.14l2.828-2.764C14.405.907 12.195 0 9.72 0 4.33 0 0 4.232 0 9.5S4.33 19 9.72 19C14.582 19 19 15.545 19 9.5c0-.561-.088-1.166-.22-1.727z"
      />
      <path
        id="google-e"
        d="M18.78 7.773H9.72v3.67h5.215c-.486 2.332-2.519 3.67-5.214 3.67-3.181 0-5.744-2.504-5.744-5.613 0-3.11 2.563-5.614 5.744-5.614A5.7 5.7 0 0 1 13.3 5.14l2.828-2.764C14.405.907 12.195 0 9.72 0 4.33 0 0 4.232 0 9.5S4.33 19 9.72 19C14.582 19 19 15.545 19 9.5c0-.561-.088-1.166-.22-1.727z"
      />
      <path
        id="google-g"
        d="M18.78 7.773H9.72v3.67h5.215c-.486 2.332-2.519 3.67-5.214 3.67-3.181 0-5.744-2.504-5.744-5.613 0-3.11 2.563-5.614 5.744-5.614A5.7 5.7 0 0 1 13.3 5.14l2.828-2.764C14.405.907 12.195 0 9.72 0 4.33 0 0 4.232 0 9.5S4.33 19 9.72 19C14.582 19 19 15.545 19 9.5c0-.561-.088-1.166-.22-1.727z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <mask id="google-b" fill="#fff">
          <use xlinkHref="#google-a" />
        </mask>
        <path fill="#FBBC05" fillRule="nonzero" d="M-.884 15.114V3.886L6.628 9.5z" mask="url(#google-b)" />
      </g>
      <g>
        <mask id="google-d" fill="#fff">
          <use xlinkHref="#google-c" />
        </mask>
        <path fill="#EA4335" fillRule="nonzero" d="M-.884 3.886L6.628 9.5 9.72 6.866l10.605-1.684V-.864H-.884z" mask="url(#google-d)" />
      </g>
      <g>
        <mask id="google-f" fill="#fff">
          <use xlinkHref="#google-e" />
        </mask>
        <path fill="#34A853" fillRule="nonzero" d="M-.884 15.114l13.256-9.932 3.49.432 4.464-6.478v20.728H-.884z" mask="url(#google-f)" />
      </g>
      <g>
        <mask id="google-h" fill="#fff">
          <use xlinkHref="#google-g" />
        </mask>
        <path fill="#4285F4" fillRule="nonzero" d="M20.326 19.864L6.628 9.5 4.86 8.205l15.466-4.319z" mask="url(#google-h)" />
      </g>
    </g>
  </svg>
)

IconGoogle.propTypes = {
  customStyle: PropTypes.string,
}

export default IconGoogle
