import cookieHelper from "helpers/cookies"
import { getAPIEnv } from "helpers/api"
import consts from "consts"

export default class UrlHelper {
  static stripQuestionMark(input = "") {
    return input.indexOf("?") === 0 ? input.slice(1) : input
  }

  static getCleanPathname(pathname) {
    return pathname === "/" ? "home" : pathname.replace("/", "")
  }

  static getReferrerFromState(state) {
    const referrerIndex = state.session.history.length - 2 >= 0 ? state.session.history.length - 2 : 0
    return state.session.history[referrerIndex] ? state.session.history[referrerIndex] : ""
  }

  static getReferrerAndAllInfoInUrlForLoginOrSignup(url, getState) {
    const referrer = UrlHelper.getReferrerFromState(getState())
    const currentPage = UrlHelper.getCleanPathname(document.location.pathname)
    const originPage = UrlHelper.getCleanPathname(referrer)

    let urlWithParams = `${url}?trkCp=${currentPage}&trkOp=${originPage}`

    const clickid = cookieHelper.getCookieValue(consts.cookieNames.clickId)
    urlWithParams += clickid ? `&clickid=${clickid}` : ""

    const cookieRegisterTracking = cookieHelper.getCookieValue(consts.cookieNames.userTracking)
    if (cookieRegisterTracking) {
      const cookieParsed = JSON.parse(cookieRegisterTracking)
      const queryStringFromCookie = Object.keys(cookieParsed)
        .map(key => key + "=" + cookieParsed[key])
        .join("&")
      urlWithParams += `&${queryStringFromCookie}`
    }

    return urlWithParams
  }

  static getCurrentDomainWithExtension() {
    return window.location.hostname
      .split(".")
      .slice(-2)
      .join(".")
  }

  static getDomainOnly(url) {
    const urlObject = new URL(url)
    const hostnameSplitted = urlObject.hostname.split(".")
    return hostnameSplitted[hostnameSplitted.length - 2]
  }

  static openInBrowser(url, targetSelf) {
    const env = getAPIEnv(consts.config.apiPath)
    const inBrowserUrl = consts.routes.inBrowserUrl[env]
    const urlToOpen = url ? inBrowserUrl + url : inBrowserUrl

    cookieHelper.setCookie({
      name: consts.cookieNames.referrerFromLanding,
      value: UrlHelper.getCleanPathname(document.location.pathname),
      domain: UrlHelper.getCurrentDomainWithExtension(),
    })
    window.open(urlToOpen, targetSelf ? "_self" : "_blank")
  }
}
