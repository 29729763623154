import { GET_ME_PENDING, GET_ME_SUCCESS, GET_ME_FAILED } from "consts/actions"

import stateHelper from "helpers/state"
import consts from "consts"

export function getFullUser() {
  return {
    accessTokenRequired: true,
    request: {
      url: consts.endpoints.me,
      method: "GET",
    },
    onStart: () => {
      return {
        type: GET_ME_PENDING,
      }
    },
    onSuccess: payload => {
      return {
        type: GET_ME_SUCCESS,
        payload,
      }
    },
    onError: payload => {
      return {
        type: GET_ME_FAILED,
        payload: stateHelper.errorPayload(payload.error.user_message),
      }
    },
  }
}
