import { OPEN_MODAL, CLOSE_MODAL } from "consts/actions"

export function open(modalType, data, action, closeOnDimmerClick) {
  return {
    type: OPEN_MODAL,
    modalType,
    data,
    action,
    closeOnDimmerClick,
  }
}

export function close() {
  return {
    type: CLOSE_MODAL,
  }
}
