import { OPEN_FLASH, CLOSE_FLASH } from "consts/actions"

const initialState = {
  open: false,
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case OPEN_FLASH:
      return {
        open: true,
        message: action.message,
        status: action.status,
        messageDeveloper: action.messageDeveloper,
      }

    case CLOSE_FLASH:
      return { open: false }

    default:
      return state
  }
}
