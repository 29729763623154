import { CAMPAIGN_CODE_PENDING, CAMPAIGN_CODE_SUCCESS, CAMPAIGN_CODE_FAILED } from "consts/actions"

const initialState = {
  status: "UNCHECKED",
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case CAMPAIGN_CODE_PENDING:
      return {
        status: "CHECKING",
      }

    case CAMPAIGN_CODE_SUCCESS:
      return {
        status: "CHECKED",
      }

    case CAMPAIGN_CODE_FAILED:
      return {
        status: action.payload,
      }

    default:
      return state
  }
}
