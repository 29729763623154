import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import types from "./types"
import styles from "./index.css"

const Alert = ({ type = types.danger, children = null, onClick = () => {}, customStyle }) => {
  const style = classNames(styles.root, customStyle, {
    [styles.info]: type === types.info,
    [styles.warning]: type === types.warning,
    [styles.success]: type === types.success,
    [styles.danger]: type === types.danger,
  })

  return (
    <div className={style} onClick={onClick}>
      {children}
    </div>
  )
}

Alert.propTypes = {
  type: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element, PropTypes.array]).isRequired,
  onClick: PropTypes.func,
  customStyle: PropTypes.string,
}

export default Alert
