import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import styles from "./../index.css"

const IconEye = ({ customStyle = null }) => (
  <svg viewBox="0 0 17 12" className={classNames(customStyle, styles.fill)}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-869.000000, -1010.000000)" fill="#000000">
        <g transform="translate(869.000000, 1010.000000)">
          <g>
            <path
              d="M8.49739264,0.0280432309 C5.25035787,0.0280432309 2.3057771,2.00460751 0.13297546,5.21507395 C-0.0443251534,5.47810011 -0.0443251534,5.84556314 0.13297546,6.10858931 C2.3057771,9.32292378 5.25035787,11.2994881 8.49739264,11.2994881 C11.7444274,11.2994881 14.6890082,9.32292378 16.8618098,6.11245734 C17.0391104,5.84943117 17.0391104,5.48196815 16.8618098,5.21894198 C14.6890082,2.00460751 11.7444274,0.0280432309 8.49739264,0.0280432309 L8.49739264,0.0280432309 Z M8.73031697,9.63236633 C6.57489775,9.78321957 4.79493865,7.80665529 4.93052147,5.40460751 C5.04176892,3.4241752 6.4845092,1.81894198 8.2644683,1.69516496 C10.4198875,1.54431172 12.1998466,3.520876 12.0642638,5.92292378 C11.9495399,7.89948805 10.5067996,9.50472127 8.73031697,9.63236633 L8.73031697,9.63236633 Z M8.62254601,7.79891923 C7.46140082,7.8801479 6.50189162,6.81643914 6.57837423,5.5245165 C6.63747444,4.4569397 7.41620654,3.5943686 8.37571575,3.52474403 C9.53686094,3.44351536 10.4963701,4.50722412 10.4198875,5.79914676 C10.3573108,6.87059158 9.57857873,7.73316268 8.62254601,7.79891923 L8.62254601,7.79891923 Z"
              id="Shape"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

IconEye.propTypes = {
  customStyle: PropTypes.string,
}

export default IconEye
