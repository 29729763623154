import { RESET_ANIMATION, SHOW_ANIMATION } from "consts/actions"

export function showAnimation(payload) {
  return {
    type: SHOW_ANIMATION,
    payload,
  }
}

export function resetAnimation() {
  return {
    type: RESET_ANIMATION,
  }
}
