import React from "react"

const IconBack = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      d="M12.793 20.207a1.122 1.122 0 0 1-1.586 0l-7.5-7.5a1 1 0 0 1 0-1.414l7.5-7.5a1.122 1.122 0 0 1 1.587 1.587l-5.485 5.495h12.566a1.125 1.125 0 1 1 0 2.25H7.309l5.485 5.495a1.123 1.123 0 0 1 0 1.587z"
    />
  </svg>
)

export default IconBack
