import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

const IconOfferCheck = ({ color, customStyle }) => (
  <svg className={classNames(customStyle)} viewBox="0 0 24 20">
    <path
      fill={color}
      d="M20.9139852,2.23685183 L7.89359802,14.9162379 C7.77891164,15.0279207 7.59321618,15.0279207 7.47879776,14.9162379 L0.0858138133,7.71661368 C-0.0286046044,7.6054528 -0.0286046044,7.42462066 0.0858138133,7.31293789 L1.88220977,5.56358916 C1.99689614,5.45216733 2.18259161,5.45216733 2.29701003,5.56358916 L7.68646585,10.8116354 L18.703057,0.0835663682 C18.8180113,-0.0278554561 19.0031708,-0.0278554561 19.1178572,0.0835663682 L20.9139852,1.8329151 C21.0286716,1.94433693 21.0286716,2.12490813 20.9139852,2.23685183 Z"
      transform="translate(1.5 4.5)"
    />
  </svg>
)

IconOfferCheck.propTypes = {
  color: PropTypes.string,
  customStyle: PropTypes.string,
}

export default IconOfferCheck
