import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import styles from "./../index.css"

const IconTv = ({ customStyle = null }) => (
  <svg viewBox="0 0 30 24" className={classNames(customStyle, styles.stroke)}>
    <defs>
      <path id="a" d="M0 0h30v24H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path d="M29.5 16.5c0 1.1-.9 2-2 2h-25c-1.1 0-2-.9-2-2v-14c0-1.1.9-2 2-2h25c1.1 0 2 .9 2 2v14z" />
      <path d="M27.5 16.5h-25v-14h25zm-14 2v3m3-3v3" />
      <path d="M24.515 23.5c-2.498-1.24-5.84-2-9.515-2-3.674 0-7.016.76-9.514 2" strokeLinecap="round" strokeLinejoin="round" />
    </g>
  </svg>
)

IconTv.propTypes = {
  customStyle: PropTypes.string,
}

export default IconTv
