import { get } from "lodash"

import { getSharingImageProps } from "helpers/static"

import consts from "consts"

/** Class Person */
export default class PersonModel {
  constructor(data, sharing) {
    this.id = data.id
    this.title = data.title
    this.subtitle = data.subtitle
    this.description = data.description
    this.descriptionSource = data.description_source
    this.imageBundle = data.image_bundle
    this.placeholderText = data.placeholder_text

    this.sharing = sharing
  }

  getId() {
    return this.id
  }

  getTitle() {
    return this.title
  }

  getSubtitle() {
    return this.subtitle
  }

  getDescription() {
    return this.description
  }

  getDescriptionSource() {
    return this.descriptionSource
  }

  getPlaceholder() {
    return this.placeholderText
  }

  getImageUrl(type, size = "source") {
    try {
      if (type === "banner_1") {
        type = type + "_desktop"
      }

      return this.imageBundle[type][size].url
    } catch (e) {
      return ""
    }
  }

  getSharingImageProps() {
    return getSharingImageProps(this.sharing, `${consts.config.htmlImagesUrl}/share-person.png?person-id=${this.getId()}`)
  }

  getSlug() {
    return encodeURIComponent(get(this.data, "slug", ""))
  }
}
