import React, { Component } from "react"
import PropTypes from "prop-types"

import Separator from "components/Separator"
import Button from "components/Button"

import i18n from "consts/i18n"

import styles from "./index.css"

export default class ModalText extends Component {
  static propTypes = {
    close: PropTypes.func.isRequired,
    title: PropTypes.string,
    message: PropTypes.string,
  }

  static defaultProps = {
    close: () => {},
    title: i18n.modalConfirm.title,
    message: null,
  }

  render() {
    const { close, message, title } = this.props

    return (
      <div>
        <h3>{title}</h3>

        <Separator />

        {message && <p className={styles.paragraph}>{message}</p>}

        {message && <Separator />}

        <Button color={Button.colors.yellowFull} customStyle={styles.button} onClick={close} value="OK" />
      </div>
    )
  }
}
