import React from "react"
import { useDispatch } from "react-redux"
import PropTypes from "prop-types"
import classNames from "classnames"

import { open as openModal } from "actions/modal"
import modalTypes from "containers/Modal/types"
import formatterHelper from "helpers/formatter"

import styles from "./index.css"

import helperFormatter from "helpers/formatter"

const ProductPanel = ({ customStyle, forceMobileDisplay, product, currentOfferReinsurance, payment }) => {
  const dispatch = useDispatch()

  const { bright_bg_logo, isSubscribe, logo, features_formatter } = product

  const reinsurance = payment?.reinsurance || product.reinsurance

  const showCGV = () => {
    dispatch(
      openModal(
        modalTypes.text,
        {
          title: "Conditions générales de ventes",
          message: currentOfferReinsurance
            ? formatterHelper.styledFormatter(currentOfferReinsurance.footer, "footer")
            : formatterHelper.styledFormatter(reinsurance.footer, "footer"),
        },
        () => {},
        false
      )
    )
  }

  const getImg = () => {
    if (payment?.reinsurance?.logoSmall) {
      return <img width="130px" className={styles.brightLogo} src={payment?.reinsurance?.logoSmall?.url} alt="logo small" />
    }
    if (bright_bg_logo) {
      return <img width="130px" className={styles.brightLogo} src={bright_bg_logo?.url} alt="bright logo" />
    }
    if (logo) {
      return <img width="130px" className={styles.brightLogo} src={logo?.url} alt="logo" />
    }
  }

  const getContent = () => {
    if (reinsurance) {
      return (
        <>
          <div className={styles.priceContainer}>
            {reinsurance && reinsurance.price ? (
              <div className={styles.pricingBigDesktop}>{helperFormatter.styledFormatter(reinsurance.price, "price")}</div>
            ) : null}

            {reinsurance && reinsurance.price_subtitle ? (
              <div className={styles.pricingSmallDesktop}>{helperFormatter.styledFormatter(reinsurance.price_subtitle, "price_subtitle")}</div>
            ) : null}
          </div>

          {reinsurance && reinsurance.features ? (
            <div className={styles.featuresDesktop}>{helperFormatter.styledFormatter(reinsurance.features, "label")}</div>
          ) : null}

          <div className={styles.aboMessageDesktop}>
            Votre abonnement commence après votre paiement.
            <br />
            Sans engagement. Abonnement annulable à tout moment.
          </div>
        </>
      )
    }

    if (features_formatter) {
      return (
        <div
          className={classNames(styles.claims, {
            [styles.periodicityClaims]: !!currentOfferReinsurance,
          })}
        >
          {helperFormatter.styled(features_formatter.format, features_formatter.parts)}
        </div>
      )
    }
  }

  return (
    <div
      className={classNames(styles.rootDesktop, customStyle, {
        [styles.panelNotSubscribe]: !isSubscribe,
        [styles.mobileDisplay]: forceMobileDisplay,
      })}
    >
      {getImg()}
      {getContent()}
      {!currentOfferReinsurance && !payment ? null : (
        <a
          className={classNames(styles.cgvDesktop, {
            [styles.cgvPeriodicity]: !!currentOfferReinsurance,
          })}
          onClick={showCGV}
          aria-label="Voir les Conditions générales de vente"
        >
          Voir les Conditions générales de vente
        </a>
      )}
    </div>
  )
}

ProductPanel.propTypes = {
  customStyle: PropTypes.string,
  forceMobileDisplay: PropTypes.bool,
  product: PropTypes.object,
  currentOfferReinsurance: PropTypes.object,
  payment: PropTypes.object,
}

export default ProductPanel
