import { omit } from "lodash"

import {
  CHOOSE_CURRENT_PROFILE,
  SET_SESSION,
  LOGIN_PENDING,
  LOGIN_FAILURE,
  LOGOUT,
  ACCESSTOKEN_REFRESH_PENDING,
  AUTOLOGIN,
  AUTOLOGIN_ERROR,
  CHECK_LOGIN,
  PENDING_CLIENT_SESSION,
  GET_ACCOUNT_SUCCESS,
  CLEAR_LOGIN_ERROR,
  SOCIAL_LOGIN_ERROR,
  SET_HAS_PASSWORD,
} from "consts/actions"

// import localStorageHelper from "helpers/localStorage"
import stateHelper from "helpers/state"

const initialState = {
  data: null,
  choseProfile: false,
  socialLoginInfos: {},
  history: [],
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_SESSION:
      return {
        ...state,
        ...action.payload,
        pendingClientSession: false,
        autologin: false,
        choseProfile: true,
      }

    case CHOOSE_CURRENT_PROFILE:
      return {
        ...state,
        choseProfile: true,
      }

    case LOGIN_PENDING:
      return { ...state, ...stateHelper.pending() }

    case LOGIN_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload,
      }

    case AUTOLOGIN:
      return { ...state, autologin: true }

    case AUTOLOGIN_ERROR:
      return { ...state, autologin: false }

    case LOGOUT:
      return {
        ...initialState,
        pendingClientSession: false,
      }

    case ACCESSTOKEN_REFRESH_PENDING:
      return { ...state, refreshPending: true }

    case CHECK_LOGIN:
      return { ...state, pendingClientSession: false, data: action.payload }

    case PENDING_CLIENT_SESSION:
      return { ...state, pendingClientSession: true }

    case GET_ACCOUNT_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          account: action.payload,
        },
      }

    case SET_HAS_PASSWORD: {
      return {
        ...state,
        data: {
          ...state.data,
          account: {
            ...state.data.account,
            has_password: action.payload.hasPassword,
          },
        },
      }
    }

    case CLEAR_LOGIN_ERROR:
      return omit(state, ["error"])

    case SOCIAL_LOGIN_ERROR:
      return {
        ...state,
        socialLoginInfos: action.payload,
      }

    case "@@router/LOCATION_CHANGE":
      state.history.push(action.payload.location.pathname)
      return state

    default:
      return state
  }
}
