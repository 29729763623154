import {
  GET_PRODUCT_LIST_PENDING,
  GET_PRODUCT_LIST_SUCCESS,
  GET_PRODUCT_LIST_FAILED,
  SET_CURRENT_PRODUCT_CHECKOUT,
  UPDATE_PRODUCT_OPTINS,
  POST_CONVERSION,
} from "consts/actions"

import stateHelper from "helpers/state"

const initialState = {}

const updateData = (state, updater) => {
  return Array.isArray(state.data)
    ? {
        ...state,
        data: updater(state.data),
      }
    : state
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_PRODUCT_LIST_PENDING:
      return stateHelper.pending()

    case GET_PRODUCT_LIST_SUCCESS:
      return action.payload

    case GET_PRODUCT_LIST_FAILED:
      return { error: action.payload }

    case SET_CURRENT_PRODUCT_CHECKOUT:
      return {
        ...state,
        currentProductCheckout: action.payload,
      }

    case UPDATE_PRODUCT_OPTINS:
      return updateData(state, data =>
        data.map(product =>
          product.id === action.payload.productId
            ? {
                ...product,
                optins: action.payload.optins,
              }
            : product
        )
      )

    case POST_CONVERSION:
      return updateData(state, data =>
        data.map(product =>
          action.equivalenceCode === product.equivalence_code
            ? {
                ...product,
                conversionDone: true,
              }
            : product
        )
      )

    default:
      return state
  }
}
