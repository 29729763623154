import {
  GET_DEVICES_PENDING,
  GET_DEVICES_SUCCESS,
  GET_DEVICES_FAILED,
  SET_DEVICES,
  REMOVE_DEVICES_PENDING,
  REMOVE_DEVICES_DONE,
} from "consts/actions"

import stateHelper from "helpers/state"

const initialState = {}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_DEVICES_PENDING:
      return stateHelper.pending()

    case GET_DEVICES_SUCCESS:
      return { data: action.payload }

    case GET_DEVICES_FAILED:
      return { error: action.payload }

    case SET_DEVICES:
      return {
        ...state,
        data: action.payload,
      }

    case REMOVE_DEVICES_PENDING:
      return {
        ...state,
        ...stateHelper.pending(),
      }

    case REMOVE_DEVICES_DONE:
      return {
        ...state,
        ...stateHelper.pending(false),
      }

    default:
      return state
  }
}
