import request from "superagent"

import { GET_DOWNLOAD_LINK_PENDING, GET_DOWNLOAD_LINK_SUCCESS, GET_DOWNLOAD_LINK_FAILED } from "consts/actions"

import consts from "consts"
import formatterHelper from "helpers/formatter"

export function downloadLink(dispatch) {
  return platform => {
    let url = formatterHelper.basic(consts.endpoints.downloadLink, { platform })

    if (!platform) {
      return dispatch({
        type: GET_DOWNLOAD_LINK_FAILED,
        error: {
          message: "Unable to detect platform",
        },
      })
    }

    dispatch({
      type: GET_DOWNLOAD_LINK_PENDING,
    })

    // Force manifest to be downloaded freshly each time
    url += "?t=" + new Date().getTime()

    request.get(url).end(function(err, payload) {
      if (err) {
        return dispatch({
          type: GET_DOWNLOAD_LINK_FAILED,
          error: {
            message: "Unable to retrieve download link",
          },
        })
      }

      // Linux manifest expose http:// url and actually, Chrome block by default direct download in HTTP on HTTPS site
      payload.body.url = payload.body.url?.replace("http://", "https://") || payload.body.url

      dispatch({
        type: GET_DOWNLOAD_LINK_SUCCESS,
        payload: payload.body,
      })
    })
  }
}
