import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import classNames from "classnames"
import { get } from "lodash"

import RouterSwitch from "components/RouterSwitch/view"
import Loader from "components/Loader"
import CenteredContent from "components/CenteredContent"
import Nav from "components/Nav"

import Connection from "containers/Connection"

// Selectors
import selectAuthentication from "selectors/authentication"

import { updateMeta, restoreDefaultMeta } from "helpers/meta"
import TrackingSegment from "managers/segmentManager"

import routeHelper from "helpers/route"
import consts from "consts"
import i18n from "consts/i18n"

import styles from "./index.css"

@connect(state => ({
  appSettings: state.appSettings,
  authentication: selectAuthentication(state),
  navVisible: state.ui.navVisible,
  session: state.session,
  showMobileNav: state.ui.showMobileNav,
}))
export default class AuthenticationWrapper extends Component {
  static propTypes = {
    authentication: PropTypes.object,
    appSettings: PropTypes.object,
    dispatch: PropTypes.func.isRequired,
    location: PropTypes.object,
    navVisible: PropTypes.bool,
    showMobileNav: PropTypes.bool,
    session: PropTypes.object,
  }

  static contextTypes = {
    router: PropTypes.object,
  }

  onLoginDisplayed = () => {
    if (get(this.props, "location.pathname") === consts.routes.promoCode) {
      TrackingSegment.trackPage("promocode_activation", {
        promocode_activation_step: "login_screen",
      })
    }
  }

  onSignupDisplayed = () => {
    if (get(this.props, "location.pathname") === consts.routes.promoCode) {
      TrackingSegment.trackPage("promocode_activation", {
        promocode_activation_step: "signup_screen",
      })
    }
  }

  componentDidMount() {
    updateMeta({
      title: i18n.pageTitle.authenticated,
    })
  }

  componentWillUnmount() {
    restoreDefaultMeta()
  }

  render() {
    const {
      authentication,
      location,
      navVisible,
      showMobileNav,
      appSettings: { frameless, frameType },
    } = this.props

    if (this.props.session.pendingClientSession || authentication.isAutologin) {
      return <Loader centerInParent={true} className={classNames({ [styles.wrapperDarkBackground]: frameType === consts.frameType.tv })} />
    }

    if (!authentication.isAuthenticated) {
      return (
        <CenteredContent className={styles.connection}>
          <Connection location={location} onLoginDisplayed={this.onLoginDisplayed} onSignupDisplayed={this.onSignupDisplayed} />
        </CenteredContent>
      )
    }

    return (
      <div
        className={classNames(styles.root, {
          [styles.rootFull]: routeHelper.isSingleOfferPage(location.pathname) && frameless,
          [styles.frameless]: frameless,
          [styles.noNav]: !navVisible,
        })}
      >
        {navVisible && !showMobileNav && <RouterSwitch groupName="navRoutes" />}
        {showMobileNav ? <Nav location={location} /> : <RouterSwitch groupName="authenticatedRoutes" />}
      </div>
    )
  }
}
