import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import styles from "./../index.css"

const IconCsa16 = ({ customStyle = null }) => (
  <svg viewBox="0 0 32 32" className={classNames(customStyle, styles.fill)}>
    <path d="M20.27 16.005c-1.073 0-1.51 1.073-1.51 3.298 0 1.865.465 2.8 1.398 2.8.99 0 1.51-1.016 1.51-3.02 0-2.06-.41-3.078-1.4-3.078" />
    <path d="M15.904 2.035c-7.133 0-13.006 5.377-13.795 12.3H7v3.284H2.123c.836 6.87 6.683 12.192 13.78 12.192 7.67 0 13.89-6.218 13.89-13.89 0-7.67-6.22-13.887-13.89-13.887zm-2.952 22.46H9.67v-14.26H8.04V7.407h4.912v17.09zm7.236.24c-3.018 0-4.72-2.11-4.72-5.944 0-2.923 1.175-5.2 2.398-7.74l1.75-3.643h3.28l-2.73 5.37c-.31.623-.623 1.174-.91 1.58.743-.526 1.436-.814 2.133-.814 2.083 0 3.427 1.75 3.427 4.913 0 3.98-1.726 6.278-4.63 6.278z" />
  </svg>
)

IconCsa16.propTypes = {
  customStyle: PropTypes.string,
}

export default IconCsa16
