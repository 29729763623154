import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import styles from "./../index.css"

const IconInstagram = ({ customStyle = null }) => (
  <svg viewBox="0 0 48 48" className={classNames(customStyle, styles.fill)}>
    <path
      d="M24 0c-6.518 0-7.335.028-9.895.144C11.55.26 9.805.667 8.28 1.26 6.7 1.873 5.36 2.694 4.027 4.028 2.694 5.362 1.873 6.7 1.26 8.278.667 9.807.26 11.55.144 14.106.028 16.665 0 17.482 0 24s.028 7.335.144 9.895c.117 2.555.523 4.3 1.116 5.826.613 1.58 1.434 2.918 2.768 4.252 1.334 1.334 2.673 2.155 4.25 2.768 1.528.593 3.272 1 5.827 1.116 2.56.116 3.377.144 9.895.144s7.335-.028 9.895-.144c2.555-.117 4.3-.523 5.826-1.116 1.58-.613 2.918-1.434 4.252-2.768 1.334-1.334 2.155-2.673 2.768-4.25.593-1.528 1-3.272 1.116-5.827.116-2.56.144-3.377.144-9.895s-.028-7.335-.144-9.895c-.117-2.555-.523-4.3-1.116-5.826-.613-1.58-1.434-2.918-2.768-4.252-1.334-1.334-2.673-2.155-4.25-2.768-1.528-.593-3.272-1-5.827-1.116C31.335.028 30.518 0 24 0zm0 4.324c6.408 0 7.167.025 9.698.14 2.34.107 3.61.498 4.457.827 1.12.436 1.92.956 2.76 1.796.84.84 1.36 1.64 1.794 2.76.328.845.72 2.116.826 4.456.115 2.53.14 3.29.14 9.698s-.025 7.167-.14 9.698c-.107 2.34-.498 3.61-.827 4.457-.436 1.12-.956 1.92-1.796 2.76-.84.84-1.64 1.36-2.76 1.794-.845.328-2.116.72-4.456.826-2.53.115-3.29.14-9.698.14-6.41 0-7.168-.025-9.698-.14-2.34-.107-3.61-.498-4.457-.827-1.12-.436-1.92-.956-2.76-1.796-.84-.84-1.36-1.64-1.794-2.76-.328-.845-.72-2.116-.826-4.456-.115-2.53-.14-3.29-.14-9.698s.025-7.167.14-9.698c.107-2.34.498-3.61.827-4.457.436-1.12.956-1.92 1.796-2.76.84-.84 1.64-1.36 2.76-1.794.845-.328 2.116-.72 4.456-.826 2.53-.115 3.29-.14 9.698-.14zm0 7.352c-6.807 0-12.324 5.517-12.324 12.324 0 6.807 5.517 12.324 12.324 12.324 6.807 0 12.324-5.517 12.324-12.324 0-6.807-5.517-12.324-12.324-12.324zM24 32c-4.418 0-8-3.582-8-8s3.582-8 8-8 8 3.582 8 8-3.582 8-8 8zm15.69-20.81c0 1.59-1.288 2.88-2.88 2.88-1.59 0-2.88-1.29-2.88-2.88 0-1.592 1.29-2.88 2.88-2.88 1.592 0 2.88 1.288 2.88 2.88z"
      fillRule="evenodd"
    />
  </svg>
)

IconInstagram.propTypes = {
  customStyle: PropTypes.string,
}

export default IconInstagram
