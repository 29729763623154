import { GET_OPEN_EUROPE_PENDING, GET_OPEN_EUROPE_SUCCESS, GET_OPEN_EUROPE_FAILED } from "consts/actions"

import stateHelper from "helpers/state"

import consts from "consts"

export function getUserIsFrench() {
  return {
    accessTokenRequired: false,
    request: {
      url: consts.endpoints.openEurope,
      method: "GET",
    },
    cache: 15 * 60 * 1000,
    onStart: () => {
      return {
        type: GET_OPEN_EUROPE_PENDING,
      }
    },
    onSuccess: payload => {
      return {
        type: GET_OPEN_EUROPE_SUCCESS,
        payload,
      }
    },
    onError: (payload, meta) => {
      return {
        type: GET_OPEN_EUROPE_FAILED,
        payload: stateHelper.errorPayload(payload.user_message, meta.httpCode),
      }
    },
  }
}
