import i18n from "consts/i18n"

const daysArray = [i18n.day[0], i18n.day[1], i18n.day[2], i18n.day[3], i18n.day[4], i18n.day[5], i18n.day[6]]

const monthsArray = [
  i18n.month[0],
  i18n.month[1],
  i18n.month[2],
  i18n.month[3],
  i18n.month[4],
  i18n.month[5],
  i18n.month[6],
  i18n.month[7],
  i18n.month[8],
  i18n.month[9],
  i18n.month[10],
  i18n.month[11],
]

export default {
  leftPad(number, length) {
    if (!number) return null

    while (number.toString().length < length) {
      number = "0" + number
    }

    return number.toString()
  },

  getDaysArray() {
    const arr = []

    for (let i = 1; i < 32; i++) {
      arr.push(this.leftPad(i, 2))
    }

    return arr
  },

  getMonthsArray() {
    const arr = []

    for (let i = 1; i < 13; i++) {
      arr.push(this.leftPad(i, 2))
    }

    return arr
  },

  getYearsArray() {
    const arr = []
    const currentYear = new Date().getFullYear()
    //@dev the platform is available for people aged 15yo and more
    const eligibleAge = currentYear - 15

    for (let i = eligibleAge; i > 1930; i--) {
      arr.push(this.leftPad(i, 2))
    }

    return arr
  },

  getPaymentFormYearsArray() {
    const arr = []
    const currentYear = new Date().getFullYear()

    for (let i = currentYear; i <= currentYear + 20; i++) {
      arr.push(this.leftPad(i, 2))
    }

    return arr
  },

  getMonth(date) {
    const month = date.getMonth() + 1
    return month < 10 ? `0${month}` : month
  },

  getDay(date) {
    const day = date.getDate()
    return day < 10 ? `0${day}` : day
  },

  getDayString(date) {
    return i18n.day[date.getDay()]
  },

  getMonthString(date) {
    return i18n.month[date.getMonth()]
  },

  getHoursString(date) {
    const hours = date.getHours()
    return hours < 10 ? `0${hours}` : hours
  },

  getMinutesString(date) {
    const minutes = date.getMinutes()
    return minutes < 10 ? `0${minutes}` : minutes
  },

  getUTCDate(date) {
    const UTCDate = date.getUTCDate()
    return UTCDate < 10 ? `0${UTCDate}` : UTCDate
  },

  format(timestamp) {
    const date = new Date(timestamp)
    return `${this.getUTCDate(date)}/${this.getMonth(date)}/${date.getFullYear()}`
  },

  formatSecondToTime(secs) {
    let hours = Math.floor(secs / 3600)
    let minutes = Math.floor((secs - hours * 3600) / 60)

    hours = hours < 10 ? `0${hours}` : hours
    minutes = minutes < 10 ? `0${minutes}` : minutes

    return `${hours}h${minutes}`
  },

  formatMonthYearHuman(timestamp) {
    const date = new Date(timestamp)
    return `${this.getMonthString(date)} ${date.getFullYear()}`
  },

  formatDayHuman(timestamp) {
    const date = new Date(timestamp)
    return `Le ${this.getDay(date)}/${this.getMonth(date)} ${i18n.at} ${this.getHoursString(date)}H${this.getMinutesString(date)}`
  },

  getCurrentTimeInSeconds() {
    return Math.round(new Date().getTime() / 1000)
  },

  dateDiffDays(now, endOfBroadcastReplay) {
    var difference = endOfBroadcastReplay.getTime() - now.getTime() // This will give difference in milliseconds
    var resultInDays = Math.round(difference / 86400000)

    return resultInDays
  },

  dateDiffHours(now, endOfBroadcastReplay) {
    var difference = endOfBroadcastReplay.getTime() - now.getTime() // This will give difference in milliseconds
    var resultInHours = Math.round(difference / 3600000)

    return resultInHours
  },

  isBetween(from, to) {
    const now = new Date()
    return now.getTime() - from.getTime() > 0 && to.getTime() - now.getTime() > 0
  },

  isAfter(date) {
    const now = new Date()
    return now.getTime() - date.getTime() > 0
  },

  available(input, replayEndAtTimeStamp) {
    let inputDate = new Date(input * 1000)
    let replayEndAt = new Date(replayEndAtTimeStamp * 1000)
    let now = new Date()

    let dateDiffDaysFuture = function(now, date) {
      var difference = Math.abs(now.getTime() - date.getTime()) // This will give difference in milliseconds
      var resultInDays = Math.round(difference / 86400000)

      return resultInDays
    }

    let dateDiffHoursFuture = function(now, date) {
      var difference = Math.abs(now.getTime() - date.getTime()) // This will give difference in milliseconds
      var resultInHours = Math.round(difference / 3600000)

      return resultInHours
    }

    let message = ""

    if (inputDate > now) {
      //future
      let availableDays = dateDiffDaysFuture(now, inputDate)
      let availableHours = dateDiffHoursFuture(now, inputDate)
      if (availableDays > 0) {
        message = `${i18n.in} ` + availableDays + (availableDays > 1 ? ` ${i18n.days}` : ` ${i18n.daySingular}`)
      } else if (availableHours > 0) {
        message = `${i18n.in} ` + availableHours + (availableHours > 1 ? ` ${i18n.hours}` : ` ${i18n.hour}`)
      } else {
        message = `${i18n.soon}`
      }
    } else {
      let availableDays = this.dateDiffDays(now, replayEndAt)
      let availableHours = this.dateDiffHours(now, replayEndAt)
      if (availableDays > 0) {
        message = `${i18n.left} ` + availableDays + (availableDays > 1 ? ` ${i18n.days}` : ` ${i18n.daySingular}`)
      } else if (availableHours >= 0) {
        availableHours = availableHours <= 0 ? 1 : availableHours
        message = `${i18n.left} ` + availableHours + (availableHours > 1 ? ` ${i18n.hours}` : ` ${i18n.hour}`)
      } else {
        message = i18n.not_broadcasted
      }
    }

    return message
  },

  humanDate(input, shortDay) {
    const date = new Date(input * 1000),
      today = new Date(),
      tomorrow = new Date(today.setDate(today.getDate() + 1)),
      yesterday = new Date(today.setDate(today.getDate() - 1)),
      timeFormatted = this.getTimeFormatted(date)

    let formated

    switch (date.toDateString()) {
      case today.toDateString():
        formated = i18n.today
        break

      case tomorrow.toDateString():
        formated = i18n.tomorrow
        break

      case yesterday.toDateString():
        formated = i18n.yesterday
        break
      default:
        let day = daysArray[date.getDay()]
        if (shortDay === true) {
          day = `${day.substr(0, 3)}`
        }
        formated = `${day} ${date.getDate()} ${monthsArray[date.getMonth()]}`
    }

    return formated + ` ${timeFormatted}`
  },

  getTimeFormatted(date) {
    let hh = date.getHours(),
      mm = date.getMinutes(),
      minutesFormated = mm

    if (mm < 10) {
      minutesFormated = `0${mm}`
    }

    return `${i18n.at} ${hh}h${minutesFormated}`
  },

  thumbnailDateFuture(input, isPrimetime = false) {
    const date = new Date(input * 1000),
      today = new Date(),
      tomorrow = new Date(today.getTime() + 24 * 60 * 60 * 1000),
      timeFormatted = this.getTimeFormatted(date)

    if (isPrimetime) {
      return timeFormatted
    }

    let finalDateFormated,
      todayTime = today.getTime(),
      dateTime = date.getTime()

    switch (date.toDateString()) {
      case today.toDateString():
        let diffHours = Math.floor((dateTime - todayTime) / 3600000),
          diffMinutes = Math.round((dateTime - todayTime) / 60000)

        if (diffHours < 1) {
          //display for exemple dans x minutes or dans x heures
          if (diffMinutes === 1) {
            finalDateFormated = `${i18n.in} ${diffMinutes} ${i18n.minute_long}`
          } else {
            finalDateFormated = `${i18n.in} ${diffMinutes} ${i18n.minutes_long}`
          }
        } else {
          //display for exemple à 22h45
          finalDateFormated = timeFormatted
        }

        break

      case tomorrow.toDateString():
        //display for exemple demain à 22h45
        finalDateFormated = `${i18n.tomorrow} ${timeFormatted}`
        break

      default:
        //display dans X jours
        let diffDays = Math.round((dateTime - todayTime) / 86400000)
        finalDateFormated = `${i18n.in} ${diffDays} ${diffDays > 1 ? i18n.days : i18n.daySingular}`
    }

    return finalDateFormated
  },

  getFormattedRelativeDate(timestampInSeconds) {
    const currentTime = this.getCurrentTimeInSeconds()
    const diff = timestampInSeconds - currentTime
    if (diff === 0) {
      return "maintenant"
    } else if (-60 < diff) {
      // less than a minute
      return "à l'instant"
    } else if (-3600 < diff) {
      // less than an hour
      const value = Math.floor(Math.abs(diff) / 60)
      return `il y a ${value} ${value > 1 ? "minutes" : "minute"}`
    } else if (-86400 < diff) {
      // less than an day
      const value = Math.floor(Math.abs(diff) / 3600)
      return `il y a ${value} ${value > 1 ? "heures" : "heure"}`
    } else if (-172800 < diff) {
      // less than two days
      return "hier"
    } else if (-2592000 < diff) {
      // less than 30 days
      const value = Math.floor(Math.abs(diff) / 86400)
      return `il y a ${value} ${value > 1 ? "jours" : "jour"}`
    } else if (-31536000 < diff && diff <= -172800) {
      // less than a year
      const value = Math.floor(Math.abs(diff) / 2592000)
      return `il y a ${value} mois`
    } else {
      // more than a year
      const value = Math.floor(Math.abs(diff) / 31536000)
      return `il y a ${value} ${value > 1 ? "ans" : "an"}`
    }
  },

  getMicroDataDate(input) {
    const date = new Date(input * 1000)

    return `${date.getFullYear()}-${this.getMonth(date)}-${this.getDay(date)}T${this.getHoursString(date)}:${this.getMinutesString(date)}`
  },
}
