import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import styles from "./../index.css"

const IconFanion = ({ customStyle = null }) => (
  <svg viewBox="0 0 22 34" className={classNames(customStyle, styles.fill)}>
    <path d="M0,0.999142136 C0,0.447331172 0.43945834,0 1.00246167,0 L20.9975383,0 C21.5511826,0 22,0.454924256 22,0.999142136 L22,33.0008579 C22,33.5526688 21.6085042,33.7864568 21.1184183,33.5191372 L11.8815817,28.4808628 C11.3946976,28.2152896 10.6085042,28.2135432 10.1184183,28.4808628 L0.881581739,33.5191372 C0.394697589,33.7847104 0,33.5450757 0,33.0008579 L0,0.999142136 Z" />
  </svg>
)

IconFanion.propTypes = {
  customStyle: PropTypes.string,
}

export default IconFanion
