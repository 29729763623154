export default class PixelCodeManager {
  static init() {
    !(function(f, b, e, v, n, t, s) {
      if (f.fbq) {
        return
      }
      n = f.fbq = function() {
        n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
      }

      if (!f._fbq) {
        f._fbq = n
      }

      n.push = n
      n.loaded = !0
      n.version = "2.0"
      n.queue = []
      t = b.createElement(e)
      t.async = !0
      t.src = v
      s = b.getElementsByTagName(e)[0]
      s.parentNode.insertBefore(t, s)
    })(window, document, "script", "https://connect.facebook.net/en_US/fbevents.js")

    window.fbq("init", "1489882337980151")
    window.fbq("track", "PageView")

    this.init = true
  }

  static warning(errorMessage) {
    // We only display error in DEV
    if (!__PROD__) {
      /*eslint-disable no-console */
      console.warn(`PixelCodeManager : ${errorMessage}`)
      /*eslint-enable no-console */
    }
  }

  static trackEvent(eventName, params) {
    if (this.init !== true) {
      this.warning(`trying to track event ${eventName} but PixelCodeManager isn't initialized`)
      return
    }

    window.fbq("track", eventName, params)
  }

  // https://developers.facebook.com/docs/facebook-pixel/implementation/gdpr
  static revokeConsent() {
    if (this.init !== true) {
      this.warning("PixelCodeManager is not initialized, no need to revoke consent")
      return
    }

    window.fbq("consent", "revoke")
  }
}
