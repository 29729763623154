import { combineReducers } from "redux"

import personalInfo from "./personalInfo"
import email from "./email"
import password from "./password"

export default combineReducers({
  personalInfo,
  email,
  password,
})
