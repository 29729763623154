import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import classNames from "classnames"
import { get } from "lodash"

// needs to be imported before the modals
// so the modals can override the styles
import styles from "./index.css"

import ModalSetPassword from "./ModalSetPassword"
import ModalDeleteAccount from "./ModalDeleteAccount"
import ModalConfirm from "./ModalConfirm"
import ModalBookmarkDeleteConfirm from "./ModalBookmarkDeleteConfirm"
import ModalCookie, { ModalCookieModalCustomStyle } from "./ModalCookie"
import ModalResetPassword, { ModalResetPasswordCustomStyle } from "./ModalResetPassword"
import ModalIframe, { ModalIframeCustomStyle } from "./ModalIframe"
import ModalOpenEurope, { ModalOpenEuropeCustomStyle, ModalOpenEuropeCustomRootStyle } from "./ModalOpenEurope"
import ModalText from "./ModalText"
import ModalChannels, { ModalChannelsCustomStyle, ModalChannelsCustomRootStyle } from "./ModalChannels"

import { close as closeModal } from "actions/modal"

import modalTypes from "./types"

@connect()
export default class Modal extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
    action: PropTypes.func,
    data: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),
    closeModal: PropTypes.func,
    closeOnDimmerClick: PropTypes.bool,
  }

  static defaultProps = {
    action: () => {},
    closeOnDimmerClick: true,
  }

  state = {
    children: null,
    customStyle: null,
    customRootStyle: null,
  }

  close = () => {
    if (this.customCloseAction) {
      this.props.dispatch(this.customCloseAction())
    }
    this.props.dispatch(closeModal())
    this.customCloseAction = null
  }

  onKeyUp = event => {
    if (this.props.closeOnDimmerClick && event.keyCode === 27) {
      this.close()
    }
  }

  handleClickOverlay = event => {
    if (this.props.closeOnDimmerClick && event.target === this.overlay) {
      this.close()
    }
  }

  componentDidMount() {
    const { type, data, action } = this.props

    let children = null
    let customStyle = null
    let customRootStyle = null

    switch (type) {
      case modalTypes.setPassword:
        children = <ModalSetPassword close={this.close} />
        break

      case modalTypes.deleteAccount:
        children = <ModalDeleteAccount close={this.close} />
        break

      case modalTypes.confirm:
        children = (
          <ModalConfirm
            close={this.close}
            action={action}
            title={get(data, "title")}
            confirmButtonStyle={get(data, "confirmButtonStyle")}
            message={get(data, "message")}
            messageComponent={get(data, "messageComponent")}
          />
        )
        break

      case modalTypes.bookmarkDeleteConfirm:
        children = (
          <ModalBookmarkDeleteConfirm
            close={this.close}
            title={get(data, "title")}
            message={get(data, "message")}
            confirmButtonStyle={get(data, "confirmButtonStyle")}
            action={action}
          />
        )
        break

      case modalTypes.cookie:
        children = <ModalCookie close={this.close} />
        customStyle = ModalCookieModalCustomStyle
        break

      case modalTypes.resetPassword:
        children = <ModalResetPassword close={this.close} />
        customStyle = ModalResetPasswordCustomStyle
        break

      case modalTypes.iframe:
        children = <ModalIframe close={this.close} url={data} />
        customStyle = ModalIframeCustomStyle
        break

      case modalTypes.openEurope:
        children = <ModalOpenEurope close={this.close} />
        customStyle = ModalOpenEuropeCustomStyle
        customRootStyle = ModalOpenEuropeCustomRootStyle
        break

      case modalTypes.text:
        children = <ModalText close={this.close} title={get(data, "title")} message={get(data, "message")} />
        break

      case modalTypes.channels:
        children = <ModalChannels close={this.close} details={data.details} />
        customStyle = ModalChannelsCustomStyle
        customRootStyle = ModalChannelsCustomRootStyle
        break

      default:
        return
    }

    this.setState({ children, customStyle, customRootStyle })

    document.body.style.overflow = "hidden"
    window.addEventListener("keyup", this.onKeyUp)
  }

  componentWillUnmount() {
    document.body.style.overflow = "auto"
    window.removeEventListener("keyup", this.onKeyUp)
  }

  render() {
    const { children, customStyle, customRootStyle } = this.state

    return (
      <div className={classNames(styles.root, customRootStyle)} onClick={this.handleClickOverlay} ref={overlay => (this.overlay = overlay)}>
        <div className={classNames(styles.content, customStyle)}>{children}</div>
      </div>
    )
  }
}
