import React, { Component } from "react"
import PropTypes from "prop-types"

import Separator from "components/Separator"
import Button from "components/Button"

import i18n from "consts/i18n"

import styles from "./index.css"

export default class ModalConfirm extends Component {
  static propTypes = {
    close: PropTypes.func.isRequired,
    action: PropTypes.func,
    confirmButtonStyle: PropTypes.string,
    title: PropTypes.string,
    message: PropTypes.string,
    messageComponent: PropTypes.element,
  }

  static defaultProps = {
    close: () => {},
    action: () => {},
    confirmButtonStyle: "yellowFull",
    title: i18n.modalConfirm.title,
    message: null,
  }

  render() {
    const { action, close, confirmButtonStyle, message, messageComponent, title } = this.props

    return (
      <div>
        <h3>{title}</h3>

        <Separator customStyle={styles.separator} />

        {(message && <p className={styles.paragraph}>{message}</p>) || messageComponent}

        {(message || messageComponent) && <Separator customStyle={styles.separator} />}

        <Button color={Button.colors.greyFull} customStyle={styles.button} onClick={close} value={i18n.cancel} />
        <Button
          color={Button.colors[confirmButtonStyle]}
          customStyle={styles.rightButton}
          onClick={() => {
            action()
            close()
          }}
          value={i18n.delete}
        />
      </div>
    )
  }
}
