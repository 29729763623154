import { get } from "lodash"

import FormatterHelper from "helpers/formatter"

import routes from "consts/routes"

export default class ChannelModel {
  constructor(data, references) {
    this.data = data
    this.references = references
  }

  isValid() {
    return !!this.data.id
  }

  isVirtual() {
    return this.data.is_virtual
  }

  getId() {
    return this.data.id
  }

  getTitle() {
    return this.data.title
  }

  getSlug() {
    return encodeURIComponent(this.data.slug_seo)
  }

  getImageUrl(size = "small", color = "dark") {
    try {
      return this.data.image_bundle[`logo_${color}`][size].url
    } catch (e) {
      return ""
    }
  }

  getOverlayUrl(size = "small") {
    try {
      return this.data.image_bundle.overlay[size].url
    } catch (e) {
      return ""
    }
  }

  getBundles() {
    if (!this.bundles) {
      this.bundles = get(this.references, "tvbundles", [])
        .filter(b => b.channel_ids)
        .filter(b => b.channel_ids.indexOf(this.getId()) !== -1)
    }

    return this.bundles
  }

  isAvailableInFreeBundles(availableBundles = []) {
    return !!this.getBundles()
      .filter(b => availableBundles.indexOf(b.id) !== -1)
      .filter(b => !b.is_charged).length
  }

  getUrl(locale) {
    return FormatterHelper.basic(routes.channel, { locale, channelId: this.getId(), channelSlug: this.getSlug() })
  }

  getReplayUrl(locale) {
    return FormatterHelper.basic(routes.channelReplay, { locale, channelId: this.getId(), channelSlug: this.getSlug() })
  }

  hasReplay() {
    return this.data.has_replay
  }

  isCommercialized() {
    return this.data.is_commercialized
  }
}
