import React from "react"
import PropTypes from "prop-types"

import Icon, { IconTypes } from "components/Icon"

import styles from "./index.css"

const ModalIframe = ({ close, url }) => (
  <div className={styles.root}>
    <div className={styles.close}>
      <Icon type={IconTypes.close} iconStyle={styles.closeIcon} onClick={close} />
    </div>
    <div className={styles.video}>
      <iframe className={styles.iframe} src={url} frameBorder="0" allow="autoplay; fullscreen; encrypted-media;" allowFullScreen />
    </div>
  </div>
)

ModalIframe.propTypes = {
  close: PropTypes.func,
  url: PropTypes.string,
}

const ModalIframeCustomStyle = styles.modal

export default ModalIframe
export { ModalIframeCustomStyle }
