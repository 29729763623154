import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import { exposeStyles } from "helpers/components"
import styles from "./index.css"

const InputSubmit = ({ value = "My Button", disabled = false, customStyle }) => {
  const style = classNames(styles.root, customStyle)

  return (
    <input
      type="submit"
      className={classNames(style, {
        [styles.disabled]: disabled,
      })}
      value={value}
    />
  )
}

InputSubmit.types = exposeStyles({}, styles)

InputSubmit.propTypes = {
  value: PropTypes.string,
  disabled: PropTypes.bool,
  customStyle: PropTypes.string,
}

export default InputSubmit
