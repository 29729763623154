import {
  LINK_VALIDATE_CODE_PENDING,
  LINK_VALIDATE_CODE_SUCCESS,
  LINK_VALIDATE_CODE_ERROR,
  LINK_ACTIVATE_CODE_PENDING,
  LINK_ACTIVATE_CODE_SUCCESS,
  LINK_ACTIVATE_CODE_ERROR,
  SET_SESSION,
  CHOOSE_CURRENT_PROFILE,
} from "consts/actions"

const initialState = {
  codeStatus: "NOT_VALIDATED",
  code: null,
  error: null,
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LINK_VALIDATE_CODE_PENDING:
      return {
        codeStatus: "PENDING",
        error: null,
        code: action.payload,
      }

    case LINK_VALIDATE_CODE_SUCCESS:
      return {
        ...state,
        codeStatus: "VALIDATED",
        deviceTargetAgent: action.payload.agent,
        showOpenEurope: action.payload.show_open_europe,
        error: null,
      }

    case LINK_VALIDATE_CODE_ERROR:
    case LINK_ACTIVATE_CODE_ERROR:
      return {
        ...state,
        codeStatus: "ERROR",
        error: action.payload,
      }

    case LINK_ACTIVATE_CODE_PENDING:
    case SET_SESSION:
    case CHOOSE_CURRENT_PROFILE:
      return {
        ...state,
        codeStatus: "PENDING_ACTIVATE",
        error: null,
      }

    case LINK_ACTIVATE_CODE_SUCCESS:
      return {
        ...state,
        codeStatus: "ACTIVATED",
        error: null,
      }

    default:
      return state
  }
}
