import { UPDATE_ACCOUNT_PENDING, UPDATE_ACCOUNT_SUCCESS, UPDATE_ACCOUNT_FAILED } from "consts/actions"

import stateHelper from "helpers/state"

const initialState = {}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_ACCOUNT_PENDING:
      return stateHelper.pending()

    case UPDATE_ACCOUNT_SUCCESS:
      return initialState

    case UPDATE_ACCOUNT_FAILED:
      return { error: action.payload }

    default:
      return state
  }
}
