import { APPLY_PRIVACY_SETTINGS, DISPLAY_COOKIE_BANNER, HIDE_COOKIE_SETTINGS, SET_PRIVACY_SETTINGS, TOGGLE_DIDOMI_BANNER } from "consts/actions"

import consts from "consts"

import { saveSettings, startTracking, stopTracking, stopAds, startAds } from "managers/privacy"

export function displayCookieBanner() {
  return {
    type: DISPLAY_COOKIE_BANNER,
  }
}

export function hideCookieBanner() {
  return {
    type: HIDE_COOKIE_SETTINGS,
  }
}

export function setPrivacySettings(settings) {
  return {
    type: SET_PRIVACY_SETTINGS,
    settings,
  }
}

export function toggleDidomiModal() {
  return {
    type: TOGGLE_DIDOMI_BANNER,
  }
}

export function applyPrivacySettings() {
  return (dispatch, getState) => {
    // apply settings that are already in the store
    const privacy = getState().privacy

    const settings = {
      [consts.privacy.acceptTracking]: privacy[consts.privacy.acceptTracking],
      [consts.privacy.acceptAds]: privacy[consts.privacy.acceptAds],
    }

    // Delete after the date
    const currentDate = new Date()
    const maxDate = new Date("2022-03-18")

    if (currentDate > maxDate) {
      if (settings[consts.privacy.acceptTracking]) {
        startTracking(getState)
      }

      if (!settings[consts.privacy.acceptTracking]) {
        stopTracking()
      }

      if (settings[consts.privacy.acceptAds]) {
        startAds()
      }

      if (!settings[consts.privacy.acceptAds]) {
        stopAds()
      }
    } else {
      startTracking(getState)
      startAds()
    }

    saveSettings(settings)

    dispatch({
      type: APPLY_PRIVACY_SETTINGS,
    })
  }
}
