import {
  GET_SUBSCRIPTIONS_PENDING,
  GET_SUBSCRIPTIONS_SUCCESS,
  GET_SUBSCRIPTIONS_FAILED,
  POST_SUBSCRIPTIONS_PENDING,
  POST_SUBSCRIPTIONS_SUCCESS,
  POST_SUBSCRIPTIONS_FAILED,
  DELETE_SUBSCRIPTIONS_PENDING,
  DELETE_SUBSCRIPTIONS_SUCCESS,
  DELETE_SUBSCRIPTIONS_FAILED,
  RESET_SUBSCRIPTIONS_ERROR,
  CLEAN_SUBSCRIPTIONS,
} from "consts/actions"

import stateHelper from "helpers/state"

const initialState = {}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_SUBSCRIPTIONS_PENDING:
      return stateHelper.pending()

    case GET_SUBSCRIPTIONS_SUCCESS:
      return action.payload

    case GET_SUBSCRIPTIONS_FAILED:
      return {
        ...state,
        ...stateHelper.pending(false),
        error: action.payload,
      }

    case POST_SUBSCRIPTIONS_PENDING:
      return {
        data: state.data,
        ...stateHelper.pending(),
      }

    case POST_SUBSCRIPTIONS_SUCCESS:
      // Temporarily ignore subscriptions and restore response payload to fix an issue
      // where re-inssurance are missing in those response and disappears from UI in already
      // subscribed molotov Pay offers boxes.
      // return {
      //     data: action.payload,
      // }
      return {
        data: state.data,
        ...stateHelper.pending(false),
      }

    case POST_SUBSCRIPTIONS_FAILED:
      return {
        data: state.data,
        error: action.payload,
      }

    case RESET_SUBSCRIPTIONS_ERROR:
      let newState = state
      if (state.error && state.error.id === action.id) {
        newState = {
          data: state.data,
        }
      }

      return newState

    case DELETE_SUBSCRIPTIONS_PENDING:
      return {
        data: state.data,
        ...stateHelper.pending(),
      }
    case DELETE_SUBSCRIPTIONS_SUCCESS:
      let newSubscriptions = []
      let deletedSubscription = action.payload

      if (state.data && deletedSubscription) {
        // Replace the deleted subscription in subscriptions array by the one returned in API payload
        newSubscriptions = state.data.map(subscription => (subscription.id === deletedSubscription.id ? deletedSubscription : subscription))
      }

      return {
        ...state,
        ...stateHelper.pending(false),
        data: newSubscriptions,
      }

    case DELETE_SUBSCRIPTIONS_FAILED:
      return {
        data: state.data,
        error: {
          subscriptionId: action.subscriptionId,
          ...action.payload,
        },
      }

    case CLEAN_SUBSCRIPTIONS: {
      return initialState
    }

    default:
      return state
  }
}
