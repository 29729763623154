import { get } from "lodash"
import Section from "models/Section"
import Program from "models/Program"
import Episode from "models/Episode"
import Channel from "models/Channel"

import FormatterHelper from "helpers/formatter"

import consts from "consts"

export const selectSection = (section, { channels, itemEntity, references, extra }) => {
  return new Section({
    ...section,
    items:
      section && section.items
        ? section.items
            .filter(p => {
              return extra && extra.selectAll
                ? true
                : (p.video &&
                    (p.video.type === consts.tileType.channel ||
                      p.video.type === consts.tileType.broadcast ||
                      p.video.type === consts.tileType.vod ||
                      p.video.type === consts.tileType.record)) ||
                    p.type === consts.tileType.feature
            })
            .map(tile => {
              const channelId = get(tile, "type") === consts.tileType.channel ? tile.id : get(tile, "video.channel_id")
              const channel = channels.find(c => c.getId() === channelId)

              if (itemEntity && itemEntity === "episode") {
                return new Episode({ ...tile, channel }, extra.programId)
              }

              if (get(tile, "type") === consts.tileType.channel) {
                return new Channel(tile, references)
              }

              if (tile.title_formatter) {
                tile.title = FormatterHelper.styledFormatter(tile.title_formatter)
              }
              return new Program({ ...tile, channel })
            })
        : [],
  })
}

export const selectSections = (sections, params) => {
  return Array.isArray(sections) ? sections.map(section => selectSection(section, params)) : []
}
