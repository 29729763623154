import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import Icon, { IconTypes } from "components/Icon"

import styles from "./index.css"
import consts from "consts"

const Toggle = ({ activated, customStyle, disabled, onClick, customActivatedStyle, displayToggleIcon }) => (
  <div
    className={classNames(
      styles.toggle,
      {
        [styles.disabled]: disabled,
        [customActivatedStyle]: activated,
        [styles.activated]: activated,
      },
      customStyle
    )}
    onClick={!disabled ? onClick : undefined}
  >
    <div className={styles.handle}>
      {displayToggleIcon &&
        (activated ? <Icon type={IconTypes.checkmark} iconStyle={styles.icon} /> : <Icon type={IconTypes.close} iconStyle={styles.icon} />)}
    </div>
  </div>
)

Toggle.propTypes = {
  activated: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  customStyle: PropTypes.string,
  customActivatedStyle: PropTypes.string,
  displayToggleIcon: PropTypes.bool,
}

Toggle.defaultProps = {
  activated: true,
  disabled: false,
  onClick: consts.NoOp,
  customStyle: "",
  customActivatedStyle: "",
  displayToggleIcon: true,
}

export default Toggle
