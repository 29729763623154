import { GET_PAYPAL_AB_TESTING_PENDING, GET_PAYPAL_AB_TESTING_SUCCESS, GET_PAYPAL_AB_TESTING_FAILED } from "consts/actions"

import stateHelper from "helpers/state"

const initialState = {
  data: undefined,
  pending: false,
  error: undefined,
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_PAYPAL_AB_TESTING_PENDING:
      return {
        ...state,
        ...stateHelper.pending(),
      }

    case GET_PAYPAL_AB_TESTING_SUCCESS:
      return {
        ...state,
        ...stateHelper.pending(false),
        ...action.payload,
      }

    case GET_PAYPAL_AB_TESTING_FAILED:
      return {
        ...state,
        ...stateHelper.pending(false),
        error: action.payload,
      }
    default:
      return state
  }
}
