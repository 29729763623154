import { get } from "lodash"

import consts from "consts"
import i18n from "consts/i18n"

import DateHelper from "helpers/date"
import pictureHelper from "helpers/picture"
import { getSharingImageProps } from "helpers/static"
import FormatterHelper from "helpers/formatter"

export default class ProgramModel {
  constructor(data, episodeSections, casting, program_metadata) {
    const { channel, channelEpisodeSections, sharing, ...programData } = data
    this.data = programData
    this.channelEpisodeSections = channelEpisodeSections

    if (channel) {
      this.channel = channel
    }

    this.episodeSections = episodeSections
    this.casting = casting
    this.sharing = sharing
    this.program_metadata = program_metadata
  }

  getProgramRedirectMetadata() {
    return this.program_metadata.redirect
  }

  getProgramCanonicalMetadata() {
    return this.program_metadata.canonical
  }

  getId() {
    return this.data.id
  }

  getVideo() {
    return this.data.video
  }

  getTitle() {
    return this.data.title
  }

  getTitleFormatter() {
    return this.data.title_formatter
  }

  getSubtitle() {
    return this.data.subtitle
  }

  getChannelId() {
    if (this.getChannel()) {
      return this.getChannel().getId()
    }

    if (!this.getVideo()) {
      return null
    }

    if (this.getVideo().type === consts.tileType.channel) {
      return this.getVideo().id
    }

    if (this.getVideo().type === consts.tileType.broadcast || this.getVideo().type === consts.tileType.vod) {
      return this.getVideo().channel_id
    }
  }

  getBackdropUrl() {
    try {
      return this.data.image_bundle.backdrop.medium.url
    } catch (e) {
      return this.getImageUrl()
    }
  }

  getImageUrl(size = "medium", type = "poster_with_channel") {
    try {
      return get(this.data, ["image_bundle", type, size, "url"], "")
    } catch (e) {
      return ""
    }
  }

  getSharingImageProps() {
    return getSharingImageProps(
      this.sharing,
      `${consts.config.htmlImagesUrl}/share-episode.png?channel-id=${this.getChannelId()}&program-id=${
        this.isEpisode() ? this.getProgramId() : this.getId()
      }`
    )
  }

  getStartAt() {
    return get(this.getVideo(), "start_at", null)
  }

  getEndAt() {
    return get(this.getVideo(), "end_at", null)
  }

  getAvailableFrom() {
    return get(this.getVideo(), "available_from", null)
  }

  // If available_until is null then this program is still available
  // BUT if available_until is null then program is available for a long time, not only until today (will display "plus que 1 heure") which is not true
  // TODO : Find a way to handle this
  getAvailableUntil() {
    return get(this.getVideo(), "available_until", +new Date() / 1000)
  }

  isLive() {
    const timeNow = Math.round(+new Date() / 1000)

    return this.getStartAt() <= timeNow && timeNow <= this.getEndAt()
  }

  isAvailable() {
    const now = new Date()
    const startDate = new Date(this.getStartAt() * 1000)
    const replayEndAt = new Date(this.getAvailableUntil() * 1000)

    const availableDays = DateHelper.dateDiffDays(now, replayEndAt)
    const availableHours = DateHelper.dateDiffHours(now, replayEndAt)

    if (startDate < now && availableDays < 0 && availableHours < 0) {
      return false
    }

    return true
  }

  getRelativeDate() {
    if (!this.getStartAt() && !this.getAvailableFrom()) {
      return null
    }

    const startDate = this.getStartAt() || this.getAvailableFrom()
    const timeNow = Math.round(+new Date() / 1000)

    if (timeNow > startDate && !this.getAvailableUntil()) {
      return `${i18n.broadcasted} ${DateHelper.humanDate(startDate)}`
    }

    if (timeNow > startDate && this.getAvailableUntil()) {
      return DateHelper.available(startDate, this.getAvailableUntil())
    } else {
      return DateHelper.thumbnailDateFuture(startDate)
    }
  }

  getEpisodeSections() {
    return this.episodeSections
  }

  getChannelEpisodeSections() {
    return this.channelEpisodeSections
  }

  getBroadcastDate() {
    if (!this.episodeSections) {
      return this.getRelativeDate()
    }

    let episodes = []
    this.episodeSections.forEach(section => {
      if (section.getItemsCount()) {
        episodes = episodes.concat(section.getItems())
      }
    })

    const now = ~~(+new Date() / 1000)

    episodes.filter(e => {
      return now < e.getStartAt()
    })

    if (episodes.length) {
      return DateHelper.humanDate(episodes[0].getStartAt())
    }

    return this.getRelativeDate()
  }

  getSlug() {
    return encodeURIComponent(get(this.data, "slug", ""))
  }

  getImageHash(url) {
    const parts = url.split(".")
    const file = parts[parts.length - 2]
    return file.split("/")[file.split("/").length - 1]
  }

  getCasting() {
    return this.casting
  }

  getCategoryId() {
    return this.data.metadata.program_category_id
  }

  getSubCategoryId() {
    return this.data.metadata.program_kind_id
  }

  getChannel() {
    return this.channel
  }

  getDescription() {
    return this.data.description
  }

  getCategoryTitle() {
    return this.data.metadata.program_category
  }

  isEpisode() {
    return false
  }

  hasReplay() {
    return (
      this.episodeSections && this.episodeSections.length && !!this.episodeSections.find(section => section.slug === "episodes-replays").items.length
    )
  }

  getEventMicroData(locale) {
    return {
      name: this.getTitle(),
      image: pictureHelper.replaceProtocol(this.getImageUrl("large")),
      startDate: DateHelper.getMicroDataDate(this.getStartAt()),
      endDate: DateHelper.getMicroDataDate(this.getEndAt()),
      url: `${consts.externalLink.landing}${this.getUrl(locale)}`,
      description: this.getDescription(),
      location: {
        "@type": "Place",
        address: {
          "@type": "PostalAddress",
          name: this.getChannel().getTitle(),
        },
        name: this.getChannel().getTitle(),
        url: this.getChannel().getUrl(locale),
      },
    }
  }

  getUrl(locale) {
    return FormatterHelper.basic(consts.routes.program, {
      locale,
      programId: this.getId(),
      channelId: this.getChannel().getId(),
      programSlug: this.getSlug(),
    })
  }

  getSEORanking() {
    return this.data.metadata.program_seo_ranking
  }
}
