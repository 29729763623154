import { SET_LOCALE } from "consts/actions"

const initialState = {
  locale: "fr_fr",
}

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_LOCALE:
      return {
        ...state,
        locale: action.locale,
      }

    default:
      return state
  }
}
