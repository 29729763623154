import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { has } from "lodash"

import PageOauthWrapper from "containers/PageOauthWrapper"
import Button from "components/Button"
import Loader from "components/Loader"
import Connection from "containers/Connection"
import CenteredContent from "components/CenteredContent"

// Actions
import { fetchOAuth, allowOAuth } from "actions/session"

// Selectors
import selectAuthentication from "selectors/authentication"
import { selectQuery } from "selectors/location"

import helperFormatter from "helpers/formatter"
import { getAssetURL } from "helpers/static"
import Formatter from "helpers/formatter"

import styles from "./index.css"
import i18n from "consts/i18n"

const iconMolotovImage = getAssetURL("image.pageOAuth.iconMolotovImage")

const ECHO_SHOW_CLIENT_ID = "58486876-4f32-4539-b163-4b5a4845766f"

@connect((state, ownProps) => ({
  authentication: selectAuthentication(state),
  query: selectQuery(ownProps),
  OAuthSession: state.OAuthSession,
}))
export default class PageOAuth extends Component {
  static propTypes = {
    location: PropTypes.object,
    dispatch: PropTypes.func,
    authentication: PropTypes.object,
    query: PropTypes.object.isRequired,
    OAuthSession: PropTypes.object,
  }

  isInvalidRequest() {
    const props = this.props || {}

    const { query } = props

    for (var param of ["client_id", "redirect_uri"]) {
      if (!has(query, param)) {
        return Formatter.advanced(i18n.OAuthPage.invalidParams, { param: param })
      }
    }
  }

  getOAuth = _props => {
    const props = _props || this.props

    const query = {
      ...props.query,
    }

    query.originalQuery = JSON.stringify(props.query)

    if (query.state_molotov) {
      query.state = query.state_molotov
    }

    props.dispatch(fetchOAuth(query))
  }

  allow = allowed => {
    const props = this.props || {}

    const query = {
      ...props.query,
      allowed,
    }

    if (query.state_molotov) {
      query.state = query.state_molotov
    }

    this.props.dispatch(allowOAuth(query))
  }

  onAllow = () => this.allow(true)

  onDeny = () => this.allow(false)

  getRedirectionComponent = app => {
    return (
      <div className={`${styles.content} ${styles.redirectionMessage}`}>
        {helperFormatter.sprintf(i18n.OAuthPage.redirectionMessage, [app.application_name])}
      </div>
    )
  }

  getRequestedComponent = app => {
    return (
      <div className={`${styles.content} ${styles.accountChoice}`}>
        <div className={styles.appLogo}>
          <img loading="lazy" src={iconMolotovImage} alt="Molotov icon" />
        </div>
        <div className={styles.authorizationTitle}>Accès Molotov</div>
        <div className={styles.accountChoiceMessage}>Autoriser {app.application_name} à avoir accès à la plateforme Molotov ?</div>
        <div className={styles.accountChoiceButtons}>
          <Button value={i18n.OAuthPage.buttonDeny} onClick={this.onDeny} color={Button.colors.greyFull} />
          <Button value={i18n.OAuthPage.buttonAllow} onClick={this.onAllow} color={Button.colors.yellowFull} />
        </div>
      </div>
    )
  }

  getErrorComponent = errorMessage => {
    return (
      <div className={`${styles.content} ${styles.errorMessageBox}`}>
        <div className={styles.errorMessage}>{errorMessage}</div>
        <div className={styles.errorButtons}>
          <Button value={i18n.cancel} onClick={this.logout} color={Button.colors.greyFull} />
          <Button value={i18n.OCSPage.retryButton} onClick={this.getOCSAuth} color={Button.colors.yellowFull} />
        </div>
      </div>
    )
  }

  componentDidUpdate() {
    if (
      this.props.authentication.isAuthenticated &&
      !this.props.OAuthSession.pending &&
      !this.props.OAuthSession.application &&
      !this.props.OAuthSession.redirectionURL &&
      !this.props.OAuthSession.error
    ) {
      this.getOAuth(this.props)
    }

    if (this.props.OAuthSession.redirectionURL) {
      document.location = this.props.OAuthSession.redirectionURL
    }
  }

  render() {
    const {
      authentication,
      OAuthSession,
      query: { client_id },
    } = this.props
    const error = this.isInvalidRequest()

    return (
      <PageOauthWrapper>
        {error && this.getErrorComponent(error)}
        {OAuthSession.error && this.getErrorComponent(OAuthSession.error)}
        {!authentication.isAuthenticated && !error && !OAuthSession.redirectionURL && (
          <CenteredContent>
            <Connection location={this.props.location} fromOAuthPage={true} fromEchoShow={client_id === ECHO_SHOW_CLIENT_ID} />
          </CenteredContent>
        )}
        {authentication.isAuthenticated && OAuthSession.pending && !error && <Loader centerInParent={false} />}
        {authentication.isAuthenticated &&
          OAuthSession.application &&
          !error &&
          !OAuthSession.pending &&
          !OAuthSession.redirectionURL &&
          this.getRequestedComponent(OAuthSession.application)}
        {authentication.isAuthenticated &&
          OAuthSession.redirectionURL &&
          OAuthSession.application &&
          !error &&
          this.getRedirectionComponent(OAuthSession.application)}
      </PageOauthWrapper>
    )
  }
}
