import React from "react"
import PropTypes from "prop-types"

import formatterHelper from "helpers/formatter"

import styles from "./index.css"

const FormBottomMessage = ({ messageFormatter }) =>
  messageFormatter ? <div className={styles.bottomMessage}>{formatterHelper.styledFormatter(messageFormatter)}</div> : null

FormBottomMessage.propTypes = {
  messageFormatter: PropTypes.object,
}

export default FormBottomMessage
