import { CHECK_REQUEST_QUEUE } from "consts/actions"

import { refresh } from "actions/session"

import selectAuthentication from "selectors/authentication"

let queue = []

const requestQueueMiddleware = store => next => action => {
  if (!action) {
    return
  }

  if (action.queueing === false) {
    return next(action)
  }

  // Action CHECK_REQUEST_QUEUE is emitted by actions/session:refresh (onSuccess)
  if (action.type === CHECK_REQUEST_QUEUE) {
    queue.map(queuedAction => {
      next(queuedAction)
    })
    queue = []
    return
  }

  const authentication = selectAuthentication(store.getState())
  if (action.request && action.accessTokenRequired !== false && !authentication.hasAccessToken) {
    let res

    if (authentication.needRefresh && !authentication.isRefreshing) {
      res = next(refresh())
    }

    if (action.forceToken) {
      res = next(action)
    }

    queue.push(action)

    return res
  } else {
    return next(action)
  }
}

export default requestQueueMiddleware
