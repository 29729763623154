import { createSelector } from "reselect"
import { get } from "lodash"
import MenuItems from "models/MenuItems"

const selectSeoMenu = state => state.seoMenu
const selectItems = createSelector([selectSeoMenu], items => get(items, "items", []))

const _selectSeoMenuModel = createSelector(
  [selectItems],
  items =>
    new MenuItems({
      items,
    })
)

export const selectSeoMenuModel = state => {
  let menuItems = state.seoMenu

  if (!(menuItems instanceof MenuItems)) {
    menuItems = _selectSeoMenuModel(state)
  }
  return menuItems
}
