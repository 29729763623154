import { createSelector } from "reselect"
import { get } from "lodash"

import selectReferences from "selectors/references"

export const selectErrorMessages = createSelector(
  [selectReferences],
  references => {
    return get(references, "error_messages", {})
  }
)
