import { SET_SIGNUP_STATE } from "consts/actions"

export function set(signup) {
  return (dispatch, getState) => {
    const currentState = getState()
    if (currentState.signup !== signup) {
      dispatch({
        type: SET_SIGNUP_STATE,
        payload: signup,
      })
    }
  }
}
