import { GET_OPEN_EUROPE_PENDING, GET_OPEN_EUROPE_SUCCESS, GET_OPEN_EUROPE_FAILED } from "consts/actions"

import stateHelper from "helpers/state"

const initialState = {
  is_france: true,
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_OPEN_EUROPE_PENDING:
      return stateHelper.pending()

    case GET_OPEN_EUROPE_SUCCESS:
      return action.payload

    case GET_OPEN_EUROPE_FAILED:
      return { error: action.payload, is_france: true }

    default:
      return state
  }
}
