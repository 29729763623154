import ProgramModel from "models/Program"

import FormatterHelper from "helpers/formatter"

import consts from "consts"

const missingParameter = () => {
  throw new Error("Missing parameter programId for model Episode")
}

export default class EpisodeModel extends ProgramModel {
  constructor(data, programId = missingParameter(), episodeSections, casting) {
    super(data, episodeSections, casting)
    this.programId = programId
  }

  getProgramId() {
    return this.programId
  }

  isEpisode() {
    return true
  }

  getUrl(locale) {
    return FormatterHelper.basic(consts.routes.program, {
      locale,
      programId: this.getProgramId(),
      channelId: this.getChannel().getId(),
      programSlug: this.getSlug(),
    })
  }
}
