import { get } from "lodash"
import { push } from "connected-react-router"

import { redirect } from "actions/routing"
import { executeApiActions, executeAdvancedApiAction, apiActionError } from "actions/api"
import { closeForm, closeInterstitial, setPaymentInterstitial, setMtvPayStep } from "actions/mtvPay"
import { validateGiftCode } from "actions/gift"
import { validatePromoCode, setProduct } from "actions/promoCode"
import { addFeedback } from "actions/feedbacks"
import { showAnimation } from "actions/animation"

import deviceForwarder from "helpers/deviceForwarder"
import routeHelper from "helpers/route"
import { getAPIEnv } from "helpers/api"

import consts from "consts"
import { RECURLY_3DS_TOKEN } from "consts/actions"
import { getOffers } from "../actions/offers"

export default {
  /**
   * Returns an array of actions corresponding to the given key for the
   * given action holder.
   *
   * Here is an example of an "action holder" format:
   * {
   *     actions: {
   *         detail: {
   *             template:"program_detail",
   *             type:"navigation",
   *             url:"...api url...",
   *         },
   *         play: {
   *             type: "play",
   *             url: "...api url...",
   *         },
   *         play_choices_modal: {
   *             payload: { ... },
   *             type:"show_actions_list_modal",
   *         },
   *     },
   *     main_cta: [],
   *     on_play: [ "play_choices_modal" ], // indicates that the action to execute onplay is "play_choices_modal" defined above
   *     on_click: [ "detail" ], // indicates that the action to execute onclick is "details" defined above
   * }
   *
   * "main_cta", "on_play" and "on_click" are usually defined at top level of entity in API response and allow to have classic UI interactions
   * like "play" and "click" to be handled in different ways by mapping them to an array of other actions transparently.
   * By transparently, we mean that from a client perspective, we should only handle top level action names like "on_play", "on_click" and other
   * "final" action types like "navigation", "play", "show_actions_list_modal" etc.
   * Intermediate action names like "detail", "play", "play_choices_modal" might be changed at any time by API without affecting clients
   * that only uses them to make a lookup in "actions" hash.
   *
   * @param {object} actionHolder - an object holding all actions and top level actions (see format above)
   * @param {string} actionName - a top level or intermediate action name
   * @return {array|bool} array of actions or false if no actions is found
   */
  getAction(actionHolder, actionName) {
    if (!actionHolder.actions) {
      return
    }

    let intermediateActionNames = actionHolder[actionName]

    // if actionName is a top level action like on_click or on_play, intermediateActionNames will be an array.
    // We must then map those intermediate action names to final actions objects
    if (Array.isArray(intermediateActionNames)) {
      return intermediateActionNames.map(intermediateActionName => actionHolder.actions[intermediateActionName])
    }
    // if actionName is NOT a top level action then it has to be an intermediate action name.
    // We must then retrieve the final action object and wrap in an array or return false if no action was found for this actionName
    else {
      return actionHolder.actions[actionName] ? [actionHolder.actions[actionName]] : false
    }
  },

  /**
   * Executes all the actions attached to an action holder for the given key
   *
   * This method should be used as much as possible since it takes care of
   * finding the action(s) to execute so you don't have to do that.
   *
   * @param {object} actionHolder - an object holding all actions and top level actions (see format above)
   * @param {string} actionName - a top level or intermediate action name
   * @param {func} fallback - Action dispatched if no actions is found or of an unknown template
   * @param {object} params - Additional parms passed to the action
   * @return {function} - thunk action
   */
  handleAction(actionHolder, actionName, fallback, params = {}) {
    return dispatch => {
      const actions = this.getAction(actionHolder, actionName)

      if (!actions) {
        typeof fallback === "function" && dispatch(fallback(params))
        return
      }

      actions.forEach(action => dispatch(this.executeAction(action, fallback, params)))
    }
  },

  /**
   * Executes a single action
   *
   * @param {object} action - a final action object (contains a type prop to identify action to execute and other props for data passing)
   * @param {func} fallback - Action dispatched if no actions is found or of an unknown template
   * @param {object} params - Additional parms passed to the action
   * @return {function} - thunk action
   */
  executeAction(action, fallback, params = {}) {
    return (dispatch, getState) => {
      switch (action.type) {
        case consts.apiActionTypes.navigation:
          const template = consts.templateMap[action.template]

          if (template) {
            dispatch(redirect(template, action))
          } else {
            console.error(`Unsupported action template "${action.template}" in action:`, action) // eslint-disable-line no-console
            typeof fallback === "function" && dispatch(fallback(params))
          }
          break

        case consts.apiActionTypes.api_action:
        case consts.apiActionTypes.login_with_password:
        case consts.apiActionTypes.submit:
        case consts.apiActionTypes.update_pin:
          dispatch(
            executeAdvancedApiAction(
              action.url,
              action.method,
              {
                ...action.payload,
                ...params,
              },
              payload => {
                if (payload.execute_actions) {
                  dispatch(executeApiActions(payload.execute_actions))
                }
                if (payload.type === "gift_validate" || payload.type === "gift_consume") {
                  dispatch(validateGiftCode(payload))
                }

                // used at first step, when validating code
                if (payload.type === "promocode_validate") {
                  dispatch(validatePromoCode(payload))
                }
              },
              payload => {
                const errorMessage = get(payload, "error.user_message", params.errorMessage)
                if (errorMessage && fallback) {
                  dispatch(fallback(errorMessage))
                }

                dispatch(apiActionError(action, payload, params))
              }
            )
          )
          break

        case consts.apiActionTypes.show_interstitial:
        case consts.apiActionTypes.show_dialog:
          if (action.section) {
            dispatch(setPaymentInterstitial(action.payload, action.section))
          } else {
            console.error(`Unsupported action type ${action.type} without section`, action) // eslint-disable-line no-console
          }
          break

        case consts.apiActionTypes.close:
          dispatch(closeInterstitial(action.section))
          if (routeHelper.isDesktopMode(window.location.href)) {
            deviceForwarder.forward([{ type: "close" }], "postWebViewAction", getState())
          }
          break

        case consts.apiActionTypes.reset:
          dispatch(closeForm(action.section))
          break

        case consts.apiActionTypes.forward:
          if (action.payload) {
            deviceForwarder.forward(action.payload, "postWebViewAction", getState())
          }
          break

        case consts.apiActionTypes.redirect:
          const regex = new RegExp("^(?:[a-z]+:)?//", "i")
          if (regex.test(action.url)) {
            window.location = action.url
          } else {
            dispatch(push(action.url))
          }
          break

        case consts.apiActionTypes.setProduct:
          dispatch(setProduct(action.payload))
          break

        // used after subscription to display congrats screen
        case consts.apiActionTypes.promocodeSuccess:
          dispatch(validatePromoCode(action.payload))
          break

        case consts.apiActionTypes.action_feedback:
          dispatch(addFeedback(action))
          break

        case consts.apiActionTypes.showAnimation:
          dispatch(showAnimation(action.payload))
          // execute actions if exists
          if (action.payload.execute_actions) {
            setTimeout(() => {
              action.payload.execute_actions.forEach(action => dispatch(this.executeAction(action)))
            }, action.payload.timeout)
          }
          break

        case consts.apiActionTypes.buy:
          dispatch(getOffers(action.platform, action.url))
          break

        case consts.apiActionTypes.payment_step:
          if (action.section) {
            dispatch(setMtvPayStep(action, action.section))
          } else {
            console.error(`Unsupported action type ${action.type} without section`, action) // eslint-disable-line no-console
          }
          break

        case consts.apiActionTypes.recurly_3ds:
          dispatch({
            type: RECURLY_3DS_TOKEN,
            payload: {
              ...params,
              token_3ds_id: action.payload.token_3ds_id,
            },
          })
          break

        case consts.apiActionTypes.payment_3ds:
          let callbackUrl = `${window.location.origin}/subscribe/${action.payload.EquivalenceCode}?page=offer&rate-plan=${action.payload.RatePlanID}`
          if (getState().appSettings.device === "desktop") {
            callbackUrl += "&mode=desktop"
          }
          if (window.location.search.includes("from=options")) {
            callbackUrl += "&from=options"
          }

          const env = getAPIEnv(consts.config.apiPath)

          var form = document.createElement("form")
          form.target = "_self"
          form.method = action.method
          form.action = action.url
          let parameters = {
            ...action.payload,
            TermUrl: `https://callback${env === "staging" ? "-staging" : ""}.molotov.tv/3dsecure/callback${encodeURIComponent(btoa(callbackUrl))}`,
          }

          for (var i in parameters) {
            if (parameters.hasOwnProperty(i)) {
              var input = document.createElement("input")
              input.type = "hidden"
              input.name = i
              input.value = parameters[i]
              form.appendChild(input)
            }
          }

          document.body.appendChild(form)
          form.submit()
          break

        default:
          console.error("Unsupported action type", action) // eslint-disable-line no-console
      }
    }
  },

  findButtonByOnClick(buttons, onClickIdentifier) {
    return buttons.find(button => button[consts.apiActionTypes.onClick].indexOf(onClickIdentifier) !== -1)
  },
}
