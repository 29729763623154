import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import HeaderSimple from "components/HeaderSimple"

import { getReferences } from "actions/references"

import styles from "./index.css"

@connect()
export default class PageOCSWrapper extends Component {
  static propTypes = {
    children: PropTypes.node,
    dispatch: PropTypes.func.isRequired,
  }

  static fetchData(dispatch) {
    return dispatch(getReferences())
  }

  componentDidMount() {
    // Hack to change body color because it is globally set in layout.css
    document.body.style.backgroundColor = "#1d1d1d"

    if (!global.serverRendered) {
      this.constructor.fetchData(this.props.dispatch, this.props, this.props)
    }
  }

  componentWillUnmount() {
    // Revert componentDidMount hack
    document.body.style.backgroundColor = ""
  }

  render() {
    return (
      <div className={styles.root}>
        <HeaderSimple />
        {this.props.children}
      </div>
    )
  }
}
