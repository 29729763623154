import React from "react"
import PropTypes from "prop-types"
import { Redirect, Route, Switch } from "react-router-dom"

import featureFlipHelpers from "helpers/featureFlip"

const RouterSwitch = ({ groupName, ...rest }) => {
  // WARNING
  // Keep this require here or routerConfig will be undefined
  const routerConfig = require("consts/router")
  const routesSet = routerConfig[groupName]

  if (!routesSet) {
    throw new Error(`Undefined set of routes ${groupName}, please check the router configuration in consts/router`)
  }

  return (
    <Switch>
      {routesSet.map((route, index) => {
        const paths = route.paths || [route.path]

        if (route.from && route.to) {
          return <Redirect from={route.from} to={route.to} {...route.routeProps} key={`route-${index}`} />
        }

        const filteredPath = paths.filter(path => featureFlipHelpers.isRouteFlipped(path))

        return filteredPath.map(path => {
          return (
            <Route
              path={path}
              render={props => (route.component ? <route.component {...props} {...route.componentProps} {...rest} /> : null)}
              {...route.routeProps}
              key={`route-${index}-${path}`}
            />
          )
        })
      })}
    </Switch>
  )
}

RouterSwitch.propTypes = {
  groupName: PropTypes.string.isRequired,
}

export default RouterSwitch
