import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

// Components
import FormLabel from "components/FormGroup/FormLabel"
import Select from "components/Select"

// Helpers
import dateHelper from "helpers/date"

// consts
import i18n from "consts/i18n"
import styles from "./index.css"

const BirthdayForm = ({ errors, errorMessages, onInputChange, customStyle = "", errorStyle = "" }) => (
  <div className={customStyle}>
    <FormLabel customStyle={styles.label} title={i18n.signupPage.age} />
    <div className={styles.selectsContainer}>
      <Select
        customStyle={styles.select}
        name="day"
        values={dateHelper.getDaysArray()}
        onChange={value => {
          onInputChange("day", value)
        }}
        placeholder={i18n.signupPage.day}
        error={errors.day}
      />
      <Select
        customStyle={styles.select}
        name="month"
        values={dateHelper.getMonthsArray()}
        onChange={value => {
          onInputChange("month", value)
        }}
        placeholder={i18n.signupPage.month}
        error={errors.month}
      />
      <Select
        customStyle={styles.select}
        name="year"
        values={dateHelper.getYearsArray()}
        onChange={value => {
          onInputChange("year", value)
        }}
        placeholder={i18n.signupPage.year}
        error={errors.year}
      />
      {errors.birthday && <div className={classNames(styles.errorMessage, errorStyle)}>{errorMessages.ERR_ACCOUNT_CREATION_MISSING_BIRTHDATE}</div>}
    </div>
  </div>
)

BirthdayForm.propTypes = {
  errors: PropTypes.object,
  errorMessages: PropTypes.object,
  onInputChange: PropTypes.func,
  customStyle: PropTypes.string,
  errorStyle: PropTypes.string,
}

export default BirthdayForm
