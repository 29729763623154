import { get } from "lodash"
import {
  CLOSE_MTVPAY_FORM,
  CLOSE_MTVPAY_INTERSTITIAL,
  GET_MTVPAY_PASSWORD_FORM_PENDING,
  GET_MTVPAY_PASSWORD_FORM_SUCCESS,
  GET_MTVPAY_PASSWORD_FORM_ERROR,
  GET_MTVPAY_PAYMENT_FORM_PENDING,
  GET_MTVPAY_PAYMENT_FORM_SUCCESS,
  GET_MTVPAY_PAYMENT_FORM_ERROR,
  GOT_BUY_ACTION_ERROR,
  LOGOUT,
  MTVPAY_PAYMENT_PENDING,
  MTVPAY_PAYMENT_SUCCESS,
  MTVPAY_PAYMENT_ERROR,
  PRODUCT_PAYMENT_INSTANCIATED,
  SET_MTVPAY_INTERSTITIAL,
  SET_MTVPAY_PASSWORD_ERROR,
  GET_MTV_PAY_STEP_SUCCESS,
  RECURLY_3DS_TOKEN,
} from "consts/actions"

import { productInitialState, updateProduct } from "helpers/payment"

const initialState = {}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case PRODUCT_PAYMENT_INSTANCIATED:
      return updateProduct(state, action.equivalenceCode, {
        paymentForm: null,
        passwordForm: null,
        step: null,
      })
    case GET_MTVPAY_PAYMENT_FORM_PENDING:
    case GET_MTVPAY_PASSWORD_FORM_PENDING:
      return updateProduct(state, action.equivalenceCode, {
        interstitial: null,
        paymentForm: null,
        passwordForm: null,
        step: null,
      })

    case GET_MTVPAY_PAYMENT_FORM_SUCCESS:
      return updateProduct(state, action.equivalenceCode, {
        paymentForm: action.payload,
        reinsurance: { ...action?.payload?.reinsurance, logoSmall: action?.payload?.image_bundle?.logo?.small },
      })

    case GET_MTVPAY_PAYMENT_FORM_ERROR:
      return updateProduct(state, action.equivalenceCode, {
        paymentForm: {
          error: action.payload,
        },
      })

    case GET_MTVPAY_PASSWORD_FORM_SUCCESS:
      return updateProduct(state, action.equivalenceCode, {
        passwordForm: action.payload,
        reinsurance: { ...action?.payload?.reinsurance, logoSmall: action?.payload?.image_bundle?.logo?.small },
      })

    case GET_MTVPAY_PASSWORD_FORM_ERROR:
      return updateProduct(state, action.equivalenceCode, {
        passwordForm: {
          error: action.payload,
        },
      })

    case GOT_BUY_ACTION_ERROR:
      return {
        ...state,
        buyActionError: action.payload,
      }

    case MTVPAY_PAYMENT_PENDING:
      return updateProduct(state, action.equivalenceCode, {
        paymentPending: true,
      })

    case MTVPAY_PAYMENT_SUCCESS:
      return updateProduct(state, action.equivalenceCode, {
        paymentPending: false,
        subscriptionSuccess: true,
      })

    case MTVPAY_PAYMENT_ERROR:
      return updateProduct(state, action.equivalenceCode, {
        paymentPending: false,
        error: action.payload.error,
        recurly_3d_token: undefined,
      })

    case SET_MTVPAY_INTERSTITIAL:
      return updateProduct(state, action.equivalenceCode, {
        interstitial: action.payload,
        passwordForm: get(state[action.equivalenceCode], "passwordForm")
          ? {
              error: null,
            }
          : null,
        paymentForm: get(state[action.equivalenceCode], "paymentForm")
          ? {
              error: null,
            }
          : null,
        passwordError: false,
      })

    case CLOSE_MTVPAY_FORM:
      return updateProduct(state, action.equivalenceCode, {
        paymentForm: null,
        passwordForm: null,
      })

    case CLOSE_MTVPAY_INTERSTITIAL:
      return updateProduct(state, action.equivalenceCode, {
        interstitial: null,
      })

    case SET_MTVPAY_PASSWORD_ERROR:
      return updateProduct(state, action.equivalenceCode, {
        passwordError: action.payload,
      })

    case GET_MTV_PAY_STEP_SUCCESS:
      return updateProduct(state, action.equivalenceCode, {
        step: action.payload,
        paymentForm: null,
        passwordForm: null,
        interstitial: null,
        buyActionError: null,
        passwordError: null,
      })

    case RECURLY_3DS_TOKEN:
      if (action.payload?.equivalenceCode) {
        return updateProduct(state, action.payload.equivalenceCode, {
          paymentForm: {
            ...get(state[action.payload.equivalenceCode], "paymentForm"),
            recurly_3d_token: action.payload.token_3ds_id,
          },
        })
      }
      return state

    case LOGOUT:
      return productInitialState

    default:
      return state
  }
}
