import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

const IconLogoWithFubo = ({ customStyle = null }) => (
  <svg width="196" height="73" viewBox="0 0 196 73" className={classNames(customStyle)}>
    <path
      fillRule="evenodd"
      fill="#fff"
      d="M162.6,55.3c1.5,0,2.7,1.1,2.7,2.6c0,1.5-1.1,2.5-2.7,2.5c-1.6,0-2.7-1.1-2.7-2.5
      C159.9,56.3,161.1,55.3,162.6,55.3z M17.5,5.3c0.8-0.4,2.6-0.3,3.5-0.2c0.7,0.1,1.8,0.4,2.2,0.9c0.2,0.3,0,1.2-0.1,1.5l-0.2,1
      c-0.2,1.2-0.5,3-0.8,5l-2.4,13.8c-0.2,1.3-1,6.6-1.2,7.8l0.2-0.8c1.7-5.3,4.7-13.4,7.8-17.6c1.2-1.6,2.9-3.5,4.9-4.5
      c1.1-0.5,1.9-0.7,3.1-0.4l0.2,0.1l0,0c0.2,0.1,0.5,0.2,0.7,0.3c0.9,0.4,2.2,1.4,2.5,2c0.2,0.5-0.1,1.4-0.1,1.7L38,16.2
      c-0.5,3.1-2.8,12.7-3.4,16c1.8-5.1,4.7-12.1,8.2-16.5c1.2-1.5,3-3.4,5-4.4c1.1-0.5,2.1-0.7,3.5-0.2l0.2,0.1c0,0,0.1,0,0.1,0
      c1.1,0.5,2.2,1.2,2.6,1.7c0.7,0.8,0.5,2.1,0.4,2.6l-1.4,7c-0.5,2.4-1,4.8-1.3,6.6l-0.1,0.4c-1.2,6.6-2.1,14.8-2.2,18.6
      c-0.2,3.9,0.4,7.7,2.4,11c0.6,1-2.5,1.2-3.4,1c-4.4-1.3-6.7-5.1-6.6-10c0.1-5.4,2.2-16.8,3.6-24.1l1.1-5.6c0.1-0.5,0.2-1.1,0.4-1.9
      c-6.5,6.3-11.8,20.1-13.3,26c-0.3,1.3-3.7,2.5-4.6,2.7c-0.1,0-0.1,0-0.2,0l-0.2,0c-1.7-0.1-1.4-4.3-1-7.7l0.3-2.6
      c0.3-2.7,1.8-12.3,2.8-17.6C23.6,26.7,18.6,44.3,17,50.9c-0.3,1.3-3.6,2.6-4.5,2.9c-0.1,0-0.1,0-0.2,0l-0.1,0
      c-2.3,0.3-1.9-4.6-1.5-7l0.2-1.7c0.7-4.8,3.3-25.1,4.3-31.6c-2.1,2.3-4.6,5.9-6.3,8.5c-1.3,2-2.4-1.3-1.7-2.5l0.4-0.8
      c0.7-1.3,1.4-2.5,2.6-4.6l0.7-1.2c1-1.7,4.2-6.3,6.2-7.5l0.1,0l0,0L17.5,5.3C17.4,5.3,17.4,5.3,17.5,5.3L17.5,5.3z M180.9,42.2
      l3.6,9l3.5-9h4.8v1.1l-7,16.5h-2.5l-5.2-12l2.5-1h-6.5v13h-5v-13h-4.2v-4.5H180.9z M87.9,0.1l0.4,0c1.9-0.2,2.9,0.1,2.8,0.7L88.6,13
      c-0.9,4.5-1.7,9-2.1,11.2l-0.1,0.6c-0.3,2-1.1,5.4-1.2,8.1l0,0.9c0,1.5,0,4,1.6,4.1c0.9,0.1,2.1-0.8,2.8-1.5
      c0.2-0.2,0.4-0.4,0.6-0.6c-0.3-1.8-0.4-3.8-0.2-5.5c0.3-2.9,1.2-5.7,2.3-8.5c1.1-2.7,3.3-5.8,5.8-7.7c1.3-1,3-1.7,4.6-1.9
      c2.8-0.5,3.6,0.5,3.6,2.2c0.7-0.3,3.5-0.5,4.7,2.3c0.4,0.8,0.7,2,0.8,3.8c-0.5,0.5-1,0.9-1.6,1.2c2.7-0.5,6-2.8,7.5-9.1l0.1-0.3
      c0.1-0.3,0.2-0.9,0.3-1.5l-0.5,0.1l0,0c-2.9,0.5-5.5,1.1-8.4,2.2c-1.3,0.5-0.3-3.1,1.2-3.6c1.5-0.7,3.2-1.2,5.7-1.8
      c0.9-0.2,1.9-0.4,2.9-0.6l0.2-1c0.3-1.3,0.6-2.4,1-3.9c0.4-1.5,1.8-1.9,3.9-1.9l0.2,0c2.2-0.1,3.3,0.2,3.2,0.9l-0.1,0.4
      c-0.2,0.7-0.6,2.2-1.1,4.5c3.4-0.4,7-0.6,10.5-0.6c10.6,0,18.9,1.9,21.7,6.1c1.6,2.4,1.8,6.2,1.6,8.7c-0.5,7.7-3,15.9-5,21.3
      c2-1,4.8-3.5,7.3-6.5c3.7-4.5,6.3-10.3,6.6-15.1c0.2-2.9,7.7-3.5,7.7-1.5c0,1.7-1.8,6.8-4.6,11.4c-5.1,8.3-14.1,16.2-16.5,16.9
      c-1,0.3-2.1,0.3-3.6-0.7c-1.1-0.7-2.1-1.5-2.5-2c-0.8-1-0.7-2.2-0.7-2.6c0.1-1.1,3.2-7.2,4.4-15.6l0.1-0.4
      c0.5-3.8,0.7-10.4-4.1-12.4c-2-0.7-3.8-1.3-5.4-1.6l-0.5-0.1c-4.1-0.8-10.4-1.7-17.9-1.2l-0.4,1.5c-1.6,6.1-2.6,10.9-2.9,12.7l0,0.2
      c-0.4,1.9-1.2,5.5-1.4,8.1l0,0.8c-0.1,1.5-0.1,4.1,1.5,4.4c0.9,0.1,2.1-0.6,2.9-1.3c0.3-0.2,0.5-0.4,0.8-0.7c-0.2-1.6-0.2-3.4,0-4.9
      c0.4-2.9,1.2-5.6,2.3-8.5c1.1-2.6,3.3-5.8,5.8-7.7c1.3-1,3-1.6,4.6-1.9c2.8-0.5,3.6,0.5,3.6,2.2c0.7-0.3,3.5-0.4,4.7,2.3
      c0.4,0.8,0.7,2,0.7,4c-0.5,0.5-1,0.9-1.5,1.2c1.8-0.4,3.5-1.7,4.8-4.6l0.1-0.3c0.7-1.5,1,1.5,0.5,2.6c-0.9,2.3-2.3,3.9-4.3,4.7
      c-0.1,0.7-0.2,1.4-0.4,2.1c-0.9,3.7-2,6.7-4.2,9.7c-2.6,3.4-5.2,5.1-8,5.6c-4,0.8-6.9-1.1-8-4.1c-0.3,0.4-0.7,0.7-1,1
      c-1.9,1.7-3.9,2.9-6.4,2.4c-1.8-0.4-3.4-1.5-4.2-3.5c-0.7-1.8-1-4-0.7-7.2l0-0.4c0.2-2.1,0.8-5.1,1.8-9.6c-1.2,1.6-2.8,2.6-4.5,3.3
      c-0.1,0.7-0.2,1.4-0.4,2.2c-0.8,3.8-2,6.8-4.2,9.8c-2.5,3.5-5.2,5.2-8,5.7c-3.8,0.7-6.5-0.9-7.8-3.6c-0.3,0.3-0.6,0.7-0.9,0.9
      c-2,1.9-3.7,3.2-6.3,2.8C82.1,42,80.5,41,79.6,39c-0.8-1.7-1.1-3.9-0.8-7.1l0-0.6c0.2-2.2,0.6-5.5,1.4-10.1
      c-1.1,1.6-2.5,2.7-4.1,3.4c-0.1,0.7-0.2,1.4-0.4,2.2c-0.8,3.8-1.9,6.8-4.1,9.8c-2.5,3.5-5.1,5.2-8,5.8c-4,0.8-6.9-1-8.1-4
      c-0.9-2.1-1.1-5.2-0.8-7.6c0.3-2.9,1.1-5.7,2.2-8.5c1.1-2.7,3.2-5.9,5.7-7.8c1.3-1,2.9-1.7,4.6-2c2.8-0.5,3.6,0.5,3.6,2.2
      c0.7-0.3,3.5-0.5,4.8,2.3l0.1,0.2c0.3,0.8,0.7,1.9,0.7,3.7c-0.4,0.4-0.8,0.8-1.3,1c2.2-0.7,4.8-2.8,6.3-7.7l0.4-2.3
      C82.5,9.1,83,6,83.8,2.3C84.2,0.8,85.6,0.3,87.9,0.1z M139.9,18.7c-0.8-0.1-2.6,0.6-3.7,1.9c-1.5,1.7-2.3,3.2-3.2,5.8l-0.1,0.3
      c-1.8,5.4-1.5,9.5,0.5,11c1.3,1,3.5-0.2,5.5-3.1c1.5-2.1,2.6-5,3.1-8.2c1.3-0.1,2.7-0.4,4-1.1c-1.6,0.5-3.2,0.6-4.7,0.4
      c-1.5-0.2-0.2-4.4,0.9-3.7c-0.2-1.3-0.9-1.8-1.7-2l-0.1,0C139.7,19.8,139.4,19.4,139.9,18.7z M68.9,18.5c-0.8-0.1-2.6,0.6-3.7,2
      c-1.5,1.8-2.3,3.4-3.2,6.2c-1.7,5.4-1.4,9.6,0.7,11.1c1.3,1,3.5-0.3,5.4-3.1c1.4-2.1,2.5-5.2,3-8.4c1.3-0.1,2.6-0.4,3.9-1.1
      c-1.5,0.5-3.1,0.5-4.5,0.3c-1.8-0.3-0.2-3.9,0.7-3.8c-0.3-1.1-0.9-1.6-1.6-1.8l-0.1,0C68.8,19.6,68.5,19.2,68.9,18.5z M104.2,18.1
      c-0.8-0.1-2.6,0.6-3.7,1.9c-1.5,1.8-2.3,3.4-3.2,6.2c-1.7,5.4-1.5,9.6,0.6,11.1c1.3,1,3.5-0.2,5.5-3.1c1.5-2.1,2.6-5.2,3.1-8.5
      c1.2-0.1,2.4-0.4,3.6-0.9c-1.4,0.3-2.9,0.4-4.2,0.2c-1.4-0.2-0.3-4.1,0.7-3.8c-0.3-1.1-0.9-1.6-1.6-1.8l-0.1,0
      C104.1,19.3,103.8,18.8,104.2,18.1z"
    />
    <path
      style={{ opacity: 0.8 }}
      fill="#fff"
      d="M75,62.6c0.7,0,1.3,0.2,1.9,0.5c0.6,0.3,1,0.8,1.4,1.4c0.3,0.6,0.5,1.2,0.5,2c0,0.7-0.2,1.4-0.5,2 c-0.3,0.6-0.8,1.1-1.4,1.4c-0.6,0.3-1.2,0.5-1.9,0.5c-0.6,0-1.2-0.1-1.6-0.4c-0.5-0.3-0.9-0.6-1.2-1.1v1.3h-1.3V59.9h1.3v4.2 c0.3-0.5,0.7-0.8,1.2-1.1C73.8,62.8,74.3,62.6,75,62.6z M74.8,69.2c0.5,0,0.9-0.1,1.3-0.3c0.4-0.2,0.7-0.6,0.9-1 c0.2-0.4,0.4-0.9,0.4-1.3c0-0.5-0.1-0.9-0.4-1.3c-0.2-0.4-0.5-0.7-0.9-0.9c-0.4-0.2-0.8-0.4-1.3-0.4c-0.5,0-0.9,0.1-1.3,0.4 c-0.4,0.2-0.7,0.5-0.9,0.9c-0.2,0.4-0.3,0.9-0.3,1.3c0,0.5,0.1,0.9,0.3,1.3c0.2,0.4,0.6,0.7,0.9,1C73.9,69.1,74.3,69.2,74.8,69.2z M85.9,62.8l-3.6,10.3h-1.4l1.1-3l-2.9-7.3h1.4l2.2,5.5l1.9-5.5H85.9z"
    />
    <path
      fillRule="evenodd"
      fill="#ffc107"
      d="M153.4,51.1c4.4,2.6,2.1,7.3,0.7,9.1c-5.8-6.3-29.3-12.8-73.1-10.9c-9.7,0.4-20.3,1.6-29.1,3.2l-0.1-0.5 c-0.1-0.6-0.2-1.5-0.1-2.2c9.5-1.8,18.1-3,27.6-3.8C110.3,43.2,141.9,44.4,153.4,51.1z"
    />
    <path
      fillRule="evenodd"
      fill="#ffc107"
      d="M40.5,52l0,0.3c0,0.6,0.1,1.2,0.4,2.2c-5.2,1.1-10.8,2.4-16.2,3.8l-1.5,0.4c-2.4,0.6-5-0.5-2.3-1.4 C26.5,55.5,33.1,53.7,40.5,52z"
    />
    <g style={{ opacity: 0.8 }} fill="#fff">
      <path d="M103.7,60.9h-2.4v4.9c0,0,0,0,0,0c0,0.9,0,2.4-1.7,2.4c-1.7,0-1.8-1.6-1.7-2.4v-4.9h-2.4v4.9 c0,3.1,1.1,4.7,4.1,4.7c0.9,0,1.4-0.3,1.8-0.6v0.4h2.4V60.9z" />
      <path d="M94.4,57.4c-2.7-0.3-3.3,0.3-3.8,1c-0.4,0.6-0.5,1.3-0.5,1.9v0.7h-1.3v2.2h1.4v7.1h2.4v-7.1h1.8v-2.2h-1.8 v-0.6c0-0.6,0.5-0.7,0.8-0.7l0.5,0L94.4,57.4z" />
      <path d="M107,57.5l-2.4,0.5v12.3h2.4v-0.4c0.7,0.4,1.5,0.6,2.4,0.6c1.1,0,2.1-0.4,3-1.1c-0.5-0.6-0.9-1.3-1.2-2.1 c-0.4,0.6-1.1,1-1.8,1c-1.3,0-2.4-1.2-2.4-2.7c0-1.5,1.1-2.7,2.4-2.7c1.3,0,2.4,1.2,2.4,2.7v0c0,1.2,0.5,2.4,1.2,3.2l0,0 c0,0,0.1,0.1,0.1,0.1c0.5,0.5,1.2,1,1.9,1.2v0c0.5,0.2,1,0.3,1.6,0.3c2.7,0,4.8-2.2,4.8-4.9c0-2.7-2.2-4.9-4.8-4.9 c-1.1,0-2.2,0.4-3,1.1c0.5,0.6,0.9,1.3,1.2,2.1c0.4-0.6,1.1-0.9,1.8-0.9c1.3,0,2.4,1.2,2.4,2.6c0,1.4-1.1,2.6-2.4,2.6 c-1.3,0-2.4-1.2-2.4-2.6c0-1.2-0.5-2.4-1.2-3.2c-0.9-1-2.1-1.6-3.6-1.6c-0.9,0-1.7,0.2-2.4,0.7V57.5z" />
    </g>
  </svg>
)

IconLogoWithFubo.propTypes = {
  customStyle: PropTypes.string,
}

export default IconLogoWithFubo
