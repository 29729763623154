import { RESET_ANIMATION, SHOW_ANIMATION } from "consts/actions"

const initialState = {}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case RESET_ANIMATION:
      return initialState

    case SHOW_ANIMATION:
      return action.payload

    default:
      return state
  }
}
