export default {
  lastRoute: null,
  currentRoute: null,

  setHistory(currentRoute) {
    this.lastRoute = this.currentRoute
    this.currentRoute = currentRoute
  },

  getLastRoute() {
    return this.lastRoute
  },
}
