import React from "react"
import PropTypes from "prop-types"
import { useSelector } from "react-redux"

import ProductLegacy from "./productLegacy"
import ProductDesktop from "./productDesktop"
import ProductPeriodicity from "containers/Product/ProductPeriodicity"

const Product = props => {
  const isDesktop = useSelector(state => state.appSettings.device === "desktop")

  const getProductComponent = () => {
    if (props.periodicity && Object.keys(props.periodicity).length > 0 && !props.periodicity.selected) {
      return <ProductPeriodicity {...props} />
    }
    if (isDesktop) {
      return <ProductDesktop {...props} />
    }
    return <ProductLegacy {...props} />
  }

  return getProductComponent()
}

Product.propTypes = {
  isDesktop: PropTypes.bool,
  periodicity: PropTypes.object,
  fromOptionPage: PropTypes.bool,
}

export default Product
