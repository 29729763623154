import { createSelector } from "reselect"
import { get } from "lodash"

import routeHelper from "helpers/route"

const selectSearch = state => get(state, "location.search", "")

export const selectQuery = createSelector(
  [selectSearch],
  search => {
    return search ? routeHelper.queryToObject(search) : {}
  }
)
