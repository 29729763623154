import { isArray } from "lodash"

import { GET_SCHEDULED_METRICS_PENDING, GET_SCHEDULED_METRICS_SUCCESS, GET_SCHEDULED_METRICS_FAILED } from "consts/actions"

import stateHelper from "helpers/state"

import consts from "consts"

export function get() {
  return {
    accessTokenRequired: true,
    request: {
      url: consts.endpoints.scheduledMetrics,
      method: "GET",
    },
    onStart: () => {
      return {
        type: GET_SCHEDULED_METRICS_PENDING,
      }
    },
    onSuccess: payload => {
      return {
        type: GET_SCHEDULED_METRICS_SUCCESS,
        payload: isArray(payload) ? payload : [],
      }
    },
    onError: payload => {
      return {
        type: GET_SCHEDULED_METRICS_FAILED,
        payload: stateHelper.errorPayload(payload.user_message),
      }
    },
  }
}
