import React from "react"
import Loadable from "react-loadable"
import { flatten, values } from "lodash"

import Loader from "components/Loader"

import consts from "consts"

const routeHelpers = {
  isCurrentPageSalto(pathname) {
    return /\/s\/welcome$/.test(pathname)
  },

  isOfferIdPage(pathname) {
    return /^\/offers\/[0-9]+/.test(pathname)
  },

  isPublicOffers(pathname) {
    return /^\/offers(#|\/?$)/.test(pathname)
  },

  isSingleOfferPage(pathname) {
    return (
      routeHelpers.isOfferIdPage(pathname) ||
      /^\/group-offers\/[a-zA-Z0-9_-]*/.test(pathname) ||
      /^\/account\/group-offers\/[a-zA-Z0-9_-]*/.test(pathname) ||
      /^\/offers\/channel\/[0-9]*/.test(pathname) ||
      /^\/offers\/bookmarks/.test(pathname) ||
      /^\/offers\/url/.test(pathname) ||
      /^\/gift/.test(pathname) ||
      /^\/promo/.test(pathname) ||
      /^\/account\/unsubscribe/.test(pathname) ||
      /^\/account\/purge/.test(pathname)
    )
  },

  isHardwarePage(pathname) {
    return /^\/hardware/.test(pathname)
  },

  isTravelPage(pathname) {
    return /^\/tv-francaise-etranger/.test(pathname)
  },

  isStrapiLandingPage(pathname) {
    return /^\/s\//.test(pathname)
  },

  isGiftPage(pathname) {
    return /^\/gift/.test(pathname)
  },

  isGiftListPage(pathname) {
    return /^\/gift\/list/.test(pathname)
  },

  isGiftReceiverPage(pathname) {
    return /^\/gift\/code/.test(pathname)
  },

  isTheMatchPage(pathname) {
    return pathname === consts.routes.theMatch
  },

  isOPChromecastPage(pathname) {
    return pathname === consts.routes.opChromecast
  },

  isAccountPage(pathname) {
    return /^\/account/.test(pathname)
  },

  isOfferPageBookmark(pathname) {
    return /^\/offers\/bookmarks/.test(pathname) && !/^\/offers\/channel\/[0-9]*/.test(pathname)
  },

  isNavPage(pathname) {
    return /^\/nav/.test(pathname)
  },

  isDeeplinkPage(pathname) {
    return /^\/deeplink/.test(pathname)
  },

  isDevicePage(pathname) {
    return /^\/tv-/.test(pathname) || /^\/regarder-tv-/.test(pathname) || /^\/source-tv-/.test(pathname)
  },

  isOnMyTvPage(pathname) {
    return /^\/account\/tv/.test(pathname) || this.isOnPublicMyTvPage(pathname)
  },

  isOnPublicMyTvPage(pathname) {
    return /^\/tv\/?$/.test(pathname)
  },

  isPasswordChangePage(pathname) {
    return /^\/change-password\//.test(pathname)
  },

  isChannelReplayPage(pathname) {
    return /replay$/.test(pathname)
  },

  isPublicProductIframePage(pathname) {
    return /^\/group-offers\/[a-zA-Z0-9_-]*$/.test(pathname)
  },

  isCampaignPage(pathname) {
    return /\/campaign$/.test(pathname)
  },

  isLinkPage(pathname) {
    return /\/link$/.test(pathname)
  },

  isOAuthPage(pathname) {
    return /\/oauth\/dialog$/.test(pathname)
  },

  isWrapperFullPage(pathname) {
    return /\/mytho$/.test(pathname) || /\/westworld$/.test(pathname)
  },

  isPromoCodePage(pathname) {
    return /^\/promo/.test(pathname)
  },

  isWinbackPage(pathname) {
    return /^\/account\/unsubscribe/.test(pathname) || /^\/account\/purge/.test(pathname)
  },

  isSubscribePage(pathname) {
    return /^\/subscribe/.test(pathname)
  },

  isSubscribeChoseOption(pathname) {
    return /^\/subscribe\/COPT/.test(pathname)
  },

  isDesktopMode(pathname) {
    return /mode=desktop/.test(pathname)
  },

  getAdGroupFromPathname(pathname) {
    // The param we're interested in is the second one in the path
    // ex:
    //     /fr_fr/p/39017 -> "p"
    //     /fr_fr/programme-tv -> "programme-tv"
    //     /fr_FR/c/46/tf1 -> "c"
    const pageMatch = pathname.match(/^\/[^\/]+\/([^\/]+)\/?/)

    return pageMatch ? pageMatch[1] || "unknown" : "unknown"
  },

  getAdGroupFromQuery(query) {
    let typeId = ""

    if (query.type) {
      typeId += query.type
    }

    if (query.id) {
      typeId += "-" + query.id
    }

    return typeId || "unknown"
  },

  getDownloadURL(campaign = "marketing_site", adgroup = null) {
    let url = consts.externalLink.adjustDownload + "&campaign=" + campaign

    if (adgroup) {
      url += "&adgroup=" + adgroup
    }

    return url
  },

  getFrameTypeFromQuery(query) {
    if (query && query["molotov-agent"]) {
      try {
        const agent = JSON.parse(query["molotov-agent"])
        if (agent && agent.type === "tv" && agent.os === "Android") {
          return consts.frameType.tv
        }
      } catch (err) {
        // Do nothing, we just don't crash
      }
    }
    return undefined
  },

  getBaseUriFromLocation(location) {
    return `${location.protocol}//${location.host}`
  },

  getSEOUri(query = {}) {
    switch (query.type) {
      case "program":
        return consts.routes.program
          .replace(":locale", "fr_FR")
          .replace(":programId-:channelId", `${query.id}-${query.channel_id}`)
          .replace(":programSlug?", query.slug)
      default:
        return "/"
    }
  },

  getDeeplinkFromLocation(location = {}) {
    return `${consts.deeplinkPath}${location.pathname}${location.search}`
  },

  getDeeplinkUriFromProgram(program, query) {
    return `${consts.deeplinkPath}/deeplink?type=program&id=${program.getId()}&channel_id=${program.getChannelId()}${
      query.action ? `&action=${query.action}` : ""
    }`
  },

  getDeeplinkUriFromEpisode(episode, query) {
    return `${
      consts.deeplinkPath
    }/deeplink?type=episode&id=${episode.getId()}&program_id=${episode.getProgramId()}&channel_id=${episode.getChannelId()}${
      query.action ? `&action=${query.action}` : ""
    }`
  },

  getDeeplinkUriFromPerson(personId) {
    return `${consts.deeplinkPath}/deeplink?type=person&id=${personId}`
  },

  getFirebaseUri(firebaseLinkId = "") {
    return `${consts.externalLink.firebasePath}${firebaseLinkId}`
  },

  // returns the current page, 0 indexed
  getCurrentPage(page) {
    let currentPage = parseInt(page, 10)

    if (isNaN(currentPage)) {
      currentPage = 0
    } else {
      currentPage = currentPage > 0 ? currentPage - 1 : currentPage
    }

    return currentPage
  },

  stripQuestionMark(input) {
    return input.indexOf("?") === 0 ? input.slice(1) : input
  },

  queryToObject(query) {
    const cleanedQuery = routeHelpers.stripQuestionMark(query)
    const keyValue = cleanedQuery.split("&")
    const result = {}

    cleanedQuery.length &&
      keyValue.forEach(chunk => {
        const c = chunk.split("=")
        const key = c[0]
        result[key] = decodeURIComponent(c[1])
      })

    return result
  },

  objectToQuery(query) {
    return Object.entries(query).reduce((prev, next, index, array) => {
      // adding '&' symbol between values, except for the last one
      const amp = index !== array.length - 1 ? "&" : ""
      const queryKey = next[0]
      const queryValue = next[1]

      return prev.concat(`${queryKey}=${queryValue}${amp}`)
    }, ``)
  },

  // transform the router configuration into a one-depth array
  // also transforms all routes that have multiple paths to
  // multiple routes with a single path
  flattenRouterConfig(routerConfig) {
    return flatten(
      values(routerConfig).reduce((acc, routeConfig) => {
        const routes = routeConfig.reduce((x, route) => {
          if (route.paths) {
            route.paths.forEach(path => {
              x.push({
                ...route,
                path,
              })
            })
          } else {
            x.push(route)
          }

          return x
        }, [])

        acc.push(routes)

        return acc
      }, [])
    )
  },

  /**
   * Returns a route configuration for the router config
   *
   * @param {function} componentImport - Must return a dynamic import
   * @param {string} modulePath - Path to the module that will be required server-side
   * @return {object} -
   */
  lazyLoadRoute(componentImport, modulePath) {
    const LoadableComponent = Loadable({
      loader: componentImport,
      loading: Loader,
    })

    const container = props => <LoadableComponent {...props} />

    return {
      component: container,
      modulePath,
      loadable: LoadableComponent,
    }
  },

  getFullUrl(pathname, query = {}) {
    const queryString = Object.keys(query).reduce((acc, key) => {
      return acc.length === 0 ? `?${key}=${query[key]}` : `${acc}&${key}=${query[key]}`
    }, "")

    return `${consts.externalLink.landing}${pathname}${queryString}`
  },

  completeUrl(url) {
    return /^http/.test(url) ? url : `http://${url}`
  },
}

export default routeHelpers
