import { createSelector } from "reselect"

import { selectChannels as selectChannelsFromReferences } from "selectors/references"
import { selectSection } from "selectors/section"

const selectChannels = state => state.channels

const getChannelParts = (channels, channelsKey, channelsFromReferences) => {
  let description
  let mainProgram
  const sections = []

  channels[channelsKey].sections &&
    channels[channelsKey].sections.forEach(section => {
      if (section.description) {
        description = section
        return
      }

      const mapItem = selectSection(section, { channels: channelsFromReferences })

      if (section.slug === "live" || section.slug === "one-replay") {
        mainProgram = mapItem.getItems()[0]
      } else {
        sections.push(mapItem)
      }
    })

  return {
    description,
    mainProgram,
    sections,
  }
}

export default createSelector(
  [selectChannels, selectChannelsFromReferences],
  (channels, channelsFromReferences) => {
    if (channels.direct.error) {
      return {
        error: channels.error,
      }
    }

    if (channels.direct.pending || !channels.direct.sections || !channelsFromReferences.length) {
      return { pending: true }
    }

    const { sections: mapItemsDirect, mainProgram: mainDirectProgram, description: directDescription } = getChannelParts(
      channels,
      "direct",
      channelsFromReferences
    )
    const { sections: mapItemsReplay, mainProgram: mainReplayProgram, description: replayDescription } = getChannelParts(
      channels,
      "replay",
      channelsFromReferences
    )

    return {
      ...channels,
      directDescription,
      replayDescription,
      getMapItemDirect() {
        return mapItemsDirect
      },
      getMapItemReplay() {
        return mapItemsReplay
      },
      mainDirectProgram,
      mainReplayProgram,
    }
  }
)
