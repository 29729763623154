import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import classNames from "classnames"
import { get } from "lodash"
import { push } from "connected-react-router"

import ProductInterstitial from "./ProductInterstitial"
import ProductPanelDesktop from "./ProductPanel/desktop"
import ProductMain from "./ProductMain"
import ProductPayment from "./ProductPayment"
import ProductPassword from "./ProductPassword"
import ProductConfirmationDesktop from "./ProductConfirmationDesktop"
import ProductDesktopHeader from "./productDesktopHeader"
import ProductWarning from "./ProductWarning"

import selectAuthentication from "selectors/authentication"
import selectProductBookmarkConstraints from "selectors/productBookmarkConstraints"
import { selectCheckoutProduct } from "selectors/products"
import { selectPaymentStep } from "selectors/mtvPay"

import { checkout as checkoutPayment } from "actions/products"
import { post as postSubscription, remove as removeSubscription, restore as restoreSubscription } from "actions/subscriptions"
import { getBookmarkConstraintsForProduct } from "actions/productBookmarkConstraints"

import formatterHelper from "helpers/formatter"

import TrackingSegment from "managers/segmentManager"

import consts from "consts"
import routes from "consts/routes"
import i18n from "consts/i18n"
import styles from "./index.css"

const STEPS = {
  PRODUCT: "PRODUCT",
  ADD_PAYMENT: "ADD_PAYMENT",
  PASSWORD: "PASSWORD",
  RESUBSCRIBE_PASSWORD: "RESUBSCRIBE_PASSWORD",
  CONFIRMATION: "CONFIRMATION",
  RESILIATION_WARNING: "RESILIATION_WARNING",
  RESILIATION_PASSWORD: "RESILIATION_PASSWORD",
  RESILIATION_CONFIRMATION: "RESILIATION_CONFIRMATION",
  OPTINS: "OPTINS",
}

const SUBSCRIBE_STEPS = [STEPS.OPTINS, STEPS.ADD_PAYMENT, STEPS.PASSWORD, STEPS.CONFIRMATION]

const RESUBSCRIBE_STEPS = [STEPS.ADD_PAYMENT, STEPS.RESUBSCRIBE_PASSWORD, STEPS.CONFIRMATION]

const UNSUBSCRIBE_STEPS = [STEPS.RESILIATION_WARNING, STEPS.RESILIATION_PASSWORD, STEPS.RESILIATION_CONFIRMATION]

@connect((state, ownProps) => ({
  appSettings: state.appSettings,
  isAuthenticated: selectAuthentication(state).isAuthenticated,
  paymentStep: selectPaymentStep(state, ownProps.product.equivalence_code),
  productBookmarkConstraints: selectProductBookmarkConstraints(ownProps.product.id)(state),
  productCheckout: selectCheckoutProduct(state),
  session: state.session,
  modalDisplayed: state.modal,
  payment: state.mtvPay.payment,
}))
export default class Product extends Component {
  static propTypes = {
    appSettings: PropTypes.object,
    customStyle: PropTypes.string,
    disabled: PropTypes.bool,
    dispatch: PropTypes.func,
    forceMobileDisplay: PropTypes.bool,
    forcePayment: PropTypes.bool,
    isAuthenticated: PropTypes.bool,
    isFromTravelPage: PropTypes.bool,
    product: PropTypes.object,
    productBookmarkConstraints: PropTypes.bool,
    productCheckout: PropTypes.string,
    productIframeMode: PropTypes.bool,
    paymentTracker: PropTypes.func,
    session: PropTypes.object.isRequired,
    triggerSubscribe: PropTypes.bool,
    modalDisplayed: PropTypes.object,
    paymentStep: PropTypes.object,
    periodicity: PropTypes.object,
    payment: PropTypes.object,
  }

  static defaultProps = {
    customStyle: "",
    disabled: false,
    forceMobileDisplay: false,
    forcePayment: false,
    hasExtraChannels: true,
    isAuthenticated: false,
    isFromTravelPage: false,
    productIframeMode: false,
    paymentTracker: consts.NoOp,
    triggerSubscribe: false,
  }

  state = {
    currentSteps: [],
    stepIndex: -1,
  }

  resetStep = () => {
    this.setState({ stepIndex: -1 })
  }

  nextStep = () => {
    const {
      appSettings: { platform },
      dispatch,
      isAuthenticated,
      product: { code, equivalence_code, id, isSubscribeWithApple, optins },
    } = this.props

    const { currentSteps, stepIndex } = this.state

    // console.log("nextStep called, current step is ", currentSteps[stepIndex]) // keep for debugging purpose

    if (!isAuthenticated) {
      dispatch(push(`${routes.offers}?subscribe=1#offer_${equivalence_code}`))
    } else {
      // By default, move to the next step. Adjustements will be done below if needed (for optins,
      // post payment case, password form when cards data are already available etc.).
      let nextStepIndex = stepIndex + 1

      // if the next step is OPTINS and the product has no opt-ins, move to the next step (STEPS.ADD_PAYMENT)
      if (currentSteps[nextStepIndex] === STEPS.OPTINS && !optins) {
        nextStepIndex++
      }

      // redirect to app store for apple devices when we get to the payment step or password step and the sub was done with itunes
      if (
        platform === consts.platform.apple &&
        (currentSteps === SUBSCRIBE_STEPS || (currentSteps === RESUBSCRIBE_STEPS && isSubscribeWithApple)) &&
        (currentSteps[nextStepIndex] === STEPS.ADD_PAYMENT || currentSteps[nextStepIndex] === STEPS.PASSWORD)
      ) {
        window.location.href = `${consts.inappLink}${code}`
        return
      }

      if (currentSteps[nextStepIndex] === STEPS.ADD_PAYMENT) {
        dispatch(checkoutPayment(id))
      }

      this.setState({ stepIndex: nextStepIndex }, () => {
        if (this.state.currentSteps[this.state.stepIndex] === STEPS.CONFIRMATION) {
          this.callPostSubscriptionActions()
        }
      })
    }
  }

  callPostSubscriptionActions = () => {}

  triggerSubscribe = () => {
    const { isSubscribe, isSubscribeWithApple, subscriptionEndAt } = this.props.product

    if (isSubscribeWithApple) {
      return
    }

    if (!isSubscribe) {
      this.subscribeClick()
    } else if (!subscriptionEndAt) {
      this.unsubscribeClick()
    } else if (parseInt(subscriptionEndAt, 10) > 0) {
      this.reSubscribeClick()
    }

    this.triggerSubscribeApplied = true
  }

  subscribeClick = () => {
    this.setState({ currentSteps: SUBSCRIBE_STEPS }, this.nextStep)
  }

  reSubscribeClick = () => {
    this.setState({ currentSteps: RESUBSCRIBE_STEPS }, this.nextStep)
  }

  unsubscribeClick = () => {
    // if eligible to winback
    if (get(this.props.session, "data.account.ab_testing.eligible_winback") === "true") {
      const subscriptionId = get(this.props.product, "subscriptionId")
      this.props.dispatch(push(formatterHelper.basic(consts.routes.unsubscribeStep1, { subscriptionId })))
      TrackingSegment.trackEvent("offer_button_clicked", { ...this.props.product, current_page: "account" })
    } else {
      this.setState({ currentSteps: UNSUBSCRIBE_STEPS }, this.nextStep)
    }
  }

  subscribe = () => {
    const { product } = this.props

    if (this.state.currentSteps === RESUBSCRIBE_STEPS) {
      this.props.dispatch(restoreSubscription(product.subscriptionId, this.nextStep))
    } else {
      this.props.dispatch(postSubscription(product.id, product.rate_plan_id, this.nextStep))
    }
  }

  unsubscribe = () => {
    this.props.dispatch(removeSubscription(this.props.product.subscriptionId, this.nextStep))
  }

  getUnsubBookmarksConstraints() {
    if (this.props.isAuthenticated && get(this.props, "terms.unsub_removes_bookmarks") && this.props.productBookmarkConstraints === null) {
      this.props.dispatch(getBookmarkConstraintsForProduct(this.props.product.id))
    }
  }

  getProductOptinsComponent(footer) {
    const {
      product: { id, optins },
    } = this.props

    let component = null

    switch (optins.type) {
      case "interstitial":
        component = (
          <ProductInterstitial
            customStyle={classNames(styles.mainDesktop)}
            onSubmit={this.subscribeClick}
            optins={optins}
            id={`${id}`}
            footer={footer}
          />
        )
        break

      default:
        console.error(`WARNING - Unsupported optins type ${optins.type}.`) // eslint-disable-line no-console
        break
    }

    return component
  }

  static getDerivedStateFromProps(props, state) {
    const { stepIndex } = state

    if (state.productChange && stepIndex !== 1) {
      return { stepIndex: 1 }
    } else if (
      props.productCheckout &&
      props.productCheckout !== props.product.id &&
      state.currentSteps[stepIndex] === STEPS.ADD_PAYMENT &&
      !props.product.conversionDone
    ) {
      if (state.productChange) {
        return { productChange: false }
      }
      let nextStepIndex = stepIndex - 1

      if (state.currentSteps[nextStepIndex] === STEPS.OPTINS && !props.product.optins) {
        nextStepIndex--
      }

      return { stepIndex: nextStepIndex }
    }

    return null
  }

  componentDidMount() {
    if (this.props.triggerSubscribe) {
      this.triggerSubscribe()
    }
    this.getUnsubBookmarksConstraints()
  }

  shouldComponentUpdate(nextProps) {
    // Disable refresh when CGV Modal is displayed or hidden
    return this.props.modalDisplayed === nextProps.modalDisplayed
  }

  componentDidUpdate(prevProps) {
    if (this.props.triggerSubscribe && this.props.triggerSubscribe !== prevProps.triggerSubscribe && !this.triggerSubscribeApplied) {
      this.triggerSubscribe()
    }

    if (this.props.product.id !== prevProps.product.id) {
      this.setState({ productChange: true })
    }

    this.getUnsubBookmarksConstraints()
  }

  render() {
    const {
      appSettings: { platform, frameless, frameType },
      customStyle,
      disabled,
      forceMobileDisplay,
      product,
      product: { equivalence_code, feature_list, id, isSubscribe, name, type, will_lose_storage_on_cancel, wording },
      paymentStep,
      productBookmarkConstraints,
      productIframeMode,
      payment,
    } = this.props

    const { stepIndex } = this.state

    const step = this.state.currentSteps[stepIndex] || STEPS.PRODUCT
    let headerTitle = (wording && wording.title) || null
    let headerSubtitle = (wording && wording.subtitle) || null
    let periodicity = (wording && wording.periodicity) || null

    if (headerTitle && typeof headerTitle === "object") {
      headerTitle = formatterHelper.styled(headerTitle.format, headerTitle.parts)
    }
    if (headerSubtitle && typeof headerSubtitle === "object") {
      headerSubtitle = formatterHelper.styled(headerSubtitle.format, headerSubtitle.parts)
    }
    if (periodicity && typeof periodicity === "object") {
      periodicity = formatterHelper.styled(periodicity.format, periodicity.parts)
    }

    const footer = get(product, "reinsurance.footer") ? (
      <div className={styles.footer}>{formatterHelper.styledFormatter(product.reinsurance.footer, "footer")}</div>
    ) : null

    // In periodicity context we don't have the equivalence code in product
    const equivalenceCode = product?.equivalence_code
      ? product.equivalence_code
      : this.props.periodicity.selected
      ? Object.keys(payment)?.[0]
      : product.equivalence_code

    const confirmationStep = this.props.periodicity.selected
      ? this.props.payment[equivalenceCode]?.step?.template === "payment_step_confirmation"
        ? this.props.payment[equivalenceCode].step
        : null
      : paymentStep?.template === "payment_step_confirmation"
      ? paymentStep
      : null

    return (
      <div
        className={classNames(styles.rootDesktop, customStyle, {
          [styles.applePlatform]: platform === consts.platform.apple,
          [styles.disabled]: disabled,
          [styles.mobileDisplay]: forceMobileDisplay,
          [styles.mobilePlatform]: platform === consts.platform.apple || platform === consts.platform.android,
          [styles.productForFrameless]: frameless,
        })}
      >
        <div className={styles.productContent}>
          {productIframeMode && <ProductDesktopHeader isConfirmationStep={!!confirmationStep} />}
          <div id={`offer_${equivalence_code}`} className={styles.anchor} />
          {// We hide the checkmark icon intentionally on "frameless + productIframeMode" mode because it overlaps with the close icon in web-app-desktop
          // webview (when in productPageIframe). And also because the checkmark icon would be cropped since the webview size fits exactly the
          // productPageIframe size.
          isSubscribe && !(productIframeMode && frameless) && <div className={styles.checkmark} />}
          {confirmationStep && <ProductConfirmationDesktop customStyle={styles.mainDesktop} subscriptionMessages={confirmationStep} />}
          {!confirmationStep && (
            <div className={styles.productContainer}>
              <div
                className={classNames(styles.productPanel, {
                  [styles.productDynamicPanelDesktop]: !confirmationStep,
                })}
              >
                {step === STEPS.OPTINS && this.getProductOptinsComponent(footer)}
                {!this.props.forcePayment && step === STEPS.PRODUCT && (
                  <ProductMain
                    customStyle={styles.mainDesktop}
                    disabled={disabled}
                    footer={footer}
                    frameType={frameType}
                    onReSubscribe={this.reSubscribeClick}
                    onSubscribe={this.subscribeClick}
                    onUnsubscribe={this.unsubscribeClick}
                    platform={platform}
                    product={product}
                  />
                )}
                {this.props.forcePayment || step === STEPS.ADD_PAYMENT ? (
                  <ProductPayment
                    callPostSubscriptionActions={this.callPostSubscriptionActions}
                    customStyle={styles.mainDesktop}
                    footer={footer}
                    isFramelessMode={frameless}
                    onCancel={this.resetStep}
                    onSuccess={this.subscribe}
                    paymentTracker={this.props.paymentTracker}
                    product={product}
                    equivalenceCode={equivalenceCode}
                  />
                ) : null}
                {(step === STEPS.PASSWORD || step === STEPS.RESUBSCRIBE_PASSWORD || step === STEPS.RESILIATION_PASSWORD) && (
                  <div className={styles.mainDesktop}>
                    <ProductPassword
                      id={`${id}`}
                      isReSubscribing={step === STEPS.RESUBSCRIBE_PASSWORD}
                      isSubscribe={isSubscribe}
                      footer={footer}
                      onCancel={this.resetStep}
                      onSuccess={isSubscribe && step !== STEPS.RESUBSCRIBE_PASSWORD ? this.unsubscribe : this.subscribe}
                    />

                    {(step === STEPS.PASSWORD || step === STEPS.RESUBSCRIBE_PASSWORD) && <p className={styles.cgu}>{i18n.product.cguAware}</p>}
                  </div>
                )}
                {step === STEPS.RESILIATION_WARNING && (
                  <ProductWarning
                    customStyle={styles.mainDesktop}
                    footer={footer}
                    hasBookmarkConstraints={productBookmarkConstraints}
                    willLoseStorageOnUnsub={will_lose_storage_on_cancel}
                    hasExtraChannels={feature_list.hasExtraChannels}
                    onCancel={this.resetStep}
                    onValidate={this.nextStep}
                    productTitle={name}
                    type={type}
                  />
                )}
              </div>
              <ProductPanelDesktop
                customStyle={styles.panelDesktop}
                forceMobileDisplay={forceMobileDisplay}
                frameType={frameType}
                product={product}
                dispatch={this.props.dispatch}
                payment={this.props.payment[equivalenceCode]}
              />
            </div>
          )}
        </div>
      </div>
    )
  }
}
