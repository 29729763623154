import { get } from "lodash"
const ITEMS_PER_PAGE = 54

export default class Section {
  constructor(data) {
    this.context = data.context
    this.items = data.items
    this.title = data.title
    this.slug = data.slug
    this.pager = data.pager

    if (this.pager) {
      this.numberOfPages = Math.floor(this.pager.total_count / ITEMS_PER_PAGE) + (this.pager.total_count % ITEMS_PER_PAGE > 0 ? 1 : 0)
    }
  }

  getDisplayType() {
    return get(this.items, [0, "data", "type"])
  }

  getItems() {
    return this.items
  }

  getItemsCount() {
    return this.items.length
  }

  getTitle() {
    return this.title
  }

  getSlug() {
    return this.slug
  }

  isLive() {
    return this.context.is_live
  }

  isBookmark() {
    return this.context.is_bookmark
  }

  getNumberOfPages() {
    return this.numberOfPages
  }

  getPager() {
    return this.pager
  }

  shouldDisplaySubtitle() {
    return this.context.subtitle_format !== "none"
  }
}
