import {
  GET_ACCOUNT_PENDING,
  GET_ACCOUNT_SUCCESS,
  GET_ACCOUNT_FAILED,
  UPDATE_ACCOUNT_PENDING,
  UPDATE_ACCOUNT_SUCCESS,
  UPDATE_ACCOUNT_FAILED,
  POST_ACCOUNT_PENDING,
  POST_ACCOUNT_FAILED,
  POST_ACCOUNT_SUCCESS,
  POST_DSP_ACCOUNT_PENDING,
  POST_DSP_ACCOUNT_FAILED,
  POST_DSP_ACCOUNT_SUCCESS,
  DELETE_ACCOUNT_FAILED,
  UPDATE_CURRENTPROFILE_PENDING,
  UPDATE_CURRENTPROFILE_SUCCESS,
  UPDATE_CURRENTPROFILE_ERROR,
} from "consts/actions"

import { setSession, getContextAuthHeaders } from "actions/session"
import { open as openFlash } from "actions/flash"
import { getFullUser } from "actions/user"

import stateHelper from "helpers/state"
import urlHelper from "helpers/url"
import cookieHelper from "helpers/cookies"
import i18n from "consts/i18n"
import consts from "consts"
import flashStatus from "containers/Flash/status"
import PixelCodeManager from "managers/pixelCodeManager"

export function get(cb = null) {
  return {
    accessTokenRequired: true,
    request: {
      url: consts.endpoints.user,
      method: "GET",
    },
    onStart: () => {
      return {
        type: GET_ACCOUNT_PENDING,
      }
    },
    onSuccess: (payload, meta, dispatch, getState) => {
      const session = getState().session.data

      dispatch({
        type: GET_ACCOUNT_SUCCESS,
        payload,
      })

      if (session) {
        dispatch(
          setSession(
            {
              ...session,
              account: payload,
            },
            true
          )
        )
      }

      cb && cb()
    },
    onError: payload => {
      return {
        type: GET_ACCOUNT_FAILED,
        payload: stateHelper.errorPayload(payload.error.user_message),
      }
    },
  }
}

export function updateAccount(data) {
  const {
    first_name, // eslint-disable-line camelcase
    last_name, // eslint-disable-line camelcase
    birthday,
    gender,
  } = data

  return {
    accessTokenRequired: true,
    request: {
      url: consts.endpoints.user,
      method: "PATCH",
      body: {
        first_name,
        last_name,
        birthday: birthday === 0 ? null : birthday,
        gender,
      },
    },
    onStart: () => {
      return {
        type: UPDATE_ACCOUNT_PENDING,
      }
    },
    onSuccess: (payload, meta, dispatch, getState) => {
      const session = getState().session.data

      if (session) {
        const updatedAccount = { account: { ...session.account, ...payload } }
        dispatch(setSession({ ...session, ...updatedAccount }))
      }
      dispatch(openFlash(i18n.profile.success, null, flashStatus.success))
      dispatch(updateAccountSuccess())
    },
    onError: payload => {
      return {
        type: UPDATE_ACCOUNT_FAILED,
        payload: stateHelper.errorPayload(payload.error.user_message),
      }
    },
  }
}

function updateAccountSuccess() {
  return {
    type: UPDATE_ACCOUNT_SUCCESS,
  }
}

export function createAccount(email, password, birthday, gender, contextData, cb, prevRoute) {
  return (dispatch, getState) => {
    const url = urlHelper.getReferrerAndAllInfoInUrlForLoginOrSignup(consts.endpoints.account, getState)
    return dispatch({
      accessTokenRequired: false,
      request: {
        url,
        method: "POST",
        headers: getContextAuthHeaders(contextData),
        body: {
          email,
          password,
          birthday,
          gender,
        },
      },
      onStart: () => {
        return {
          type: POST_ACCOUNT_PENDING,
        }
      },
      onSuccess: (payload, meta, dispatch) => {
        dispatch({
          type: POST_ACCOUNT_SUCCESS,
          payload: payload.account,
        })
        cookieHelper.deleteCookie({ name: consts.cookieNames.clickId, domain: urlHelper.getCurrentDomainWithExtension() })
        PixelCodeManager.trackEvent("CompleteRegistration")
        window.gtag &&
          window.gtag("event", "conversion", {
            send_to: consts.privacy.gtagRegistration,
            user_id: payload.account.id,
          })
        window.dataLayer &&
          prevRoute &&
          window.dataLayer.push({
            event: "gtm.custom_event",
            event_name: "sign_up",
            event_category: prevRoute.includes("offer") ? "sign_up_page_offer" : "sign_up_form",
            event_action: "sign_up_sucess",
            event_label: "mail",
            previous_page_path: `${prevRoute}`,
          })
        dispatch(setSession(payload))
        cb && cb()
      },
      onError: payload => {
        return {
          type: POST_ACCOUNT_FAILED,
          payload: stateHelper.errorPayload(payload.error.user_message),
        }
      },
    })
  }
}

export function createDSPAccount(token, password, birthday, gender, contextData, cb) {
  return {
    accessTokenRequired: false,
    request: {
      url: consts.endpoints.setupAccount,
      method: "POST",
      headers: getContextAuthHeaders(contextData),
      body: {
        token,
        password,
        birthday,
        gender,
      },
    },
    onStart: () => {
      return {
        type: POST_DSP_ACCOUNT_PENDING,
      }
    },
    onSuccess: (payload, meta, dispatch) => {
      dispatch({
        type: POST_DSP_ACCOUNT_SUCCESS,
        payload: payload.account,
      })
      PixelCodeManager.trackEvent("CompleteRegistration")
      window.gtag &&
        window.gtag("event", "conversion", {
          send_to: consts.privacy.gtagRegistration,
          user_id: payload.account.id,
        })
      dispatch(setSession(payload))
      cb && cb()
    },
    onError: payload => {
      return {
        type: POST_DSP_ACCOUNT_FAILED,
        payload: stateHelper.errorPayload(payload.error.user_message),
      }
    },
  }
}

export function deleteAccount(onSuccess = () => {}) {
  return {
    accessTokenRequired: true,
    request: {
      url: consts.endpoints.user,
      method: "DELETE",
    },
    onSuccess: () => {
      return () => {
        onSuccess()
      }
    },
    onError: payload => {
      return {
        type: DELETE_ACCOUNT_FAILED,
        payload: stateHelper.errorPayload(payload.error.user_message),
      }
    },
  }
}

export function updateProfileNames(firstName, lastName) {
  return {
    request: {
      url: consts.endpoints.profile,
      method: "PATCH",
      body: {
        first_name: firstName,
        last_name: lastName,
      },
    },
    onStart: () => {
      return {
        type: UPDATE_CURRENTPROFILE_PENDING,
      }
    },
    onSuccess: (payload, meta, dispatch) => {
      dispatch(openFlash(i18n.profile.success, null, flashStatus.success))
      dispatch(getFullUser())

      return {
        type: UPDATE_CURRENTPROFILE_SUCCESS,
        payload: {
          profileId: payload.id,
          newProfile: payload,
        },
      }
    },
    onError: payload => {
      return {
        type: UPDATE_CURRENTPROFILE_ERROR,
        payload: {
          error: (payload.error && payload.error.user_message) || "Erreur lors de la mise à jour du profile",
        },
      }
    },
  }
}
