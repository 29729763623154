import {
  GET_HOMEPAGE_PROGRAMS_PENDING,
  GET_HOMEPAGE_PROGRAMS_SUCCESS,
  GET_HOMEPAGE_PROGRAMS_FAILED,
  GET_PROGRAM_PENDING,
  GET_PROGRAM_SUCCESS,
  GET_PROGRAM_FAILED,
  GET_PROGRAMS_PENDING,
  GET_PROGRAMS_SUCCESS,
  GET_PROGRAMS_FAILED,
} from "consts/actions"

import stateHelper from "helpers/state"

const initialState = {
  landingOnAir: {},
  seoHome: {},
  programs: {},
}

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_HOMEPAGE_PROGRAMS_PENDING:
      return {
        ...state,
        landingOnAir: stateHelper.pending(),
      }

    case GET_HOMEPAGE_PROGRAMS_SUCCESS:
      return {
        ...state,
        landingOnAir: action.payload,
      }

    case GET_HOMEPAGE_PROGRAMS_FAILED:
      return {
        ...state,
        landingOnAir: {
          error: action.payload,
        },
      }

    case GET_PROGRAM_PENDING:
      return {
        ...state,
        programs: {
          ...state.programs,
          [action.id]: stateHelper.pending(),
        },
      }

    case GET_PROGRAM_SUCCESS:
      return {
        ...state,
        programs: {
          ...state.programs,
          [action.id]: action.payload,
        },
      }

    case GET_PROGRAM_FAILED:
      return {
        ...state,
        programs: {
          ...state.programs,
          [action.id]: {
            error: action.payload,
          },
        },
      }

    case GET_PROGRAMS_PENDING:
      return {
        ...state,
        seoHome: stateHelper.pending(),
      }

    case GET_PROGRAMS_SUCCESS:
      return {
        ...state,
        seoHome: action.payload,
      }

    case GET_PROGRAMS_FAILED:
      return {
        ...state,
        seoHome: {
          error: action.payload,
        },
      }

    default:
      return state
  }
}
