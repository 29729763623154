import keyMirror from "keymirror"

export default keyMirror({
  setPassword: null,
  deleteAccount: null,
  deleteBookmarks: null,
  changeRating: null,
  confirm: null,
  bookmarkDeleteConfirm: null,
  cookie: null,
  resetPassword: null,
  resetEmail: null,
  iframe: null,
  openEurope: null,
  text: null,
  channels: null,
})
