import { get } from "lodash"

import {
  CHECK_NOTIFICATIONS_OPTIN,
  GET_NOTIFICATIONS_PENDING,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_ERROR,
  SAVE_NOTIFICATIONS_PENDING,
  SAVE_NOTIFICATIONS_SUCCESS,
  SAVE_NOTIFICATIONS_ERROR,
  DELETE_EMAIL_SUBSCRIPTION_PENDING,
  DELETE_EMAIL_SUBSCRIPTION_SUCCESS,
  DELETE_EMAIL_SUBSCRIPTION_ERROR,
} from "consts/actions"

import stateHelper from "helpers/state"

const initialState = {
  notifications: {},
  notificationTypes: [],
  savingStatus: {},
  unsubscribeEmail: {},
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case CHECK_NOTIFICATIONS_OPTIN:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          optins: checkOptin(state.notifications.optins, action.payload),
        },
      }

    case GET_NOTIFICATIONS_PENDING:
      return {
        ...state,
        notifications: stateHelper.pending(),
      }

    case GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: action.payload,
        notificationTypes: getNotificationTypes(action.payload),
      }

    case GET_NOTIFICATIONS_ERROR:
      return {
        ...state,
        notifications: action.payload,
      }

    case SAVE_NOTIFICATIONS_PENDING:
      return {
        ...state,
        savingStatus: stateHelper.pending(),
      }

    case SAVE_NOTIFICATIONS_SUCCESS:
    case SAVE_NOTIFICATIONS_ERROR:
      return {
        ...state,
        savingStatus: action.payload,
      }

    case DELETE_EMAIL_SUBSCRIPTION_PENDING:
      return {
        ...state,
        unsubscribeEmail: {
          pending: true,
        },
      }

    case DELETE_EMAIL_SUBSCRIPTION_SUCCESS:
    case DELETE_EMAIL_SUBSCRIPTION_ERROR:
      return {
        ...state,
        unsubscribeEmail: action.payload,
      }

    default:
      return state
  }
}

const checkOptin = (optins, payload) => {
  return optins.map(optin => {
    if (optin.id === payload.id) {
      optin.checkboxes = optin.checkboxes.map(checkbox => {
        if (checkbox.type === payload.type) {
          checkbox.checked = payload.checked
        }

        return checkbox
      })
    }

    return optin
  })
}

const getNotificationTypes = payload => {
  let checkboxes = get(payload, "optins[0].checkboxes", [])

  if (!Array.isArray(checkboxes)) {
    checkboxes = []
  }

  return checkboxes.map(checkbox => checkbox.type)
}
