import { GET_MTVPAY_INVOICES_PENDING, GET_MTVPAY_INVOICES_SUCCESS, GET_MTVPAY_INVOICES_FAILED } from "consts/actions"

import stateHelper from "helpers/state"

const initialState = null

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_MTVPAY_INVOICES_PENDING:
      return stateHelper.pending()

    case GET_MTVPAY_INVOICES_SUCCESS:
      return action.payload

    case GET_MTVPAY_INVOICES_FAILED:
      return {
        error: action.payload,
      }

    default:
      return state
  }
}
