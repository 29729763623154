import TrackingSegment from "managers/segmentManager"
import PixelCodeManager from "managers/pixelCodeManager"

import localStorageHelper from "helpers/localStorage"

import consts from "consts"

let trackingLoaded = false
let adsLoaded = false

export const stopAds = () => {
  // facebook pixel
  PixelCodeManager.revokeConsent()
  stopGtmBucket(consts.privacy.gtmAdBucket)
  stopGtmBucket(consts.privacy.gtagId)
  adsLoaded = false
}

export const startAds = () => {
  if (adsLoaded) {
    return
  }

  startGtmBucket(consts.privacy.gtmAdBucket)
  startGtag()

  // taboola
  /* eslint-disable */
  window._tfa = window._tfa || []
  window._tfa.push({
    notify: "event",
    name: "page_view",
    id: 1160509,
  })
  !(function(t, f, a, x) {
    if (!document.getElementById(x)) {
      t.async = 1
      t.src = a
      t.id = x
      f.parentNode.insertBefore(t, f)
    }
  })(document.createElement("script"), document.getElementsByTagName("script")[0], "//cdn.taboola.com/libtrc/unip/1160509/tfa.js", "tb_tfa_script")
  /* eslint-enable */

  // Pixel
  typeof PixelCodeManager.init === "function" && PixelCodeManager.init()

  adsLoaded = true
}

export const startTracking = store => {
  if (trackingLoaded) {
    return
  }

  startGtmBucket(consts.privacy.gtmTrackBucket)

  typeof TrackingSegment.init === "function" && TrackingSegment.init(store)

  trackingLoaded = true
}

export const stopTracking = () => {
  stopGtmBucket(consts.privacy.gtmTrackBucket)
  TrackingSegment.logout()
  trackingLoaded = false
}

export const startGtmBucket = id => {
  // id = google tag manager bucket id

  // google tag manager
  /* eslint-disable */
  !(function(w, d, s, l, i) {
    w[l] = w[l] || []
    w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" })
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l != "dataLayer" ? "&l=" + l : ""
    j.setAttribute("id", id)
    j.async = true
    j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl
    f.parentNode.insertBefore(j, f)
  })(window, document, "script", "dataLayer", id)
  /* eslint-enable */
}

export const stopGtmBucket = id => {
  // id = google tag manager bucket id
  const element = document.getElementById(id)
  if (element) {
    element.remove()
  }
}

export const startGtag = () => {
  /* eslint-disable */
  !(function(w, d, s, l, i) {
    w[l] = w[l] || []
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l != "dataLayer" ? "&l=" + l : ""
    j.setAttribute("id", i)
    j.async = true
    j.src = "https://www.googletagmanager.com/gtag/js?id=" + i + dl
    f.parentNode.insertBefore(j, f)
  })(window, document, "script", "dataLayer", consts.privacy.gtagId)
  /* eslint-enable */

  function gtag() {
    if (window.dataLayer) {
      window.dataLayer.push(arguments)
    } else {
      window.dataLayer = [arguments]
    }
  }

  window.gtag = gtag

  gtag("js", new Date())
  gtag("config", consts.privacy.gtagId)
}

// save settings to local storage
export const saveSettings = settings => {
  const today = new Date()
  const payload = JSON.stringify({
    ...settings,
    expire: today.setMonth(today.getMonth() + 13),
  })
  localStorageHelper.setCachedData(consts.localStorageKey.cookieSettings, payload)
}

export const clearCookies = () => {
  document.cookie.split(";").forEach(cookie => {
    document.cookie = cookie.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/")
  })
}
