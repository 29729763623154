import React from "react"
import { Link } from "react-router-dom"

import Logo from "components/Logo"
import consts from "consts"

import styles from "./index.css"

const HeaderSimple = () => {
  return (
    <header className={styles.root}>
      <div className={styles.content}>
        <Link className={styles.logo} to={consts.routes.default}>
          <Logo height={50} mixedColor={true} />
        </Link>
      </div>
    </header>
  )
}

export default HeaderSimple
