import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import styles from "./index.css"

const Separator = ({ customStyle = null, text = null }) => {
  return text ? (
    <p className={styles.textSeparator}>
      <span className={styles.textSeparatorLine} />
      <span className={styles.textSeparatorContent}>{text}</span>
    </p>
  ) : (
    <hr className={classNames(styles.root, customStyle)} />
  )
}

Separator.propTypes = {
  customStyle: PropTypes.string,
  text: PropTypes.string,
}

export default Separator
