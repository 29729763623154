import { GET_RECORD_METRICS_PENDING, GET_RECORD_METRICS_SUCCESS, GET_RECORD_METRICS_FAILED } from "consts/actions"

import stateHelper from "helpers/state"

const initialState = {}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_RECORD_METRICS_PENDING:
      return {
        ...state,
        ...stateHelper.pending(),
      }

    case GET_RECORD_METRICS_SUCCESS:
      return {
        data: action.payload,
      }

    case GET_RECORD_METRICS_FAILED:
      return { error: action.payload }

    default:
      return state
  }
}
