import { OPEN_MODAL, CLOSE_MODAL } from "consts/actions"

const initialState = {
  open: false,
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case OPEN_MODAL:
      return {
        open: true,
        type: action.modalType,
        data: action.data,
        action: action.action,
        closeOnDimmerClick: action.closeOnDimmerClick,
      }

    case CLOSE_MODAL:
      return { open: false }

    default:
      return state
  }
}
