import {
  UPDATE_PASSWORD_PENDING,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_FAILED,
  RESET_PASSWORD_PENDING,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILED,
  CHECK_PASSWORD_PENDING,
  CHECK_PASSWORD_SUCCESS,
  CHECK_PASSWORD_FAILED,
  RESET_PASSWORD_FORM,
} from "consts/actions"

import { open as openFlash } from "actions/flash"

import stateHelper from "helpers/state"

import flashStatus from "containers/Flash/status"
import consts from "consts"
import i18n from "consts/i18n"

export function update(currentPassword, newPassword, onSuccess = () => {}) {
  const body = { new_password: newPassword }

  if (currentPassword) {
    body.password = currentPassword
  }

  return {
    accessTokenRequired: true,
    request: {
      url: consts.endpoints.updatePassword,
      method: "PATCH",
      body,
    },
    onStart: () => {
      return {
        type: UPDATE_PASSWORD_PENDING,
      }
    },
    onSuccess: () => {
      return dispatch => {
        onSuccess()
        dispatch(updateSuccess())
        dispatch(openFlash(i18n.changePasswordPage.success, null, flashStatus.success))
      }
    },
    onError: payload => {
      return {
        type: UPDATE_PASSWORD_FAILED,
        payload: stateHelper.errorPayload(payload.error.user_message),
      }
    },
  }
}

function updateSuccess() {
  return {
    type: UPDATE_PASSWORD_SUCCESS,
  }
}

export function reset(email) {
  return {
    accessTokenRequired: false,
    request: {
      url: consts.endpoints.resetPassword,
      method: "POST",
      body: {
        email,
      },
    },
    onStart: () => {
      return {
        type: RESET_PASSWORD_PENDING,
      }
    },
    onSuccess: () => {
      return {
        type: RESET_PASSWORD_SUCCESS,
      }
    },
    onError: payload => {
      return {
        type: RESET_PASSWORD_FAILED,
        payload: stateHelper.errorPayload(payload.error.user_message),
      }
    },
  }
}

// Change password when forgotten
export function change(password, token) {
  return {
    accessTokenRequired: false,
    request: {
      url: `${consts.endpoints.resetPassword}/${token}`,
      method: "POST",
      body: {
        password,
      },
    },
    onStart: () => {
      return {
        type: RESET_PASSWORD_PENDING,
      }
    },
    onSuccess: () => {
      return {
        type: RESET_PASSWORD_SUCCESS,
      }
    },
    onError: payload => {
      return {
        type: RESET_PASSWORD_FAILED,
        payload: stateHelper.errorPayload(payload.error.user_message),
      }
    },
  }
}

export function check(password, onSuccess = () => {}) {
  return {
    accessTokenRequired: true,
    request: {
      url: consts.endpoints.checkPassword,
      method: "POST",
      body: {
        password,
      },
    },
    onStart: () => {
      return {
        type: CHECK_PASSWORD_PENDING,
      }
    },
    onSuccess: () => {
      return dispatch => {
        onSuccess()
        dispatch(checkSuccess())
      }
    },
    onError: payload => {
      return {
        type: CHECK_PASSWORD_FAILED,
        payload: stateHelper.errorPayload(payload.error.user_message),
      }
    },
  }
}

function checkSuccess() {
  return {
    type: CHECK_PASSWORD_SUCCESS,
  }
}

export function resetPasswordForm() {
  return {
    type: RESET_PASSWORD_FORM,
  }
}
