import { get } from "lodash"

import {
  CARD_FORM_REQUESTED,
  CLOSE_MTVPAY_FORM,
  CLOSE_MTVPAY_INTERSTITIAL,
  DELETE_MTVPAY_CARD_SUCCESS,
  INFOS_SCREEN_REQUESTED,
  GET_MTVPAY_PAYMENT_METHOD_PENDING,
  GET_MTVPAY_PAYMENT_METHOD_SUCCESS,
  GET_MTVPAY_PAYMENT_METHOD_FAILED,
  GET_MTVPAY_INVOICES_PENDING,
  GET_MTVPAY_INVOICES_SUCCESS,
  GET_MTVPAY_INVOICES_FAILED,
  GET_MTVPAY_PASSWORD_FORM_PENDING,
  GET_MTVPAY_PASSWORD_FORM_SUCCESS,
  GET_MTVPAY_PASSWORD_FORM_ERROR,
  GET_MTVPAY_PAYMENT_FORM_PENDING,
  GET_MTVPAY_PAYMENT_FORM_SUCCESS,
  GET_MTVPAY_PAYMENT_FORM_ERROR,
  GOT_BUY_ACTION_ERROR,
  INVALID_CARD_INFORMATIONS,
  MTVPAY_PAYMENT_PENDING,
  MTVPAY_PAYMENT_SUCCESS,
  MTVPAY_PAYMENT_ERROR,
  PRODUCT_PAYMENT_INSTANCIATED,
  RESET_CARD_ERRORS,
  SET_PAYMENT_DATA,
  SAVE_MTVPAY_CARD_PENDING,
  SAVE_MTVPAY_CARD_SUCCESS,
  SAVE_MTVPAY_CARD_ERROR,
  SET_MTVPAY_INTERSTITIAL,
  SET_MTVPAY_PASSWORD_ERROR,
  GET_MTV_PAY_STEP_PENDING,
  GET_MTV_PAY_STEP_SUCCESS,
  GET_MTV_PAY_STEP_FAILED,
  GET_OFFER_PERIODICITY_PENDING,
  GET_OFFER_PERIODICITY_SUCCESS,
  GET_OFFER_PERIODICITY_FAILED,
} from "consts/actions"

import { executeApiActions } from "actions/api"

import { open as openFlash } from "actions/flash"

import formatterHelper from "helpers/formatter"
import stateHelper from "helpers/state"
import cookieHelper from "helpers/cookies"
import urlHelper from "helpers/url"

import PixelCodeManager from "managers/pixelCodeManager"

import consts from "consts"
import i18n from "consts/i18n"

export function getPaymentMethod() {
  return {
    accessTokenRequired: true,
    request: {
      url: consts.endpoints.mtvPayPaymentMethod,
      method: "GET",
    },
    onStart: () => {
      return {
        type: GET_MTVPAY_PAYMENT_METHOD_PENDING,
      }
    },
    onSuccess: payload => {
      return {
        type: GET_MTVPAY_PAYMENT_METHOD_SUCCESS,
        payload,
      }
    },
    onError: payload => {
      return {
        type: GET_MTVPAY_PAYMENT_METHOD_FAILED,
        payload: stateHelper.errorPayload(payload.user_message),
      }
    },
  }
}

export function getInvoices() {
  return {
    accessTokenRequired: true,
    request: {
      url: consts.endpoints.mtvPayInvoices,
      method: "GET",
    },
    onStart: () => {
      return {
        type: GET_MTVPAY_INVOICES_PENDING,
      }
    },
    onSuccess: payload => {
      return {
        type: GET_MTVPAY_INVOICES_SUCCESS,
        payload,
      }
    },
    onError: payload => {
      return {
        type: GET_MTVPAY_INVOICES_FAILED,
        payload: stateHelper.errorPayload(payload.user_message),
      }
    },
  }
}

export function fetchPaymentForm(action, equivalenceCode) {
  return {
    accessTokenRequired: true,
    request: {
      base: "",
      url: action.url,
      method: action.method,
    },
    onStart: () => {
      return {
        type: GET_MTVPAY_PAYMENT_FORM_PENDING,
        equivalenceCode,
      }
    },
    onSuccess(payload) {
      return {
        type: GET_MTVPAY_PAYMENT_FORM_SUCCESS,
        payload,
        equivalenceCode,
      }
    },
    onError(payload) {
      return {
        type: GET_MTVPAY_PAYMENT_FORM_ERROR,
        payload: stateHelper.errorPayload(get(payload, "user_message", i18n.payment.errorPaymentFormDefault)),
        equivalenceCode,
      }
    },
  }
}

export function fetchPasswordForm(action, equivalenceCode) {
  return {
    accessTokenRequired: true,
    request: {
      base: "",
      url: action.url,
      method: action.method,
    },
    onStart: () => {
      return {
        type: GET_MTVPAY_PASSWORD_FORM_PENDING,
        equivalenceCode,
      }
    },
    onSuccess(payload) {
      return {
        type: GET_MTVPAY_PASSWORD_FORM_SUCCESS,
        payload,
        equivalenceCode,
      }
    },
    onError(payload) {
      return {
        type: GET_MTVPAY_PASSWORD_FORM_ERROR,
        payload: stateHelper.errorPayload(get(payload, "user_message", i18n.payment.errorPasswordFormDefault)),
        equivalenceCode,
      }
    },
  }
}

export function checkSecurePayment(body, equivalenceCode) {
  return {
    accessTokenRequired: true,
    request: {
      url: consts.endpoints.mtvPaySecureCheck,
      method: "POST",
      body: {
        md: body.MD,
        pa_res: body.PaRes,
      },
    },
    onStart() {
      return {
        type: MTVPAY_PAYMENT_PENDING,
        equivalenceCode,
      }
    },
    onSuccess(payload, meta, dispatch) {
      if (payload.execute_actions) {
        dispatch(executeApiActions(payload.execute_actions, { productPayment: true }))
      }

      return {
        type: MTVPAY_PAYMENT_SUCCESS,
        equivalenceCode,
      }
    },
    onError(payload, meta, dispatch) {
      if (payload.error.execute_actions) {
        dispatch(executeApiActions(payload.error.execute_actions, { productPayment: true }))
      } else if (get(payload, "error.error_code") == consts.api.errorCodes.INVALID_CREDENTIALS) {
        dispatch({
          type: SET_MTVPAY_PASSWORD_ERROR,
          equivalenceCode,
          payload: get(payload, "error.user_message"),
        })
      }

      return {
        type: MTVPAY_PAYMENT_ERROR,
        equivalenceCode,
        payload,
      }
    },
  }
}

export function postPayment(action, body, equivalenceCode) {
  const clickid = cookieHelper.getCookieValue(consts.cookieNames.clickId)
  const urlWithParams = new URL(action.url)
  if (clickid) {
    urlWithParams.searchParams.append("clickid", clickid)
  }
  return {
    accessTokenRequired: true,
    request: {
      base: "",
      url: urlWithParams.toString(),
      method: action.method,
      body,
    },
    onStart() {
      return {
        type: MTVPAY_PAYMENT_PENDING,
        equivalenceCode,
      }
    },
    onSuccess(payload, meta, dispatch, getState) {
      let pending = false
      if (payload.execute_actions) {
        dispatch(executeApiActions(payload.execute_actions, { productPayment: true, equivalenceCode }))
        payload.execute_actions.forEach(e => {
          if (e.type === consts.apiActionTypes.payment_3ds || e.type === consts.apiActionTypes.recurly_3ds) {
            pending = true
          }
        })
      }

      if (clickid) {
        cookieHelper.deleteCookie({ name: consts.cookieNames.clickId, domain: urlHelper.getCurrentDomainWithExtension() })
      }

      // non null value needed
      PixelCodeManager.trackEvent("Subscribe", { value: "3.99", currency: "EUR", predicted_ltv: "19.99" })
      window.gtag &&
        window.gtag("event", "conversion", {
          send_to: consts.privacy.gtagSubScribe,
          transaction_id: "",
          user_id: getState().account.id,
        })

      return {
        type: pending ? MTVPAY_PAYMENT_PENDING : MTVPAY_PAYMENT_SUCCESS,
        equivalenceCode,
      }
    },
    onError(payload, meta, dispatch) {
      if (payload.error.execute_actions) {
        dispatch(executeApiActions(payload.error.execute_actions, { productPayment: true }))
      } else if (get(payload, "error.error_code") == consts.api.errorCodes.INVALID_CREDENTIALS) {
        dispatch({
          type: SET_MTVPAY_PASSWORD_ERROR,
          equivalenceCode,
          payload: get(payload, "error.user_message"),
        })
      }

      return {
        type: MTVPAY_PAYMENT_ERROR,
        equivalenceCode,
        payload,
      }
    },
  }
}

export function setPaymentInterstitial(payload, equivalenceCode) {
  return {
    type: SET_MTVPAY_INTERSTITIAL,
    payload,
    equivalenceCode,
  }
}

export function closeForm(equivalenceCode) {
  return {
    type: CLOSE_MTVPAY_FORM,
    equivalenceCode,
  }
}

export function closeInterstitial(equivalenceCode) {
  return {
    type: CLOSE_MTVPAY_INTERSTITIAL,
    equivalenceCode,
  }
}

export function resetPasswordError(equivalenceCode) {
  return {
    type: SET_MTVPAY_PASSWORD_ERROR,
    equivalenceCode,
    payload: false,
  }
}

export function gotBuyActionError(error) {
  return {
    type: GOT_BUY_ACTION_ERROR,
    payload: error,
  }
}

export function productPaymentInstanciated(equivalenceCode) {
  return {
    type: PRODUCT_PAYMENT_INSTANCIATED,
    equivalenceCode,
  }
}

export function cardFormRequested() {
  return {
    type: CARD_FORM_REQUESTED,
  }
}

export function infosScreenRequested() {
  return {
    type: INFOS_SCREEN_REQUESTED,
  }
}

export function invalidCardInformations() {
  return {
    type: INVALID_CARD_INFORMATIONS,
  }
}

export function resetCardErrors() {
  return {
    type: RESET_CARD_ERRORS,
  }
}

export function setPaymentData(field, data) {
  return {
    type: SET_PAYMENT_DATA,
    field,
    payload: data,
  }
}

export function saveCardForm(paymentMethod, data, successCallBack) {
  return dispatch => {
    return dispatch({
      accessTokenRequired: true,
      request: {
        url: formatterHelper.basic(consts.endpoints.mtvPayCard, { recurringReference: paymentMethod.id }),
        method: "PUT",
        body: data,
      },
      onStart: () => {
        return {
          type: SAVE_MTVPAY_CARD_PENDING,
        }
      },
      onSuccess(payload) {
        successCallBack && successCallBack()
        return {
          type: SAVE_MTVPAY_CARD_SUCCESS,
          payload,
        }
      },
      onError(payload) {
        return {
          type: SAVE_MTVPAY_CARD_ERROR,
          payload: stateHelper.errorPayload(get(payload, "error.user_message", i18n.genericErrorMessage)),
        }
      },
    })
  }
}

export function saveRecurlyCardForm(params, successCallBack) {
  return dispatch => {
    return dispatch({
      accessTokenRequired: true,
      request: {
        url: consts.endpoints.recurlyPayCard,
        method: "POST",
        body: {
          ...params,
        },
      },
      onStart: () => {
        return {
          type: SAVE_MTVPAY_CARD_PENDING,
        }
      },
      onSuccess(payload) {
        let pending = false
        if (payload.execute_actions) {
          dispatch(executeApiActions(payload.execute_actions))
          payload.execute_actions.forEach(e => {
            if (e.type === consts.apiActionTypes.payment_3ds || e.type === consts.apiActionTypes.recurly_3ds) {
              pending = true
            }
          })
        }
        if (!pending) {
          successCallBack && successCallBack()
        }
        return {
          type: pending ? SAVE_MTVPAY_CARD_PENDING : SAVE_MTVPAY_CARD_SUCCESS,
          payload,
        }
      },
      onError(payload) {
        return {
          type: SAVE_MTVPAY_CARD_ERROR,
          payload: stateHelper.errorPayload(get(payload, "error.user_message", i18n.genericErrorMessage)),
        }
      },
    })
  }
}

export function deleteCard(paymentMethod, successCallBack) {
  // If no payment method id -> recurly
  const url = paymentMethod.id
    ? formatterHelper.basic(consts.endpoints.mtvPayCard, { recurringReference: paymentMethod.id })
    : consts.endpoints.recurlyDeleteCard
  return dispatch => {
    return dispatch({
      accessTokenRequired: true,
      request: {
        url,
        method: "DELETE",
      },
      onSuccess() {
        dispatch({
          type: DELETE_MTVPAY_CARD_SUCCESS,
          payload: paymentMethod,
        })
        successCallBack && successCallBack()
      },
      onError(payload) {
        dispatch(openFlash(get(payload, "error.user_message", i18n.genericErrorMessage), null))
      },
    })
  }
}

export function setMtvPayStep(action, equivalenceCode) {
  return dispatch => {
    return dispatch({
      accessTokenRequired: true,
      request: {
        url: action.url,
        base: "",
        method: "GET",
      },
      onStart: () => {
        dispatch({
          type: GET_MTV_PAY_STEP_PENDING,
        })
      },
      onSuccess: payload => {
        dispatch({
          type: GET_MTV_PAY_STEP_SUCCESS,
          payload: {
            ...payload,
            template: action.template,
          },
          equivalenceCode,
        })
      },
      onError: payload => {
        dispatch({
          type: GET_MTV_PAY_STEP_FAILED,
          payload: stateHelper.errorPayload(payload.user_message),
        })
      },
    })
  }
}

export function fetchOfferPeriodicity(action) {
  return dispatch => {
    return dispatch({
      accessTokenRequired: true,
      request: {
        url: action.url,
        base: "",
        method: "GET",
      },
      onStart: () => {
        dispatch({
          type: GET_OFFER_PERIODICITY_PENDING,
        })
      },
      onSuccess: payload => {
        dispatch({
          type: GET_OFFER_PERIODICITY_SUCCESS,
          payload: {
            ...payload,
            template: action.template,
          },
        })
      },
      onError: payload => {
        dispatch({
          type: GET_OFFER_PERIODICITY_FAILED,
          payload: stateHelper.errorPayload(payload.user_message),
        })
      },
    })
  }
}
