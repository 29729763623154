import React, { Component } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import classNames from "classnames"
import FacebookLogin from "@molotovtv/react-facebook-login"

import Input from "components/Input"
import Button from "components/Button"
import InputSubmit from "components/InputSubmit"
import Alert from "components/Alert"
import Separator from "components/Separator"
import ButtonFacebookLogin from "components/ButtonFacebookLogin"
import ButtonGoogleLogin from "components/ButtonGoogleLogin"
import ButtonAppleLogin from "components/ButtonAppleLogin"
import Icon, { IconTypes } from "components/Icon"

import consts from "consts"
import i18n from "consts/i18n"
import routes from "consts/routes"

import localStorageHelper from "helpers/localStorage"
import { getAssetURL } from "helpers/static"
import { updateMeta, restoreDefaultMeta } from "helpers/meta"

import styles from "./index.css"

export default class PageLogin extends Component {
  static propTypes = {
    onClickRegister: PropTypes.func,
    authentication: PropTypes.object.isRequired,
    noAccountComponent: PropTypes.node,
    customStyle: PropTypes.string,
    loginFormDisabled: PropTypes.bool,
    errorMessages: PropTypes.object,
    showGoogleConnect: PropTypes.bool,
    title: PropTypes.node,
    fromOAuthPage: PropTypes.bool,

    onSubmit: PropTypes.func,
    onInputChange: PropTypes.func,
    onFacebookButtonClick: PropTypes.func,
    onFacebookResponse: PropTypes.func,
    onGoogleLogin: PropTypes.func,
    onAppleLogin: PropTypes.func,
    fromEchoShow: PropTypes.bool,
    showOpenEurope: PropTypes.bool,
  }

  static defaultProps = {
    fromEchoShow: false,
    showOpenEurope: false,
  }

  state = {
    email: "",
    errors: {},
    password: "",
    showPassword: false,
  }

  static getMeta() {
    return {
      og: {
        title: consts.i18n.pageTitle.public,
        type: "website",
        image: getAssetURL("image.pageHome.ogImage"),
        imageAlt: "Logo Molotov.tv",
        url: `${consts.externalLink.landing}${consts.routes.profile}`,
        imageHeight: 400,
        imageWidth: 588,
        description: "Connexion à votre compte",
      },
    }
  }

  onInputChange = (value, name) => {
    this.setState({
      errors: {
        ...this.state.errors,
        [name]: false,
      },
      [name]: value,
    })

    this.props.onInputChange(value, name)
  }

  onSubmit = ev => {
    ev.preventDefault()

    const errors = {
      email: this.state.email.length === 0,
      password: this.state.password.length === 0,
    }

    this.setState({ errors })

    if (Object.keys(errors).filter(e => errors[e]).length) {
      return
    }

    this.props.onSubmit(ev)
  }

  toggleShowPassword = () => {
    this.setState({
      showPassword: !this.state.showPassword,
    })
  }

  onClickRegister = () => {
    window.scrollTo(0, 0)
    this.props.onClickRegister()
  }

  getPasswordForgottenUrl() {
    return `${routes.resetPassword}${this.state.email ? `?email=${encodeURIComponent(this.state.email)}` : ""}`
  }

  constructor(props) {
    super(props)

    const lastEmail = localStorageHelper.getCachedData(consts.localStorageKey.lastEmailLogged)

    if (lastEmail) {
      this.state = {
        ...this.state,
        email: lastEmail ? lastEmail : "",
      }
      this.props.onInputChange(lastEmail, "email")
    }
  }

  componentDidMount() {
    updateMeta(PageLogin.getMeta())
  }

  componentWillUnmount() {
    restoreDefaultMeta()
  }

  render() {
    const {
      authentication,
      customStyle,
      loginFormDisabled,
      onFacebookButtonClick,
      onFacebookResponse,
      onGoogleLogin,
      onAppleLogin,
      errorMessages,
      title,
      fromEchoShow,
      showOpenEurope,
    } = this.props

    const { errors, showPassword } = this.state

    const googleEnabled = consts.config.googleEnabled && !fromEchoShow

    if (!__IS_CLIENT__) {
      return null
    }

    return (
      <div>
        <div className={classNames(styles.root, customStyle)}>
          {title || <h3 className={styles.title}>{i18n.loginPage.title}</h3>}
          {showOpenEurope && (
            <div className={styles.openEurope}>
              <strong>Rappel : </strong>
              {i18n.signupPage.openEurope}
            </div>
          )}
          <form onSubmit={this.onSubmit}>
            {!fromEchoShow && (consts.config.fbEnabled || googleEnabled || consts.config.appleEnabled) ? (
              <div>
                {googleEnabled && <ButtonGoogleLogin rootStyle={styles.googleButton} onGoogleResponse={onGoogleLogin} />}
                {consts.config.appleEnabled && (
                  <ButtonAppleLogin
                    rootStyle={classNames(styles.appleButton, { [styles.oauthButton]: consts.config.fbEnabled })}
                    onAppleResponse={onAppleLogin}
                  />
                )}
                {consts.config.fbEnabled && (
                  <div className={classNames(styles.facebookButton, { [styles.oauthButton]: consts.config.appleEnabled })}>
                    <ButtonFacebookLogin textButton="avec Facebook" onClick={onFacebookButtonClick} callback={onFacebookResponse} />
                  </div>
                )}
                {authentication.error && authentication.error === 112 && (
                  <Alert customStyle={styles.errorFacebook}>
                    {/* Display facebook link when email is not shared */}
                    <span>
                      {authentication.error.message.replace(i18n.loginPage.errorFacebookMissingEmailRetry, "")}
                      <FacebookLogin
                        appId={consts.config.facebookAppId}
                        autoLoad={false}
                        fields="email"
                        scope="email,user_birthday,public_profile,user_friends"
                        authType="rerequest"
                        textButton={i18n.loginPage.errorFacebookMissingEmailRetry}
                        callback={onFacebookResponse}
                        cssClass={styles.facebookRetry}
                      />
                    </span>
                  </Alert>
                )}
                <Separator text="ou" />
              </div>
            ) : null}
            <div className={styles.label}>{i18n.email}</div>
            <Input
              name="email"
              disabled={loginFormDisabled}
              rootStyle={classNames(styles.inputRoot, { [styles.invalid]: errors.email })}
              customStyle={classNames(styles.input, { [styles.inputError]: errors.email })}
              placeholder="exemple@email.com"
              value={this.state.email}
              onChange={this.onInputChange}
              autoComplete={"username"}
            />
            {errors.email && <div className={styles.errorMessage}>{errorMessages.ERR_LOGIN_MISSING_EMAIL}</div>}
            <div className={styles.label}>{i18n.loginPage.password}</div>
            <div className={styles.passwordWrapper}>
              <Input
                name="password"
                type={showPassword ? "text" : "password"}
                disabled={loginFormDisabled}
                placeholder={i18n.loginPage.password}
                rootStyle={classNames(styles.inputRoot, { [styles.invalid]: errors.password })}
                customStyle={classNames(styles.input, {
                  [styles.inputError]: errors.password,
                })}
                onChange={this.onInputChange}
                autoComplete={"current-password"}
              />
              {this.state.password.length ? (
                <Icon type={IconTypes.eye} onClick={this.toggleShowPassword} rootStyle={styles.showIconRoot} iconStyle={styles.showIcon} />
              ) : null}
            </div>
            {errors.password && <div className={styles.errorMessage}>{errorMessages.ERR_LOGIN_MISSING_PASSWORD}</div>}
            {authentication.error && authentication.error.code === 100 && <div className={styles.error}>{authentication.error.message}</div>}
            {authentication.error.code !== 112 && <div className={styles.error}>{authentication.error.message}</div>}
            <InputSubmit
              type="submit"
              disabled={loginFormDisabled}
              color={Button.colors.yellowFull}
              customStyle={classNames(InputSubmit.types.yellowFull, {
                [styles.button]: true,
                [styles.disabled]: loginFormDisabled || authentication.isPending,
              })}
              value={i18n.login}
            />
            <Link to={this.getPasswordForgottenUrl()} className={styles.forgottenPwd}>
              {i18n.loginPage.forgottenPassword}
            </Link>
          </form>
        </div>
        {this.props.noAccountComponent || (
          <div
            className={classNames(styles.connectLink, {
              [styles.oAuthNoAccount]: this.props.fromOAuthPage,
            })}
          >
            {this.props.fromOAuthPage ? i18n.loginPage.oAuthNotRegistredLabel : ""}
            <div
              className={classNames(styles.styleConnectLink, {
                [styles.oAuthRegisterLink]: this.props.fromOAuthPage,
              })}
              onClick={this.onClickRegister}
            >
              {this.props.fromOAuthPage ? i18n.loginPage.oAuthDoRegisterLabel : i18n.loginPage.noAccountLabel}
            </div>
          </div>
        )}
      </div>
    )
  }
}
