import React, { Component } from "react"
import { Link } from "react-router-dom"
import classNames from "classnames"
import { connect } from "react-redux"
import PropTypes from "prop-types"

import styles from "./index.css"

import consts from "consts"
import i18n from "consts/i18n"

import routes from "consts/routes"
import { getAssetURL } from "helpers/static"

import Button from "components/Button"
import ContentHeader from "components/ContentHeader"
import ContentBox from "components/ContentBox"

const notFountImage = getAssetURL("image.pageErrors.notFound")

@connect(state => {
  return {
    appSettings: state.appSettings,
  }
})
export default class Page500 extends Component {
  static propTypes = {
    appSettings: PropTypes.object.isRequired,
    rootStyle: PropTypes.string,
  }

  render() {
    return (
      <div className={classNames(styles.root, this.props.rootStyle)}>
        <ContentHeader title={<span>Oups !</span>} image={{ src: notFountImage }} />
        <div className={ContentHeader.above}>
          <ContentBox>
            <h3 className={ContentBox.hasTextCentered}>
              <div className={classNames(ContentBox.hasTextCentered, styles.text)}>
                Nous travaillons sans relâche pour résoudre le problème au plus vite. Nous vous prions de bien vouloir nous en excuser.
              </div>
              {// test if not inFrame
              !this.props.appSettings.frameless && (
                <span className={styles.header}>Rendez-vous sur la page d&#39;accueil ou consultez notre Aide en ligne.</span>
              )}
            </h3>
            {// test if not inFrame
            !this.props.appSettings.frameless && (
              <section className={styles.buttonsContainer}>
                <Link
                  to={routes.default}
                  onClick={e => {
                    e.preventDefault()
                    window.location.assign(window.location.href.replace(/(http(s?):\/\/[^\/]+\/).*/, "$1"))
                  }}
                >
                  <Button customStyle={styles.button} color={Button.colors.black} value={i18n.backToHome} />
                </Link>
                <Link
                  to={consts.externalLink.support}
                  onClick={e => {
                    e.preventDefault()
                    window.location.assign(consts.externalLink.support)
                  }}
                >
                  <Button customStyle={styles.button} color={Button.colors.black} value="Aide en ligne" />
                </Link>
              </section>
            )}
          </ContentBox>
        </div>
      </div>
    )
  }
}
