import { createSelector } from "reselect"
import i18n from "consts/i18n"

const selectMetrics = state => (state.user.data ? state.user.data.metrics : {})
const selectRecordMetrics = state => state.recordMetrics
const selectScheduledMetrics = state => {
  return state.scheduledMetrics
}

const getCurrentStorage = recordMetrics => {
  let currentStorage = 0

  recordMetrics.map(({ duration }) => {
    currentStorage += duration
  })

  return currentStorage
}

const getScheduledStorage = scheduledMetrics => {
  let scheduledStorage = 0

  scheduledMetrics.map(({ duration }) => {
    scheduledStorage += duration
  })

  return scheduledStorage
}

const getStorageMax = (isIllimited, currentStorage, maxRecordableDurationSeconds) => {
  return isIllimited ? currentStorage : maxRecordableDurationSeconds
}

export default createSelector(
  [selectMetrics, selectRecordMetrics, selectScheduledMetrics],
  (metrics, recordMetrics, scheduledMetrics) => {
    const isIllimited = metrics.max_recordable_duration === -1
    const recordMetricsData = recordMetrics.data || []
    const scheduledMetricsData = scheduledMetrics.data || []

    let metricsFormated = {
      pending: true,
    }

    const currentStorage = getCurrentStorage(recordMetricsData)
    const scheduledStorage = getScheduledStorage(scheduledMetricsData)
    const storageMax = getStorageMax(isIllimited, currentStorage + scheduledStorage, metrics.max_recordable_duration)

    metricsFormated = {
      isIllimited,
      currentStorage,
      scheduledStorage,
      storageMax,
      metrics: [...recordMetricsData],
      percentageUsage: Math.round((currentStorage / storageMax) * 100),
    }

    if (scheduledStorage) {
      metricsFormated.metrics.push({
        scheduled: true,
        name: i18n.storage.scheduled_storage,
        duration: scheduledStorage,
      })
    }

    return metricsFormated
  }
)
