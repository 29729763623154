import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"

import styles from "./index.css"

const Wave = ({ color, customStyle }) => {
  const circleStyle = {
    backgroundColor: color,
  }

  return (
    <div className={classnames(styles.waveContainer, customStyle)}>
      <div className={classnames(styles.circle, styles.c1)} style={circleStyle} />
      <div className={classnames(styles.circle, styles.c2)} style={circleStyle} />
      <div className={classnames(styles.circle, styles.c3)} style={circleStyle} />
    </div>
  )
}

Wave.propTypes = {
  color: PropTypes.string,
  customStyle: PropTypes.string,
}

export default Wave
