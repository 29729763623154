import { get } from "lodash"

const selectDspPayment = state => state.dspSub

// eslint-disable-next-line no-unused-vars
export const selectDspPaymentForProduct = (state, equivalenceCode) => {
  const dspPayment = selectDspPayment(state)

  return get(dspPayment, [equivalenceCode], {})
}
