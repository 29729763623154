import { createSelector } from "reselect"
import { get } from "lodash"
// Selectors
import selectAuthentication from "selectors/authentication"
import { selectInteraction } from "selectors/interaction"

// Helpers
import dateHelper from "helpers/date"
import formatterHelper from "helpers/formatter"

import consts from "consts"

const selectInvoices = state => state.mtvPay.invoices
const selectCards = state => state.mtvPay.cards

export const selectMtvPayAndPaypalInvoices = createSelector([selectInvoices, selectAuthentication], (mtvPayInvoices, authentication) => {
  return mtvPayInvoices
    ? {
        isPending: mtvPayInvoices.pending,
        data: getInvoices(mtvPayInvoices.invoices, authentication.accessToken),
      }
    : null
})

export const selectMtvPayCards = createSelector([selectCards], mtvPayPaymentMethod => {
  return mtvPayPaymentMethod
    ? {
        isPending: mtvPayPaymentMethod.pending,
        data: {
          cards: getPaymentMethod(get(mtvPayPaymentMethod, "details.cards")),
          wording: mtvPayPaymentMethod.wording,
        },
      }
    : null
})

export const selectMtvPayPaypal = createSelector([selectCards], mtvPayPaymentMethod => {
  return mtvPayPaymentMethod
    ? {
        isPending: mtvPayPaymentMethod.pending,
        data: {
          paypalAccounts: getPaymentMethod(get(mtvPayPaymentMethod, "details.paypal_accounts")),
        },
      }
    : null
})

export const selectRecurlyCard = createSelector([selectCards], mtvPayPaymentMethod => {
  return mtvPayPaymentMethod
    ? {
        isPending: mtvPayPaymentMethod.pending,
        data: {
          recurlyCard: getPaymentMethod(get(mtvPayPaymentMethod, "details.recurly")),
        },
      }
    : null
})

function getPaymentMethod(paymentMethods) {
  if (!paymentMethods) return null

  if (paymentMethods && paymentMethods[0]) {
    const paymentMethod = paymentMethods[0]

    return paymentMethod.email
      ? {
          email: paymentMethod.email,
        }
      : {
          id: paymentMethod.recurring_reference,
          type: paymentMethod.payment_method,
          number: paymentMethod.preview,
          expirationDate: `${dateHelper.leftPad(paymentMethod.expiry_month, 2)}/${paymentMethod.expiry_year}`,
        }
  }

  // Recurly
  if (paymentMethods.card_last_digits) {
    const { expiration_month, expiration_year } = paymentMethods
    const expirationDate = `${expiration_month}/${expiration_year}`
    return {
      type: paymentMethods.card_type,
      number: `**** **** **** ${paymentMethods.card_last_digits}`,
      expirationDate: `${expiration_month}`.length === 1 ? `0${expirationDate}` : expirationDate,
    }
  }

  //Recurly new - credit card
  if (paymentMethods.type === "credit_card") {
    const { card_type, card_last_digits, expiration_month, expiration_year } = paymentMethods.card
    const expirationDate = `${expiration_month}/${expiration_year}`

    return {
      type: card_type,
      number: `**** **** **** ${card_last_digits}`,
      expirationDate: `${expiration_month}`.length === 1 ? `0${expirationDate}` : expirationDate,
    }
  }

  //Recurly new - paypal
  if (paymentMethods.type === "paypal") {
    const { email } = paymentMethods.paypal

    return {
      type: paymentMethods.type,
      number: email,
    }
  }
}

function getInvoices(invoices = [], accessToken) {
  return invoices.map(invoice => {
    const date = invoice.Date * 1000

    return {
      amount: invoice.AmountString,
      date,
      download: `${formatterHelper.basic(
        `${consts.config.apiPath}${
          invoice.Platform === "molotovpay"
            ? consts.endpoints.mtvPayInvoice
            : invoice.Platform === "recurly"
            ? consts.endpoints.recurlyInvoice
            : consts.endpoints.paypalInvoice
        }`,
        {
          invoiceId: invoice.ID,
        }
      )}?access_token=${accessToken}`,
      id: invoice.ID,
      mode: invoice.Preview,
      paymentSuccessful: true, // force to true, if the payment fails the invoice won't be generated
      service: date,
      type: invoice.Platform,
    }
  })
}

const selectMtvPayPayment = state => state.mtvPay.payment

export const selectMtvPayPaymentForProduct = (state, equivalenceCode) => {
  const mtvPayPayment = selectMtvPayPayment(state)

  return mtvPayPayment[equivalenceCode] || {}
}

export const selectPaymentForm = (state, equivalenceCode) => {
  const mtvPayPayment = selectMtvPayPaymentForProduct(state, equivalenceCode)

  return mtvPayPayment.paymentForm
}

export const selectInterstitial = (state, equivalenceCode) => {
  const mtvPayPayment = selectMtvPayPaymentForProduct(state, equivalenceCode)

  if (mtvPayPayment.interstitial) {
    return {
      ...mtvPayPayment.interstitial,
      interaction: selectInteraction(mtvPayPayment.interstitial),
    }
  }
}

export const selectPaymentStep = (state, equivalenceCode) => {
  const mtvPayPayment = selectMtvPayPaymentForProduct(state, equivalenceCode)
  return mtvPayPayment.step
}

export const selectPaymentPending = (state, equivalenceCode) => {
  const mtvPayPayment = selectMtvPayPaymentForProduct(state, equivalenceCode)

  return mtvPayPayment.paymentPending
}

export const selectPasswordForm = (state, equivalenceCode) => {
  const mtvPayPayment = selectMtvPayPaymentForProduct(state, equivalenceCode)

  return mtvPayPayment.passwordForm
}

export const selectPasswordFormError = (state, equivalenceCode) => {
  const mtvPayPayment = selectMtvPayPaymentForProduct(state, equivalenceCode)

  if (mtvPayPayment.passwordError) {
    return { message: mtvPayPayment.passwordError }
  }
  return {}
}
