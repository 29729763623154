import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import styles from "./../index.css"

const IconCsa18 = ({ customStyle = null }) => (
  <svg viewBox="0 0 32 32" className={classNames(customStyle, styles.fill)}>
    <path d="M20.045 16.584c-1.055 0-1.57.91-1.57 2.706 0 1.932.487 2.87 1.57 2.87 1.084 0 1.57-.91 1.57-2.787 0-1.85-.486-2.79-1.57-2.79M20.045 13.913c.948 0 1.3-.69 1.3-2.152 0-1.463-.406-2.152-1.324-2.152-.894 0-1.275.662-1.275 2.015 0 1.573.325 2.29 1.3 2.29" />
    <path d="M15.874 2.092C8.87 2.092 3.1 7.47 2.318 14.398h4.814v3.322H2.33c.824 6.88 6.573 12.21 13.544 12.21 7.54 0 13.65-6.23 13.65-13.918 0-7.687-6.11-13.92-13.65-13.92zM12.98 24.598H9.75v-14.33H8.14V7.425h4.84v17.173zm7.065.22c-2.833 0-4.51-1.906-4.51-5.037 0-2.48.944-4.046 2.598-4.504v-.143c-1.347-.337-2.127-1.59-2.127-3.637 0-2.77 1.466-4.29 4.04-4.29 2.573 0 4.038 1.52 4.038 4.29 0 2.023-.78 3.3-2.125 3.637v.143c1.653.458 2.598 2.047 2.598 4.505 0 3.132-1.678 5.037-4.513 5.037z" />
  </svg>
)

IconCsa18.propTypes = {
  customStyle: PropTypes.string,
}

export default IconCsa18
