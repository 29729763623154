import { get } from "lodash"
import consts from "consts"

const deviceForwarder = {
  forward(payload, actionName, state) {
    // send actions to android
    if (window.Android && typeof window.Android[actionName] === "function") {
      window.Android[actionName](JSON.stringify(payload))
    }
    // send actions to ios
    else if (get(state, "appSettings.platform") === consts.platform.apple) {
      window.webkit.messageHandlers[actionName].postMessage(JSON.stringify(payload))
    }
    // send actions to js-front-app
    else if (global.parent && global.parent.postMessage) {
      global.parent.postMessage(
        {
          mtvPostOverlayActions: payload,
        },
        "*"
      )
    }
  },
}

export default deviceForwarder
