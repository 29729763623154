import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import classnames from "classnames"

import Icon, { IconTypes } from "components/Icon"

import consts from "consts"
import i18n from "consts/i18n"

import selectAuthentication from "selectors/authentication"
import selectorNavigator from "selectors/navigator"
import selectorAccount from "selectors/account"

import { set as setSignup } from "actions/signup"
import { toggleUserMenu, toggleMobileNav } from "actions/ui"
import { logout } from "actions/session"

import routesHelper from "helpers/route"
import formatterHelper from "helpers/formatter"
import dynamicAssetsHelper from "helpers/dynamicAssets"

import routes from "consts/routes"

import styles from "./index.css"
import routeHelpers from "helpers/route"

@connect(
  state => {
    return {
      account: selectorAccount(state),
      authentication: selectAuthentication(state),
      isGiftEnabled: consts.config.giftEnabled,
      navigator: selectorNavigator(state),
      locale: state.locale,
      scrollTop: state.ui.scrollTop,
      logoOffsetTop: state.ui.logoOffsetTop,
      headerMenuVisible: state.ui.headerMenuVisible,
      windowWidth: state.ui.windowWidth,
      showMobileNav: state.ui.showMobileNav,
    }
  },
  dispatch => ({
    setSignup: signup => {
      dispatch(setSignup(signup))
    },
    dispatch,
  })
)
export default class Header extends Component {
  static propTypes = {
    authentication: PropTypes.object,
    account: PropTypes.object,
    headerMenuVisible: PropTypes.bool,
    isGiftEnabled: PropTypes.bool,
    pathname: PropTypes.string.isRequired,
    detectDevice: PropTypes.func,
    setSignup: PropTypes.func,
    navigator: PropTypes.object,
    logoOnly: PropTypes.bool,
    dispatch: PropTypes.func,
    locale: PropTypes.object.isRequired,
    scrollTop: PropTypes.number,
    logoOffsetTop: PropTypes.number,
    showMobileNav: PropTypes.bool,
    windowWidth: PropTypes.number,
  }

  static defaultProps = {
    authentication: {},
    account: {},
    logoOnly: false,
  }

  state = {
    forceMobileMode: false,
  }

  toggleMobileNav = () => {
    this.props.dispatch(toggleMobileNav())
  }

  toggleHeaderMenu = (ev = null) => {
    // Stopping event propagation here allows to close the header menu
    // when the user click on anything but the menu itself or the link to toggle it.
    ev && ev.stopPropagation()
    this.props.dispatch(toggleUserMenu())
  }

  logout = () => {
    this.props.dispatch(logout())
  }

  onLogoClick(e) {
    if (this.props.pathname === routes.default) {
      e.preventDefault()
      window.scrollTo(0, 0)
    }
  }

  getIcon() {
    const leftIcon = {
      type: this.props.showMobileNav ? IconTypes.close : IconTypes.burger,
      rootStyle: this.props.showMobileNav ? styles.close : styles.burger,
      onClick: this.toggleMobileNav,
    }

    return <Icon key="icon" type={leftIcon.type} rootStyle={leftIcon.rootStyle} iconStyle={styles.icon} onClick={leftIcon.onClick} />
  }

  isHeaderActive = () => {
    const { pathname, scrollTop, logoOffsetTop } = this.props

    if (pathname === consts.routes.default) {
      if (!scrollTop || !logoOffsetTop || scrollTop <= logoOffsetTop) {
        return true
      }
    }

    return false
  }

  isLogoOnlyHeader() {
    return routesHelper.isDeeplinkPage(this.props.pathname) || this.props.logoOnly
  }

  onClickDownload = () => {
    // ugly workaround here, by default we should use the adjust link with fallback query for a desktop, but for some reason fallback isn't working from adjust side
    if (this.props.navigator.isMac || this.props.navigator.isWindows || this.props.navigator.isWindows64 || this.props.navigator.isLinux) {
      window.location = "/download?campaign=marketing_site"
    } else {
      window.location = routesHelper.getDownloadURL("marketing_site")
    }
  }

  // Adjust header mode "mobile" vs "desktop" to fix issue of too many links with unpredictable width in header breaking layout
  onWindowResize = () => {
    // 21 is for the margin
    if (this.navigationContainer) {
      const navWidth = Array.from(this.navigationContainer.childNodes).reduce((acc, el) => acc + el.offsetWidth + 21, 0)

      // 100 is for the logo size + a security margin
      if (!this.isLogoOnlyHeader() && navWidth + 100 > this.contentContainer.offsetWidth) {
        if (!this.state.forceMobileMode) {
          this.setState({
            forceMobileMode: true,
          })
        }
      } else if (this.state.forceMobileMode) {
        this.setState({
          forceMobileMode: false,
        })
      }
    }
  }

  componentDidMount() {
    this.onWindowResize()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.windowWidth !== this.props.windowWidth) {
      this.onWindowResize()
    }
  }

  render() {
    const { authentication, account, headerMenuVisible, isGiftEnabled, setSignup, pathname, locale, showMobileNav } = this.props

    const isHome = pathname === consts.routes.default

    const query = {
      utm_source: "molotovtv",
      utm_medium: "header",
      utm_campaign: "createaccount",
    }
    const parsedQuery = routeHelpers.objectToQuery(query)

    const notAuthenticatedUrl = `${consts.routes.newFunnel}?${parsedQuery}`

    if (routesHelper.isStrapiLandingPage(pathname)) {
      return <></>
    }

    const isAuthenticated = !!authentication?.accessToken

    return (
      <header
        className={classnames(styles.root, {
          [styles.rootHome]: isHome,
          [styles.rootHomeActive]: !this.isHeaderActive() || showMobileNav,
          [styles.forceMobileMode]: this.state.forceMobileMode,
        })}
      >
        {routesHelper.isTravelPage(pathname) || routesHelper.isSubscribePage(pathname) ? (
          <div
            className={classnames(styles.content, {
              [styles.noMaxWidthLimit]: !isAuthenticated,
            })}
            ref={el => (this.contentContainer = el)}
          >
            <div className={styles.navWrapper}>{this.getIcon()}</div>
            <Link
              className={classnames(styles.navElement, styles.logo, {
                [styles.logoHidden]: this.isHeaderActive(),
                [styles.logoLeft]: true,
              })}
              onClick={e => this.onLogoClick(e)}
              to={consts.routes.default}
            >
              <Icon type={IconTypes.logoWithFubo} iconStyle={classnames(styles.logoStyle, styles.logoStyleAlone)} />
            </Link>
            {!routesHelper.isSubscribePage(pathname) && (
              <Link className={styles.subscribe} to={`/subscribe/${consts.productEquivalenceCode.molotovExtra}?page=offer`}>
                Découvrir
              </Link>
            )}
          </div>
        ) : (
          <div
            className={classnames(styles.content, {
              [styles.contentWithAbsoluteLogo]: isHome,
            })}
            ref={el => (this.contentContainer = el)}
          >
            <div className={styles.navWrapper}>
              {this.getIcon()}
              <nav role="navigation" key="navigation" ref={el => (this.navigationContainer = el)}>
                <span className={classnames(styles.downloadLink, styles.navElement)} onClick={this.onClickDownload}>
                  Télécharger
                </span>
                <Link className={classnames(styles.navElement)} to={routes.hardware}>
                  Appareils
                </Link>
                <Link className={classnames(styles.navElement)} to={authentication.isAuthenticated ? routes.offers : routes.products}>
                  Offres
                </Link>
                {isGiftEnabled ? (
                  <Link className={classnames(styles.navElement)} to={routes.giftlist}>
                    {i18n.publicMenu.gift}
                  </Link>
                ) : null}
                <Link className={classnames(styles.navElement)} to={formatterHelper.basic(consts.routes.seoHome, { locale: locale.locale })}>
                  {i18n.publicMenu.seoHome}
                </Link>
                <Link className={classnames(styles.navElement)} to={formatterHelper.basic(consts.routes.movies, { locale: locale.locale })}>
                  {i18n.publicMenu.movies}
                </Link>
                <a
                  className={classnames(styles.navElement, styles.headerSubLogo)}
                  href={consts.externalLink.molotovTvAwards}
                  aria-label="molotov tv awards"
                >
                  <Icon type={IconTypes.molotovTvAwards} />
                </a>

                <a
                  className={classnames(styles.navElement, styles.headerLogo)}
                  href={dynamicAssetsHelper.getTopbarInformations().routeLink}
                  aria-label="molotov extra"
                >
                  <Icon
                    iconStyle={styles[dynamicAssetsHelper.getTopbarInformations().className]}
                    type={IconTypes[dynamicAssetsHelper.getTopbarInformations().logo]}
                  />
                </a>
                {!authentication.isAuthenticated && <span className={classnames(styles.navElement, styles.menu_separator)}>|</span>}
                {!authentication.isAuthenticated && (
                  <Link
                    className={styles.navElement}
                    to={routes.profile}
                    onClick={() => {
                      setSignup("")
                      window.dataLayer &&
                        window.dataLayer.push({
                          event: "gtm.custom_event",
                          event_name: "start_create_account",
                          event_category: "navigation_bar",
                          event_action: "log_in",
                        })
                    }}
                  >
                    Me connecter
                  </Link>
                )}
                {!authentication.isAuthenticated && (
                  <Fragment>
                    <span className={classnames(styles.navElement, styles.menu_separator)}>|</span>
                    <Link
                      className={classnames(styles.navElement)}
                      to={notAuthenticatedUrl}
                      onClick={() => {
                        setSignup("signup")
                        window.dataLayer &&
                          window.dataLayer.push({
                            event: "gtm.custom_event",
                            event_name: "start_create_account",
                            event_category: "navigation_bar",
                            event_action: "create_account",
                          })
                      }}
                    >
                      Créer un compte
                    </Link>
                  </Fragment>
                )}
                {!routesHelper.isPasswordChangePage(pathname) && authentication.isAuthenticated && (
                  <Fragment>
                    <span className={classnames(styles.navElement, styles.menu_separator)}>|</span>
                    <span onClick={this.toggleHeaderMenu} className={classnames(styles.navElement, styles.username)} data-test="user-menu">
                      {account.displayName}
                      <span className={classnames(styles.navElement, styles.downArrowhead)} />
                    </span>
                  </Fragment>
                )}
              </nav>
            </div>
            {isHome && <span className={classnames(styles.navElement, styles.dlIconMobile)} onClick={this.onClickDownload} />}
            <Link
              className={classnames(styles.navElement, styles.logo, { [styles.logoHidden]: this.isHeaderActive() })}
              onClick={e => this.onLogoClick(e)}
              to={consts.routes.default}
              aria-label="homepage"
            >
              <Icon type={IconTypes.logoWithFubo} iconStyle={styles.logoStyle} rootStyle={styles.iconLogoWrapper} />
            </Link>
            {!this.isLogoOnlyHeader() && authentication.isAuthenticated && headerMenuVisible && (
              <ul onClick={this.toggleHeaderMenu} className={styles.headerMenu}>
                <li>
                  <Link className={styles.navElement} to={routes.profile}>
                    {i18n.my_account}
                  </Link>
                </li>
                <li>
                  <a className={styles.navElement} href={consts.externalLink.support}>
                    {i18n.helpOnline}
                  </a>
                </li>
                <li>
                  <a className={classnames(styles.navElement, styles.logout)} onClick={this.logout}>
                    {i18n.logout}
                  </a>
                </li>
              </ul>
            )}
          </div>
        )}
      </header>
    )
  }
}
