import { GET_FREE_PRODUCT_PENDING, GET_FREE_PRODUCT_SUCCESS, GET_FREE_PRODUCT_FAILED } from "consts/actions"

import stateHelper from "helpers/state"

const initialState = {}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_FREE_PRODUCT_PENDING:
      return stateHelper.pending()

    case GET_FREE_PRODUCT_SUCCESS:
      return action.payload

    case GET_FREE_PRODUCT_FAILED:
      return { error: action.payload }

    default:
      return state
  }
}
