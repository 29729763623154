const defaultProductProps = {
  cancellation_success_message: {},
  caption_icons: [],
  color: "",
  feature_list: [],
  isSubscribe: false,
  isSubscribeWithAmazon: false,
  isSubscribeWithApple: false,
  isSubscribeWithGooglePlay: false,
  isSubscribeWithMolotovpay: false,
  price: 0,
  reinsurance: {},
  subscriptionEndAt: 0,
  subscriptionId: null,
  subscription_success_message: {},
  terms: {},
  thumbnails: [],
  wording: {},
}

export default defaultProductProps
