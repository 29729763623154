import { get } from "lodash"
import {
  SUBMIT_SETTINGS_PENDING,
  SUBMIT_SETTINGS_SUCCESS,
  SUBMIT_SETTINGS_FAILED,
  SUBMIT_CODE_PIN_SUCCESS,
  SUBMIT_CODE_PIN_FAILED,
  RESET_PARENTAL_CONTROL,
  SUBMIT_PASSWORD_CHECK_PENDING,
  SUBMIT_PASSWORD_CHECK_SUCCESS,
  SUBMIT_PASSWORD_CHECK_FAILED,
} from "consts/actions"

import stateHelper from "helpers/state"

const initialState = {}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SUBMIT_PASSWORD_CHECK_PENDING:
    case SUBMIT_SETTINGS_PENDING:
      return stateHelper.pending()

    case SUBMIT_SETTINGS_FAILED:
      return { error: action.payload }

    case SUBMIT_PASSWORD_CHECK_SUCCESS:
    case SUBMIT_SETTINGS_SUCCESS:
      return {
        ...state,
        pending: false,
        settings: {
          ...action.payload,
          pin_created: get(state.settings, "pin_created", action.payload.pin_created),
        },
      }

    case SUBMIT_CODE_PIN_SUCCESS:
      return {
        ...state,
        settings: {
          ...action.payload,
          ...state.settings,
          pin_created: action.payload.pin_created,
        },
      }

    case SUBMIT_CODE_PIN_FAILED:
      return {
        ...state,
        errorMessage: get(action, "payload.response.error.user_message"),
      }

    case SUBMIT_PASSWORD_CHECK_FAILED:
      return {
        ...state,
        pending: false,
        errorMessage: get(action, "payload.message"),
      }

    case RESET_PARENTAL_CONTROL:
      return initialState

    default:
      return state
  }
}
