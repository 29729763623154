import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

// Components
import BirthdayForm from "components/BirthdayForm"
import GenderForm from "components/GenderForm"
import Button from "components/Button"
import Separator from "components/Separator"

// Actions
import { googleLogin, appleLogin } from "actions/session"

// Consts
import styles from "./index.css"
import i18n from "consts/i18n"

@connect(state => ({
  socialLoginInfos: state.session.socialLoginInfos,
}))
export default class SignupInformations extends Component {
  static propTypes = {
    errorMessages: PropTypes.object,
    dispatch: PropTypes.func,
    socialLoginInfos: PropTypes.object,
  }

  state = {
    errors: {},
    day: "",
    month: "",
    year: "",
    gender: "",
  }

  onInputChange = (name, value) => {
    this.setState({
      errors: {
        ...this.state.errors,
        [name]: false,
        birthday: ["day", "month", "year"].indexOf(name) !== -1 ? false : this.state.errors.birthday,
      },
      [name]: value,
    })
  }

  onGenderChange = value => {
    this.setState({
      errors: {
        ...this.state.errors,
        gender: false,
      },
      gender: value,
    })
  }

  onSubmit = e => {
    e && e.preventDefault()

    const { day, month, year, gender } = this.state

    const errors = {
      birthday: day.length === 0 || month.length === 0 || year.length === 0,
      day: day.length === 0,
      month: month.length === 0,
      year: year.length === 0,
      gender: gender.length === 0,
    }

    this.setState({ errors })

    if (Object.keys(errors).filter(e => errors[e]).length) {
      return
    }

    const birthday = `${year}-${month}-${day}`

    const { socialLoginInfos } = this.props

    switch (socialLoginInfos.grantType) {
      case "google":
        this.props.dispatch(
          googleLogin(socialLoginInfos.token, socialLoginInfos.ssoAppId, socialLoginInfos.contextData, {
            birthday,
            gender,
          })
        )
        break
      case "apple":
        this.props.dispatch(
          appleLogin({ authorization: { id_token: socialLoginInfos.token } }, socialLoginInfos.contextData, {
            birthday,
            gender,
          })
        )
        break
      default:
        break
    }
  }

  render() {
    const { errorMessages } = this.props

    const { errors } = this.state

    return (
      <div className={styles.root}>
        <form onSubmit={this.onSubmit}>
          <h3 className={styles.title}>{i18n.loginPage.completeInformations}</h3>
          <Separator customStyle={styles.separator} />
          <div className={styles.description}>{i18n.loginPage.description}</div>
          <BirthdayForm errors={errors} errorMessages={errorMessages} onInputChange={this.onInputChange} />
          <GenderForm error={errors.gender} errorMessages={errorMessages} onChange={this.onGenderChange} gender={this.state.gender} />
          <Button color={Button.colors.yellowFull} value={i18n.save} onClick={this.onSubmit} customStyle={styles.button} />
        </form>
      </div>
    )
  }
}
