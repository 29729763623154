import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import styles from "./../index.css"

const ParentalControl = ({ customStyle = null }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className={classNames(customStyle, styles.parentalControl)}>
    <path
      fillRule="evenodd"
      d="M19.918 16.209a.712.712 0 01-.965.298l-.746-.403a.226.226 0 00-.333.228l.394 3.027a.566.566 0 01-.561.641h-.448a.566.566 0 01-.562-.483l-.193-1.193a.416.416 0 00-.412-.351.458.458 0 00-.465.377l-.185 1.159a.567.567 0 01-.561.473h-.448a.558.558 0 01-.562-.631l.518-4.687a.658.658 0 00-.254-.596l-2.493-1.896a.339.339 0 00-.544.307l.79 6.538a.863.863 0 01-.86.965h-.386a.988.988 0 01-.974-.816L9 15.436a.414.414 0 00-.404-.342.447.447 0 00-.448.351l-.755 3.739a.994.994 0 01-.965.79h-.491a.699.699 0 01-.694-.773L6 12.497a.354.354 0 00-.562-.325l-1.088.79a.833.833 0 01-1.044-.053c-.386-.342-.404-.904-.062-1.237l3.23-3.194c.298-.29.693-.457 1.115-.457h1.86c.421 0 .834.158 1.141.457l4.107 3.87c.01.008 4.942 2.922 4.942 2.922a.702.702 0 01.28.939zm-2.372-5.978c.65.079 1.176.605 1.255 1.255a1.453 1.453 0 01-1.632 1.632 1.453 1.453 0 01-1.255-1.255 1.45 1.45 0 011.632-1.632zM8.592 3.007a2.196 2.196 0 012.001 2 2.192 2.192 0 01-2.36 2.353A2.196 2.196 0 016.23 5.359a2.196 2.196 0 012.361-2.352z"
    />
  </svg>
)

ParentalControl.propTypes = {
  customStyle: PropTypes.string,
}

export default ParentalControl
