// This component is now only used for the mobile version of Landing website.
// It has no usage for mobile apps that have their own native menu (which redirects to individual Landing pages)
import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import classNames from "classnames"

import NavItem from "components/Nav/NavItem"

import { logout } from "actions/session"
import { set as setSignup } from "actions/signup"

import selectAuthentication from "selectors/authentication"
import selectorNavigator from "selectors/navigator"

import i18n from "consts/i18n"
import routes from "consts/routes"

import styles from "./index.css"
import consts from "consts"
import formatterHelper from "helpers/formatter"

@connect(
  state => {
    return {
      appSettings: state.appSettings,
      authentication: selectAuthentication(state),
      isGiftEnabled: consts.config.giftEnabled,
      navigator: selectorNavigator(state),
      locale: state.locale,
      showMobileNav: state.ui.showMobileNav,
    }
  },
  dispatch => ({
    logout: () => {
      dispatch(logout())
    },
    setSignup: signup => {
      dispatch(setSignup(signup))
    },
  })
)
export default class Nav extends Component {
  static propTypes = {
    customStyle: PropTypes.string,
    isGiftEnabled: PropTypes.bool,
    logout: PropTypes.func,
    setSignup: PropTypes.func,
    authentication: PropTypes.object,
    appSettings: PropTypes.object,
    location: PropTypes.object,
    navigator: PropTypes.object,
    locale: PropTypes.object.isRequired,
    showMobileNav: PropTypes.bool,
  }

  static defaultProps = {
    customStyle: "",
    appSettings: {},
  }

  makeNavItem = (data, index) => {
    if (!data) return null

    const { link, title, onClick, openExternal } = data

    return (
      <NavItem
        key={index}
        link={link}
        title={title}
        onClick={onClick}
        rootStyle={classNames({
          [styles.navItemMobile]: this.props.appSettings.frameType === consts.frameType.mobile || this.props.showMobileNav,
          [styles.navItemPageNav]: this.props.showMobileNav,
        })}
        openExternal={openExternal}
        showMobileNav={this.props.showMobileNav}
        location={this.props.location}
      />
    )
  }

  logout = () => {
    this.props.logout()
  }

  getNavItems = (publicMode = false) => {
    const {
      appSettings: { frameless },
      isGiftEnabled,
      setSignup,
      locale,
    } = this.props

    return publicMode
      ? [
          { link: routes.download, title: i18n.publicMenu.download },
          { link: routes.hardware, title: i18n.publicMenu.devices },
          { link: routes.products, title: i18n.publicMenu.offers },
          frameless || !isGiftEnabled ? null : { link: routes.giftlist, title: i18n.publicMenu.gift },
          { link: formatterHelper.basic(consts.routes.seoHome, { locale: locale.locale }), title: i18n.publicMenu.seoHome },
          { link: formatterHelper.basic(consts.routes.movies, { locale: locale.locale }), title: i18n.publicMenu.movies },
          { link: consts.externalLink.molotovTvAwards, title: i18n.publicMenu.molotovTvAwards, openExternal: true },
          { link: routes.profile, title: i18n.publicMenu.profile },
          {
            link: routes.signup,
            onClick: () => {
              setSignup("signup")
            },
            title: i18n.publicMenu.createAccount,
          },
        ]
      : [
          { link: routes.profile, title: i18n.nav.profile },
          { link: routes.offers, title: i18n.nav.products },
          frameless || !isGiftEnabled ? null : { link: routes.giftlist, title: i18n.publicMenu.gift },
          { link: routes.payment, title: i18n.nav.payment },
          { link: routes.parentalControl, title: i18n.nav.parentalControl },
          { link: routes.storage, title: i18n.nav.storage },
          { link: routes.notifications, title: i18n.nav.notifications },
          { link: routes.devices, title: i18n.nav.devices },
          { link: routes.tv, title: i18n.nav.tv },
        ]
  }

  authenticatedMenu() {
    const {
      customStyle,
      appSettings: { frameless },
      location: { pathname },
      showMobileNav,
      locale,
    } = this.props

    return (
      <div
        className={classNames(styles.root, customStyle, {
          [styles.rootMobile]: showMobileNav,
          [styles.isAccountPage]: !showMobileNav,
        })}
      >
        <div
          className={classNames(styles.nav, {
            [styles.navMobile]: showMobileNav,
            [styles.publicMenuWrapper]: this.needsWrapper(pathname),
          })}
        >
          {this.getNavItems(false).map(this.makeNavItem)}
        </div>
        <div
          className={classNames(styles.nav, {
            [styles.navMobile]: showMobileNav,
          })}
        >
          {[
            { link: formatterHelper.basic(consts.routes.seoHome, { locale: locale.locale }), title: i18n.publicMenu.seoHome },
            { link: formatterHelper.basic(consts.routes.movies, { locale: locale.locale }), title: i18n.publicMenu.movies },
            { link: consts.externalLink.molotovTvAwards, title: i18n.publicMenu.molotovTvAwards, openExternal: true },
            { link: routes.gtu, title: i18n.nav.cgu, openExternal: frameless },
            { link: routes.privacy, title: i18n.nav.privacy, openExternal: frameless },
            { link: routes.mentions, title: i18n.nav.mentions, openExternal: frameless },
          ].map(this.makeNavItem, this)}
          {!frameless && (
            <a className={styles.logout} onClick={this.logout} data-test="logout-link" aria-label="Logout link">
              {i18n.logout}
            </a>
          )}
        </div>
      </div>
    )
  }

  needsWrapper = pathname => {
    const pathsWrapperNeeded = [
      consts.routes.default,
      consts.routes.rickMorty,
      consts.routes.got,
      consts.routes.travel,
      consts.routes.mytho,
      consts.routes.westworld,
    ]
    return pathsWrapperNeeded.includes(pathname)
  }

  publicMenu() {
    const {
      location: { pathname },
    } = this.props

    return (
      <div className={classNames({ [styles.publicMenuWrapper]: this.needsWrapper(pathname) })}>{this.getNavItems(true).map(this.makeNavItem)}</div>
    )
  }

  render() {
    const { authentication } = this.props

    return authentication.isAuthenticated ? this.authenticatedMenu() : this.publicMenu()
  }
}
