import { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"

@connect(state => ({
  menus: state.ui.seoMenuVisible,
}))
export default class LazyMenuItem extends Component {
  static propTypes = {
    children: PropTypes.node,
    menus: PropTypes.object,
    menuId: PropTypes.string,
  }

  render() {
    const { children, menus, menuId } = this.props

    const open = menuId && menus[menuId] && menus[menuId].open

    if (!open) {
      return null
    }

    return children
  }
}
