const subscribers = {
  resize: [],
}

const runSubscribers = type => {
  subscribers[type].map(cb => cb())
}

const resizeListener = () => {
  runSubscribers("resize")
}

export default {
  onResize(cb) {
    const isListening = !!subscribers.resize.length

    if (subscribers.resize.indexOf(cb) < 0) {
      subscribers.resize.push(cb)
    }

    if (subscribers.resize.length && !isListening) {
      window.addEventListener("resize", resizeListener)
    }
  },
  removeResizeListener(cb) {
    const cbIndex = subscribers.resize.indexOf(cb)

    if (cbIndex >= 0) {
      subscribers.resize.splice(cbIndex, 1)
    }

    if (!subscribers.resize.length) {
      window.removeEventListener("resize", resizeListener)
    }
  },
}
