import { GET_SEO_LANDING_MENU_PENDING, GET_SEO_LANDING_MENU_SUCCESS, GET_SEO_LANDING_MENU_FAILED } from "consts/actions"

import stateHelper from "helpers/state"

import consts from "consts"

export function getSEOMenu() {
  return dispatch => {
    return dispatch({
      accessTokenRequired: false,
      request: {
        url: consts.endpoints.seolandingMenu,
        method: "GET",
      },
      cache: 15 * 60 * 1000,
      onStart: (payload, meta, dispatch) => {
        dispatch({
          type: GET_SEO_LANDING_MENU_PENDING,
        })
      },
      onSuccess: (payload, meta, dispatch) => {
        dispatch({
          type: GET_SEO_LANDING_MENU_SUCCESS,
          payload,
        })
      },
      onError: (payload, meta, dispatch) => {
        dispatch({
          type: GET_SEO_LANDING_MENU_FAILED,
          payload: stateHelper.errorPayload(payload.user_message, meta.httpCode),
        })
      },
    })
  }
}
