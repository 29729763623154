import { GET_DOWNLOAD_LINK_PENDING, GET_DOWNLOAD_LINK_SUCCESS, GET_DOWNLOAD_LINK_FAILED } from "consts/actions"

const initialState = {}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_DOWNLOAD_LINK_PENDING:
      return {
        ...state,
        isPending: true,
      }

    case GET_DOWNLOAD_LINK_SUCCESS:
      return {
        ...state,
        isPending: false,
        payload: action.payload,
      }

    case GET_DOWNLOAD_LINK_FAILED:
      return {
        ...state,
        isPending: false,
        error: action.error,
      }

    default:
      return state
  }
}
