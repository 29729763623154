import React from "react"
import PropTypes from "prop-types"

const IconFacebookFull = ({ customStyle = null }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className={customStyle}>
    <g fillRule="evenodd">
      <path d="M20 3H4a1 1 0 0 0-1 1v16c0 .6.4 1 1 1h8.6v-7h-2.3v-2.7h2.3v-2c0-2.3 1.4-3.6 3.5-3.6l2.1.1v2.5h-1.4c-1.2 0-1.4.5-1.4 1.3v1.7h2.7l-.3 2.7h-2.4v7H20c.6 0 1-.4 1-1V4c0-.6-.4-1-1-1z" />
    </g>
  </svg>
)

IconFacebookFull.propTypes = {
  customStyle: PropTypes.string,
}

export default IconFacebookFull
