import React, { Component } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import ResizeWatcher from "components/ResizeWatcher"

import styles from "./index.css"

export default class HeaderBackground extends Component {
  static propTypes = {
    customStyle: PropTypes.string,
  }

  static defaultProps = {
    customStyle: "",
  }

  state = {
    top: -1200,
  }

  handleResize = () => {
    const width = window.innerWidth
    let top = 0

    top = (width - 1280) / -2

    if (top > 0) {
      top = 0
    }

    if (width < 640) top = 79

    this.setState({
      top,
    })
  }

  componentDidMount() {
    this.handleResize()
  }

  render() {
    const { customStyle } = this.props

    const { top } = this.state

    return (
      <ResizeWatcher onResize={this.handleResize}>
        <div className={classNames(styles.root, customStyle)} style={{ top }} />
      </ResizeWatcher>
    )
  }
}
