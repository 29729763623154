import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import styles from "./index.css"

const CenteredContent = ({ className, children }) => <div className={classNames(className, styles.rootCenteredContent)}>{children}</div>

CenteredContent.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  className: PropTypes.string,
}

export default CenteredContent
