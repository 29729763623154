import {
  FETCH_PROMO_CODE_PAGE_SUCCESS,
  FETCH_PROMO_CODE_PAGE_ERROR,
  RESET_PROMO_CODE,
  SET_PRODUCT,
  VALIDATE_PROMO_CODE_SUCCESS,
} from "consts/actions"

import consts from "consts"

export const fetchPromoCodePage = () => {
  return {
    request: {
      url: `${consts.endpoints.promoCodeClaim}`,
      method: "GET",
    },
    onSuccess(payload) {
      return {
        type: FETCH_PROMO_CODE_PAGE_SUCCESS,
        payload: { data: payload },
      }
    },
    onError() {
      return {
        type: FETCH_PROMO_CODE_PAGE_ERROR,
      }
    },
  }
}

export const validatePromoCode = payload => {
  return {
    type: VALIDATE_PROMO_CODE_SUCCESS,
    payload,
  }
}

export const setProduct = payload => {
  return {
    type: SET_PRODUCT,
    payload,
  }
}

export const resetPromoCode = () => {
  return {
    type: RESET_PROMO_CODE,
  }
}

export const fetchPromocodeClaim = action => {
  return {
    request: {
      base: "",
      url: `${action.url}`,
      method: action.method,
    },
    onSuccess(payload) {
      return {
        type: FETCH_PROMO_CODE_PAGE_SUCCESS,
        payload: { data: payload },
      }
    },
    onError() {
      return {
        type: FETCH_PROMO_CODE_PAGE_ERROR,
      }
    },
  }
}
