const cookieHelper = {
  setCookie({ name, value, expires, domain, sameSite }) {
    let cookieString = `${name}=${value};`
    if (expires) {
      cookieString += `expires=${expires};`
    }
    if (domain) {
      cookieString += `domain=${domain};`
    }
    if (sameSite) {
      cookieString += `SameSite=${sameSite};`
    } else {
      cookieString += "SameSite=None;Secure;"
    }
    document.cookie = `${cookieString}path=/`
  },

  getCookieValue(name) {
    const fullCookieString = cookieHelper.findCookie(name)
    return fullCookieString && fullCookieString.split(`${name}=`).length === 2 ? fullCookieString.split(`${name}=`)[1] : ""
  },

  findCookie(name) {
    return (document.cookie.split(";").find(row => row.indexOf(name) > -1) || "").replace("path=/", "")
  },

  deleteCookie({ name, domain }) {
    cookieHelper.setCookie({ name, value: "", expires: "Thu, 01 Jan 1970 00:00:00 GMT", domain })
  },
}

export default cookieHelper
