import {
  GET_BOOKMARKS_RECORD_PENDING,
  GET_BOOKMARKS_RECORD_SUCCESS,
  GET_BOOKMARKS_RECORD_FAILED,
  SET_BOOKMARKS_RECORD,
  TOGGLE_BOOKMARK_RECORD,
  TOGGLE_BOOKMARKS,
} from "consts/actions"

import stateHelper from "helpers/state"

import bookmarkHelpers from "helpers/bookmark"

const initialState = {}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_BOOKMARKS_RECORD_PENDING:
      return stateHelper.pending()

    case TOGGLE_BOOKMARK_RECORD:
      return {
        ...state,
        data: bookmarkHelpers.getNewDataForToggle(state, action),
      }

    case TOGGLE_BOOKMARKS:
      return {
        ...state,
        data: bookmarkHelpers.getNewDataForToggle(state, action),
      }

    case SET_BOOKMARKS_RECORD:
      return {
        data: action.payload,
      }

    case GET_BOOKMARKS_RECORD_SUCCESS:
      return {
        data: bookmarkHelpers.formatData(action.payload),
      }

    case GET_BOOKMARKS_RECORD_FAILED:
      return { error: action.payload }

    default:
      return state
  }
}
