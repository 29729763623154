import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import { push } from "connected-react-router"

import PageOauthWrapper from "containers/PageOauthWrapper"
import Button from "components/Button"
import Loader from "components/Loader"
import PageLogin from "containers/PageLogin"
import CenteredContent from "components/CenteredContent"

// Actions
import { fetchOCSAuth, logout } from "actions/session"

// Selectors
import selectAuthentication from "selectors/authentication"
import selectAccount from "selectors/account"
import { selectErrorMessages } from "selectors/errorMessages"
import { selectQuery } from "selectors/location"

import helperFormatter from "helpers/formatter"

import styles from "./index.css"
import i18n from "consts/i18n"
import consts from "consts"

import routes from "consts/routes"

const noAccountComponent = (
  <span className={styles.noAccount}>
    {i18n.OAuthPage.notRegistered}
    <Link className={styles.registerLink} to={routes.signup}>
      {" "}
      {i18n.OAuthPage.doRegister}
    </Link>
  </span>
)

@connect((state, ownProps) => ({
  authentication: selectAuthentication(state),
  OCSSession: state.OCSSession,
  account: selectAccount(state),
  errorMessages: selectErrorMessages(state),
  query: selectQuery(ownProps),
}))
export default class PageOCSAuth extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    authentication: PropTypes.object,
    OCSSession: PropTypes.object,
    account: PropTypes.object,
    location: PropTypes.object,
    errorMessages: PropTypes.object,
    query: PropTypes.object.isRequired,
  }

  getOCSAuth = _props => {
    const props = _props || this.props

    const query = {
      ...props.query,
    }

    query.originalQuery = JSON.stringify(props.query)

    if (query.state_molotov) {
      query.state = query.state_molotov
    }

    props.dispatch(fetchOCSAuth(query))
  }

  goToOCSOffer = () => {
    this.props.dispatch(push(routes.publicOffer.replace(/:equivalenceCode/, consts.productEquivalenceCode.ocs)))
  }

  logout = () => {
    this.props.dispatch(logout())
  }

  getAccountChoiceComponent = email => {
    return (
      <div className={`${styles.content} ${styles.accountChoice}`}>
        <h3 className={styles.title}>{i18n.loginPage.title}</h3>

        <div className={styles.accountChoiceMessage}>{i18n.OCSPage.accountChoiceMessage}</div>
        <div className={styles.accountChoiceEmail}>{email}</div>
        <div className={styles.accountChoiceButtons}>
          <Button value={i18n.accountChoice.buttonNo} onClick={this.logout} color={Button.colors.greyFull} />
          <Button value={i18n.accountChoice.buttonYes} onClick={this.getOCSAuth} color={Button.colors.yellowFull} />
        </div>
      </div>
    )
  }

  getRedirectionComponent = () => {
    return (
      <div className={`${styles.content} ${styles.redirectionMessage}`}>{helperFormatter.sprintf(i18n.OAuthPage.redirectionMessage, ["OCS"])}</div>
    )
  }

  getErrorComponent = (errorMessage, showSubscribeButton) => {
    return (
      <div className={`${styles.content} ${styles.errorMessageBox}`}>
        <div className={styles.errorMessage}>{errorMessage}</div>
        <div className={styles.errorButtons}>
          <Button value={i18n.cancel} onClick={this.logout} color={Button.colors.greyFull} />
          {showSubscribeButton ? (
            <Button value={i18n.OCSPage.subscribeButton} onClick={this.goToOCSOffer} color={Button.colors.yellowFull} />
          ) : (
            <Button value={i18n.OCSPage.retryButton} onClick={this.getOCSAuth} color={Button.colors.yellowFull} />
          )}
        </div>
      </div>
    )
  }

  componentDidUpdate() {
    if (
      this.props.authentication.isAuthenticated &&
      this.props.OCSSession.choseProfile &&
      !this.props.OCSSession.pending &&
      !this.props.OCSSession.redirectionURL &&
      !this.props.OCSSession.error
    ) {
      this.getOCSAuth(this.props)
    }

    if (this.props.OCSSession.redirectionURL && !this.redirectionPlanned) {
      this.redirectionPlanned = true
      setTimeout(() => {
        document.location = this.props.OCSSession.redirectionURL
      }, 1000)
    }
  }

  render() {
    const { authentication, OCSSession, account, errorMessages, location } = this.props

    return (
      <PageOauthWrapper>
        {!authentication.isAuthenticated && (
          <CenteredContent>
            <PageLogin noAccountComponent={noAccountComponent} errorMessages={errorMessages} location={location} />
          </CenteredContent>
        )}
        {authentication.isAuthenticated && !OCSSession.choseProfile && this.getAccountChoiceComponent(account.email)}
        {authentication.isAuthenticated && OCSSession.choseProfile && OCSSession.pending && <Loader centerInParent={false} />}
        {authentication.isAuthenticated && OCSSession.redirectionURL && this.getRedirectionComponent()}
        {authentication.isAuthenticated && OCSSession.error && this.getErrorComponent(OCSSession.error.message, OCSSession.noOCSSubscription)}
      </PageOauthWrapper>
    )
  }
}
