import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import styles from "./../index.css"

const Csa18 = ({ customStyle = null }) => (
  <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className={classNames(customStyle, styles.csa18)}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.05 13H5.5v-2H2.05C2.55 5.947 6.814 2 12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10c-5.185 0-9.449-3.947-9.95-9zm5.45 5H10V6H6.5v2h1v10zm7.5.25c2.083 0 3.25-1.25 3.25-3.5 0-1.766-.75-2.75-2-3.25C17.5 11 18 10.203 18 8.75c0-1.99-1.109-3-3-3-1.892 0-3 1.01-3 3 0 1.47.5 2.25 1.75 2.75-1.25.5-2 1.468-2 3.25 0 2.25 1.167 3.5 3.25 3.5zm0-5.75c.797 0 1.25.752 1.25 2s-.454 2-1.25 2-1.25-.612-1.25-2c0-1.254.453-2 1.25-2zm0-2c-.5 0-1-.5-1-1.5 0-.972.286-1.5 1-1.5S16 8 16 9s-.5 1.5-1 1.5z"
      fill="#fff"
    />
  </svg>
)

Csa18.propTypes = {
  customStyle: PropTypes.string,
}

export default Csa18
