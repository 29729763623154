import { createSelector } from "reselect"

import { get } from "lodash"

const selectDomain = state => state.abTesting

export default createSelector([selectDomain], state => {
  return {
    pending: get(state, "pending"),
    data: get(state, "data"),
    error: get(state, "error"),
  }
})
