import React, { Component } from "react"
import PropTypes from "prop-types"

import Separator from "components/Separator"
import Button from "components/Button"

import i18n from "consts/i18n"
import helperFormatter from "helpers/formatter"

import styles from "./index.css"

export default class ProductWarning extends Component {
  static propTypes = {
    customStyle: PropTypes.string,
    hasExtraChannels: PropTypes.bool,
    footer: PropTypes.object,
    productTitle: PropTypes.string.isRequired,
    onCancel: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    hasBookmarkConstraints: PropTypes.bool,
    type: PropTypes.number,
    willLoseStorageOnUnsub: PropTypes.bool,
  }

  static defaultProps = {
    customStyle: "",
    hasBookmarkConstraints: false,
    willLoseStorageOnUnsub: false,
  }

  render() {
    const {
      customStyle,
      hasExtraChannels,
      footer,
      productTitle,
      onCancel,
      onValidate,
      hasBookmarkConstraints,
      type,
      willLoseStorageOnUnsub,
    } = this.props

    return (
      <div className={customStyle}>
        <h3 className={styles.title}>{i18n.productWarning.title}</h3>
        <Separator customStyle={styles.separator} />
        {type !== 4 && <div className={styles.resiliationIcon}>!</div>}

        <p className={styles.text}>{helperFormatter.sprintf(i18n.productWarning.confirmMessage, [productTitle])}</p>

        {willLoseStorageOnUnsub && (
          <p className={styles.text}>{helperFormatter.sprintf(i18n.productWarning.willReduceBookmarkCapacity, [productTitle])}</p>
        )}
        {type === 4 && <p className={styles.text}>{helperFormatter.sprintf(i18n.productWarning.cannotUnsubscribe, [productTitle])}</p>}
        {hasExtraChannels && <p className={styles.text}>{helperFormatter.sprintf(i18n.productWarning.willLosePrograms, [productTitle])}</p>}
        {hasBookmarkConstraints && <p className={styles.text}>{helperFormatter.sprintf(i18n.productWarning.willLoseBookmarks, [productTitle])}</p>}
        <Separator customStyle={styles.separator} />
        <div className={styles.buttons}>
          <Button color={Button.colors.greyFull} customStyle={styles.button} onClick={onCancel} value={i18n.cancel} />
          {type !== 4 && <Button color={Button.colors.yellowFull} customStyle={styles.rightButton} onClick={onValidate} value={i18n.validate} />}
        </div>

        {footer}
      </div>
    )
  }
}
