export default class StateHelper {
  static pending(isPending = true) {
    return {
      pending: isPending,
    }
  }

  static errorPayload(message, code) {
    return {
      message: message,
      code: code,
    }
  }
}
