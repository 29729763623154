import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import Icon, { IconTypes } from "components/Icon"

import styles from "./index.css"

const RadioButton = ({ name, value, valueDisplayed = value, subtitle = null, checked = false, customStyle, rootStyle, onChange = () => {} }) => {
  const id = `${name}_${value}`
  const iconType = checked ? IconTypes.radiobutton : IconTypes.radiobuttonOutline

  return (
    <span className={classNames(styles.radioWrapper, rootStyle)}>
      <Icon type={iconType} iconStyle={styles.icon} rootStyle={styles.rootIcon} />
      <input
        type="radio"
        id={id}
        name={name}
        value={value}
        checked={checked}
        className={styles.input}
        onChange={event => onChange(event.target.value)}
      />
      <label htmlFor={id} className={classNames(styles.label, customStyle)}>
        {valueDisplayed}
        {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
      </label>
    </span>
  )
}

RadioButton.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  valueDisplayed: PropTypes.string,
  customStyle: PropTypes.string,
  subtitle: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  rootStyle: PropTypes.string,
}

export default RadioButton
