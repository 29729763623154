import React from "react"

const IconFlag = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="31">
    <linearGradient id="flag-a" x1="50%" x2="50%" y1="5.04%" y2="33.69%">
      <stop offset="0%" stopColor="#FFE211" />
      <stop offset="100%" stopColor="#FFC107" />
    </linearGradient>
    <path
      fill="url(#flag-a)"
      fillRule="evenodd"
      d="M.75.96c0-.53.43-.96.95-.96h20.6c.52 0 .95.44.95.96v28.93c0 .52-.35.7-.76.38L12 22.43 1.51 30.27c-.42.32-.76.14-.76-.38V.96z"
    />
  </svg>
)

export default IconFlag
