import { APPLY_PRIVACY_SETTINGS, DISPLAY_COOKIE_BANNER, HIDE_COOKIE_SETTINGS, SET_PRIVACY_SETTINGS, TOGGLE_DIDOMI_BANNER } from "consts/actions"

import consts from "consts"

const initialState = {
  [consts.privacy.acceptTracking]: false,
  [consts.privacy.acceptAds]: false,
  displayCookieBanner: false,
  settingsApplied: false,
  toggleDidomiBanner: false,
}

export default function(state = initialState, action) {
  switch (action.type) {
    case APPLY_PRIVACY_SETTINGS:
      return {
        ...state,
        settingsApplied: true,
      }

    case DISPLAY_COOKIE_BANNER:
      return {
        ...state,
        displayCookieBanner: true,
      }

    case HIDE_COOKIE_SETTINGS:
      return {
        ...state,
        displayCookieBanner: false,
      }

    case SET_PRIVACY_SETTINGS:
      return {
        ...state,
        [consts.privacy.acceptTracking]: action.settings[consts.privacy.acceptTracking],
        [consts.privacy.acceptAds]: action.settings[consts.privacy.acceptAds],
      }

    case TOGGLE_DIDOMI_BANNER:
      return {
        ...state,
        toggleDidomiBanner: !state.toggleDidomiBanner,
      }

    default:
      return state
  }
}
