import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import classNames from "classnames"
import { get } from "lodash"

import SectionTitle from "components/SectionTitle"
import BorderBox from "components/BorderBox"

import routeHelper from "helpers/route"

import TrackingSegment from "managers/segmentManager"

import styles from "./index.css"

import consts from "consts"
import i18n from "consts/i18n"

@connect((state, ownProps) => {
  const legalName = ownProps.location.pathname.substr(7)
  const getProperty = (property, fallback = "") => {
    return get(state, ["references", "legal", legalName, property], fallback)
  }
  return {
    appSettings: state.appSettings,
    htmlText: getProperty("html_text"),
    title: getProperty("title", i18n.legal[legalName].title),
  }
})
export default class PageLegalText extends Component {
  static propTypes = {
    appSettings: PropTypes.object,
    location: PropTypes.object,
    title: PropTypes.string,
    htmlText: PropTypes.string,
  }

  componentDidMount() {
    TrackingSegment.trackPage("account_cgu")
  }

  render() {
    const {
      appSettings: { frameType },
      location: { pathname },
      htmlText,
      title,
    } = this.props

    const isAccountPage = routeHelper.isAccountPage(pathname)
    return (
      <BorderBox
        className={classNames({ [styles.darkBackground]: frameType === consts.frameType.tv })}
        navVisible={frameType !== consts.frameType.tv && isAccountPage}
      >
        <SectionTitle customStyle={classNames({ [styles.darkBackground]: frameType === consts.frameType.tv })} title={title} />
        <div
          className={classNames(styles.root, { [styles.darkBackground]: frameType === consts.frameType.tv })}
          dangerouslySetInnerHTML={{ __html: htmlText }}
        />
      </BorderBox>
    )
  }
}
